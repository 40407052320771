
<div class="bl-background">
  <figure class="bl-image-container">
    <img class="bl-image-container__img" src="../../../../assets/image/iso3 1.png" />
  </figure>
  <div class="bl-container">
    <p>Machine Learning to Support the Review of Audited Financial Statements</p>
  </div>

  <a
    *ngIf="userLoged"
    (click)="redirectTo()"
    class="bl-button--btn-secundary"
    title="Generate a new analysis"
    >Generate a new analysis</a
  >
  <a
    *ngIf="!userLoged"
    (click)="singIn()"
    class="bl-button--btn-secundary"
    title="Generate a new analysis"
    >Sign in Luca</a
  >
</div>
