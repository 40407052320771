<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->

<div class="bl-afs-review bl-wrapper">
  <p-toast class="bl-toast bl-toast--error" key="errorMessageAfsReview" closable="true"></p-toast>
  <div>
    <div class="bl-afs-review__header-wrapper">
      <div>
        <h1 class="bl-afs-review__header">AFS Review</h1>
        <span
          [ngClass]="
            analysisData?.status === 'In progress'
              ? 'bl-afs-review__header--status-progress'
              : 'bl-afs-review__header--status-completed'
          "
          >Status: {{ analysisData?.status }}</span
        >
      </div>
      <div class="bl-afs-review__header">
        <div class="bl-afs-review__header--analysis-id">
          <!-- <span>Analysis ID: </span>
          <span>{{ analysisData?.id }}</span> -->
        </div>
      </div>
    </div>
    <div>
      <div class="col-md-10 col-sm-12 bl-afs-review__group-data-wrapper">
        <div class="bl-afs-review__group-data">
          <span>Loan number: </span>
          <span class="bl-afs-review__group-data--strong-letter">{{
            analysisData?.operationNumber
          }}</span>
        </div>

        <div class="bl-afs-review__group-data">
          <span>Audit Period: </span>
          <span class="bl-afs-review__group-data--strong-letter"
            >{{ analysisData?.initDate?.toString() | slice:0:10 }} -
            {{ analysisData?.endDate?.toString() | slice:0:10 }}</span
          >
        </div>
        <div class="col-12 bl-afs-review__content-info">
          <div class="bl-afs-review__border-content">
            <bid-content-border-info
              [iconClass]="'far fa-clock'"
              [mainTxt]="((analysisData?.summary?.efaTimer || 0) + (analysisData?.summary?.icrTimer || 0)) | minsec"
              [contentTxt]="'Processing time'"
            >
            </bid-content-border-info>
            <!-- <bid-content-border-info *ngIf="analysisTime" [iconClass]="'far fa-clock'" [mainTxt]="
              analysisTime | minsec
              " [contentTxt]="'Processing time'">
            </bid-content-border-info> -->
          </div>

          <div>
            <!-- <bid-content-border-info
              [iconClass]="'fas fa-cogs'"
              [mainTxt]="(100 - analysisData?.score?.perc_manual | number:'1.1-1')"
              [type]="'%'"
              [contentTxt]="'Percent of test automated'"
            >
            </bid-content-border-info> -->
          </div>
        </div>
      </div>
      <div class="col-md-2 col-sm-12 bl-afs-review__select-custom-wrapper">
        <div>
          <div class="bl-select-custom">
            <p-dropdown
              [options]="referencesSelect"
              [(ngModel)]="referencesSelectItem"
              class="bl-select-custom__style"
            >
              <ng-template let-item pTemplate="selectedItem">
                <i class="{{ item.label }} bl-select-custom__style__icon"></i
                ><span class="bl-select-custom__style__value-select">{{ item.value }}</span>
              </ng-template>
              <ng-template pTemplate="item">
                <div class="bl-select-custom__style__content">
                  <span class="bl-select-custom__style__title">Document</span>
                  <a
                    class="bl-select-custom__style__link"
                    [href]="analysisData.efa.path"
                    target="_blank"
                  >
                    {{ analysisData.efa.name }}</a
                  >
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bl-afs-review__table-wrapper align-end">
    <bid-afs-review-table
      *ngIf="analysisData?.testResults"
      [headerTable]="headerTableAFS"
      [bodyTable]="analysisData?.testResults"
      [editable]="analysisData?.status === 'In progress'"
      [analysisId]="analysisId"
      [language]="language"
    ></bid-afs-review-table>
  </div>
</div>
