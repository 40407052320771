import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';
import { selects } from '@bl-constants/selects.const';
import { FindingCrudService } from '@core/icr-review/finding-crud.service';
import { MessageToastService } from '@core/messages/message-toast.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SummaryReportService } from '@core/summaryReport/summary-report.service';
//import { MatDialog } from '@angular/material';
//import { ModalInternalReviewComponent } from '../modal-internal-review/modal-internal-review.component';
//import { TooltipPosition } from '@angular/material/tooltip';
import { GetFindingsService } from '@core/icr/get-findings.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'bid-finding-content',
  templateUrl: './finding-content.component.html',
  styleUrls: ['./finding-content.component.scss'],
})
export class FindingContentComponent implements OnInit, OnChanges {
  @Input() public finding: any;
  @Input() public flatCreateFinding: any;
  @Input() public sendNotify: boolean = false;
  @Input() public isFinished: boolean = false;
  @Output() checkFinding = new EventEmitter();
  @Output() ActivateNotify = new EventEmitter<boolean>();
  @Output() ActivateFinish = new EventEmitter<boolean>();
  @Output() ActivateFinish_ = new EventEmitter<boolean>();
  @Output() ActivateAdd = new EventEmitter<string>();
 //positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  //position = new FormControl(this.positionOptions[0]);

  followUp: SelectItem[] = [];
  followUpSelected = [];
  followUpWith = [];
  bucketCombo: SelectItem[] = [];
  bucketComboSelected = [];
  //findingForm: FormGroup;
  showTextArea = false;
  showTextAreaRecommendation = false;
  templateFinding: string = ``;
  templateRecomen: string = '';
  beforeValueFinding: string = '';
  beforeValueRecom: string = '';
  pastFollowUpWith: any;
  checkSummaryReport: boolean;
  disabledBtn: boolean = false;
  comentsPTL: string = '';
  comentsPRM: string  = '';
  isPRM = false;
  isPTL = false;
  isFinishIcr = false;
  checkFolloeUpwith = [false, false];
  mouseoverRequestActionsPlan = false;
  blockSave = false;
  showIcrFeatures: boolean = environment.version != 1;
  IsActionMatrix = false;

  findingForm = new FormGroup({
    id: new FormControl(''),
    icrReviewId: new FormControl(''),
    risk: new FormControl('', Validators.required),
    bucket: new FormControl('', Validators.required),
    text: new FormControl('', Validators.required),
    recommendation: new FormControl(''),
    wordsRisk: new FormControl(''),
    wordsBucket: new FormControl(''),
    follow: new FormControl(''),
    followUpWith: new FormControl(''),
    comments: new FormControl(''),
    period: new FormControl('', Validators.required),
    reviewed: new FormControl(''),
    type: new FormControl(''),
    IsActionMatrix: new FormControl(false),
    checkFollow0: new FormControl(false),
    checkFollow1: new FormControl(false),
  });

  constructor(
    private findingCrudService: FindingCrudService,
    private messageToastService: MessageToastService,
    private messageService: MessageService,
    private summaryReportService: SummaryReportService,
    //private modalInternalReview: MatDialog,
    private getFindingsService: GetFindingsService,
  ) {
    this.checkSummaryReport = false;
  }

  ngOnInit() {
    this.findingCrudService.setPostion(null);
    this.bucketCombo = selects.pages.icr_review.select.bucket;
    this.followUp = selects.pages.icr_review.multiselects.followUpWith;
    if (this.finding) {
      this.getFinding(this.finding);
      this.checkSumaryReportExis();
      this.refreshComment();
    }
  }

  ngOnChanges(changes: any) {
     if (changes['isFinished']) {
      this.blockSave = changes['isFinished'].currentValue;
    }
  }

  checkSumaryReportExis() {
    this.summaryReportService.getGenerateSummaryReport().subscribe((res: any) => {
      this.checkSummaryReport = res;
    });
  }

  isEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  getFinding(data: any) {
    let validate_Notify = 0;
    let validate_Finish = 0;
    let validate_Finish1 = 0;
    let validate_total = 0;
    let validate_reviewed = 0;
    let isFinish = false;
    //console.log("getFinding", data)
    //if (data && data !== null && this.findingForm) {
    if (this.finding) {
      const _finding = this.finding.id;
      var analysisId = localStorage.getItem('analysisId') || '{}';
      this.getFindingsService.getFindings(JSON.parse(analysisId)).subscribe((res: any) => {
        const noContentResp = this.isEmpty(res);
        if (res && !noContentResp) {
          data = res;
          res.forEach((element: any) => {
            if (element.id == _finding) {
              this.finding = element;
            }
            if (element.reviewed == false) {
              validate_reviewed = validate_reviewed + 1;
            }
            if (element.reviewed == true && element.isNotify == false) {
              if (element.isPRM == true || element.isPTL == true) {
                validate_Notify = validate_Notify + 1;
              }
            }
            if (
              element.reviewed == true &&
              element.isNotify == false &&
              element.isPRM == false &&
              element.isPTL == false
            ) {
              validate_Finish = validate_Finish + 1;
            }
            if (element.reviewed == true && element.isNotify == true) {
              validate_Finish1 = validate_Finish1 + 1;
            }

            if (element.isFinishIcr == true) {
              validate_reviewed = validate_reviewed + 1;
              isFinish = true;
            }
            validate_total = validate_total + 1;
          });
          let is_Notify = validate_reviewed > 0 ? false : validate_Notify > 0 ? true : false;
          let is_Finish =
            validate_reviewed > 0
              ? false
              : validate_Finish == validate_total
                ? true
                : validate_Finish1 == validate_total
                  ? true
                  : validate_Finish1 + validate_Finish1 == validate_total
                    ? true
                    : false;
          this.ActivateNotify.emit(is_Notify);
          this.ActivateFinish.emit(is_Finish);
          this.ActivateFinish_.emit(isFinish ? false : validate_Finish1 > 0 ? true : false);
          this.ActivateAdd.emit('true');
          this.checkSummaryReport = false;
          this.checkSumaryReportExis();
          //this.refreshComment();
        }
      });

      this.findingForm.setValue({
        id: data.id,
        icrReviewId: data.icrReviewId,
        risk: data.risk,
        bucket: data.buckets,
        text: data.text,
        recommendation: data.recommendation ? data.recommendation : '',
        wordsRisk: data.word_risk,
        wordsBucket: data.word_buckets,
        follow: data.follow,
        followUpWith: this.getfollowUpWith(data.followUpWith).toString(),
        period: data.period,
        comments: data.comments,
        reviewed: data.reviewed,
        type: data.type,
        IsActionMatrix: data.isActionMatrix,
        checkFollow0: data.isPTL ? data.isPTL : false,
        checkFollow1: data.isPRM ? data.isPRM : false,
      });
      this.isFinishIcr = data.isFinishIcr ? data.isFinishIcr : false;

      this.builtFindingObservations(data);
      if (!this.flatCreateFinding) {
        this.beforeValueFinding = data.text;
        this.beforeValueRecom = data.recommendation;
      }
      this.beforeValueFinding = data.text;
      this.beforeValueRecom = data.recommendation;
      //}
    }
    this.showTextArea = this.flatCreateFinding ? true : false;
  }

  builtFindingObservations(data: any)
  {
    let specialWords;
    let orations;

    orations = (data.text || '').split('.');
    specialWords = data.word_risk !== '' ? data.word_risk.split(';') : [];
    /*if (specialWords.length >= 0) {
      this.templateFinding = this.parseFinding(orations, specialWords, data.risk);
    } else {
      this.templateFinding = data.text;
    }
    this.templateRecomen = data.recommendation ? data.recommendation : '';
    this.showTextArea = false;
    this.showTextAreaRecommendation = false;*/
  }

  parseFinding(orations: any, breakStr: any, kindRisk: string) {
    const addClass =
      kindRisk === 'High'
        ? '<span class="bl-scrollpanel__risk-high">'
        : '<span class="bl-scrollpanel__risk-low">';
    const closeTag = '</span>';
    orations.forEach((oration: string, i: number, or: any) => {
      breakStr.forEach((word: string) => {
        if (oration.search(word) > -1) {
          const findWork = oration.split(word);
          const result = this.joinPhrase(findWork, word, addClass, closeTag);
          or[i] = result;
        }
      });
    });
    return orations.join('.');
  }

  joinPhrase(data: any, word: string, addClass: string, closeTag: string) {
    let joinPhrase = '';
    for (let i = 0; i < data.length; i++) {
      const addingWork = addClass + '' + word + '' + closeTag;
      if (data.length - 1 !== i) {
        joinPhrase += data[i] + addingWork;
      } else {
        joinPhrase += data[i];
      }
    }
    return joinPhrase;
  }

  updateFinding()
  {
    this.showTextArea = !this.showTextArea;
    if (this.showTextArea === true) {
      this.beforeValueFinding = this.finding.text;
    }
  }

  refreshFinding()
  {
    this.findingForm.patchValue({
      text: this.beforeValueFinding,
    });
  }

  saveFinding()
  {
    this.findingForm.patchValue({
      text: this.finding.text,
    });
    this.builtFindingObservations(this.finding);
    this.updateFindingForm('disk');
    this.showTextArea = false;
  }

  updaterecommendation() {
    this.showTextAreaRecommendation = !this.showTextAreaRecommendation;
    if (this.showTextAreaRecommendation === true) {
      this.beforeValueRecom = this.finding.recommendation;
    }
  }

  refreshRecommendation() {
    this.findingForm.patchValue({
      text: this.finding.recommendation,
    });
  }

  saveRecommen() {
    this.findingForm.patchValue({
      text: this.finding.recommendation,
    });
    this.builtFindingObservations(this.finding);
    this.updateFindingForm('disk');
    this.showTextAreaRecommendation = false;
  }

  checkFollow() {
    if (!this.checkSummaryReport) {

      switch (this.findingForm.value.follow) {
        case 'Follow-up With':

          this.findingForm.patchValue({
            followUpWith: this.pastFollowUpWith.toString(),
          });

          this.findingForm.controls['followUpWith'].setValidators([Validators.required]);
          this.findingForm.controls['followUpWith'].updateValueAndValidity();

          break;
        case 'No Follow-up':
          this.pastFollowUpWith = this.findingForm.value.followUpWith || '';
          this.findingForm.patchValue({
            followUpWith: null,
          });
          this.findingForm.controls['followUpWith'].setValidators([]);
          this.findingForm.controls['followUpWith'].updateValueAndValidity();
          break;
      }
    }
  }

  save() 
  {
    if (this.finding.type === 'Manual' && !this.finding.id)
    {
      this.createFinding();
    } else {
      localStorage.setItem('lastFinding', JSON.stringify(this.finding));
      this.updateFindingForm('global');
    }
  }

  cancel() {
    if (this.flatCreateFinding) {
      this.findingForm.reset();
    } else {
      this.getFinding(this.finding);
    }
  }

  updateFindingForm(kind: string)
  {

    if (kind === 'disk')
    {
      this.findingForm.patchValue({
        reviewed: 'false',
        text: this.findingForm.value.text,
      });
      this.updateFindingFinal(this.findingForm.value, kind);
    } 
    else
    {
      const inputObj = 
      {
        id: this.finding.id,
        icrReviewId: this.finding.icrReviewId,
        risk: this.findingForm.value.risk,
        buckets: this.findingForm.value.bucket,
        text: this.findingForm.value.text,
        recommendation: this.findingForm.value.recommendation,
        word_risk: this.findingForm.value.wordsRisk,
        word_buckets: this.findingForm.value.wordsBucket,
        follow: this.findingForm.value.follow,
        followUpWith: this.parseToString(this.findingForm.value.followUpWith),
        comments: this.findingForm.value.comments,
        period: this.findingForm.value.period,
        reviewed: true,
        type: this.finding.type,
        IsActionMatrix: this.findingForm.value.IsActionMatrix,
        isPRM: this.isPRM,
        isPTL: this.isPTL,
        commentPTL: this.comentsPTL,
        commentPRM: this.comentsPRM,
      };

      this.findingForm.patchValue({
        reviewed: 'true',
      });
      this.builtFindingObservations(inputObj);
      this.updateFindingFinal(inputObj, kind);
    }
  }

  updateFindingFinal(data: any, kind: string) {
    this.findingCrudService.updateObservation(data).subscribe(
      (res: any) => {
        if (res === true) 
        {         
          this.showToastSuccess('Success', 'Finding added Successfully', 'successFormFinding');
          this.findingForm.patchValue({
              reviewed: 'false',
          });

          if(kind === 'global')
          {
            this.findingCrudService.setPostion(0);
          }

          this.templateFinding = '';
          this.showTextArea = false;
          this.showTextAreaRecommendation = false;
          this.findingForm.reset(this.findingForm.value);
          this.sendCheckFinding(this.findingForm);

        } 
        else 
        {
          this.showToastError(
            'An error was encountered when loading the data, please try again later 5',
            'errorMessageicr-review-form',
          );
        }
      },
      () => {
        this.showToastError(
          'An error was encountered when loading the data, please try again later 5',
          'errorMessageicr-review-form',
        );
      },
    );
  }

  createFinding() 
  {
    console.log("createFinding")
    const inputObj = {
      icrReviewId: this.findingForm.value.icrReviewId,
      risk: this.findingForm.value.risk,
      buckets: this.findingForm.value.bucket,
      text: this.findingForm.value.text,
      recommendation: this.findingForm.value.recommendation,
      word_risk: this.findingForm.value.wordsRisk,
      word_buckets: this.findingForm.value.wordsBucket,
      follow: this.findingForm.value.follow,
      followUpWith: this.parseToString(this.findingForm.value.followUpWith),
      comments: this.findingForm.value.comments,
      period: this.findingForm.value.period,
      reviewed: true,
      type: this.finding.type,
      IsActionMatrix: this.findingForm.value.IsActionMatrix, 
    };
    const analysisRecover = localStorage.getItem('analysisId') || '';
  console.log("INPUTOBJ", inputObj)
    this.findingCrudService.createObservation(inputObj, analysisRecover).subscribe(
      (res: any) => {
        if (res) {
          this.findingForm.patchValue({
            id: res.id,
            /*TODO: revisar valor reviewed */
            reviewed: 'true', 
          });
          this.sendCheckFinding(this.findingForm);
          this.findingCrudService.setPostion(0);
          this.showToastSuccess('Success', 'Created Successfully', 'successFormFinding');

          this.showTextArea = false;
          this.showTextAreaRecommendation = false;
          //this.templateFinding = this.findingForm.value.text;
          this.templateRecomen = this.finding.recommendation;
          this.findingForm.reset(this.findingForm.value);
          localStorage.setItem('lastFinding', JSON.stringify(res));
        } else {
          this.showToastError(
            'An error was encountered when loading the data, please try again later 7',
            'errorMessageicr-review-form',
          );
        }
      },
      () => {
        this.showToastError(
          'An error was encountered when loading the data, please try again later 8',
          'errorMessageicr-review-form',
        );
      },
    );
  }

  parseToString(arr: any) {
    let followUpWithStr = '';
    if (arr !== null) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].label !== '' && arr[i].label !== null) {
          if (i < arr.length - 1) {
            followUpWithStr += arr[i].label + ',';
          } else {
            followUpWithStr += arr[i].label;
          }
        }
      }
    }
    return followUpWithStr;
  }

  getfollowUpWith(str: string) {
    if (str !== null) {
      const splitfollowUpWith = (str || '').split(',');
      const arr = [];
      for (let i = 0; i < splitfollowUpWith.length; i++) {
        if (splitfollowUpWith[i] !== '') {
          const obj = {
            label: splitfollowUpWith[i],
            value: splitfollowUpWith[i],
          };
          arr.push(obj);
        }
      }
      if (arr.length > 0) {
        return arr;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  showToastSuccess(title: string, msg: string, key: string) {
    this.messageService.add({
      key,
      severity: 'success',
      summary: title,
      detail: msg,
    });
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }

  sendCheckFinding(findingChecked: any) {
    this.checkFinding.emit(findingChecked);
  }

  /*openInternalReviewModal() {
    var jsonparam = JSON.stringify({
      comentsPTL: this.comentsPTL,
      comentsPRM: this.comentsPRM,
      isPTL: this.isPTL,
      isPRM: this.isPRM,
      isFinishIcr: this.isFinishIcr,
    });

    const dialogRef = this.modalInternalReview.open(ModalInternalReviewComponent, {
      width: '600px',
      data: { term: jsonparam, comentsPTL: this.comentsPTL, comentsPRM: this.comentsPRM },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.comentsPTL = result.comentsPTL || '';
        this.comentsPRM = result.comentsPRM || '';
      }

      if (result && result.isSave) {
        this.save();
      }
    });
  }*/

  followUpChange(event: any, index: number) {
    if (event) {
      if (this.followUpSelected) {
        //this.followUpSelected.push(this.followUp[index]);
      }
    } else {
      this.followUpSelected = this.followUpSelected.filter(
        (item: any) => item.label != this.followUp[index].label,
      );
    }

    if (this.followUp[index].label == 'Project Team Leader') {
      this.isPTL = event;
    }
    if (this.followUp[index].label == 'PRM') {
      this.isPRM = event;
    }

    if (this.followUpSelected.length > 0) {
      this.findingForm.get('followUpWith')?.setValue(this.followUpSelected[0] ) ;
    } else {
      this.findingForm.value.follow = '';
    }
    if (
      this.findingForm.value.checkFollow0 == true ||
      this.findingForm.value.checkFollow1 == true
    ) {
      this.findingForm.value.follow = 'Follow-up With';
      this.findingForm.value.IsActionMatrix = true;
      this.findingForm.reset(this.findingForm.value);
    } else if (
      this.findingForm.value.checkFollow0 == false &&
      this.findingForm.value.checkFollow1 == false
    ) {
      this.findingForm.value.follow = 'No Follow-up';
      this.findingForm.value.IsActionMatrix = false;
      this.findingForm.reset(this.findingForm.value);
    }
  }
  showToastWarning(summary: string, detail: string) {
    this.messageToastService.showToastWarn('warn', summary, detail);
  }
  refreshComment() {
    this.isPRM = this.finding.isPRM;
    this.isPTL = this.finding.isPTL;
    this.comentsPRM = this.finding.commentPRM ? this.finding.commentPRM : '';
    this.comentsPTL = this.finding.commentPTL ? this.finding.commentPTL : '';
  }

  initCheckFollow() { }
  /*
  TODO: check method usage
  ngAfterViewInit() {
    this.sendNotify = this.finding && this.finding.isNotify ? true : false;
  }*/
}
