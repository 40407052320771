<p-toast class="bl-toast bl-toast--error" key="errorMessageicr-review-form" closable="true"></p-toast>
<p-toast class="bl-toast bl-toast--success" key="successFormFinding"></p-toast>
<div class="bl-finding-form">
  <form [formGroup]="findingForm">
    <div class="row">

      <div class="col-lg-4 col-md-6">
        <label class="bl-finding-form__label_inline--bold">Risk</label>
        <span> (Select the option)</span>
        <div class="bl-radiobutton">
          <div class="bl-radiobutton__block-line">
            <p-radioButton name="risk" value="Low" formControlName="risk"
              [disabled]="checkSummaryReport === true" [(ngModel)]="finding.risk"></p-radioButton>
            <label class="bl-radiobutton__label bl-radiobutton__bg--low">Low-medium</label>
          </div>
          <div class="bl-radiobutton__block-line--mrg-left">
            <p-radioButton name="risk" value="High" formControlName="risk"
              [disabled]="checkSummaryReport === true" [(ngModel)]="finding.risk"></p-radioButton>
            <label class="bl-radiobutton__label bl-radiobutton__bg--high">High</label>
            <button pTooltip="Select the level option" tooltipPosition="top" class="btn-i"
              aria-label="Select the level option">i
            </button>
          </div>
        </div>

        <br>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="bl-select">

          <label class="bl-select__label bl-select__label--big">Bucket Classification
            <button pTooltip="Select the Cube Classification option" tooltipPosition="top"
              class="btn-i" aria-label="Button that displays a tooltip in various positions">i
            </button>
          </label>
          <p-dropdown [options]="bucketCombo" [(ngModel)]="finding.buckets" formControlName="bucket"
            defaultLabel="select" styleClass="bl-dropdown" [disabled]="checkSummaryReport === true"></p-dropdown>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 t-padding-right-0 t-padding-left-0">
        <label class="bl-finding-form__label_inline--bold">Period
          <button pTooltip="Select the period option" tooltipPosition="top" class="btn-i"
            aria-label="Button that displays a tooltip in various positions">i
          </button></label>
        <div class="bl-radiobutton">
          <div class="bl-radiobutton__block-line">
            <p-radioButton name="period" value="Current" formControlName="period"
              [disabled]="checkSummaryReport === true" [(ngModel)]="finding.period">
            </p-radioButton>
            <label class="bl-radiobutton__label">Current</label>
          </div>
          <div class="bl-radiobutton__block-line--mrg-left">
            <p-radioButton name="period" value="Previous year" formControlName="period"
              [disabled]="checkSummaryReport === true" [(ngModel)]="finding.period">
            </p-radioButton>
            <label class="bl-radiobutton__label">Previous year</label>
          </div>
        </div>
      </div>
      <div class="bl-finding-form__required-fields">
        <span> <i class="fas fa-exclamation-circle"></i> All fields are required</span>
      </div>
    </div>
    <!-- observation/finding -->
    <div class="row">
      <div *ngIf="!showTextArea" class="col-11">
        <span class="bl-label__block--bold">Finding/Observations
          <button pTooltip="Enter Findings/Observations data" tooltipPosition="top" class="btn-i"
            aria-label="Button that displays a tooltip in various positions">i
          </button>
        </span>
        <textarea class="bl-textarea__area--size-medium" formControlName="text" [(ngModel)]="finding.text" style="max-height: 198px; height: 180px; outline: none;
          background-color: #f0fafe;" [readonly]="true"></textarea>
      </div>
      <div *ngIf="showTextArea" class="col-11">
        <div class="bl-textarea">
          <label class="bl-textarea__label--bold">Finding/Observations
            <button pTooltip="Enter Findings/Observations data" tooltipPosition="top" class="btn-i"
              aria-label="Button that displays a tooltip in various positions">i
            </button>
          </label>
          <textarea class="bl-textarea__area--size-medium" formControlName="text" [(ngModel)]="finding.text"
            [disabled]="checkSummaryReport === true"></textarea>
        </div>
      </div>
      <div class="col-1">
        <br>
        <a (click)="updateFinding()" class="bl-finding-form__color-icon"
          [class.disabled]="checkSummaryReport === true"><i class="fas fa-pen"></i></a>
        <br>
        <a (click)="refreshFinding()" [class.disabled]="
              (!showTextArea && findingForm.status === 'INVALID') ||
              (findingForm.controls && findingForm.controls.text.pristine)
            " class="bl-finding-form__color-icon"><i class="fas fa-undo-alt"></i></a>
        <br>
        <a (click)="saveFinding()" [class.disabled]="
              !showTextArea ||
              (flatCreateFinding && findingForm.status === 'INVALID') ||
              (findingForm.controls && findingForm.controls.text.pristine)
            " class="bl-finding-form__color-icon">
          <i class="far fa-save"></i>
        </a>
      </div>
    </div>
    <!-- comments -->
    <div class="row">
      <div *ngIf="!showTextAreaRecommendation" class="col-11">
        <span class="bl-label__block--bold">Recommendation
          <button pTooltip="Enter Recommendation data" tooltipPosition="top" class="btn-i"
            aria-label="Enter recommendation data">i
          </button>
        </span>
        <textarea class="bl-textarea__area--size-medium" formControlName="recommendation"
          [(ngModel)]="finding.recommendation" style="max-height: 198px; height: 180px; outline: none;
        background-color: #f0fafe;" [readonly]="true"></textarea>
      </div>
      <div *ngIf="showTextAreaRecommendation" class="col-11">
        <div class="bl-textarea">
          <label class="bl-textarea__label--bold">Recommendation
            <button pTooltip="Enter Recommendation data" tooltipPosition="top" class="btn-i"
              aria-label="Button that displays a tooltip in various positions">i
            </button>
          </label>
          <textarea class="bl-textarea__area--size-medium" formControlName="recommendation"
            [(ngModel)]="finding.recommendation" [disabled]="checkSummaryReport === true"></textarea>
        </div>
      </div>
      <div class="col-1">
        <br>
        <a (click)="updaterecommendation()" class="bl-finding-form__color-icon"
          [class.disabled]="checkSummaryReport === true"><i class="fas fa-pen"></i></a>
        <br>
        <a (click)="refreshRecommendation()" [class.disabled]="
              (!showTextAreaRecommendation && findingForm.status === 'INVALID') ||
              ( findingForm.controls && findingForm.controls.text.pristine)
            " class="bl-finding-form__color-icon"><i class="fas fa-undo-alt"></i></a>
        <br>
        <a (click)="saveFinding()" [class.disabled]="
              !showTextAreaRecommendation ||
              (flatCreateFinding && findingForm.status === 'INVALID') ||
              ( findingForm.controls && findingForm.controls.text.pristine )
            " class="bl-finding-form__color-icon">
          <i class="far fa-save"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-3 t-padding-top-0" *ngIf="showIcrFeatures">
        <div class="bl-radiobutton">
          <div class="bl-radiobutton__block-group">
            <div class="t-padding-top-15" *ngIf="!sendNotify">
              <label class="bl-label__block--bold">Request Comment With
              </label>
              <button pTooltip="Enter comment data" tooltipPosition="top" class="btn-i"
                aria-label="Button that displays a tooltip in various positions">i
              </button>
              <div *ngFor="let followUp of followUp; let z = index" class="bl-checkbox nowrap">
                <br>
                <p-checkbox label="{{followUp.label}}" (onChange)="followUpChange($event,z)"
                  [formControlName]="'checkFollow'+z" binary="true">
                </p-checkbox>
              </div>
            </div>

            <br><br>
            <!--button *ngIf="sendNotify" class="bl-button--btn-secundary bl-button--btn-secundary"
              (click)="openInternalReviewModal()">Internal review</button-->
          </div>
        </div>
      </div>
      <div [ngClass]="(showIcrFeatures)?'col-9 t-padding':'col-11'">
        <div class="bl-textarea t-padding-top-35">
          <label class="bl-label__block--bold">Comments</label>
          <textarea class="bl-textarea__area--size-medium" placeholder="" formControlName="comments"
            bidDisableControl="{{ checkSummaryReport === true }}" [(ngModel)]="finding.comments"></textarea>
        </div>
        <div class="bl-finding-form__btn-location-right">
          <div (mouseover)="mouseoverRequestActionsPlan=true" (mouseout)="mouseoverRequestActionsPlan=false"
            class="check-actions-plan">
            <mat-checkbox formControlName="IsActionMatrix" [(ngModel)]="finding.isActionMatrix" [disabled]="sendNotify">
              Request Action Plan
              <button pTooltip="Select if you require an Action Plan" tooltipPosition="top"
                class="btn-i" aria-label="Button that displays a tooltip in various positions">i
              </button>
            </mat-checkbox>
            <div>
              <span *ngIf="mouseoverRequestActionsPlan" class="label-mouseover">Enable the request for the action
                plan</span>
            </div>
          </div>
          <button (click)="cancel()" type="submit"
            class="bl-button--btn-cancel bl-finding-form__btn-location-right--spacing-right" [disabled]="
              findingForm.status === 'INVALID' || blockSave === true
            ">
            Cancel
          </button>
          <button (click)="save()" type="submit" class="bl-button--btn-save" [disabled]="
              findingForm.status === 'INVALID' ||
              blockSave === true
            ">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>