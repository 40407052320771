import { Component, OnInit } from '@angular/core';
import { usersAdmins } from '@bl-interfaces/usersAdmins';
//import { MatTableDataSource } from '@angular/material/table';
import { adminUsers } from '@core/admin-users-services/admin-users.services';
//import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx'
type AOA = any[][];

@Component({
  selector: 'bid-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  dataSource: any;
  ELEMENT_DATA: usersAdmins[] = [];

  accessLevels = [
    {value:'NORMAL ACCESS',label:'NORMAL ACCESS'},
    {value:'RESTRICTED ACCESS',label:'RESTRICTED ACCESS'},
    {value:'FULL ACCESS',label:'FULL ACCESS'},

  ]

  displayedColumns: string[] = [
    'id',
    'name',
    'email',
    'accessLevel',
    'accessFMP',
    'lastUpdate',
    'updateFor'
  ];

  jsonUploadFile: any = [];
  
  constructor(
    private _userAdminServices : adminUsers,
    //private spinner:NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(){
    this._userAdminServices.getExternalUserList()
      .subscribe(
        (res:any)=>{
          this.ELEMENT_DATA = res.map((item: any,index:number)=>{
            return {
              id: index+1,
              name:item.displayName,
              country:item.country,
              organization: item.organization,
              rol: item.rol,
              type:item.type,
              status: item.status,
              email: item.mail,
              accessLevel: item.levelAccess,
              accessFMP: item.accessFMP,
              lastUpdate: item.lastUpdate,
              updateFor: item.modifiedBy
            }
     
          })
       
         //this.dataSource = new MatTableDataSource<usersAdmins>(this.ELEMENT_DATA);
        }
      )
  }

  addExternalUser(json: any){
    //this.spinner.show();
    this._userAdminServices.addExternalUser(json)
      .subscribe(
        (res)=>{
          console.log('res:',res);
        },
        (error)=>{
          console.log('error',error)
        }
      )
  } 

  upDateExternalUser(json: any){
    this._userAdminServices.upDateExternalUser(json)
      .subscribe(
        (res)=>{
          //this.spinner.hide();
          this.getUsers();
        },
        (error)=>{
          //this.spinner.hide();
        }
      )
  }

  daleteExternalUser(json: any){
    this._userAdminServices.daleteExternalUser(json)
      .subscribe(
        (res)=>{
          
        },
        (error)=>{

        }
      )
  }

  saveAccessFMP(event: any){
    console.log('event:',event);
    var user = JSON.parse(localStorage.getItem('rolUserLocalStorage') || '{}');
    console.log('users',user)
    var date = new Date();
    var json = {
      displayName: event.value.name,
      country: event.value.country,
      mail: event.value.email,
      organization: event.value.organization,
      type: event.value.type,
      rol: event.value.rol,
      status: event.value.status,
      accessFMP: event.value.accessFMP,
      lastUpdate: date,
      levelAccess: event.value.levelAccess,
      modifiedBy: user.displayName
    }
   
   this.upDateExternalUser(json);
  }

  downLoad(){

  }

  loadFile(evt: any){
    this.jsonUploadFile = [];
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[2];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      //this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(XLSX.utils.sheet_to_json(ws, { header:2,blankrows:false }));

     XLSX.utils.sheet_to_json(ws, { header:2,blankrows:false })
      .forEach((item: any,index)=>{
        if(index > 0 ){
          this.jsonUploadFile.push(
            {
              displayName: item.__EMPTY_1,
              mail: item.__EMPTY_2,
              accessFMP: item.__EMPTY_4=='NO'?false: true,
              rol: item.__EMPTY_5,
              type: item.__EMPTY_5,
              levelAccess: item.__EMPTY_3,
              modifiedBy: item.__EMPTY_6 ,
              status: true,
              organization: item.__EMPTY_7 ,
              country: item.__EMPTY_8 ,
            }
          )
        }
      })
    };
    reader.readAsBinaryString(target.files[0]);
  }

  sendMasiveUsers(){
    //this.spinner.show()

    this._userAdminServices.bulkLoad(this.jsonUploadFile)
      .subscribe(
        (res)=>{
          //this.spinner.hide();
          this.getUsers();
        },
        (error)=>{
          //this.spinner.hide();
        }
      )
  }
}
