import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReviewTimeService {
  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  saveReviewTime(analysisId: string, time: any) {
    return this.httpClient.patch(environment.api.base + 'efas/' + analysisId + '/save-time?seconds=' + time, {});
  }
}
