export const templateSuccess = {
  afsReviewTemplate: `Go to AFS Review`,
  afsReviewLink: `/AfsReview`,
  scoreTemplate: `Go to Score`,
  scoreLink: `/score`,
  icrReviewTemplate: `Go to ICR Review`,
  icrReviewLink: `/IcrReview`,
  updateActionPlanTemplate: `Go to Update Action Plan`,
  //updateActionPlanLink: `/UpdateActionPlan`,
};
