<div class="bl-user-button">
  <a class="user" (click)="menu.toggle($event); hideAndShow()" [title]="user.displayName">
    <span *ngIf="user?.mail"  class="bl-user-button__email">{{user.mail}}</span>
    <span  *ngIf="user?.mail === ''" class="bl-user-button__email"></span>
    <!--i class="fas fa-user-circle"></i-->
    <p-avatar icon="pi pi-user" styleClass="mr-2" size="xlarge" shape="circle"></p-avatar>
    <div class="bl-user-button__pos-arrow-up">
      <i class="pi pi-angle-double-down"></i>
    </div>
  </a>
  <div class="bl-slide-menu bl-slide-menu--medium-size">
    <p-slideMenu #menu [model]="items" [popup]="true" appendTo="body"></p-slideMenu>
  </div>
</div>
