import { Component, Input } from '@angular/core';
import { TestDictionaryService } from '@core/test-dictionary/test-dictionary.service';
import { MessageToastService } from '@core/messages/message-toast.service';

@Component({
  selector: 'bid-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  @Input() header: any;
  @Input() chips: any;
  @Input() id: any;
  @Input() existingParameter: any;
  @Input() addingParameter: any;
  display: boolean;
  data: any = [];
  item: any;
  index: number = -1;
  constructor(
    private testDictionaryService: TestDictionaryService,
    private messageToastService: MessageToastService,
  ) {
    this.display = false;
  }

  removeWords() {
    this.display = true;
  }

  removeWordsDctionary(data: any, item: any, i: number) {
    this.display = true;
    this.data = data;
    this.item = item;
    this.index = i;
  }

  confirmRemoveWordsDctionary() {
    this.display = false;
    this.testDictionaryService.removeParameter(this.id, false, [this.item]).subscribe(
      (res: any) => {
        if (res) {
          this.data.splice(this.index, 1);
        } else {
          this.showToastError(
            'An error occurred while deleting this parameter. try again later.',
            'errorMessageTestDictionaryDetail',
          );
        }
      },
      () => {
        this.showToastError(
          'An error occurred while deleting this parameter. try again later.',
          'errorMessageTestDictionaryDetail',
        );
      },
    );
  }

  cancelRemoveParameter() {
    this.display = !this.display;
  }

  showToastError(summary: string, key: string) {
    this.messageToastService.showToastError('error', summary, key);
  }
}
