import { Component, OnInit, DoCheck } from "@angular/core";
import { mainMenu, mainMenuInvited } from "@bl-constants/main-menu.const";
import { StepMenu } from "../../interfaces/step-menu";
import { StepperService } from "@core/stepper/stepper.service";
import { UserService } from "@core/user/user.service";
import { Observable } from "rxjs";
import { Role } from "@bl-enum/role";
import { environment } from "@environments/environment";
import { AuthenticationService } from "@core/authentication/authentication.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, DoCheck {
  menu: any;
  steps: StepMenu[] = [];
  refresh: boolean = false;
  activeNav: boolean = true;
  disabledHeader = false;
  isAdmin$: Observable<boolean>;
  isUs$!: Observable<[]>;
  loadingHeader = "Loading";
  isExternalUser: boolean = false;
  isLogged: boolean = false;
  rolUser: any;
  canSearch;
  _stepperService: any;
  _userService: any;
  _authService: any;
  isCollapsed: boolean =false;
  constructor(
    private stepperService: StepperService,
    private userService: UserService,
    private authService: AuthenticationService
  ) {
    this.isAdmin$ = this.userService.getIsAdminUser();
    this.canSearch = false;
    this._stepperService = stepperService;
    this._userService = userService;
    this._authService = authService;
  }
  ngOnInit() {
    this.isExternalUser = false;
    const accounts = this.authService.msalService.instance.getAllAccounts();
    let username = '';
    if(accounts.length != 0){
      username = accounts[0].username;
    }
    this.userService
      .getUserByMail(username)
      .subscribe((objeto: any) => {
        this.rolUser = objeto;
        this.isLogged = true;
        this.userService.setUserData(objeto);

        // localstorage
        if (objeto.role == Role.ADMIN || objeto.role == Role.INVITED) {
          this.activeNav = true;
          this.canSearch = true;
          this.menu = mainMenu.mainMenu;
          this.steps = mainMenu.steps;
          console.log("menu", this.menu)

          /*if (environment.version == 1) {
            this.menu.pop();
          }*/

          if (performance.navigation.type === 1) {
            const strStepsLocalStorage =
              localStorage.getItem("stepsLocalStorage") || '{}';
            this.steps = JSON.parse(strStepsLocalStorage);
          } else {
            this.stepperService.getSteper().subscribe((res: any) => {
              if (res && this.steps) {
                console.log("steps", this.steps);

                for (let i = 0; i < this.steps.length; i++) {
                  for (let z = 0; z < res.length; z++) {
                    if (res[z].name === this.steps[i].name) {
                      this.steps[i].stepSelected = res[z].stepSelected;
                      if (res[z].name === "afsReview") {
                        this.steps[i].disabled = res[z].disabled;
                      }
                      if (res[z].name === "score") {
                        this.steps[i].disabled = res[z].disabled;
                      }
                      if (res[z].name === "icrReview") {
                        this.steps[i].disabled = res[z].disabled;
                      }
                    }
                  }
                }
                const stringSteps = JSON.stringify(this.steps);
                localStorage.setItem("stepsLocalStorage", stringSteps);
              } else {
                const strStepsLocalStorage =
                  localStorage.getItem("stepsLocalStorage") || '{}';
                this.steps = JSON.parse(strStepsLocalStorage);
              }
            });
          }
        }  

        const rolUser = JSON.stringify(objeto);
        localStorage.setItem("rolUserLocalStorage", rolUser);
      });
  }
  ngDoCheck() {
    this.isAdmin$.subscribe((user: any) => {
      if (user === true) {
        this.activeNav = true;
        this.stepperService.getSteper().subscribe((res: any) => {
          if (res && this.steps) {
            for (let i = 0; i < this.steps.length; i++) {
              for (let z = 0; z < res.length; z++) {
                if (res[z].name === this.steps[i].name) {
                  this.steps[i].stepSelected = res[z].stepSelected;
                  if (res[z].name === "generateNewAnalysis") {
                    this.steps[i].disabled = res[z].disabled;
                  }
                  if (res[z].name === "afsReview") {
                    this.steps[i].disabled = res[z].disabled;
                  }
                  if (res[z].name === "score") {
                    this.steps[i].disabled = res[z].disabled;
                  }
                  if (res[z].name === "icrReview") {
                    this.steps[i].disabled = res[z].disabled;
                  }
                }
              }
            }
            const stringSteps = JSON.stringify(this.steps);
            localStorage.setItem("stepsLocalStorage", stringSteps);
          } else {
            const strStepsLocalStorage =
              localStorage.getItem("stepsLocalStorage") || '{}';
            this.steps = JSON.parse(strStepsLocalStorage);
          }
        });
      } else if (user === false && this.isExternalUser) {
        this.activeNav = false;
        this.stepperService.getSteper().subscribe((res: any) => {
          if (res && this.steps) {
            for (let i = 0; i < this.steps.length; i++) {
              for (let z = 0; z < res.length; z++) {
                if (res[z].name === this.steps[i].name) {
                  this.steps[i].stepSelected = res[z].stepSelected;
                  if (res[z].name === "loadActionPlan") {
                    this.steps[i].disabled = res[z].disabled;
                  }
                  if (res[z].name === "myActionPlan") {
                    this.steps[i].disabled = res[z].disabled;
                  }
                }
              }
            }
            const stringSteps = JSON.stringify(this.steps);
            localStorage.setItem("stepsLocalStorage", stringSteps);
          } else {
            const strStepsLocalStorage =
              localStorage.getItem("stepsLocalStorage") || '{}';
            this.steps = JSON.parse(strStepsLocalStorage);
          }
        });
      }
    });
  }

  toggleSearch() {
    this.isCollapsed = !this.isCollapsed;
  }
  get panelAnimationClass() {
    return !this.isCollapsed ? 'panel-collapse' : 'panel-expand';
  }
}

/*
  logout(){
    this.authService.logout();
  }
  toggleSearch() {
    this.isCollapsed = !this.isCollapsed;
  }
  get panelAnimationClass() {
    return !this.isCollapsed ? 'panel-collapse' : 'panel-expand';
  }*/