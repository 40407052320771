import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SaveTestService {
  // eslint-disable-next-line
  constructor(private http: HttpClient) {}

  updateTestResults(data: any) {
    if (data) {
      for (const i in data) {
        if (data[i].answerFms === null) {
          data[i].answerFms = data[i].answerLuca;
        }
      }
    }
    return this.http.put(environment.api.base + 'efas', data);
  }
}
