import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { IEfaOutput } from '@bl-interfaces/iefa-output';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GetAnalysisService {

  constructor(private httpClient: HttpClient) {}

  getAnalysis(analysisId: string): Observable<IEfaOutput> {
    return this.httpClient.get<IEfaOutput>(environment.api.base + 'efas?id=' + analysisId)
      .pipe(catchError((err: HttpErrorResponse) => {

        if (err.error instanceof Error) {
          console.error('Error:', err.error.message);
        } else {
          console.error(`Backend error code ${err.status} : ${err.error}`);
        }
 
        return new Observable<IEfaOutput>();
      }));
  
  }

  removeAnalysis(analysisId: string): Observable<any> {
    return this.httpClient.delete(environment.api.base + 'efas/' + analysisId, {});
  }
}
