import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[bidDisableControl]',
})
export class DisableControlDirective {
  @Input() set bidDisableControl(condition: string | boolean) {
    // Convertir el input string a boolean si es necesario
    const booleanValue = condition === 'true' || condition === true;
    
    if (this.ngControl && this.ngControl.control) {
      const action = booleanValue ? 'disable' : 'enable';
      
      // Habilitar o deshabilitar el control según la condición
      this.ngControl.control[action]();
      
      // Si se deshabilita el control, resetear su valor
      if (booleanValue) {
        this.ngControl.control.setValue('');
      }
    }
  }

  constructor(private ngControl: NgControl) {}
}