import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FindingCrudService {
  private sendPositionSubject = new BehaviorSubject([]);

  private sendPositioObservable: Observable<any> = this.sendPositionSubject.asObservable();

  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  updateObservation(dataForm: any) {
    return this.httpClient.put(environment.api.base + 'icrs/observations', dataForm);
  }

  createObservation(dataForm: any, analysisId: string) {
    dataForm.analysisId = analysisId;
    return this.httpClient.post(environment.api.base + 'icrs/observations?id=' + analysisId, dataForm);
  }

  deleteObservation(crObservationId: string) {
    return this.httpClient.delete(environment.api.base + 'icrs/observations/' + crObservationId);
  }

  deleteObservationList(arrayFindings: any) {
    return this.httpClient.request('delete', environment.api.base + 'icrs/observations/list', {
      body: arrayFindings,
    });
  }

  setPostion(pos: any) {
    this.sendPositionSubject.next(pos);
  }

  getPostion(): Observable<boolean> {
    return this.sendPositioObservable;
  }

  UpdateObservationList(dataFinding: any) {
    return this.httpClient.put(environment.api.base + 'icrs/observations/list', dataFinding);
  }

  sendNotifications(analysisId: any) {
    return this.httpClient.put(
      environment.api.base + 'icrs/InternalReview?analysisId=' + analysisId,
      {},
    );
  }
  UpdateActionPlan(analysisId: string, FechaFin: string) {
    return this.httpClient.put(
      environment.api.base +
        'icrs/UpdateActionPlan?analysisId=' +
        analysisId +
        '&FechaFin=' +
        FechaFin,
      {},
    );
  }
}
