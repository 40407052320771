import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageToastService } from '@core/messages/message-toast.service';
//import smoothscroll from 'smoothscroll-polyfill';
import { DatePipe } from '@angular/common';
import { headersTables } from '@bl-constants/table-header.const';
import { UploadFileService } from '../../../core/upload-pdf/upload-file.service';
import { EzshareParameterService } from '@core/analysis/ezshare-parameter.service';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'bid-ezshare-table',
  templateUrl: './ezshare-table.component.html',
  styleUrls: ['./ezshare-table.component.scss'],
  providers: [DatePipe],
})
export class EzshareTableComponent implements OnInit {
  @Output() documentSelected = new EventEmitter();
  @Input() loanNumber: string;
  headerTable: any;
  bodyTable: any;
  lastLoan: string = "";
  loader = false;
  lastSearchLoan: string;
  ezsharedParameter: string = "";
  _ezsharedParameterService;
  _uploadFileService;
  selectedDocument: any;

  modalEzshare = false;

  constructor(
    private messageToastService: MessageToastService,
    private uploadFileService: UploadFileService,
    private ezshareParameterService: EzshareParameterService,
  ) {
    this.lastSearchLoan = '';
    this.loanNumber = '';
    this._ezsharedParameterService = ezshareParameterService;
    this._uploadFileService = uploadFileService;
  }

  ngOnInit() {
    this.headerTable = headersTables.headerSelectEZshareTable;
  }

  customSort(event: SortEvent) {
    event?.data?.sort((data1: any, data2: any) => {
      const value1 = data1[event?.field || ""];
      const value2 = data2[event?.field || ""];
      let result = null;
      if (event.field === 'date') {
        const dateA = new Date(value1);
        const dateB = new Date(value2);
        result = dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
      } else {
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }
      }
      return (event?.order || 1) * result;
    });
  }

  getData() {
    this.uploadFileService.documentSearch(this.ezsharedParameter).subscribe((result) => {
      this.bodyTable = result;
      this.loader = false;
    });
    this.lastLoan = this.loanNumber.toString();
  }

  getEzshareParameter() {
    this.loader = true;
    this.bodyTable = [];
    this.ezshareParameterService.getEzsharedParameters(this.loanNumber.toUpperCase()).subscribe(
      (res: any) => {
        if (res && res.split("MULTI_LOANS='")[1]) {
          this.ezsharedParameter = res.split("MULTI_LOANS='")[1].split("'", 1)[0];
          this.getData();
        } else {
          this.ezsharedParameter = '';
        }
      },
      // eslint-disable-next-line
      (error) => console.log(error),
    );
  }

  chooseFile() {
    if (this.lastSearchLoan !== this.loanNumber) {
      this.lastSearchLoan = this.loanNumber;
      this.getEzshareParameter();
    }
    this.modalEzshare = true;
  }

  selectFile(selectedDocument: any) {
    this.documentSelected.emit(selectedDocument);
    this.modalEzshare = false;
  }

  backToTop() {
    /*smoothscroll.polyfill();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });*/
  }

  showToastSuccess() {
    this.messageToastService.showToastSuccess(
      'Success',
      'Analysis completed. Go to AFS Review< to see the results',
      'successMessageAfsReview',
    );
  }

  showToastError(summary: string, key: string) {
    this.messageToastService.showToastError('error', summary, key);
  }
}
