import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageToastService } from '@core/messages/message-toast.service';
import { AfsReviewService } from '@core/afs-review/afs-review.service';
import { selects } from '@bl-constants/selects.const';
import { templateSuccess } from '../../constants/templateSuccess';
import { Router } from '@angular/router';
import { SaveTestService } from '@core/afs-review/save-test.service';
import { StepperService } from '@core/stepper/stepper.service';
import smoothscroll from 'smoothscroll-polyfill';
import { ExportPdfService } from '@core/export-pdf/export-pdf.service';
import { TooltipInfoTableAfsReviewService } from '@core/messages/tooltip-info-table-afs-review.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'bid-afs-review-table',
  templateUrl: './afs-review-table.component.html',
  styleUrls: ['./afs-review-table.component.scss'],
  providers: [DatePipe],
})
export class AfsReviewTableComponent implements OnInit {
  @Input() headerTable: any;
  @Input() bodyTable: any;
  @Input() editable: boolean = false;
  @Input() scoreScreen = false;
  @Output() sendStatus = new EventEmitter<string>();
  @Input() tableButtons = true;
  @Input() analysisId: string = "";
  @Input() language: 'En' | 'Fr' | 'Po' | 'Sp' = "En";
  initialDataBodyTable = [];
  myFormGroup!: FormGroup;
  disabledButton: boolean;
  subtmitedForm: boolean;
  ManualError: boolean = false;
  templateSuccess = templateSuccess;
  tooltipInfoAFSReview: string = "";
  STEP2 = [
    { name: 'afsReview', stepSelected: true },
    { name: 'score', stepSelected: true },
  ];

  changeStatus: any;

  valueSelect: any = [];
  valueSelectManual: any = [];
  valueSelectComment: any = { };
  bodyTableAux = [];
  _saveTestService;

  constructor(
    private messageToastService: MessageToastService,
    private afsReviewService: AfsReviewService,
    private router: Router,
    private saveTestService: SaveTestService,
    private stepperService: StepperService,
    private exportPdfService: ExportPdfService,
    private tooltipInfoTableAfsReviewService: TooltipInfoTableAfsReviewService,
  ) {
    this.disabledButton = true;
    this.subtmitedForm = false;
    this._saveTestService = saveTestService;
  }

  ngOnInit() {
    this.tooltipInfoAFSReview = this.tooltipInfoTableAfsReviewService.getTooltipContent();
    if (this.editable && this.bodyTable && this.bodyTable.length > 1) {
      this.generateDinamicForm(this.bodyTable);
    } else {
      this.myFormGroup = new FormGroup({});
    }
    this.afsReviewService.returnScore().subscribe((res: any) => {
      if (res === false) {
        this.disabledButton = false;
      }
    });

    this.valueSelect = selects.pages.afs_review.select.answerFMS;
    this.valueSelectManual = selects.pages.afs_review.select.answerFMSManual;
    this.valueSelectComment = selects.pages.afs_review.select.commentType[this.language];
     
  }

  generateDinamicForm(bodyTable: any) {
    const group:any = {};
    const textareaParameterLuca = 'textareaParameterLuca-';
    const textareasComments = 'textareasComments-';
    const radio = 'radio-';
    const textareaAuditionToFms = 'textareaAuditionToFms-';
    const selectanswerFms = 'selectanswerFms-';
    if (bodyTable && bodyTable.length > 1) {
      for (let i = 0; i <= bodyTable.length; i++) {
        group[textareaParameterLuca + i] = new FormControl('');
        group[textareasComments + i] = new FormControl({
          value: '',
          disabled: this.changeStatus,
        });
        group[radio + i] = new FormControl(
          bodyTable[i] && bodyTable[i].parameterFms ? false : null,
        );
        group[textareaAuditionToFms + i] = new FormControl('');
        group[selectanswerFms + i] = new FormControl('', [Validators.required]);
      }
      this.myFormGroup = new FormGroup(group);
    }
  }

  validation() {
    if (this.myFormGroup.touched) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }

  applyDatabase() {
    this.bodyTable = this.bodyTable.map((row: any, i: number) => {
      if (this.myFormGroup.value['radio-' + i]) {
        row.parameterFms = row.luca_database ? row.luca_database : '';
      }
      return row;
    });
  }

  save(action: string) {
    let fresData = '';
    this.applyDatabase();
    switch (action) {
      case 'saveTest':
        this.bodyTableAux = this.bodyTable;
        fresData = JSON.stringify(this.bodyTable);
        this.parseSortData(this.bodyTableAux);
        this.bodyTable = JSON.parse(fresData);
        this.afsReviewService.setTest(this.bodyTable);
        this.afsReviewService.setScore(false);
        this.updateTestResults(action);
        break;
      case 'calculateScore':
        this.subtmitedForm = true;
        this.ManualError = false;
        for (let i = 0; i < this.bodyTable.length; i++) {
          this.bodyTable[i].language = this.language;
          if (
            this.bodyTable[i].answer_luca === 'Manual' &&
            (this.bodyTable[i].answerFms === '' || this.bodyTable[i].answerFms === null)
          ) {
            this.disabledButton = true;
            this.ManualError = true;
            break;
          } else {
            this.disabledButton = false;
          }
        }
        console.log("save", this.disabledButton ,  this.ManualError)
        if (this.disabledButton === true && this.ManualError === true) {
          this.showToastError('Required answer in manual', 'errorMessageAfsReviewTable');
          this.showErrors();
        } else {
          this.bodyTableAux = this.bodyTable;
          fresData = JSON.stringify(this.bodyTable);
          this.parseSortData(this.bodyTableAux);
          this.bodyTable = JSON.parse(fresData);
          this.afsReviewService.setTest(this.bodyTable);
          this.afsReviewService.setScore(true);
          this.updateTestResults(action);
          this.stepperService.setStepper(this.STEP2);
        }
        break;
    }
    this.changeStatus = true;
    this.sendStatus.emit(this.changeStatus);
  }

  parseSortData(data: any) {
    data.sort((a: any, b: any) => {
      if (a.test_id > b.test_id) {
        return 1;
      }
      if (a.test_id < b.test_id) {
        return -1;
      }

      return 0;
    });
  }

  updateTestResults(action: string) {
    this.saveTestService.updateTestResults(this.bodyTableAux).subscribe(
      () => {
        if (action === 'saveTest') {
          this.showToastSuccess('Analysis saved.');
        } else {
          this.showToastSuccess('Analysis completed. Go to AFS Review< to see the results');
          this.router.navigate(['/score']);
        }
      },
      () => {
        this.showToastError(
          'Error loading data, please try again later 4',
          'errorMessageAfsReviewTable',
        );
      },
    );
  }

  exportToPdf() {
    this.exportPdfService.layoutPDF(this.bodyTable, this.analysisId);
  }

  showErrors() {
    setTimeout(() => {
      smoothscroll.polyfill();
      try{
        const domEl = document.querySelector('.bl-select--has-error');

        if(domEl){
          const element = domEl.getBoundingClientRect().top;
          const bodyRect = document.body.getBoundingClientRect().top;
          window.scrollTo({
            top: element - bodyRect,
            behavior: 'smooth',
          });
        }
      
      } catch(e){

      }
      
    }, 500);
  }

  backToTop() {
    //smoothscroll.polyfill();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  showToastSuccess(message: string) {
    this.messageToastService.showToastSuccess('Success', message, 'successMessageAfsReview');
  }

  showToastError(summary: string, key: string) {
    this.messageToastService.showToastError('error', summary, key);
  }
}
