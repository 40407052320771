import { Component, OnInit, DoCheck } from '@angular/core';
import { headersTables } from '../../shared/constants/table-header.const';
import { ManageUserService } from '@core/manage-users/manage-user.service';
import { StepperService } from '@core/stepper/stepper.service';
import { UserService } from '@core/user/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { NgxSpinnerService } from 'ngx-spinner';
import { MessageToastService } from '@core/messages/message-toast.service';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import { MessageService } from 'primeng/api';
import { User } from '@bl-interfaces/user';
import { Role } from '@bl-enum/role';
@Component({
  selector: 'bid-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, DoCheck {
  headerTableUser: any;
  formGroupSeacherUsers!: FormGroup;
  lengthDataUsers: number = -1;
  showHistoricalSearch: boolean;
  STEP0 = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'afsReview', stepSelected: false, disabled: true },
    { name: 'score', stepSelected: false, disabled: true },
    { name: 'icrReview', stepSelected: false, disabled: true },
  ];
  searcherTermTxt: string = '';
  headerTableUsers: any;
  headerTable = {
    headerUserTable: [
      { field: 'displayName', header: 'Name', width: '25%' },
      { field: 'mail', header: 'Email', width: '25%' },
      { field: 'delete', header: 'Delete', width: '25%' },
      { field: 'status', header: 'Status', width: '25%' },
    ],
  };
  bodyTable: any = {   };
  completeDataUsers: User[] = [];
  addAdmin: boolean = false;
  addUser: boolean = false;
  inputEmailUser: string = '';
  userToAdd!: User;
  showEmailError: boolean = false;
  userFinder: any;
  addUserDisabled = true;
  userRoles = Role;

  constructor(
    private messageService: MessageService,
    private manageUserService: ManageUserService,
    private stepperService: StepperService,
    private userService: UserService,
    //private spinner: NgxSpinnerService,
    private messageToastService: MessageToastService,
    private showHistoricalSearchService: ShowHistoricalSearchService,
  ) {
    this.showHistoricalSearch = false;
  }

  ngOnInit() {
    //this.spinner.show();
    this.formGroupSeacherUsers = new FormGroup({
      seacherTerm: new FormControl('', Validators.required),
    });
    this.stepperService.setStepper(this.STEP0);
    this.headerTableUser = headersTables.headerUsersTable;
    this.loadBodyTable();
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.headerTableUsers = this.headerTable.headerUserTable;
    this.inputEmailUser = '';
    this.userToAdd = {
      displayName: '',
      mail: '',
      role: Role.INVITED,
      status: true,
    };
  }

  ngDoCheck() {
    this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });
  }

  loadBodyTable() {
    this.userService.getUserList().subscribe(
      (res: any) => {
        if (!this.bodyTable) {
          this.bodyTable = {
            users: [],
          };
        }
        this.bodyTable.users = res;
        this.completeDataUsers = res;
        //this.spinner.hide();
      },
      () => {
        //this.spinner.hide();
        this.showToastError(
          'We had a problem loading the data, please try again later.',
          'errorUser',
        );
      },
    );
  }

  filterByRole(role: string): User[] {
    return this.bodyTable.users.filter((u: any) => u.role === role);
  }

  showToastError(summary: string, key: string) {
    this.messageToastService.showToastError('error', summary, key);
  }

  showToastWarn(title: string, summary: string, key: string) {
    this.messageToastService.showToastWarn(title, summary, key);
  }

  showToastSuccess(title: string, summary: string, key: string) {
    this.messageService.add({
      key,
      severity: 'success',
      summary: title,
      //detail: msg,
    });
  }

  toggleAddAdmin() {
    this.addAdmin = !this.addAdmin;
  }

  toggleAddUser() {
    this.addUser = !this.addUser;
  }

  checkEmail(role: Role) {
    this.userToAdd.displayName = '';

    this.userService.checkEmail(this.inputEmailUser).subscribe(
      (res: string) => 
      {
        this.showEmailError = true;
        this.addUserDisabled = true;

        if (res != null)
        {
          if(res.length >0)
          {
            this.addUserDisabled = false;
            this.showEmailError = false;
  
            this.userToAdd.mail = this.inputEmailUser;
            this.userToAdd.displayName = res;
            this.userToAdd.role = role;
            this.addUserDisabled = false;
          }
        }
      },
      (err) => 
      {
        if (err.status !== 200) {
          this.showEmailError = true;
          this.addUserDisabled = true;
          this.showToastError(
            'We had a problem with the operation, please try again later.',
            'errorUser',
          );
        }
      },
    );
  }

  addUserTable() {
    this.userService.getUserByMail(this.userToAdd.mail).subscribe(
      (res) => {
        if (res) {
          this.showToastWarn('Operation cancelled.', 'User already exists.', 'warnUser');
          this.cancelAddUser();
        } else {
          console.log("userToAdd",this.userToAdd)
          this.userService.addUser(this.userToAdd).subscribe(
            () => {
              this.showToastSuccess('User create', 'New user has been created.', 'successUser');
              this.loadBodyTable();
            },
            () => {
              this.showToastError('User could not be created.', 'errorUser');
            },
          );
          this.resetAdd();
        }
      },
      () => {
        this.showToastWarn('Operation cancelled.', 'User already exists.', 'warnUser');
        this.cancelAddUser();
      },
    );
  }

  deleteUser(user: any) {
    this.userService.deleteUser(user).subscribe(
      (res) => {
        if (res) {
          this.showToastSuccess(
            'User deleted',
            'The selected user has been deleted.',
            'successUser',
          );
          this.loadBodyTable();
        } else {
          this.showToastError('There was an error during deletion.', 'errorUser');
        }
      },
      () => {
        this.showToastError('There was an error during deletion.', 'errorUser');
      },
    );
  }

  updateStatus(user: any) {
    this.userService.updateUser(user).subscribe(
      (res) => {
        if (res) {
          this.showToastSuccess(
            'Status updated',
            "The user's status has been updated",
            'successUser',
          );
        } else {
          this.showToastError('There was an error during update.', 'errorUser');
          //this.spinner.show();
          this.loadBodyTable();
        }
      },
      () => {
        this.showToastError('There was an error during update.', 'errorUser');
        //this.spinner.show();
        this.loadBodyTable();
      },
    );
  }

  resetAdd() {
    this.addAdmin = false;
    this.addUser = false;
    this.cancelAddUser();
  }

  cancelAddUser() {
    this.inputEmailUser = '';
    this.userToAdd = {
      displayName: '',
      mail: '',
      role: Role.INVITED,
      status: true,
    };
    this.showEmailError = false;
    this.addUserDisabled = true;
  }
}
