<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
<!--bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher-->
<div class="bl-wrapper">
  <div class="bl-score">
    <p-dialog
      header="Submit confirmation"
      [(visible)]="display"
      [modal]="true"
      [responsive]="true"
      [maximizable]="false"
      [baseZIndex]="10000"
      [closable]="false"
      styleClass="bl-modal"
      [positionTop]="65"
      [positionLeft]="390"
    >
      <p>
        You are about to finish the AFS review, you will not be able to modify the results. Do you
        want to continue?
      </p>
      <p-footer>
        <button type="button" pButton (click)="toggleDialog()" label="Cancel"></button>
        <button
          type="button"
          pButton
          (click)="setStatusCompleted()"
          label="Continue"
          class="ui-button-secondary"
        ></button>
      </p-footer>
    </p-dialog>
    <p-toast class="bl-toast bl-toast--error" key="errorMessageScore" closable="true"></p-toast>
    <div>
      <div class="bl-score__header-wrapper">
        <div class="bl-score__header-left">
          <div>
            <h1 class="bl-score__header">Score</h1>
            <span
              [ngClass]="
                analysisData?.status === 'In progress'
                  ? 'bl-score__header--status-progress'
                  : 'bl-score__header--status-completed'
              "
              >Status: {{ analysisData?.status }}</span
            >
          </div>
          <div class="bl-score__header">
            <div class="bl-score__header--analysis-id">
              <!-- <span>Analysis ID: </span>
            <span>{{ analysisData?.id }}</span> -->
            </div>
          </div>
          <div class="bl-score__group-data">
            <span>Loan number: </span>
            <span class="bl-score__group-data--strong-letter">{{
              analysisData?.operationNumber
            }}</span>
          </div>
          <div class="bl-score__group-data">
            <span>Audit Period: </span>
            <span class="bl-score__group-data--strong-letter"
              >{{ analysisData?.initDate.toString() | slice:0:10 }} -
              {{ analysisData?.endDate.toString() | slice:0:10 }}</span
            >
          </div>
        </div>
        <div class="bl-score__header-right">
          <div class="bl-score__button-wrapper" style="display: inline-flex; align-items: flex-end;padding: 0.2857142857rem 1.0714285714rem 0.2857142857rem 1.0714285714rem;">
            <button
              *ngIf="showBtn && !urlSummaryReport"
              (click)="downloadDraftSummaryReport()"
              class="bl-button--btn-seacher t-margin-right-20"
              type="button"
              style="width: 200px;"
            >
              Preview Summary Report
            </button>
            <!-- toggleDialog() -->
            <button
              class="bl-score__button bl-button--btn-secundary bl-button--btn-secundary--lg" style="border: 2px solid #5c84c3;padding: 0.2857142857rem 1.0714285714rem 0.2857142857rem 1.0714285714rem;"
              type="submit"
              (click)="toggleDialog()"
              *ngIf="showBtn && !statusCompleted"
              style="width: 200px;"
            >
              Finish AFS Review
            </button>
            <bid-review-result
              *ngIf="showBtn && !urlSummaryReport"
              [checkId]=""
              [onlyButton]="true"
              [analysis]="analysisData"
              (summaryReportCheck) ="checkSummaryReport($event)"
            ></bid-review-result>
            <div *ngIf="urlSummaryReport" class="bl-internal-control-review-result__link-content">
              <span class="bl-internal-control-review-result__link-content__txt"
                >Summary Report :</span
              >
              <a
                (click)="downloadSummary(urlSummaryReport, summaryReportName)"
                class="bl-internal-control-review-result__link-content__link" 
                target="_blank"
                >{{ summaryReportName }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bl-score__test-result-wrapper">
        <h2 class="bl-score__test-result-title">Test Result</h2>
        <div class="bl-score__content-info">
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [noIcon]="true"
              [mainTxt]="scoreResult?.outcome"
              [contentTxt]="'Initial Analysis'"
            >
            </bid-content-border-info>
          </div>
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [noIcon]="true"
              [mainTxt]="scoreResult?.level"
              [contentTxt]="'Detailed Analysis'"
              [tooltipInfo]="tooltipInfo"
            >
            </bid-content-border-info>
          </div>
        </div>
      </div>
      <div class="bl-score__classification-wrapper">
        <h2 class="bl-score__test-result-title">Score Classification</h2>
        <div class="bl-score__content-info">
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'fas fa-edit'"
              [mainTxt]="(scoreA | number: '1.1-1') + ' | '"
              [contentTxt]="'Basic Elements of the Auditor\'s Opinion'"
              [letter]="'A'"
              [typeTotalPercent]="'40%'"
            >
            </bid-content-border-info>
          </div>
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'fas fa-edit'"
              [mainTxt]="(scoreB | number: '1.1-1') + ' | '"
              [contentTxt]="'Project Financial Statements'"
              [letter]="'B'"
              [typeTotalPercent]="'13%'"
            >
            </bid-content-border-info>
          </div>
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'fas fa-edit'"
              [mainTxt]="(scoreC | number: '1.1-1') + ' | '"
              [contentTxt]="'Notes to the Financial Statements'"
              [letter]="'C'"
              [typeTotalPercent]="'47%'"
            >
            </bid-content-border-info>
          </div>
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'fas fa-edit'"
              [mainTxt]="(scoreA + scoreB + scoreC | number: '1.1-1') + ' | '"
              [contentTxt]="'Total Score'"
              [letter]="'T'"
              [typeTotalPercent]="'100%'"
            >
            </bid-content-border-info>
          </div>
        </div>
      </div>
    </div>

    <h2 class="bl-score__test-result-title">Initial Analysis Tests</h2>
    <div class="col-10 bl-score__table-wrapper">
      <bid-afs-review-table
        *ngIf="tableScore"
        [headerTable]="headerTableScore"
        [bodyTable]="filterTableScore"
        [editable]="false"
        [scoreScreen]="true"
        [tableButtons]="false"
      ></bid-afs-review-table>
      <p-message
        *ngIf="analysisData?.status === 'In progress'"
        class="bl-score__info-message"
        severity="info"
        [escape]="false"
        text='If you want to modify the answers go to "<a class="bl-score__info-message-link" href="#/AfsReview">AFS Review</a>"'
      >
      </p-message>
      <p-message
        *ngIf="analysisData?.status === 'Completed'"
        class="bl-score__info-message"
        severity="info"
        [escape]="false"
        text='If you want to see the answers go to "<a class="bl-score__info-message-link" href="#/AfsReview">AFS Review</a>"'
      >
      </p-message>
    </div>
    <div class="bl-score__overview-wrapper">
      <h2 class="bl-score__test-result-title">Overview analysis</h2>
      <div class="bl-score__group-data-wrapper">
        <div class="bl-score__content-info">
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'fas fa-cogs'"
              [mainTxt]="scoreResult?.perc_auto | number: '1.1-1'"
              [type]="'%'"
              [contentTxt]="'Final Automated Percent'"
            >
            </bid-content-border-info>
          </div>
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'fas fa-edit'"
              [mainTxt]="scoreResult?.perc_manual | number: '1.1-1'"
              [type]="'%'"
              [contentTxt]="'Percent Manual'"
            >
            </bid-content-border-info>
          </div>
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'far fa-clock'"
              [mainTxt]="analysisData?.summary?.efaTimer + analysisData?.summary?.icrTimer | minsec"
              [contentTxt]="'Processing time'"
            >
            </bid-content-border-info>
            <!-- <bid-content-border-info *ngIf="analysisTime" [iconClass]="'far fa-clock'" [mainTxt]="
            analysisTime | minsec
            " [contentTxt]="'Processing time'">
            </bid-content-border-info> -->
          </div>
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'far fa-clock content-border__icon--darkblue'"
              [mainTxt]="analysisData?.summary?.reviewTimer | minsec"
              [contentTxt]="'Review time'"
            >
            </bid-content-border-info>
          </div>
          <div class="bl-score__content-info-wrapper">
            <bid-content-border-info
              [iconClass]="'fas fa-clock content-border__icon--darkblue'"
              [mainTxt]="
                analysisData?.summary?.reviewTimer +
                  analysisData?.summary?.efaTimer +
                  analysisData?.summary?.icrTimer | minsec
              "
              [contentTxt]="'Total time'"
            >
            </bid-content-border-info>
            <!-- <bid-content-border-info *ngIf="analysisTime" [iconClass]="'fas fa-clock content-border__icon--darkblue'"
              [mainTxt]="
                analysisTime +
                analysisData?.summary?.reviewTimer | minsec
            " [contentTxt]="'Total time'">
            </bid-content-border-info> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
