import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { ShowHistoricalSearchService } from "@core/history/show-historical-search.service";
import { UserService } from "@core/user/user.service";
import { AuthenticationService } from "@core/authentication/authentication.service";

@Component({
  selector: "bid-user-button",
  templateUrl: "./user-button.component.html",
  styleUrls: ["./user-button.component.scss"],
})
export class UserButtonComponent implements OnInit {
  items: MenuItem[] = [];
  toggleButton: boolean;
  user: any;
  constructor(
    //private showHistoricalSearchService: ShowHistoricalSearchService,
    private userService: UserService,
    private authService: AuthenticationService
  ) {
    this.toggleButton = true;
  }

  ngOnInit() {
    this.userService.getUserData().subscribe((res: any) => {
      console.log("user", res);
      this.user = res;
    });

    if (this.authService.msalService.instance.getAllAccounts().length > 0) {
      this.items = [
        {
          label: "LogOut",
          icon: "fas fa-power-off",
          command: () => {
            console.log("aaaa");

            this.authService.logout();
          },
        },
      ];
    } else {
      this.items = [
        {
          label: "Login",
          icon: "fas fa-sign-in-alt",
          command: () => {
            this.authService.login();
          },
        },
      ];
    }
  }

  hideAndShow() {
    this.toggleButton = !this.toggleButton;
    //this.showHistoricalSearchService.setShowHistoricalSearch(false);
  }
}
