import { Component, OnInit, DoCheck } from '@angular/core';
import { headersTables } from '@bl-constants/table-header.const';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TestDescriptionsService } from '@core/test-descriptions/test-descriptions.service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { StepperService } from '@core/stepper/stepper.service';
import { MessageToastService } from '@core/messages/message-toast.service';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';

@Component({
  selector: 'bid-test-description',
  templateUrl: './test-description.component.html',
  styleUrls: ['./test-description.component.scss'],
})
export class TestDescriptionComponent implements OnInit, DoCheck {
  headerTableTestDescriptions: any;
  analysisData: any;
  formGroupSeacherTestDescriptions!: FormGroup;
  testDescriptions: any;
  seracherTerm: string = '';
  STEP0: any = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'afsReview', stepSelected: false, disabled: true },
    { name: 'score', stepSelected: false, disabled: true },
    { name: 'icrReview', stepSelected: false, disabled: true },
  ];
  //showHistoricalSearch: boolean;
  constructor(
    private testDescriptionsService: TestDescriptionsService,
    //private spinner: NgxSpinnerService,
    private stepperService: StepperService,
    private messageToastService: MessageToastService,
    private showHistoricalSearchService: ShowHistoricalSearchService,
  ) {
    //this.showHistoricalSearch = false;
  }

  ngOnInit() {
    //this.spinner.show();
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.seracherTerm = '';
    this.stepperService.setStepper(this.STEP0);
    this.headerTableTestDescriptions = headersTables.headerDesciptionTable;
    this.formGroupSeacherTestDescriptions = new FormGroup({
      seacherTerm: new FormControl('', Validators.required),
    });
    this.getTestDesciptions();
  }

  ngDoCheck() {
    /*this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });*/
  }

  getTestDesciptions() {
    this.testDescriptionsService.getTestDictionary().subscribe(
      (res: any) => {
        if (res) {
          this.testDescriptions = res;
          //this.spinner.hide();
        } else {
          //this.spinner.hide();
        }
      },
      () => {
        //this.spinner.hide();
        this.showToastError(
          'An error was encountered when loading the data, please try again later 3',
          'errorMessageTestDescriptions',
        );
      },
    );
  }

  seacherTerm() {
    if (this.formGroupSeacherTestDescriptions.value.seacherTerm !== '') {
      this.seracherTerm = this.formGroupSeacherTestDescriptions.value.seacherTerm;
      this.testDescriptions = this.testDescriptions.filter(
        (testDescription: any) =>
          testDescription.name
            .toLowerCase()
            .replace(/\s/g, '')
            .indexOf(
              this.formGroupSeacherTestDescriptions.value.seacherTerm
                .toLowerCase()
                .replace(/\s/g, ''),
            ) > -1 ||
          testDescription.id ===
            parseInt(this.formGroupSeacherTestDescriptions.value.seacherTerm, 0),
      );
      this.formGroupSeacherTestDescriptions.patchValue({
        seacherTerm: '',
      });
    }
  }

  removeSeacherTerm() {
    //this.spinner.show();
    this.seracherTerm = '';
    this.formGroupSeacherTestDescriptions.patchValue({
      seacherTerm: '',
    });
    this.seracherTerm = '';
    this.getTestDesciptions();
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }
}
