import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  private stepperSubject = new BehaviorSubject([]);
  private stepperObservable: Observable<any> = this.stepperSubject.asObservable();

  setStepper(step: any) {
    this.stepperSubject.next(step);
  }

  getSteper(): Observable<[]> {
    return this.stepperObservable;
  }
}
