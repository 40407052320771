import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/user/user.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'bid-menu-dashboard',
  templateUrl: './menu-dashboard.component.html',
  styleUrls: ['./menu-dashboard.component.scss'],
})
export class MenuDashboardComponent implements OnInit, DoCheck {
  items: any;
  currentPageAdminModule: boolean = false;
  specialRoute: string;
  user: any;
  MOCK_USER_ADMIN: any[] = [];
  access: boolean = false;
  constructor(private router: Router, private userService: UserService) {
    this.specialRoute = '';
  }

  ngOnInit() {
    this.userService
      .getIsAdminUser()
      .pipe(takeWhile((isAdmin) => isAdmin === undefined, true))
      .subscribe(
        (res) => {
          console.log("getIsAdminUser", res)
          if (res !== undefined) {
            this.access = res;
          }
        },
        () => {
          return;
        },
      );
    this.items = [
      {
        label: 'Dashboard',
        command: () => {
          this.router.navigate(['/dashboard']);
        },
      },

      {
        label: 'User Activity',
        command: () => {
          this.router.navigate(['/dashboard-users']);
        },
      },
       
    ];
    this.checkCurrentUrl(this.router.url.split('/')[1]);
  }

  ngDoCheck() {
    this.checkCurrentUrl(this.router.url.split('/')[1]);
  }

  checkCurrentUrl(currentUrl: string) {
 
    if (currentUrl === 'dashboard') {
      this.currentPageAdminModule = true;
    } else if (currentUrl === 'dashboard-users') {
      this.currentPageAdminModule = true;
    }
  }
}
