import { Component, OnInit, Input, Output, EventEmitter, DoCheck, OnChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { menuRiskSelectbuttons } from '@bl-constants/menu-risk-select-buttons.const';
import { selects } from '@bl-constants/selects.const';
import { FindingCrudService } from '@core/icr-review/finding-crud.service';
import { SummaryReportService } from '@core/summaryReport/summary-report.service';
import { MessageToastService } from '@core/messages/message-toast.service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '@environments/environment';

export interface Model {
  name: string;
  code: string;
}
@Component({
  selector: 'bid-finding-crud',
  templateUrl: './finding-crud.component.html',
  styleUrls: ['./finding-crud.component.scss'],
})

// eslint-disable-next-line
export class FindingCrudComponent implements OnInit, OnChanges, DoCheck {
  @Input() icrData: any;
  @Input() checkId: any;
  @Output() sendFinding = new EventEmitter();
  @Output() createNewFinding = new EventEmitter();
  @Output() checkFinding = new EventEmitter();
  menu: SelectItem[] = [];
  selectedRisk;
  selected: any;
  selectedItem;
  myGroup: any;
  bucketSelect: SelectItem[] = [];
  bucketSelectItem: string;
  periodSelect: SelectItem[] = [];
  periodSelectItem: string;
  display = false;
  deleteFinding: any;
  icrDataInitial: any;
  findingIndex: number = -1;
  checkSummaryReport: boolean;
  findingEmpty;
  arrayDelete: any = [];
  removeAllStatus = false;
  selectAllStatus = false;
  search = '';
  nonOcurrences = false;
  ActivateNotify = false;
  ActivateAddFinding = true;
  showIcrFeatures: boolean = environment.version != 1;
  _findingCrudService: any;
  _summaryReportService: any;
  _messageToastService: any;



  constructor(
    private findingCrudService: FindingCrudService,
    private summaryReportService: SummaryReportService,
    private messageToastService: MessageToastService,
    //private spinner: NgxSpinnerService,
  ) {
    this.bucketSelectItem = 'All';
    this.periodSelectItem = 'All';
    this.selectedItem = 'item0';

    this.icrDataInitial = null;
    this.selectedRisk = 'All';
    this.checkSummaryReport = false;
    this.findingEmpty = {
      buckets: '',
      comments: '',
      text: 'Findings/Observation',
      follow: '',
      followUpWith: '',
      icrReviewId: 'b3dd14db-539f-497b-9031-ed71d29b7a3d',
      id: '',
      risk: '',
      word_buckets: '',
      word_risk: '',
      period: '',
      reviewed: false,
      type: 'Manual',
      IsActionMatrix: false,
      checkFollow0: false,
      checkFollow1: false,
    };
    this._findingCrudService = findingCrudService;
    this._summaryReportService = summaryReportService;
    this._messageToastService = messageToastService;
  }

  //eslint-disable-next-line
  ngOnInit() {
    this.findingCrudService.setPostion(null);
    this.checkSumaryReportExis();
    this.menu = menuRiskSelectbuttons;
    this.bucketSelect = selects.pages.icr_review.select.bucketClassification;
    this.periodSelect = selects.pages.icr_review.select.period;
    this.filterFindings();
  }

  //eslint-disable-next-line
  ngOnChanges() {
    if (this.checkId) {
      this.filterFindings();
    }
  }

  //eslint-disable-next-line
  ngDoCheck() {
    this.checkSummaryReport = false;
    this.checkSumaryReportExis();
    this.findingCrudService.getPostion().subscribe((res: any) => {
      if (res === 0) {
        this.selectedItem = 'item0';
        this.findingCrudService.setPostion(null);
      }
    });
  }

  getFindings() {
    const strFindings = localStorage.getItem('saveFindings') || '{}';
    return JSON.parse(strFindings);
  }

  checkSumaryReportExis() {
    this.summaryReportService.getGenerateSummaryReport().subscribe((res: any) => {
      this.checkSummaryReport = res;
    });
  }

  isEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  addFinding() {
    let adding = false;
    
    this.icrData.forEach((finding: any) => {
      if (finding.id === '') {
        adding = true;
      }
    });
    if (!adding) {
      this.icrData.unshift({
        buckets: '',
        comments: '',
        text: 'Findings/Observation',
        follow: '',
        followUpWith: '',
        icrReviewId: 'b',
        id: '',
        risk: '',
        word_buckets: '',
        word_risk: '',
        period: '',
        reviewed: false,
        type: 'Manual',
        isActionMatrix: false,
        checkFollow0: false,
        checkFollow1: false,
      });
      this.selectedItem = 'item' + 0;
      this.sendFinding.emit(this.icrData[0]);
      this.createNewFinding.emit(true);
      this.saveFindings();
    }
  }

  selectFinding(finding: any, i:number) {
    let adding = false;
    this.icrData.forEach((findingAdding: any) => {
      if (findingAdding.id === '') {
        adding = true;
      }
    });
    if (adding === false) {
      this.selectedItem = 'item' + i;
      this.sendFinding.emit(finding);
      this.createNewFinding.emit(false);
    }
  }

  checkSelected(event: any, i: number, finding: any) {
    if (event) {
      this.arrayDelete[i] = finding;
    } else {
      this.arrayDelete.splice(i, 1);
    }
  }

  removeAllChange(event: any) {
    if (!event) {
      this.arrayDelete = [];
    }
    //this.spinner.show();
    setTimeout(() => {
      //this.spinner.hide();
    }, 5000);
    this.removeAllStatus = event;
    this.resetSeacherForm();
  }

  selectAll(event: any) {
    if (event) {
      this.arrayDelete = JSON.parse(JSON.stringify(this.icrData));
    } else {
      this.arrayDelete = [];
    }
  }

  showConfirmationDeleteFinding(finding: any, index: number) {
    this.findingIndex = index;
    this.display = true;
    this.deleteFinding = finding;
  }

  showConfirmationDeleteFindingList() {
    this.findingIndex = 0;
    this.display = true;
  }

  removeFindingList() {
    this.display = false;
    //this.spinner.show();
    this.findingCrudService
      .deleteObservationList(this.arrayDelete.filter((x: any) => x != null))
      .subscribe(
        (res: any) => {
          if (res) {
            this.resetSeacherForm();
            this.icrData = this.getFindings();
            this.arrayDelete.forEach((element: any) => {
              let index = this.icrData.findIndex((findingVal: any) => findingVal.id === element.id);
              if (index > -1) {
                this.checkFinding.emit(this.icrData[index].id);
                this.icrData.splice(index, 1);
              }
            });
            this.deletedNormalList();
            this.removeAllStatus = false;
            this.resetSeacherForm();
            this.arrayDelete = [];
            //this.spinner.hide();
          } else {
            //this.spinner.hide();
            this.showToastError(
              'An error was encountered when loading the data, please try again later 9',
              'errorMessageicr-review-form',
            );
            this.resetSeacherForm();
          }
        },
        () => {
          //this.spinner.hide();
          this.showToastError(
            'An error was encountered when loading the data, please try again later 10',
            'errorMessageicr-review-form',
          );
          this.resetSeacherForm();
        },
      );
    if (this.icrData.length <= 1) {
      this.noFinding();
    }
  }

  removeFinding() {
    //this.spinner.show();
    let noLastFinding = false;
    this.display = false;
    if (this.deleteFinding.id) {
      this.findingCrudService.deleteObservation(this.deleteFinding.id).subscribe(
        (res: any) => {
          if (res === true) {
            this.checkFinding.emit(this.deleteFinding.id);

            if (this.icrDataInitial !== null) {
              this.icrData = this.getFindings();
              const index = this.icrData.findIndex(
                (finding: any) => finding.id === this.deleteFinding.id,
              );
              this.icrData.splice(index, 1);
              this.icrDataInitial.splice(this.findingIndex, 1);

              this.deletedFilterList();
            } else {
              this.icrData = this.getFindings();
              this.icrData.splice(this.findingIndex, 1);
              this.deletedNormalList();
              this.resetSeacherForm();
            }
            this.deleteFinding = null;
            //this.spinner.hide();
          } else {
            //this.spinner.hide();
            this.showToastError(
              'An error was encountered when loading the data, please try again later 11',
              'errorMessageicr-review-form',
            );
            this.resetSeacherForm();
          }
        },
        () => {
          //this.spinner.hide();
          this.showToastError(
            'An error was encountered when loading the data, please try again later 12',
            'errorMessageicr-review-form',
          );
          this.resetSeacherForm();
        },
      );
    } else {
      this.icrData.splice(this.findingIndex, 1);
      this.selectedItem = 'item' + 0;
      this.sendFinding.emit(this.icrData[this.findingIndex]);
      this.saveFindings();
      this.icrData = this.getFindings();
      noLastFinding = true;
      this.resetSeacherForm();
      //this.spinner.hide();
    }
    if (this.icrData.length === 1 && !noLastFinding && this.icrDataInitial === null) {
      this.noFinding();
    } else if (this.icrDataInitial !== null) {
      if (this.icrDataInitial.length === 1) {
        this.icrData = this.getFindings();
        if (this.icrData.length === 1) {
          this.noFinding();
        }
      }
    }
  }

  noFinding() {
    this.selectedItem = 'item' + 0;
    this.sendFinding.emit({
      buckets: '',
      comments: '',
      text: 'Findings/Observation',
      follow: '',
      followUpWith: '',
      icrReviewId: 'b3dd14db-539f-497b-9031-ed71d29b7a3d',
      id: '',
      risk: '',
      word_buckets: '',
      word_risk: '',
      period: '',
      reviewed: false,
      type: 'Manual',
    });
    setTimeout(() => {
      this.showToastWarning(
        'Information: No Findings/ Observations were flagged as medium or high risk.If you need if, you can add findings.',
        'warnMessageicr-review-form',
      );
    }, 3000);
  }

  deletedNormalList() {
    if (this.icrData.length > 0 && this.findingIndex !== 0) {
      this.selectedItem = 'item' + (this.findingIndex - 1);
      this.sendFinding.emit(this.icrData[this.findingIndex - 1]);
    } else if (this.icrData.length > 0 && this.findingIndex === 0) {
      this.selectedItem = 'item' + this.findingIndex;
      this.sendFinding.emit(this.icrData[this.findingIndex]);
    } else if (this.findingIndex === 0 && this.icrData.length === 0) {
      this.sendFinding.emit(this.findingEmpty);
    }
    this.display = false;
    this.saveFindings();
    this.filterFindings();
  }

  deletedFilterList() {
    if (this.icrDataInitial.length > 0 && this.findingIndex !== 0) {
      this.selectedItem = 'item' + (this.findingIndex - 1);
      this.sendFinding.emit(this.icrDataInitial[this.findingIndex - 1]);
    } else if (this.icrDataInitial.length > 0 && this.findingIndex === 0) {
      this.selectedItem = 'item' + this.findingIndex;
      this.sendFinding.emit(this.icrDataInitial[this.findingIndex]);
    } else if (this.findingIndex === 0 && this.icrDataInitial.length === 0) {
      this.sendFinding.emit(this.findingEmpty);
    }
    this.display = false;
    this.saveFindings();
    this.filterFindings();
  }

  saveFindings() {
    const stringData = JSON.stringify(this.icrData);
    localStorage.setItem('saveFindings', stringData);
  }

  searchFindings() {
    let findings = 0;
    this.icrData.forEach((finding: any) => {
      finding.visible = finding.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      if (finding.visible) {
        findings++;
      }
    });
    if (this.icrData.length && findings === 0) {
      this.nonOcurrences = true;
    } else {
      this.nonOcurrences = false;
    }
  }

  saveFindingsTemp(icrData: string) {
    const stringData = JSON.stringify(icrData);
    localStorage.setItem('saveFindings', stringData);
  }

  cancelRemoveFinding() {
    this.display = false;
    this.deleteFinding = null;
  }

  resetSeacherForm() {
    this.selectedRisk = 'All';
    this.bucketSelectItem = 'All';
    this.periodSelectItem = 'All';
    this.search = '';
    this.filterFindings();
    this.searchFindings();
  }

  filterFindings() {
    
    let icrData = this.getFindings();
    
    if (icrData && icrData.length) {
      icrData = icrData.filter(
        (finding: any) =>
          (finding.risk === this.selectedRisk || this.selectedRisk === 'All') &&
          (finding.buckets === this.bucketSelectItem || this.bucketSelectItem === 'All') &&
          (finding.period === this.periodSelectItem || this.periodSelectItem === 'All'),
      );
      this.ActivateAddFinding = true;
      icrData.forEach((element: any) => {
        if (element.isNotify == true) {
          this.ActivateAddFinding = false;
        }
      });
      this.selectedItem = 'item0';
      this.sendFinding.emit(icrData[0]);
      this.createNewFinding.emit(false);
      this.icrDataInitial = icrData;
      const finding = localStorage.getItem('lastFinding');
      if (finding) {
        const obj = JSON.parse(finding);
        const index = icrData.findIndex((item: any, i: number) => {
          return item.id == obj.id;
        });
        this.selectFinding(obj, index);
      }    
      this.icrData = icrData;
    }
  }

  showToastWarning(summary: string, detail: string) {
    this.messageToastService.showToastWarn('warn', summary, detail);
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }
}
