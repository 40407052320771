export const mainMenu = {
  mainMenu: [
    {
      field: 'Generate a New Analysis',
      icon: 'pi pi-file',
      link: '/generate-new-analysis',
      disabled: false,
    },
    {
      field: 'AFS Review',
      icon: 'pi pi-file',
      link: '/AfsReview',
      disabled: true,
    },
    { field: 'Score', icon: 'pi pi-file', link: '/score', disabled: true },
    {
      field: 'ICR Review',
      icon: 'pi pi-file',
      link: '/IcrReview',
      disabled: true,
    },
  ],
  steps: [
    {
      name: 'generateNewAnalysis',
      stepSelected: false,
    },
    {
      name: 'afsReview',
      stepSelected: false,
    },
    {
      name: 'score',
      stepSelected: false,
    },
    {
      name: 'icrReview',
      stepSelected: false,
    },
    {
      name: 'analytics',
      stepSelected: false,
    },
    {
      name: 'management',
      stepSelected: false,
    },
  ],
  stepsError: [
    {
      name: 'generateNewAnalysis',
      stepSelected: true,
    },
    {
      name: 'afsReview',
      stepSelected: false,
      disabled: true,
    },
    {
      name: 'score',
      stepSelected: false,
      disabled: true,
    },
    {
      name: 'icrReview',
      stepSelected: false,
      disabled: true,
    },
    {
      name: 'analytics',
      stepSelected: false,
      disabled: true,
    },
    {
      name: 'management',
      stepSelected: false,
      disabled: true,
    },
  ],
};
export const mainMenuInvited = {
  mainMenu: [
    {
      field: 'Generate new Action Plan',
      icon: 'fas fa-file-medical-alt',
      link: '/load-action-plan',
      disabled: false,
    },
    {
      field: 'Approve Action Plan',
      icon: 'fas fa-file-archive',
      link: '/ApproveActionPlan',
      disabled: false,
    },
    {
      field: 'My Action Plan',
      icon: 'fas fa-file-archive',
      link: '/MyActionPlan',
      disabled: false,
    },
  ],
  steps: [
    {
      name: 'loadActionPlan',
      stepSelected: false,
    },
    {
      name: 'myActionPlan',
      stepSelected: false,
    },
  ],
  stepsError: [
    {
      name: 'loadActionPlan',
      stepSelected: true,
    },
    {
      name: 'myActionPlan',
      stepSelected: false,
      disabled: true,
    },
  ],
};
