import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AfsReviewService {
  private testSubject = new BehaviorSubject([]);
  private checkCalculateScoreSubject = new BehaviorSubject<boolean>(false);

  private testObservable: Observable<any> = this.testSubject.asObservable();
  private checkCalculateScoreObservable: Observable<any> =
    this.checkCalculateScoreSubject.asObservable();

  setTest(dataTest: any) {
    this.testSubject.next(dataTest);
  }

  returnTest(): Observable<[]> {
    return this.testObservable;
  }

  setScore(booleanScore: any) {
    this.checkCalculateScoreSubject.next(booleanScore);
  }

  returnScore(): Observable<boolean> {
    return this.checkCalculateScoreObservable;
  }
}
