export const selects = {
  pages: {
    afs_review: {
      select: {
        answerFMS: [
          { label: '', value: '' },
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' },
        ],
        answerFMSManual: [
          { label: '', value: '' },
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' },
          { label: 'N/A', value: 'NA' },
        ],
        commentType: {
          En: [
            { label: '', value: '' },
            { label: 'Clean', value: 'Clean' },
            { label: 'Qualified', value: 'Qualified' },
            { label: 'Disclaimer of Opinion', value: 'Disclaimer of Opinion' },
            { label: 'Adverse', value: 'Adverse' },
          ],
          Fr: [
            { label: '', value: '' },
            { label: 'Favorable', value: 'Favorable' },
            { label: 'Avec réserve', value: 'Avec réserve' },
            {
              label: "Impossibilité d'exprimer opinion",
              value: "Impossibilité d'exprimer opinion",
            },
            { label: 'Défavorable', value: 'Défavorable' },
          ],
          Po: [
            { label: '', value: '' },
            { label: 'Limpa', value: 'Limpa' },
            { label: 'Com Ressalva', value: 'Com Ressalva' },
            { label: 'Abstenção', value: 'Abstenção' },
            { label: 'Adversa', value: 'Adversa' },
          ],
          Sp: [
            { label: '', value: '' },
            { label: 'Limpia', value: 'Limpia' },
            { label: 'Salvedad', value: 'Salvedad' },
            { label: 'Abstención', value: 'Abstención' },
            { label: 'Adversa', value: 'Adversa' },
          ],
        },
      },
    },
    icr_review: {
      select: {
        bucket: [
          { label: 'Choose', value: '' },
          {
            label: 'Eligibility of expenses',
            value: 'Eligibility of expenses',
          },
          { label: 'Contractual compliance', value: 'Contractual compliance' },
          {
            label: 'Financial information issues',
            value: 'Financial information issues',
          },
          { label: 'Procurement issues', value: 'Procurement issues' },
          {
            label: 'Technical or operational aspects',
            value: 'Technical or operational aspects',
          },
        ],
        bucketClassification: [
          { label: 'All', value: 'All' },
          {
            label: 'Eligibility of expenses',
            value: 'Eligibility of expenses',
          },
          { label: 'Contractual compliance', value: 'Contractual compliance' },
          {
            label: 'Financial information issues',
            value: 'Financial information issues',
          },
          { label: 'Procurement issues', value: 'Procurement issues' },
          {
            label: 'Technical or operational aspects',
            value: 'Technical or operational aspects',
          },
        ],
        period: [
          { label: 'All', value: 'All' },
          { label: 'Current year', value: 'Current' },
          { label: 'Previous year', value: 'Previous year' },
        ],
      },
      multiselects: {
        followUpWith: [
          { label: 'Project Team Leader', value: 'Project Team Leader' },
          { label: 'PRM', value: 'PRM' },
        ],
        internalReview: [
          { label: 'FMS', value: 'FMS' },
          { label: 'Project Team Leader', value: 'Project Team Leader' },
          { label: 'PRM', value: 'PRM' },
        ],
      },
    },
    users: {
      roles: [
        { label: 'Public user', value: 'Public user' },
        { label: 'Executor', value: 'Executor' },
        {
          label: 'Headquarters or Task Manager',
          value: 'Headquarters or Task Manager',
        },
        { label: 'Manager', value: 'Manager' },
        { label: 'Project Team Leader', value: 'Project Team Leader' },
      ],
    },
    dictionary: {
      select: {
        category: [
          { label: 'Select Category', value: 0 },
          { label: 'Opinion of the auditor', value: 'Opinion_of_the_auditor' },
          {
            label: 'Statements of cash receipts and payments',
            value: 'Statements_of_cash_receipts_and_payments',
          },
          {
            label: 'Statements of Cumulative of investments',
            value: 'Statement_of_Cumulative_Investments',
          },
          {
            label: 'Notes of the financial statement',
            value: 'Notes_to_the_financial_statement',
          },
        ],
        bucketClassification: [
          { label: 'Contractual compliance', value: 'Contractual compliance' },
          {
            label: 'Eligibility of expenses',
            value: 'Eligibility of expenses',
          },
          {
            label: 'Financial information issues',
            value: 'Financial information issues',
          },
          { label: 'Procurement issues', value: 'Procurement issues' },
          {
            label: 'Technical or operational aspects',
            value: 'Technical or operational aspects',
          },
          { label: 'Unclassified', value: 'Unclassified' },
        ],
      },
    },
  },
};
