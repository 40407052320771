import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IcrDictionaryService {
  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  getIcrDictionary(lang: string) {
    return this.httpClient.get(environment.api.base + 'dictionarys/keywords?language=' + lang);
  }

  addKeywords(data: any) {
    return this.httpClient.post(
      environment.api.base +
        'dictionarys/keywords?language=' +
        data.lang +
        '&risk=' +
        data.risk +
        '&bucket=' +
        data.bucket,
      data.keyword,
    );
  }

  deleteKeyword(id: string) {
    return this.httpClient.delete(environment.api.base + 'dictionarys/keywords/' + id);
  }
}
