<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
<p-toast class="bl-toast bl-toast--error" key="errorTools" closable="true"></p-toast>
<p-toast class="bl-toast bl-toast--success" key="successTools" closable="true" [escape]="true">
</p-toast>
<!--bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher-->
<div class="bl-wrapper">
  <div class="bl-tools">
    <div class="bl-tools__wrapper">
      <div class="bl-title">
        <h1>Tools</h1>
      </div>
      <div class="bl-tools__update-models">
        <span>Update models</span>
      </div>
      <div class="bl-tools__update-content">
        <div class="bl-tools__update-content-text">
          <span class="bl-tools__update-run"
            ><i class="bl-tools__update-icon fas fa-sync-alt"></i> You can run a manual update at
            any time.</span
          >
          <button *ngIf="!showProgessBar" (click)="updateModels()" class="bl-button--btn-secundary">
            Update Models
          </button>
          <div *ngIf="showError">
            <span class="bl-tools__msg-error">An Unexpected error occurred. Please try again.</span>
          </div>
        </div>
        <div *ngIf="showProgessBar" class="t-text-align-right bl-tools__progressbar-container">
          <div class="bl-tools__progressbar-wrapper col-6">
            <p-progressBar class="bl-tools__progressbar" [value]="progressValue"></p-progressBar>
          </div>
        </div>
      </div>
      <div class="bl-tools__update-info">
        <span class="bl-tools__top-ten">Top ten</span>
        <span>Last update: {{ lastTrainingDate | date: 'longDate' }}</span>
      </div>
      <div>
        <!--bid-tools-table
          [headerTable]="headersTableTools"
          [bodyTable]="bodyTableTools"
        ></bid-tools-table-->
      </div>
    </div>
  </div>
</div>
