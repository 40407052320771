<p-toast
  class="bl-toast bl-toast--success"
  key="successMessageTestDescriptions"
  closable="true"
  [escape]="true"
></p-toast>
<p-toast
  class="bl-toast bl-toast--error"
  key="errorMessageTestDescriptions"
  closable="true"
></p-toast>
<p-dialog
  header="Quit"
  [(visible)]="display"
  [modal]="true"
  [responsive]="true"
  [maximizable]="false"
  [baseZIndex]="10000"
  [closable]="false"
  styleClass="bl-modal"
  [positionTop]="65"
  [positionLeft]="390"
>
  <p>Do you want to exit ?</p>
  <p-footer>
    <button type="button" pButton (click)="disclaimerEdition()" label="Yes, exit"></button>
    <button
      type="button"
      pButton
      (click)="confirmEditing()"
      label="Keep editing"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>
<div class="bl-table">
  <form [formGroup]="formTestDescriptions">
    <p-table [columns]="headerTable" [value]="bodyTable" [responsive]="true" sortMode="multiple">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            [ngClass]="{
              sortable: col.field === 'type' || col.field === 'id' || col.field === 'name'
            }"
            *ngFor="let col of columns"
            [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="col.field === 'notes' || col.field === 'crud'"
            [ngStyle]="{ width: col.width }"
          >
            <span> {{ col.header }}</span>
            <p-sortIcon
              *ngIf="col.field === 'type' || col.field === 'id' || col.field === 'name'"
              [field]="col.field"
              ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order"
              ariaLabelAsc="Activate to sort in ascending order"
            >
            </p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template
        *ngIf="bodyTable.length > 0"
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-i="rowIndex"
      >
        <tr>
          <td
            *ngFor="let col of columns"
            [ngClass]="{
              'bl-select--has-error': !rowData[col.field] && rowData.answer_luca === 'Manual'
            }"
          >
            <div
              [ngSwitch]="col.field"
              [ngClass]="{
                colorRed: rowData.answerFms === 'No' || rowData.answer_luca === 'No',
                colorGrey: rowData.answer_luca === 'Manual'
              }"
            >
              <div *ngSwitchCase="'type'">
                <a title="Initial analysis" *ngIf="rowData[col.field] === 'Init'"
                  ><i class="bullet-blue"></i
                ></a>

                <a
                  title="Initial & Basic Elements of the Auditor 's Opinion"
                  *ngIf="rowData[col.field] === 'Init_pass' || rowData[col.field] === 'A_pass'"
                >
                  <i class="two-color-bullet"></i
                ></a>
                <a
                  title="A: Basic Elements of the Auditor`s Opinion"
                  *ngIf="rowData[col.field] === 'A'"
                  ><i class="bullet-brown"></i
                ></a>
                <a title="B: Project Financial Statements" *ngIf="rowData[col.field] === 'B'"
                  ><i class="bullet-green"></i
                ></a>
                <a title="C: Notes to Financial Statements" *ngIf="rowData[col.field] === 'C'"
                  ><i class="bullet-yellow"></i
                ></a>
              </div>
              <div *ngSwitchCase="'id'">
                {{ rowData[col.field] }}
              </div>
              <div *ngSwitchCase="'name'">
                <div *ngIf="showTextArea && row === i" class="bl-textarea">
                  <textarea
                    [name]="'textareaTestNames-' + i"
                    [formControlName]="'textareaTestNames-' + i"
                    [(ngModel)]="rowData[col.field]"
                    class="bl-textarea__area--size-small bl-textarea__area--colorTable"
                  ></textarea>
                </div>
                <a *ngIf="row !== i || !showTextArea" class="tooltip top"
                  >{{ rowData[col.field] }}
                  <span class="tiptext">{{ rowData[col.field] }}</span>
                </a>
              </div>
              <div *ngSwitchCase="'description'">
                <div *ngIf="showTextArea && row === i" class="bl-textarea">
                  <textarea
                    [name]="'textareaTestDescriptions-' + i"
                    [formControlName]="'textareaTestDescriptions-' + i"
                    [(ngModel)]="rowData[col.field]"
                    class="bl-textarea__area--size-small bl-textarea__area--colorTable bl-textarea__area--heigth-tall"
                  ></textarea>
                </div>
                <span *ngIf="row !== i || !showTextArea">{{ rowData[col.field] }}</span>
              </div>
              <div *ngSwitchCase="'crud'">
                <ul class="bl-dictionary__inline-buttons">
                  <li *ngIf="!showTextArea || (showTextArea && row !== i)">
                    <a (click)="updateTest(i, rowData)" class="bl-finding-form__color-icon"
                      ><i class="fas fa-pen"></i
                    ></a>
                  </li>
                  <li *ngIf="showTextArea && row === i">
                    <a
                      (click)="closeTextArea(i)"
                      class="bl-finding-form__color-icon"
                      [class.disabled]="row !== i"
                      ><i class="fas fa-times"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      class="bl-finding-form__color-icon"
                      (click)="returnLastText(rowData, i)"
                      [class.disabled]="row !== i"
                      ><i class="fas fa-undo-alt"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      (click)="confirmSaveData(rowData, i)"
                      class="bl-finding-form__color-icon"
                      [class.disabled]="row !== i"
                    >
                      <i class="far fa-save"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>
</div>
