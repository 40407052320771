import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EstimatedProcessingTimeService {
  files: File[] | undefined;

  calculateEstimatedTime(files: any, reviewPages: number, icrpages: number , controlReport: string) {
    let result = 0;
    if (reviewPages > 0 && icrpages > 0) {
      result = 0;
      result = 150 + 3 * reviewPages + 6 * icrpages;
    } else if (reviewPages > 0 && !icrpages) {
      result = 0;
      result = 90 + 9 * reviewPages;
    } else if (controlReport === 'noIcr' && reviewPages > 0) {
      result = 0;
      result = 90 + 3 * reviewPages;
    }
    return result;
  }
}
