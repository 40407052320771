<p-dialog
  header="Remove keyword of Contractual Compliance"
  [(visible)]="display"
  [modal]="true"
  [responsive]="true"
  [maximizable]="false"
  [baseZIndex]="10000"
  [closable]="false"
  styleClass="bl-modal"
  [positionTop]="65"
  [positionLeft]="390"
>
  <p>
    Do you want to remove the keyword <strong>{{ chipsSeleted }}</strong
    >? This operation cannot be undone, it is irrerversible.
  </p>
  <p-footer>
    <button type="button" pButton (click)="toggleDialog()" label="Cancel"></button>
    <button
      type="button"
      pButton
      (click)="removeWord()"
      label="Yes"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>
<div class="bl-chips__area-content bl-chips__area-content--big-heigth">
  <ul class="bl-chips__wrapper">
    <li
      *ngFor="let chip of chips; let i = index"
      class="bl-chips__dictionary-list"
      [ngClass]="{
        'bl-chips__red': chip.status === 'error',
        'bl-chips__green': chip.status === 'added'
      }"
    >
      <span class="bl-chips__dictionary">
        <i
          [ngClass]="{
            'bl-chips__bullets-yellow': chip?.risk === 'Low',
            'bl-chips__bullets-red': chip?.risk === 'High'
          }"
        ></i>
        <span class="bl-chips__text">{{ chip?.name }}</span>
        <a (click)="removeWordDictionary(chips, i)" class="bl-chips__icon-remove"
          ><i class="fas fa-times"></i
        ></a>
      </span>
    </li>
  </ul>
</div>
