import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class adminUsers {
  private sendPositionSubject = new BehaviorSubject([]);

  private sendPositioObservable: Observable<any> = this.sendPositionSubject.asObservable();

  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  addExternalUser(json: any){
    return this.httpClient.post(environment.api.base + 'ExternalUser/AddExternalUser',json)
  }

  upDateExternalUser(json: any){
    return this.httpClient.put(environment.api.base + 'ExternalUser/UpdateExternalUser',json)
  }

  daleteExternalUser(json: any){
    return this.httpClient.post(environment.api.base + 'ExternalUser/DeleteExternalUser',json)
  }

  getExternalUserList (){
    return this.httpClient.get(environment.api.base + 'ExternalUser/GetExtenalUserList');
  }

  bulkLoad (json: any) {
    return this.httpClient.post(environment.api.base + 'ExternalUser/BulkLoad',json);
  }

}
