import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  getToolsData() {
    return this.httpClient.get(environment.api.base + 'trainings/dates');
  }

  getLastTrainingDate() {
    return this.httpClient.get(environment.api.base + 'trainings/date');
  }

  getTrainModels() {
    return this.httpClient.get(environment.api.base + 'trainings/models');
  }
}
