<div class="container">
    <div class="row">
        <div class="col-6">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label></mat-label>
                <input matInput placeholder="Buscar usuario por nombre o email" value="">
              </mat-form-field>
        </div>
        <div class="col-2">
            <a mat-stroked-button color="primary"  href="PlantillaUsuarios.xlsx" download="PlantillaUsuarios.xlsx">
              Descargar template
            </a>
        </div>
        <div class="col-2">
            <button mat-raised-button color="accent" [disabled] = '!jsonUploadFile' (click)="sendMasiveUsers()">Cargar usuarios</button>
        </div>
        <div class="col-2">
            <input type="file" name="" id="" (change)="loadFile($event)"> 
        </div>
    </div>
    
    <div class="row">
        <!--table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" style="width: 100%" #tabla1>

            <ng-container matColumnDef="id">
                <th class="th-header__th-color" mat-header-cell *matHeaderCellDef style="width: 5%">#</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <a class="tooltip top">{{ element.id }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th class="th-header__th-color" mat-header-cell *matHeaderCellDef style="width: 10%">Nombre</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <a class="tooltip top">{{ element.name }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                    <th class="th-header__th-color" mat-header-cell *matHeaderCellDef style="width: 15%">Email</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <a class="tooltip top">{{ element.email }}</a>
                    </td>
              </ng-container>

              <ng-container matColumnDef="accessLevel">
                <th class="th-header__th-color" mat-header-cell *matHeaderCellDef style="width: 20%">Nivel de acceso</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <mat-form-field appearance="fill"  
                    matTooltip="Seleccione un nivel">
                      <mat-label>Nivel de acceso</mat-label>
                      <mat-select [(ngModel)]="element.accessLevel" (ngModelChange)="saveAccessFMP($event)">
                        <mat-option *ngFor="let level of accessLevels" [value]="level.value">
                          {{level.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </td>
          </ng-container>

          <ng-container matColumnDef="accessFMP">
            <th class="th-header__th-color" mat-header-cell *matHeaderCellDef style="width: 20%">Acceso FMP</th>
            <td mat-cell *matCellDef="let element; let i = index">
                <mat-slide-toggle [(ngModel)]="element.accessFMP" (ngModelChange)="saveAccessFMP($event)"></mat-slide-toggle>
            </td>
          </ng-container>

          <ng-container matColumnDef="lastUpdate">
            <th class="th-header__th-color" mat-header-cell *matHeaderCellDef style="width: 20%">Última modificación</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <a class="tooltip top">{{ element.lastUpdate }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="updateFor">
            <th class="th-header__th-color" mat-header-cell *matHeaderCellDef style="width: 10%">Modificado por</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <a class="tooltip top">{{ element.updateFor }}</a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table --> 
    </div>
</div>

