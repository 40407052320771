import { Component, OnInit, DoCheck } from '@angular/core';
import { headersTables } from '@bl-constants/table-header.const';
import { MessageToastService } from '@core/messages/message-toast.service';
import { StepperService } from '@core/stepper/stepper.service';
import { ToolsService } from '@core/tools/tools.service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'bid-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
  providers: [DatePipe],
})
export class ToolsComponent implements OnInit, DoCheck {
  headersTableTools: any;
  bodyTableTools: any = [];
  showProgessBar: boolean;
  onAnalysis: boolean = false;
  progressValue: number = -1;
  estimatedTime: number = -1;
  lastTrainingDate: string = '';
  showError: boolean = false;
  showHistoricalSearch: boolean;
  _messageToastService: any;
  _stepperService: any;
  _toolsService: any;
  _showHistoricalSearchService: any;
  STEP0 = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'afsReview', stepSelected: false, disabled: true },
    { name: 'score', stepSelected: false, disabled: true },
    { name: 'icrReview', stepSelected: false, disabled: true },
  ];
  constructor(
    private messageToastService: MessageToastService,
    private stepperService: StepperService,
    private toolsService: ToolsService,
    //private spinner: NgxSpinnerService,
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private datePipe: DatePipe,
  ) {
    this.showProgessBar = false;
    this.showHistoricalSearch = false;
    this._messageToastService=messageToastService;
    this._stepperService=stepperService;
    this._toolsService=toolsService;
    this._showHistoricalSearchService=showHistoricalSearchService;
  }

  ngOnInit() {
    //this.spinner.show();
    this.stepperService.setStepper(this.STEP0);
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.estimatedTime = 20;
    this.progressValue = 0;
    this.headersTableTools = headersTables.headerToolsTable;
    this.loadLastDay();
    this.loadDataTable();
  }

  ngDoCheck() {
    this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });
  }

  loadLastDay() {
    this.toolsService.getLastTrainingDate().subscribe(
      (res: any) => {
        this.lastTrainingDate = res;
      },
      () => {
        this.showToastError(
          'We had a problem loading the Last update, please try again later.',
          'errorTools',
        );
      },
    );
  }

  loadDataTable() {
    this.toolsService.getToolsData().subscribe(
      (res: any) => {
        if (res) {
          this.bodyTableTools = this.parseData(res);
          //this.spinner.hide();
        }
      },
      () => {
        //this.spinner.hide();
        this.showToastError(
          'We had a problem loading the data of table (Top Ten), please try again later.',
          'errorTools',
        );
      },
    );
  }

  parseData(data: any) {
    const arr = [];
    const obj: any = {
      date: '',
      hour: '',
      runmode: '',
    };
    for (let i = 0; i < data.length; i++) {
      if (data[i].trainingDate) {
        const date = data[i].trainingDate.split('-');
        const day = date[2].split('T');
        const hours = day[1].split(':');
        const minutes = hours[1];
        let seconds = hours[2].split('Z');
        if (seconds[0].indexOf('.') > -1) {
          seconds = seconds[0].split('.');
        }

        obj.date = this.datePipe.transform(data[i].trainingDate, 'dd/MM/yyyy') || '';
        obj.hour = hours[0] + ':' + minutes + ':' + seconds[0];
      }
      if (data[i].type) {
        obj.runmode = data[i].type.toString();
      }
      arr.push({
        date: obj.date,
        hour: obj.hour,
        runmode: data[i].type.toString(),
      });
    }
    return arr;
  }

  updateModels() {
    this.progressbarStart();
    this.toolsService.getTrainModels().subscribe(
      (res: any) => {
        if (res !== true) {
          this.progressbarEnd();
          this.showError = true;
        }
      },
      () => {
        this.progressbarEnd();
        this.showError = true;
      },
    );
  }

  progressbarStart() {
    let loadFinish = false;
    this.showProgessBar = true;
    this.progressValue = 0;
    window.scrollTo(0, document.body.scrollHeight);
    const intervalBar = setInterval(() => {
      this.progressValue += 1;
      if (this.progressValue === 100) {
        this.showProgessBar = false;
        this.showToastSuccess();
        this.loadDataTable();
        this.loadLastDay();
        clearInterval(intervalBar);
        loadFinish = true;
      }
    }, 100 * this.estimatedTime);

    return loadFinish;
  }

  progressbarEnd() {
    this.showProgessBar = false;
  }

  showToastSuccess() {
    this.messageToastService.showToastSuccess('Success', 'Updated completed', 'successTools');
  }

  showToastError(summary: string, key: string) {
    this.messageToastService.showToastError('error', summary, key);
  }
}
