import { Component, Input } from '@angular/core';

@Component({
  selector: 'bid-content-border-info',
  templateUrl: './content-border-info.component.html',
  styleUrls: ['./content-border-info.component.scss'],
})
export class ContentBorderInfoComponent {
  @Input() iconClass: any;
  @Input() mainTxt: any;
  @Input() type: any;
  @Input() typeTotalPercent: any;
  @Input() contentTxt: any;
  @Input() letter: string = '';
  @Input() tooltipInfo: string = '';
  @Input() noIcon: boolean = false;
}
