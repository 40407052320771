import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { menuIcrDictionarySelectbuttons } from '@bl-constants/menu-icr-dictionary.const';
import { menuRiskSelectbuttons } from '@bl-constants/menu-risk-select-buttons.const';
import { menuAccionFindingsSelectbuttons } from '@bl-constants/accion-findings.const';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { languages } from './../../shared/constants/languages.const';
import { SelectItem } from 'primeng/api';
import { headersTables } from '@bl-constants/table-header.const';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';

@Component({
  selector: 'bid-icr-dictionary-findings',
  templateUrl: './icr-dictionary-findings.component.html',
  styleUrls: ['./icr-dictionary-findings.component.scss'],
})
export class IcrDictionaryFindingsComponent implements OnInit, DoCheck {
  pages: any;
  languageCombo: any = [];
  selectedOptionMenu: any;
  selectedAccionMenu: any;
  formGroupSeacherFindings!: FormGroup;
  menu: SelectItem[];
  accion: any;
  headersTablesAddedFindings: any[] = [];
  headersTablesRemovedFindings: any[] = [];
  bodyTableAddedFinding: any[] = [];
  selectedRisk: any;
  showHistoricalSearch: boolean;
  constructor(
    private router: Router,
    private showHistoricalSearchService: ShowHistoricalSearchService,
  ) {
    this.showHistoricalSearch = false;
    this.pages = menuIcrDictionarySelectbuttons;
    this.languageCombo = languages;
    this.selectedOptionMenu = 'Added and removed findings';
    this.selectedAccionMenu = 'Added manually';
    this.selectedRisk = 'All';
    this.menu = menuRiskSelectbuttons;
    this.accion = menuAccionFindingsSelectbuttons;
  }

  ngOnInit() {
    this.headersTablesAddedFindings = headersTables.headerAddedFindingsTable;
    this.headersTablesRemovedFindings = headersTables.headerRemovedFindingsTable;
    this.formGroupSeacherFindings = new FormGroup({
      language: new FormControl('', Validators.required),
      selectPeriod: new FormControl('', Validators.required),
    });
  }

  ngDoCheck() {
    /*this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });*/
  }

  rediredTo(data: any) {
    if (data.value === 'ICR dictionary') {
      this.router.navigate(['/icr-dictionary']);
    } else if (data.value === 'Added and removed findings') {
      this.router.navigate(['/icr-dictionary-findings']);
    }
  }
  getAccion(data: any) {
    switch (data) {
      case 'Added manually':
        this.selectedAccionMenu = 'Added manually';
        break;
      case 'Removed':
        this.selectedAccionMenu = 'Removed';
        break;
    }
  }

  searchResult() {
    this.bodyTableAddedFinding = [
      {
        creationDate: '02/05/2020',
        risk: 'High',
        finding: 'Financial information Issues',
        Bucket: 'Financial information Issues',
        observation:
          'Some expenses were omitted from the detailed.Some expenses were omitted from the detailed.Some expenses were omitted from the detailed.Some expenses were omitted from the detailed.',
        operationNumber: '3588/OC-BA',
        auditPeriod: '2020/01/01 - 2020/01/22',
        keyWordsSuggested: [
          {
            word: 'inadequate',
            status: 'high',
          },
          {
            word: 'policies',
            status: 'low',
          },
        ],
        AcceptandEditKeyword: true,
        reviewList: true,
      },
      {
        creationDate: '02/05/2020',
        risk: 'High',
        finding: 'Financial information Issues',
        Bucket: 'Financial information Issues',
        observation: 'Some expenses were omitted from the detailed.',
        operationNumber: '3588/OC-BA',
        auditPeriod: '2020/01/01 - 2020/01/22',
        keyWordsSuggested: [
          {
            word: 'managemen',
            status: 'low',
          },
        ],
        AcceptandEditKeyword: true,
        reviewList: true,
      },
    ];
  }
}
