import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetFindingsService {
  private findingSubject = new BehaviorSubject([]);
  private findingRespSubject = new BehaviorSubject([]);

  private findingObservable: Observable<any> = this.findingSubject.asObservable();
  private findingRespObservable: Observable<any> = this.findingRespSubject.asObservable();

  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  getFindings(analysisId: string) {
    return this.httpClient.get(environment.api.base + 'icrs/' + analysisId + '/observations');
  }

  setcheckMarkFinding(test: any) {
    this.findingSubject.next(test);
  }

  getcheckMarkFinding(): Observable<[]> {
    return this.findingObservable;
  }
}
