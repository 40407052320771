import { Component, OnInit, DoCheck } from '@angular/core';
import { ScoreService } from '@core/score/score.service';
import { ranges } from '@shared/interfaces/ranges';
import { scoreResult } from '@shared/interfaces/score-result';
import { MessageToastService } from '@core/messages/message-toast.service';
import { AfsReviewService } from '@core/afs-review/afs-review.service';
import { headersTables } from '@bl-constants/table-header.const';
import { TooltipTestLevelService } from '@core/messages/tooltip-test-level.service';
import { StartAnalysisService } from '@core/analysis/start-analysis.service';
import { AnalysisStatusService } from '@core/analysis/analysis-status.service';
import { Router } from '@angular/router';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import { GetAnalysisService } from '@core/analysis/get-analysis.service';
import { StepperService } from '@core/stepper/stepper.service';
import { SummaryReportService } from '@core/summaryReport/summary-report.service';
//import { NgxSpinnerService } from 'ngx-spinner';
//import { MatDialog } from '@angular/material';
//import { ModalFindingsComponent } from '../../shared/components/modal-findings/modal-findings.component';


@Component({
  selector: 'bid-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnInit, DoCheck {
  ranges: ranges[] = [];
  scoreResult!: scoreResult;
  scoreA: number = 0;
  scoreB: number = 0;
  scoreC: number = 0;
  calculateScore: boolean = false;
  tableScore: any = [];
  analysisData: any;
  headerTableScore: any;
  tooltipInfo: string = '';
  display: boolean;
  showHistoricalSearch: boolean = false;
  scoreUploaded: boolean = false;
  showBtn: boolean = false;
  statusCompleted: boolean = false;
  filterTableScore: any = [];
  summaryReportName: string = '';
  urlSummaryReport: string = '';
  CURRENTSTEPNOICR: any [] = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'score', stepSelected: true },
  ];
  CURRENTSTEPICR: any[] = [
    { name: 'afsReview', stepSelected: true },
    { name: 'score', stepSelected: true },
    { name: 'icrReview', stepSelected: true },
  ];
  analysisTime: any;
  constructor(
    private scoreService: ScoreService,
    private messageToastService: MessageToastService,
    private afsReviewService: AfsReviewService,
    private startAnalysisService: StartAnalysisService,
    private getAnalysisService: GetAnalysisService,
    private tooltipTestLevelService: TooltipTestLevelService,
    private analysisStatusService: AnalysisStatusService,
    private router: Router,
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private stepperService: StepperService,
    private summaryReportService: SummaryReportService,
    //public modal: MatDialog,
    //private spinner: NgxSpinnerService,
  ) {
    this.display = false;
  }

  ngOnInit() {
    //this.spinner.show();
    const analysis = JSON.parse(localStorage.getItem('analisisResultLocalStorage') || '{}');
    this.stepperService.setStepper( (analysis.icrStatus == 'Without ICR') ? this.CURRENTSTEPNOICR: this.CURRENTSTEPICR );
    
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.analysisTime = sessionStorage.getItem('pocessingTime');
    this.analysisTime = parseInt(this.analysisTime, 0);
    this.afsReviewService.returnScore().subscribe((res: any) => {
      this.calculateScore = res;
    });
    this.headerTableScore = headersTables.headerScoreTable;
    this.getAnalysisIdObservable();
    this.getRangesScore();
    window.scrollTo(0, 0);
  }

  ngDoCheck() {
    this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });
  }

  loadBtn() {
    setTimeout(() => {
      this.showBtn = true;
    }, 500);
  }

  downloadDraftSummaryReport() {
    const analysisRecover = localStorage.getItem('analysisId') || '';
    //this.spinner.show();
    this.getAnalysis(analysisRecover);
    this.summaryReportService.generateDraftReport(analysisRecover).subscribe(
      async (res: string) => {
        await this.summaryReportService.download(res, 'draft').toPromise()
        .then( (response: any) => {
          const blob = new Blob([response], { type: response.type });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          //this.spinner.hide();

        }, (error) => {
          console.log('error draf=>',error)
          //this.spinner.hide();
        });
      },
      () => {
        this.showToastError(
          'An error occurred when loading summary draft report',
          'errorMessageScore',
        );
      },
    );
  }

  getResultTestScore(dataAnalysis: any) {
    if (dataAnalysis) {
      this.scoreService.updateScore(dataAnalysis.testResults, dataAnalysis.score.id).subscribe(
        (res: scoreResult) => {
          const noContentResp = this.isEmpty(res);
          if (res && !noContentResp) {
            this.getRangesScore();
            this.scoreResult = res;
            this.scoreA = +this.scoreResult.a_score;
            this.scoreB = +this.scoreResult.b_score;
            this.scoreC = +this.scoreResult.c_score;
          } else {
            this.showToastError('Error loading data, please try again later 1', 'errorMessageScore');
          }
        },
        () => {
          this.showToastError('Error loading data, please try again later 2', 'errorMessageScore');
        },
      );
    }
  }

  isEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  getAnalysisIdObservable() {
    //this.spinner.show();
    this.startAnalysisService.getAnalysis().subscribe(
      (_analysisId) => {
        const id = (localStorage.getItem('analysisId') || '').replace(/\"/g, '');
        this.getAnalysis(id);
      },
      () => {
        this.showToastError(
          'An error was encountered when loading the data, please try again later 2',
          'errorMessageAfsReview',
        );
      },
    );
  }

  getAnalysis(analysisId: string) {
    console.log("getAnalysis", analysisId)
    //this.spinner.show();
    let initScore: any = {};
    if (analysisId) {
      this.saveAnalysisId(analysisId);
      this.getAnalysisService.getAnalysis(analysisId).subscribe((res) => {
        const noContentResp = this.isEmpty(res);
        if (res && !noContentResp) {
          this.analysisData = res;
          this.summaryReportName = res.summaryReport && res.summaryReport.name;
          this.urlSummaryReport = this.summaryReportName ? res.summaryReport.path : '';
          if (this.calculateScore === null || this.calculateScore === false) {
            initScore = localStorage.getItem('analisisResultLocalStorage');
            initScore = JSON.parse(initScore);
          }
          if (res.status === 'In progress') {
            this.statusCompleted = false;
            this.loadBtn();
          } else {
            this.statusCompleted = true;
            this.loadBtn();
          }
          if (
            this.analysisData &&
            this.analysisData.testResults &&
            this.analysisData.testResults.length > 0
          ) {
            this.filterScore(this.analysisData.testResults);
            //this.spinner.hide();
          }
          if (this.calculateScore === true) {
            this.getResultTestScore(this.analysisData);
          } else {
            this.getResultTestScore(initScore);
          }
        }
      });
    } else {
      if (this.calculateScore !== null && this.calculateScore === false) {
        initScore = localStorage.getItem('analisisResultLocalStorage');
        initScore = JSON.parse(initScore);
      } else {
        const analysisIdLocalStorage = localStorage.getItem('analysisId');
        if (analysisIdLocalStorage !== null) {
          this.getAnalysis(JSON.parse(analysisIdLocalStorage));
        } else {
          this.router.navigate(['/generate-new-analysis']);
        }
      }
    }
  }

  generateSummaryReport() {
    this.summaryReportService.generateReport(this.analysisData.id).subscribe((res: any) => {
      if (res) {
        this.urlSummaryReport = res;
      }
    });
  }

  checkSummaryReport(data: any){
    this.summaryReportName=data.name;
    this.urlSummaryReport=data.path;
  }

  filterScore(data: any) {
    this.filterTableScore = data.filter(
      (element: any) => element.type === 'Init_pass' || element.type === 'A_pass',
    );
  }

  saveAnalysisId(data: string) {
    //const analysisData = JSON.stringify(data);
    localStorage.setItem('analysisId', data);
  }

  getRangesScore() {
    this.scoreService.getScoreRanges().subscribe(
      (res: ranges[]) => {
        const noContentResp = this.isEmpty(res);
        if (res && !noContentResp) {
          this.ranges = res;
          this.tooltipInfo = this.tooltipTestLevelService.getTooltipContent(this.ranges);
        } else {
          this.ranges = [
            {
              score: 'Inferior',
              range: '1-6',
            },
            {
              score: 'Basic',
              range: '7',
            },
            {
              score: 'Medium',
              range: '8',
            },
            {
              score: 'Superior',
              range: '9-10',
            },
          ];
          this.tooltipInfo = this.tooltipTestLevelService.getTooltipContent(this.ranges);
        }
      },
      () => {
        //this.spinner.hide();
        this.showToastError('Error loading data, please try again later 3', 'errorMessageScore');
      },
    );
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }

  toggleDialog() {
    this.display = !this.display;
  }

  async downloadSummary(url: string, filename: string){

    if(url.indexOf('blob.core.windows') > 0){ 
      await this.summaryReportService.download(url, 'summary').toPromise()
          .then( (response: any) => {

            const blob = new Blob([response], { type: response.type });
            const url = window.URL.createObjectURL(blob);

            const anchorElement = document.createElement('a');
            document.body.appendChild(anchorElement);
            anchorElement.href = url;
            anchorElement.download = filename + ".docx";
            anchorElement.click();

            window.URL.revokeObjectURL(url);

            //this.spinner.hide();

          }, (error) => {
            
            //this.spinner.hide();

          });
    } else {
      window.open(url);
    }

  }

  setStatusCompleted() {
    //this.spinner.show();
    if (this.analysisData && this.analysisData.id) {
      this.analysisStatusService.setStatusCompleted(this.analysisData.id).subscribe(
        (respStatus) => {
          if (respStatus) {
            this.getAnalysis(this.analysisData.id);
            this.statusCompleted = true;
            this.toggleDialog(); 
          }
        },
        () => {
          //this.spinner.hide();
          this.showToastError('Error', 'Error al modificar el estado del análisis');
        },
      );
    } else {
      //this.spinner.hide();
    }
  }

  //finishAFSReview(){

    /*this.modal.open(FindingContentComponent,{
      width: '600px',
      data: { term: '15' },
    })*/

    /*const dialogRef = this.modal.open(ModalFindingsComponent, {
      width: '700px',
      height: '600px',
      data: { term: '15'},
    });

    // eslint-disable-next-line
    dialogRef.afterClosed().subscribe(result =>{
      //this.displayicr = true;
    });*/

  //}
}
