<bid-seacher *ngIf="showHistoricalSearch"></bid-seacher>
<div class="bl-wrapper">
  <div class="bl-generate-analysis col-12 col-md-8">
    <form [formGroup]="analyzeForm">
      <h1 class="bl-generate-analysis__title">Generate a New Analysis</h1>
      <p class="bl-generate-analysis__subtitle">(All fields are required for a new analysis)</p>
      <div class="bl-generate-analysis__operation-number">
        <div class="bl-input" [ngClass]="{ 'bl-input--error': !operationNumberValid }">
          <label class="bl-input__label">Loan number</label>
          <input
            type="text"
            pInputText
            formControlName="operationNumber"
            placeholder="Ex: ####/XX-CC-#"
            (change)="getValueOperationNumber()"
            [attr.disabled]="onAnalysis ? '' : null"
            required
          />
        </div>
      </div>
      <div class="bl-generate-analysis__audit-date">
        <div class="bl-datepicker" [ngClass]="{ 'bl-datepicker--error': !auditDateValid }">
          <label class="bl-datepicker__label">Audit Period:</label>
          <p-calendar
            icon="pi pi-calendar"
            showIcon="true"
            selectionMode="range"
            dateFormat="yy/mm/dd"
            formControlName="auditDate"
            ngDefaultControl
            [disabled]="onAnalysis"
          ></p-calendar>
        </div>
      </div>
      <div class="bl-generate-analysis__processing-info">
        <div class="bl-generate-analysis__language-wrapper">
          <label class="bl-generate-analysis__language-label" for="language">
            Select the language of the AFS:
          </label>
          <p-selectButton
            [options]="languages"
            formControlName="language"
            styleClass="bl-generate-analysis__language-select"
            id="language"
            [disabled]="onAnalysis"
          ></p-selectButton>
        </div>
        <div class="bl-generate-analysis__processing-time-wrapper">
          <span class="bl-generate-analysis__processing-time-label">
            Estimated processing time:
          </span>
          <div class="bl-generate-analysis__processing-time">
            <i class="far fa-clock"></i>
            <span>
              {{ estimatedTime === 0 ? '--' : (estimatedTime | minsec) }}
            </span>
          </div>
        </div>
      </div>
      <div class="bl-generate-analysis__upload-wrapper">
        <div class="bl-generate-analysis__upload-top">
          <span class="bl-generate-analysis__upload-label">
            Select the document for review (.pdf)
          </span>
          <div [class.bl-generate-analysis__upload-container]="!reviewFile && !ezshareDocument">
            <bid-ezshare-table
              class="bl-generate-analysis__upload"
              (documentSelected)="selectDocument($event)"
              [loanNumber]="analyzeForm.get('operationNumber')?.value"
              [auditPeriodType]="analyzeForm.get('auditDate')?.value"
              *ngIf="!ezshareDocument && !reviewFile"
            >
            </bid-ezshare-table>
            <div
              class="bl-generate-analysis__upload ui-fileupload ui-widget"
              *ngIf="ezshareDocument"
            >
              <div class="ui-fileupload-buttonbar ui-widget-header ui-corner-top">
                <span
                  class="ui-fileupload-choose ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left ui-state-disabled"
                  icon="pi pi-plus"
                  pbutton=""
                >
                  <input type="file" multiple="" accept=".pdf" /> <!--class="ui-clickable"-->
                  <p-button >Choose file in local folders</p-button>
                </span>
              </div>
              <div class="ui-fileupload-content ui-widget-content ui-corner-bottom">
                <div class="ui-fileupload-files">
                  <div class="ui-fileupload-row ng-star-inserted">
                    <div></div>
                    <div>{{ ezshareDocument.documentName }}</div>
                    <div>{{ ezshareDocument.size }}</div>
                    <div>
                      <button
                        (click)="ezshareDocument = undefined"
                        icon="pi pi-times"
                        pbutton=""
                        type="button"
                        class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only"
                      >
                        <span
                          aria-hidden="true"
                          class="ui-button-icon-left ui-clickable pi pi-times"
                        ></span
                        ><span class="ui-button-text ui-clickable">ui-btn</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p-fileUpload
              *ngIf="!ezshareDocument"
              #fileReview
              multiple="multiple"
              name="documentReview[]"
              formControlName="reviewDocument"
              accept=".pdf"
              [showUploadButton]="false"
              [showCancelButton]="false"
              chooseLabel="Choose file in local folders"
              styleClass="bl-generate-analysis__upload"
              (onSelect)="getFile($event, 'review')"
              (onRemove)="removeFile('review')"
              
              ngDefaultControl
            >
            </p-fileUpload> <!-- [disabled]="reviewFile || !analyzeForm.get('operationNumber')?.valid"-->
          </div>
        </div>
        <div class="bl-generate-analysis__upload-bottom">
          <div class="ui-g bl-generate-analysis__upload-radio-wrapper">
            <span class="ui-g bl-generate-analysis__upload-radio-label">
              Internal Control Report (ICR):
            </span>
            <p-radioButton
              (onClick)="removeFile('ICR')"
              class="bl-generate-analysis__upload-radio"
              name="controlReport"
              value="{{ controlReportValues.IN_FILE }}"
              label="Integrated in AFS"
              formControlName="controlReport"
              inputId="opt1"
              [disabled]="onAnalysis"
            ></p-radioButton>
            <p-radioButton
              (onClick)="removeFile('ICR')"
              class="bl-generate-analysis__upload-radio"
              name="controlReport"
              value="{{ controlReportValues.UPLOAD_ICR }}"
              label="A separate file"
              formControlName="controlReport"
              inputId="opt2"
              [disabled]="onAnalysis"
            ></p-radioButton>
            <p-radioButton
              (onClick)="removeFile('ICR')"
              class="bl-generate-analysis__upload-radio"
              name="controlReport"
              value="{{ controlReportValues.NO_ICR }}"
              label="Review without ICR"
              formControlName="controlReport"
              inputId="opt3"
              [disabled]="onAnalysis"
            ></p-radioButton>
          </div>
          <div
            class="bl-generate-analysis__upload-wrapper-2"
            *ngIf="analyzeForm.get('controlReport')?.value === 'uploadIcr'"
          >
            <span class="bl-generate-analysis__upload-label"> Select an ICR (.pdf) </span>
            <div [class.bl-generate-analysis__upload-container]="!ICRFile && !ezshareICRDocument">
              <bid-ezshare-table
                class="bl-generate-analysis__upload"
                (documentSelected)="selectDocument($event, true)"
                [loanNumber]="analyzeForm.get('operationNumber')?.value"
                *ngIf="!ezshareICRDocument && !ICRFile"
              >
              </bid-ezshare-table>
              <div
                class="bl-generate-analysis__upload ui-fileupload ui-widget"
                *ngIf="ezshareICRDocument"
              >
                <div class="ui-fileupload-buttonbar ui-widget-header ui-corner-top">
                  <span
                    class="ui-fileupload-choose ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left ui-state-disabled"
                    icon="pi pi-plus"
                    pbutton=""
                    ><input type="file" multiple="" accept=".pdf" /><span
                      aria-hidden="true"
                      class="ui-button-icon-left ui-clickable pi pi-plus"
                    ></span
                    ><span class="ui-button-text ui-clickable"
                      >Choose file in local folders</span
                    ></span
                  >
                </div>
                <div class="ui-fileupload-content ui-widget-content ui-corner-bottom">
                  <div class="ui-fileupload-files">
                    <div class="ui-fileupload-row ng-star-inserted">
                      <div></div>
                      <div>{{ ezshareICRDocument.documentName }}</div>
                      <div>{{ ezshareICRDocument.size }}</div>
                      <div>
                        <button
                          (click)="ezshareICRDocument = undefined"
                          icon="pi pi-times"
                          pbutton=""
                          type="button"
                          class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only"
                        >
                          <span
                            aria-hidden="true"
                            class="ui-button-icon-left ui-clickable pi pi-times"
                          ></span
                          ><span class="ui-button-text ui-clickable">ui-btn</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p-fileUpload
                *ngIf="!ezshareICRDocument"
                multiple="multiple"
                name="ICRDocument[]"
                formControlName="ICRDocument"
                accept=".pdf"
                [showUploadButton]="false"
                [showCancelButton]="false"
                chooseLabel="Choose ICR file in local folders"
                styleClass="bl-generate-analysis__upload"
                (onSelect)="getFile($event, 'ICR')"
                (onRemove)="removeFile('ICR')"
                ngDefaultControl
                
              ></p-fileUpload> <!-- [disabled]="ICRFile || !analyzeForm.get('operationNumber').valid" -->
            </div>
          </div>
          <div
            class="col-md-8 t-text-align-left t-padding-bottom-0"
            *ngIf="analyzeForm.get('controlReport')?.value === 'inFile'"
          >
            <div class="col-md-12">
              <p>ICR Page Range</p>
              <p>Enter start and end page numbers for the ICR section in the PDF document.</p>
            </div>
            <div class="col-md-6">
              <div class="bl-input">
                <label for="vertical-min">Start Page</label>
                <div
                  class="t-display-flex t-padding-top-15"
                  style="align-items: center"
                  [class.bl-datepicker--error]="errorMessageMinPage && minPage === undefined"
                >
                  <input
                    pInputText
                    formControlName="minPage"
                    id="vertical-min"
                    placeholder="- -"
                    class="bl-dropdown t-text-align-center t-margin-right-10"
                    type="number"
                    min="0"
                    max="{{ analyzeForm.value.maxPage }}"
                    (blur)="checkValue($event, 'minPage')"
                  />
                  <button
                    pButton
                    type="button"
                    class="bl-button__btn-number bl-button__btn-number--left pi pi-minus"
                    (click)="
                      modifyPage('minPage', -1); checkValue2(analyzeForm.value.minPage, 'minPage')
                    "
                    [disabled]="analyzeForm.value.minPage <= 0"
                  ></button>
                  <button
                    pButton
                    type=" button"
                    class="bl-button__btn-number bl-button__btn-number--right pi pi-plus"
                    (click)="
                      modifyPage('minPage', 1); checkValue2(analyzeForm.value.minPage, 'minPage')
                    "
                    [disabled]="maxPage && analyzeForm.value.minPage >= analyzeForm.value.maxPage"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="bl-input">
                <label for="vertical-max">End Page</label>
                <div
                  class="t-display-flex t-padding-top-15"
                  style="align-items: center"
                  [class.bl-datepicker--error]="errorMessageMinPage && minPage === undefined"
                >
                  <input
                    pInputText
                    formControlName="maxPage"
                    id="vertical-max"
                    placeholder="- -"
                    class="bl-dropdown t-text-align-center t-margin-right-10"
                    type="number"
                    min="{{ analyzeForm.value.minPage }}"
                    (blur)="checkValue($event, 'maxPage')"
                  />
                  <button
                    pButton
                    type="button"
                    class="bl-button__btn-number bl-button__btn-number--left pi pi-minus"
                    (click)="
                      modifyPage('maxPage', -1); checkValue2(analyzeForm.value.maxPage, 'maxPage')
                    "
                    [disabled]="analyzeForm.value.minPage >= analyzeForm.value.maxPage"
                  ></button>
                  <button
                    pButton
                    type=" button"
                    class="bl-button__btn-number bl-button__btn-number--right pi pi-plus"
                    (click)="
                      modifyPage('maxPage', 1); checkValue2(analyzeForm.value.maxPage, 'maxPage')
                    "
                  ></button>
                </div>
              </div>
            </div>
            <p
              class="bl-generate-analysis__info-message col-md-12 t-padding-bottom-0"
              *ngIf="errorMessageMinPage && minPage === undefined"
            >
              <span class="ui-message-text ui-message-text--red t-margin-0">
                You must select a page range to Start the analysis.
              </span>
            </p>
            <p class="bl-generate-analysis__info-message col-md-12 t-padding-bottom-0">
              <span class="ui-message-text t-margin-0">
                Note: The page numbers should the ones from the PDF document and not the ones in
                shown in the header or footer of the AFS document.
              </span>
            </p>
          </div>
        </div>
      </div>
      <p-messages *ngIf="msgs.length > 0" styleClass="bl-generate-analysis__warn-message" [(value)]="msgs"></p-messages>
      <p-message
        class="bl-generate-analysis__info-message"
        severity="info"
        text='"Processing time and success factor depend on the quality of the document and the number of pages that are images rather than text. If the original digital file is available, it is strongly recommended that you use it"'
      >
      </p-message>
      <div class="t-text-align-right">
        <p-button
          type="submit"
          class="bl-generate-analysis__btn-start"
          [label]="analysisCompleted ? 'AFS Review' : 'Start analysis'"
          [icon]="errorAnalysis ? 'fas fa-redo-alt' : undefined"
          (click)="
            analyzeForm.get('controlReport')?.value === 'inFile' && minPage === undefined
              ? (errorMessageMinPage = true)
              : startAnalysis()
          "
          *ngIf="(reviewFile || ezshareDocument) && !onAnalysis"
        >
        </p-button>
        <span *ngIf="errorAnalysis" class="bl-generate-analysis__error-form">
          An unexpected error occured. Please try again
        </span>
        <span *ngIf="analysisNotFinding" class="bl-generate-analysis__error-form">
          The analysis cannot be generated because the loan number is not in the database. Please
          try another loan number.
        </span>
      </div>
      <div
        class="t-text-align-right bl-generate-analysis__progressbar-container"
        *ngIf="onAnalysis && !errorAnalysis"
      >
        <div
          class="bl-generate-analysis__progressbar-wrapper col-8"
          *ngIf="preparingDocuments !== undefined"
        >
          <span class="bl-generate-analysis__progressbar-label"> Preparing documents </span>
          <p-progressBar
            class="bl-generate-analysis__progressbar bl-generate-analysis__progressbar--blue"
            [value]="preparingDocuments"
          ></p-progressBar>
        </div>
        <div
          class="bl-generate-analysis__progressbar-wrapper col-8"
          *ngIf="preparingDocuments === undefined"
        >
          <span class="bl-generate-analysis__progressbar-label">{{messageStep}}</span>
          <p-progressBar
            class="bl-generate-analysis__progressbar"
            [value]="progressValue"
          ></p-progressBar>
        </div>
      </div>
    </form>
    <p-toast
      class="bl-toast bl-toast--success"
      key="successMessage"
      closable="true"
      [escape]="true"
    >
      <ng-template let-message pTemplate="message">
        <span class="ui-toast-icon template-position">
          <i class="fas fa-check"></i>
        </span>
        <div class="ui-toast-message-text-content">
          <h3 class="ui-toast-summary">Success</h3>
          <span class="ui-toast-detail"
            >Analysis completed.
            <a [routerLink]="[templateSuccess.afsReviewLink]" class="link-style">{{
              templateSuccess.afsReviewTemplate
            }}</a>
            to see the results</span
          >
        </div>
      </ng-template>
    </p-toast>
    <p-toast class="bl-toast bl-toast--success" key="successMessageUpload"></p-toast>
  </div>
</div>
