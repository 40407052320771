import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '@bl-interfaces/user';
import { analysisuser } from '@bl-interfaces/analysis_user';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  //private userSubject = new BehaviorSubject({});
  //private userObservable: Observable<any> = this.userSubject.asObservable();
  private isAdminUser = new BehaviorSubject(undefined);
  private isAdminUserObs: Observable<any> = this.isAdminUser.asObservable();

  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  /*setUserData(dataUser: any) {
    this.userSubject.next(dataUser);
  }*/

  setIsAdminUser(value: any) {
    console.log("setIsAdminUser", value)
    this.isAdminUser.next(value);
  }

  getUserData(): Observable<any> {
    let ret: any = null
    let user = localStorage.getItem('rolUserLocalStorage');
    if (user) {
      user = JSON.parse(user);
      ret = user
    } 
    return of(ret); // Retorna el observable con el valor obtenido o un array vacío.
  }

  setUserData(data: any) {
     
    localStorage.setItem('rolUserLocalStorage', JSON.stringify(data));
 
  }

  getUserAuthorization(): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.api.base + 'users/authorization');
  }

  getUserList(): Observable<User[]> {
    return this.httpClient.get<User[]>(environment.api.base + 'users');
  }

  getUserByMail(email: string): Observable<User> {
    const url = `${environment.api.base}users/user/${email}`;
    console.log('Invocando URL:', url);
    return this.httpClient.get<User>(url);
  }

  getIsAdminUser() {
    return this.isAdminUserObs;
  }

  checkEmail(email: string): Observable<string> 
  {
    return this.httpClient.get(`${environment.api.base}users/user/${email}/check`, {responseType: 'text'});
  }

  addUser(user: User): Observable<boolean> {
    console.log(user)
    return this.httpClient.post<boolean>(`${environment.api.base}users`, user);
  }

  updateUser(user: User): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.api.base}users`, user);
  }

  deleteUser(user: User) {
    return this.httpClient.request('DELETE', `${environment.api.base}users`, {
      body: user,
    });
  }

  getUserConvergencia(request: string): Observable<User> {
    return this.httpClient.get<User>(
      environment.api.base + `convergences/users?request=${request}`
    ).pipe(catchError((err: HttpErrorResponse) => {

      if (err.error instanceof Error) {
        console.error('Error:', err.error.message);
      } else {
        console.error(`Backend error code ${err.status} : ${err.error}`);
      }

      return new Observable<User>();
    }));
  }

  addUserAnalysis(_Data: analysisuser) {
    return this.httpClient.put(environment.api.base + `icrs/UpdateAnalysisConfigMail`, _Data);
  }
}
