<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
<p-toast
  class="bl-toast bl-toast--error"
  key="errorMessageTestDescriptions"
  closable="true"
></p-toast>
<!--bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher-->
<div class="bl-wrapper">
  <div class="bl-dictionary">
    <div class="bl-dictionary__wrapper">
      <div class="bl-title">
        <h1>Tests Descriptions</h1>
      </div>
      <form [formGroup]="formGroupSeacherTestDescriptions" class="bl-content">
        <div class="bl-content__custom-size-select"></div>
        <div class="bl-searcher-term">
          <div class="bl-input">
            <label class="bl-input__label bl-input__label--big">Search test</label>
            <div class="bl-searcher-term__input ui-inputgroup">
              <input
                type="text"
                pInputText
                formControlName="seacherTerm"
                (keydown.enter)="seacherTerm()"
                placeholder="Sample: AFS Integrity, Integrity or 6"
                [attr.disabled]="seracherTerm"
              />
              <button (click)="seacherTerm()" pButton type="button" [disabled]="seracherTerm">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div *ngIf="seracherTerm" class="bl-searcher-term__term-seacher-content">
            <span class="bl-searcher-term__term-seacher">
              {{ seracherTerm }}
              <a (click)="removeSeacherTerm()" class="bl-searcher-term__icon-remove"
                ><i class="fas fa-times"></i
              ></a>
            </span>
          </div>
        </div>
      </form>
      <div class="bl-table-legend col-12">
        <div class="bl-table-legend__type-of-test-wrapper col-lg-8 col-md-8 col-sm-6 col-xs-6">
          <div class="bl-table-legend__score-title">
            <span>Type of test</span>
          </div>
          <ul class="bl-table-legend__list">
            <li
              class="bl-table-legend__list--blue-bullet bl-table-legend__list--element-list-margin"
            >
              Initial analysis
            </li>
            <li
              class="bl-table-legend__list--two-color-bullet bl-table-legend__list--element-list-margin"
            >
              Initial & Basic Elements of the Auditor 's Opinion
            </li>
          </ul>
          <ul class="bl-table-legend__list">
            <li
              class="bl-table-legend__list--brown-bullet bl-table-legend__list--element-list-margin"
            >
              A: Basic Elements of the Auditor`s Opinion
            </li>
            <li
              class="bl-table-legend__list--green-bullet bl-table-legend__list--element-list-margin"
            >
              B: Project Financial Statements
            </li>
            <li
              class="bl-table-legend__list--yellow-bullet bl-table-legend__list--element-list-margin"
            >
              C: Notes to Financial Statements
            </li>
          </ul>
        </div>
      </div>
      <bid-test-descriptions-table
        *ngIf="testDescriptions"
        [headerTable]="headerTableTestDescriptions"
        [bodyTable]="testDescriptions"
      ></bid-test-descriptions-table>
    </div>
  </div>
</div>
