<!--bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher-->
<div class="bl-users">
  <div class="bl-wrapper">
    <div class="bl-selectbuttonMenu">
      <p-selectButton
        [options]="pages"
        [(ngModel)]="selectedOptionMenu"
        (onChange)="rediredTo($event)"
      ></p-selectButton>
    </div>
    <form [formGroup]="formGroupSeacherFindings">
      <div class="bl-content bl-content--inline">
        <div class="bl-content__margin-field">
          <div class="bl-content__custom-size-select bl-content__padding">
            <div class="bl-select">
              <label class="bl-select__label bl-select__label--big"
                >Select Dictionary (language)</label
              >
              <p-dropdown
                [options]="languageCombo"
                formControlName="language"
                defaultLabel="select"
                styleClass="bl-dropdown"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="bl-content__margin-field bl-content__margin-field--medium-size">
          <div class="bl-datepicker bl-content__padding">
            <label class="bl-datepicker__label bl-datepicker__label--big">Select Period </label>
            <p-calendar
              icon="pi pi-calendar"
              showIcon="true"
              selectionMode="range"
              dateFormat="yy/mm/dd"
              formControlName="selectPeriod"
              [ngClass]="{
                'bl-datepicker--error': false
              }"
              required
            ></p-calendar>
          </div>
        </div>
        <div class="bl-content__margin-field bl-content__button">
          <div class="bl-content__padding col-lg-4 col-md-4">
            <button
              (click)="searchResult()"
              class="bl-button--btn-secundary bl-button--btn-secundary--lg"
              type="submit"
            >
              Search findings
            </button>
          </div>
        </div>
      </div>
    </form>
    <div>
      <label class="bl-manage-finding__risk-label">Select a risk to filter keywords:</label>
      <div class="bl-selectbuttons">
        <p-selectButton [options]="menu" [(ngModel)]="selectedRisk"></p-selectButton>
      </div>
    </div>
    <div class="bl-title">
      <span>List of findings</span>
    </div>
    <div class="bl-selectbuttonMenu">
      <p-selectButton
        [options]="accion"
        [(ngModel)]="selectedAccionMenu"
        (onChange)="getAccion(selectedAccionMenu)"
      ></p-selectButton>
    </div>
    <bid-added-finding-table
      *ngIf="selectedAccionMenu === 'Added manually'"
      [headerTable]="headersTablesAddedFindings"
      [bodyTable]="bodyTableAddedFinding"
    ></bid-added-finding-table>
    <bid-removed-finding-table
      *ngIf="selectedAccionMenu === 'Removed'"
      [headerTable]="headersTablesRemovedFindings"
      [bodyTable]="bodyTableAddedFinding"
    ></bid-removed-finding-table>
  </div>
</div>
