import { Component, OnInit, DoCheck } from "@angular/core";
import { StepperService } from "@core/stepper/stepper.service";
import { Router } from "@angular/router";
import { ShowHistoricalSearchService } from "@core/history/show-historical-search.service";
import { UserService } from "@core/user/user.service";
import { Observable } from "rxjs";
import { Role } from "@bl-enum/role";
import { AuthenticationService } from "@core/authentication/authentication.service";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "bid-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, DoCheck {
  titleCenterButton: string = '';
  roluser: any;
  showHistoricalSearch: boolean = false;
  userLoged: boolean = false;
  activeNewAnalysis: boolean = false;
  observableIsAdmin$: Observable<boolean>;
  STEP0 = [
    { name: "generateNewAnalysis", stepSelected: false },
    { name: "afsReview", stepSelected: false, disabled: true },
    { name: "score", stepSelected: false, disabled: true },
    { name: "icrReview", stepSelected: false, disabled: true },
  ];
  STEP1 = [
    { name: "loadActionPlan", stepSelected: false },
    { name: "myActionPlan", stepSelected: false, disabled: false },
  ];
  constructor(
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private stepperService: StepperService,
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private titleService : Title,
    private metaService : Meta
  ) {
    this.observableIsAdmin$ = this.userService.getIsAdminUser();
    }
  ngOnInit() {
    //this.showHistoricalSearch = false;
    //this.showHistoricalSearchService.setShowHistoricalSearch(false);

    this.authService.msalService.instance.handleRedirectPromise()
      .then((res) => {
        if (res) {
          console.log('Inicio de sesión por redirección exitoso');
        }
      })
      .catch((err) => {
        console.error('Error al manejar la redirección:', err);
      });

    //this.checkoutAccount();

    /*this.userService
      .getUserByMail(this.authService.msalService.instance.getAllAccounts()[0].username)
      .subscribe((res) => {
        if (res) {
          switch (res.role) {
            case Role.ADMIN:
              this.stepperService.setStepper(this.STEP0);
              this.userService.setIsAdminUser(true);
              this.activeNewAnalysis = true;
              this.roluser = Role.ADMIN;

              break;

            case Role.INVITED:
              this.stepperService.setStepper(this.STEP0);
              this.activeNewAnalysis = true;
              this.roluser = Role.INVITED;
              break;

           
          }
        }
        this.checkoutAccount();
      });*/
  }

  checkoutAccount() {
 
    /*if (!this.authService.msalService.instance.getActiveAccount()) {
      this.authService.msalService.instance.loginRedirect();
      this.userLoged = false;
    } else {*/
      this.userLoged = (this.authService.msalService.instance.getAllAccounts().length > 0);
    //}
    
    console.log(this.userLoged);
    
  }

  ngDoCheck() {
    this.showHistoricalSearchService
      .getShowHistoricalSearch()
      .subscribe((res: any) => {
        this.showHistoricalSearch = res;
      });

    this.checkoutAccount();
  }
  redirectTo(): void {
    
      this.redirectToGenerateaNewAnalysis();
    
  }
  redirectToGenerateaNewAnalysis() {
    this.STEP0[0].stepSelected = true;
    this.stepperService.setStepper(this.STEP0);
    this.router.navigate(["/generate-new-analysis"]);
  }
  redirectToLoadActionPlan() {
    this.STEP1[0].stepSelected = true;
    this.stepperService.setStepper(this.STEP1);
    if (this.roluser == "OEG") this.router.navigate(["/load-action-plan"]);
    if (this.roluser == "OEA") this.router.navigate(["/ApproveActionPlan"]);
  }

  singIn() {
    if (!this.userLoged) {
      this.authService.login();
    } else {
      console.log("aaaa");

      this.authService.logout();
    }
  }
}
