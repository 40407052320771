import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';
import { headersTables } from '@bl-constants/table-header.const';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { MessageToastService } from '@core/messages/message-toast.service';
import { StartAnalysisService } from '@core/analysis/start-analysis.service';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import { ReviewTimeService } from '@core/review-time/review-time.service';
import { GetAnalysisService } from '@core/analysis/get-analysis.service';
import { IEfaOutput } from '@bl-interfaces/iefa-output';
import { StepperService } from '@core/stepper/stepper.service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { AfsReviewService } from '@core/afs-review/afs-review.service';

type Language = 'En' | 'Fr' | 'Po' | 'Sp';

@Component({
  selector: 'bid-afs-review',
  templateUrl: './afs-review.component.html',
  styleUrls: ['./afs-review.component.scss'],
})

export class AfsReviewComponent implements OnInit, DoCheck, OnDestroy {
  headerTableAFS: any;
  referencesSelect!: SelectItem[];
  referencesSelectItem: string = "";
  analysisData!: IEfaOutput;
  //showHistoricalSearch: boolean;
  reviewInterval: any;
  analysisId: any;
  calculateScore: boolean = false;
  STEP2: Array<object>;
  subscription: any;
  analysisTime: any;
  _getAnalysisService;
  _startAnalysisService;
  _reviewTimeService;
  language: Language = "En";

  constructor(
    public messageToastService: MessageToastService,
    private startAnalysisService: StartAnalysisService,
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private reviewTimeService: ReviewTimeService,
    private getAnalysisService: GetAnalysisService,
    private router: Router,
    private stepperService: StepperService,
    //private spinner: NgxSpinnerService,
    private afsReviewService: AfsReviewService,
  ) {
    this.STEP2 = [
      { name: 'generateNewAnalysis', stepSelected: false },
      { name: 'afsReview', stepSelected: true },
    ];
    this._getAnalysisService = getAnalysisService;
    this._startAnalysisService = startAnalysisService;
    this._reviewTimeService = reviewTimeService;
  }

  ngOnInit() {
    //this.spinner.show();
    //this.showHistoricalSearch = false;
    this.analysisTime = sessionStorage.getItem('pocessingTime');
    this.analysisTime = parseInt(this.analysisTime, 0);
    this.getAnalysisIdObservable();
    this.referencesSelect = [{ label: 'far fa-file-alt', value: 'References' }];
    this.headerTableAFS = headersTables.headerAFSTable;
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.startReviewTime();
    this.stepperService.setStepper(this.STEP2);
    const stringSteps = JSON.stringify(this.STEP2);
    localStorage.setItem('stepsLocalStorage', stringSteps);
    this.afsReviewService.returnScore().subscribe((res: any) => {
      this.calculateScore = res;
    });
 
  }

  ngDoCheck() {
    /*this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });*/
  }

  getAnalysisIdObservable() {
    //this.spinner.show();
    console.log("getAnalysisIdObservable")
    this.startAnalysisService.getAnalysis().subscribe(
      (analysisId: any) => {
        
        let data = localStorage.getItem('analysisId') || "";
        analysisId = data.replace(/\"/g, '');
        if (analysisId) {
          this.getAnalysis(analysisId);
          this.analysisId = analysisId;
        } else {
          this.getAnalysis(null);
        }
      },
      () => {
        //this.spinner.hide();
        this.showToastError(
          'An error was encountered when loading the data, please try again later',
          'errorMessageAfsReview',
        );
      },
    );
  }

  getAnalysis(analysisId: string | null) {
    //this.spinner.show();
    
    if (analysisId) {
      this.saveAnalysisId(analysisId);
      this.getAnalysisService.getAnalysis(analysisId).subscribe((res) => {
        const noContentResp = this.isEmpty(res);
        if (res && !noContentResp) {
          this.analysisData = res;
          this.language = this.analysisData.language as Language;
          //if (this.calculateScore === true) {
            const stringAnalysisData = JSON.stringify(this.analysisData);
            localStorage.setItem('analisisResultLocalStorage', stringAnalysisData);
          //}
          //this.spinner.hide();
        }
      });
    } else {
      const analysisIdLocalStorage = localStorage.getItem('analysisId');
      if (analysisIdLocalStorage !== null) {
        this.getAnalysis(JSON.parse(analysisIdLocalStorage));
        const strStepsLocalStorage = localStorage.getItem('stepsLocalStorage') || "{}";
        this.STEP2 = JSON.parse(strStepsLocalStorage);
        this.stepperService.setStepper(this.STEP2);
      } else {
        this.router.navigate(['/generate-new-analysis']);
      }
    }
  }

  isEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  saveAnalysisId(data: any) {
   // const stringData = JSON.stringify(data);
    console.log("saveAnalysisId", data)
    localStorage.setItem('analysisId', data);
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }

  startReviewTime() {
    if (this.reviewInterval !== undefined) {
      clearInterval(this.reviewInterval);
    }
    this.reviewInterval = setInterval(this.updateReviewTime, 1000);
  }

  updateReviewTime() {

    let reviewTime = parseInt(sessionStorage.getItem('reviewTime') || '0', 10);
    if (reviewTime === null) {
      sessionStorage.setItem('reviewTime', '1');
    } else {
      reviewTime = +reviewTime + 1;
      sessionStorage.setItem('reviewTime', reviewTime.toString());
    }
  }

  saveReviewTime() {
    if (this.analysisData && this.analysisData.status === 'In progress') {
      const finalTime = sessionStorage.getItem('reviewTime');
      if (this.reviewInterval !== undefined) {
        clearInterval(this.reviewInterval);
      }
      if (finalTime) {
        if (this.analysisData) {
          this.reviewTimeService.saveReviewTime(this.analysisData.id, finalTime).subscribe();
        }
        sessionStorage.removeItem('reviewTime');
      }
    }
  }

  ngOnDestroy(): void {
    this.saveReviewTime();
  }
}
