import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { IcrDictionaryService } from '@core/icr-dictionary/icr-dictionary.service';

@Component({
  selector: 'bid-area-chips',
  templateUrl: './area-chips.component.html',
  styleUrls: ['./area-chips.component.scss'],
})
export class AreaChipsComponent implements OnChanges {
  @Input() chips: any;
  @Input() existingWord: any;
  @Input() addedWord: any;
  @Output() reloadData = new EventEmitter<boolean>();
  display: boolean;
  indicesError: any;
  removeFinding: any;
  idxRemoveFinding: any;
  chipsSeleted: string = "";
  _icrDictionaryService;

  constructor(private icrDictionaryService: IcrDictionaryService) {
    this.display = false;

    this._icrDictionaryService = icrDictionaryService;
  }

  ngOnChanges() {
    this.checkExistingWord(this.existingWord);
    this.checkAddedWord(this.addedWord);
  }

  checkExistingWord(word: string) {
    if (word) {
      let findIdxError = 0;
      const arrIdx: any = [];
      for (let i = 0; i < word.length; i++) {
        findIdxError = this.chips.findIndex(
          (item: any) => this.parseSpecialChar(item.name) === this.parseSpecialChar(word[i]),
        );
        if (findIdxError > -1) {
          this.chips[findIdxError].status = 'error';
          arrIdx.push(findIdxError);
        }
      }
      console.log("checkExistingWord", arrIdx)
      for (let i = 0; i < arrIdx.length; i++) {
        setTimeout(() => {
          this.chips[arrIdx[i]].status = '';
          this.reloadData.emit(true);
        }, 3000);
      }
    }
  }

  checkAddedWord(word: any) {
    const arrIdx: any = [];
    if (word && word.length > 0) {
      for (let i = 0; i < word.length; i++) {
        const idx = this.chips.findIndex(
          (item: any) => this.parseSpecialChar(item.name) === this.parseSpecialChar(word[i].name),
        );
        if (idx !== -1) {
          arrIdx.push(idx);
        }
      }
      console.log("checkAddedWord", arrIdx)
      if (arrIdx.length > 0) {
        for (let i = 0; i < arrIdx.length; i++) {
          this.chips[arrIdx[i]].status = 'added';
        }

        for (let i = 0; i < arrIdx.length; i++) {
          setTimeout(() => {
            this.chips[i].status = '';
            this.reloadData.emit(true);
          }, 3000);
        }
      }
    }
  }

  removeWordDictionary(data: any, i: number) {
    this.display = true;
    this.removeFinding = data;
    this.chipsSeleted = data[i].name;
    this.idxRemoveFinding = i;
  }

  toggleDialog() {
    this.display = !this.display;
  }

  removeWord() {
    this.icrDictionaryService
      .deleteKeyword(this.removeFinding[this.idxRemoveFinding].id)
      .subscribe((res: any) => {
        if (res === true) {
          this.display = false;
          this.removeFinding.splice(this.idxRemoveFinding, 1);
          this.reloadData.emit(true);
        }
      });
  }

  parseSpecialChar(data: string) {
    let parseParams = data;
    if (parseParams.toLowerCase().indexOf('á')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[àáâãäå]', 'g'), 'a');
    }
    if (parseParams.toLowerCase().indexOf('é')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[èéêë]', 'g'), 'e');
    }
    if (parseParams.toLowerCase().indexOf('í')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[ìíîï]', 'g'), 'i');
    }
    if (parseParams.toLowerCase().indexOf('ó')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[òóôõö]', 'g'), 'o');
    }
    if (parseParams.toLowerCase().indexOf('ú')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[ùúûü]', 'g'), 'u');
    }
    return parseParams;
  }

  cancelRemoveParameter() {
    this.display = true;
  }
}
