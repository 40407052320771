import { Component, Input } from '@angular/core';

@Component({
  selector: 'bid-removed-finding-table',
  templateUrl: './removed-finding-table.component.html',
  styleUrls: ['./removed-finding-table.component.scss'],
})
export class RemovedFindingTableComponent {
  @Input() headerTable: any;
  @Input() bodyTable: any;
}
