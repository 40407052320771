
<div class="bl-messages">
  <p-messages [(value)]="msgs"></p-messages>
</div>
<div class="bl-score">

  <p-dialog header="Submit confirmation" [(visible)]="notifyConfirmShow" [modal]="true" [responsive]="true"
    [maximizable]="false" [baseZIndex]="10000" [closable]="false" styleClass="bl-modal" [positionTop]="65"
    [positionLeft]="390">
    <p>
      Are you sure you want to send notification?
    </p>
    <p-footer>
      <button type="button" pButton (click)="notifyConfirmShow = false" label="Cancel"></button>
      <button type="button" pButton (click)="checkUsers()" label="Continue" class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>

  <p-dialog header="Finished" [(visible)]="dialogFinishedVisible" [modal]="true" [responsive]="true"
    [maximizable]="false" [baseZIndex]="10000" [closable]="false" styleClass="bl-modal" [positionTop]="65"
    [positionLeft]="390">
    <p>
      Process completed successfully
    </p>
    <p-footer>
      <button type="button" pButton (click)="dialogFinishedVisible = false; showGenerate = false" label="Close"></button>
    </p-footer>
  </p-dialog>


  <p-toast class="bl-toast bl-toast--error" key="errorMessageGenerateSummaryReport" closable="true"></p-toast>
  <p-dialog header="Generate Summary Report" [(visible)]="display" [modal]="true" [responsive]="true"
    [maximizable]="false" [baseZIndex]="10000" [closable]="false" styleClass="bl-modal" [positionTop]="65"
    [positionLeft]="390">
    <p>
      You`re about to generate the Summary Report. You will not be able to modify the result. Are
      you sure that you want to proceed?
    </p>
    <p-footer>
      <button type="button" pButton (click)="display = false" label="Cancel"></button>
      <button type="button" pButton (click)="generateSummaryReport()" label="Generate"
        class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <div class="bl-internal-control-review-result__border-botton" *ngIf="!onlyButton">
    <div class="bl-wrapper bl-wrapper--padding-header">
      <div class="bl-internal-control-review-result__wrapper">
        <div class="col-5 t-padding-0">
          <span class="bl-internal-control-review-result__title">
            Internal Control Automated Review Results
          </span>
        </div>
        <div class="col-7 bl-internal-control-review-result__right-wrapper" style="text-align: right !important;">
          <!-- <div class="bl-internal-control-review-result__btn" *ngIf="!showGenerate && !existSummaryReport">
           --> 
           
           <button *ngIf="!urlSummaryReport" (click)="downloadDraftSummaryReport()"
              class="bl-button--btn-seacher t-margin-right-20" type="button">
              Preview Summary Report
            </button>
            <button type="submit" class="bl-button--btn-secundary"
            *ngIf="showGenerate && existSummaryReport && !isFinishIcr">
                <!--*ngIf="showGenerate && existSummaryReport  (click)="requestActionPlanOpen()" ">--> 
              Finish ICR Review
            </button>
            <button type="submit" class="bl-button--btn-secundary" (click)="notifyConfirmShow = true"
              *ngIf="ActiveNotifyRRC">
              Notify
            </button>
            <button *ngIf="ableBtnNotify && !showGenerate && !existSummaryReport && !isFinishIcr" (click)="toggleDialog()" title="{{
                status === 'In progress' && icrStatus === 'In progress'
                  ? 'Afs and ICR review´s must be completed'
                  : status === 'In progress'
                  ? 'Afs review´s must be completed'
                  : icrStatus === 'In progress'
                  ? 'ICR review´s must be completed'
                  : ''
              }}" type="submit" class="bl-button--btn-save t-margin-left-20"
              style="border: 2px solid #5c84c3;padding: 0.2857142857rem 1.0714285714rem 0.2857142857rem 1.0714285714rem;">
              Generate Summary Report
            </button>
            <div class="spinner-container" *ngIf="isGeneratingIcr">
              <mat-progress-spinner mode="indeterminate" class="mat-spinner-color" [diameter]="25">
              </mat-progress-spinner>
            </div>
           <!--</div>--> 
          
        </div>

        <div class="col-lg-12 t-padding-0" *ngIf="showGenerate || existSummaryReport || isFinishIcr" style="text-align:right;">
          <div  class="bl-internal-control-review-result__link-content">
            <span class="bl-internal-control-review-result__link-content__txt">Summary Report :</span>
            <a (click)="downloadSummary(urlSummaryReport, summaryReportName)" class="bl-internal-control-review-result__link-content__link"
              target="_blank">{{ summaryReportName }}</a>
          </div>
        </div>

        <div class="col-lg-12 t-padding-0">
          <div class="col-lg-4 col-md-6 col-sm-12 t-padding-0">
            <div class="bl-internal-control-review-result">
              <span class="bl-internal-control-review-result__inline">Loan number:</span>
              <span class="bl-internal-control-review-result__inline--bold-txt">{{
                analysisData?.operationNumber
                }}</span>
            </div>
            <div class="bl-internal-control-review-result">
              <span class="bl-internal-control-review-result__inline">{{
                analysisData?.projectName
                }}</span>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 t-padding-0">
            <div class="bl-internal-control-review-result">
              <span class="bl-internal-control-review-result__inline">Audit Period:</span>
              <span class="bl-internal-control-review-result__inline--bold-txt">
                {{ analysisData?.initDate.toString() | slice:0:10  }}
                -
                {{ analysisData?.endDate.toString() | slice:0:10 }}
              </span>
            </div>
            <div class="bl-internal-control-review-result">
              <span class="bl-internal-control-review-result__inline">External Auditor:</span>
              <span class="bl-internal-control-review-result__inline--bold-txt">{{
                analysisData?.externalAuditor
                }}</span>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 t-padding-0">
            <span class="bl-internal-control-review-result__inline">Executing Agency:</span>
            <span class="bl-internal-control-review-result__inline--bold-txt">{{
              analysisData?.executingAgency
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="onlyButton">
    <!-- <button (click)="downloadDraftSummaryReport()"
      class="bl-button--btn-seacher t-margin-left-20"
      type="button">
      Preview Summary Report
    </button> -->
    <button [disabled]="btnDisabled || status === 'In progress' || icrStatus === 'In progress'" title="{{
        status === 'In progress' && icrStatus === 'In progress'
          ? 'Afs and ICR review´s must be completed'
          : status === 'In progress'
          ? 'Afs review´s must be completed'
          : icrStatus === 'In progress'
          ? 'ICR review´s must be completed'
          : ''
      }}" (click)="toggleDialog()" type="submit"
      class="bl-score__button bl-button--btn-save bl-button--btn-secundary--lg t-margin-left-20"
      style="border: 2px solid #5c84c3; padding: 0.2857142857rem 1.0714285714rem 0.2857142857rem 1.0714285714rem;">
      Generate Summary Report
    </button>
  </ng-container>
</div>
<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
<p-toast class="bl-toast bl-toast--success" key="successMessage"></p-toast>