export const environment = {
  production: false,
  version: 1,
  api: {
    base: "https://apim-np-d-idb-integration.iadb.org/ext/operations/luca-2/develop/api/v1/",
    baseUpload: "https://apim-np-d-idb-integration.iadb.org/operations/luca-ezshare/api/v1/", 
    history: "efas/History",
    id: "c4901a55-9d6d-4cf5-873e-5e0f58a7dd3b",
    subscriptionHeader: "Ocp-Apim-Subscription-Key",
    subscriptionKey: "7a2c284bf023423fa8522668303f4535",
    scope: 'api://c4901a55-9d6d-4cf5-873e-5e0f58a7dd3b/api-access', 
  },
  auth: {
    tenant: "9dfb1a05-5f1d-449a-8960-62abcb479e7d",
    clientId: "64778b9f-62dd-4ae9-a14c-0b823371f83d",
    authority:
      "https://login.microsoftonline.com/9dfb1a05-5f1d-449a-8960-62abcb479e7d", 
    redirectUri: "https://luca-dev-2.iadb.org/",
    postLogoutRedirectUri: "https://luca-dev-2.iadb.org/", 
    endpoints: {
      "https://luca-dev-2.iadb.org/":
        "f0031bdd-341e-456f-9d3e-ecb997d05556",
    },
  },
  dashboard: {
    kpi: 'https://app.powerbi.com/reportEmbed?reportId=24193532-d605-4a3d-ae32-18373676b0fd&autoAuth=true&ctid=9dfb1a05-5f1d-449a-8960-62abcb479e7d'
  },
  instrumentationKey: '8fbc036b-fc2c-4ff7-92f5-647d3c8eae23'
};