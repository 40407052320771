import { analysis } from '@bl-interfaces/analysis';
import { Injectable } from '@angular/core';
import { IEfaInput } from '@bl-interfaces/iefa-input';
import { IEfaOutput } from '@bl-interfaces/iefa-output';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StartAnalysisService {
  efaPostData: IEfaInput | undefined;
  error = false;
  private analysisSubject = new BehaviorSubject(false);

  private analysisObservable: Observable<any> = this.analysisSubject.asObservable();

  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {} 

  sendEfa(
    analyzeForm: any,
    fileName: string,
    includedIcr: boolean,
    ezshared: any,
    pageMin: any,
    pageMax: any,
    icrFileName: string,
  ): Observable<IEfaOutput> {
    this.efaPostData = {
      file: fileName,
      operationNumber: analyzeForm.operationNumber.toUpperCase(),
      language: analyzeForm.language,
      initDateStr: this.formatDate(analyzeForm.auditDate[0]),
      endDateStr: this.formatDate(analyzeForm.auditDate[1]),
      includeIcr: includedIcr,
      ezsharedLink: ezshared,
      icrInitPage: pageMin,
      icrEndPage: pageMax,
      file_icr: icrFileName,
    };
    return this.httpClient.post<IEfaOutput>(environment.api.base + 'efas', this.efaPostData);
  }

  start(
    analyzeForm: any,
    fileName: string,
    includedIcr: boolean,
    ezshared: any,
    pageMin: number,
    pageMax: number,
    icrFileName: string,
  ): Observable<IEfaOutput> {
    this.efaPostData = {
      file: fileName,
      operationNumber: analyzeForm.operationNumber.toUpperCase(),
      language: analyzeForm.language,
      initDateStr: this.formatDate(analyzeForm.auditDate[0]),
      endDateStr: this.formatDate(analyzeForm.auditDate[1]),
      includeIcr: includedIcr,
      ezsharedLink: ezshared,
      icrInitPage: pageMin,
      icrEndPage: pageMax,
      file_icr: icrFileName,
    };
    console.log("start")
    return this.httpClient.post<IEfaOutput>(environment.api.base + 'analysis/start', this.efaPostData);
  }

  status(
    analyzeForm: any,
    fileName: string,
    includedIcr: boolean,
    ezshared: any,
    pageMin: number,
    pageMax: number,
    icrFileName: string,
    queueId: string
  ): Observable<IEfaOutput> {
    this.efaPostData = {
      file: fileName,
      operationNumber: analyzeForm.operationNumber.toUpperCase(),
      language: analyzeForm.language,
      initDateStr: this.formatDate(analyzeForm.auditDate[0]),
      endDateStr: this.formatDate(analyzeForm.auditDate[1]),
      includeIcr: includedIcr,
      ezsharedLink: ezshared,
      icrInitPage: pageMin,
      icrEndPage: pageMax,
      file_icr: icrFileName,
      queueId: queueId
    };
    return this.httpClient.post<IEfaOutput>(environment.api.base + 'analysis/status', this.efaPostData);
  }

  getIcrRadio(analyzeForm: any): boolean {
    return analyzeForm === 'inFile';
  }

  setAnalysisData(analysisData: any) {
    this.analysisSubject.next(analysisData);
  }

  getAnalysis(): Observable<analysis> {
    return this.analysisObservable;
  }

  formatDate(date: string) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('/');
  }
}
