import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AfsReviewService } from "./afs-review/afs-review.service";
import { MessageService } from "primeng/api";
import { SaveTestService } from "../core/afs-review/save-test.service";
import { UserService } from "../core/user/user.service";
import { CustomHttpInterceptorService } from "../core/CustomHttpInterceptor/custom-http-interceptor.service";

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    AfsReviewService,
    MessageService,
    SaveTestService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptorService,
      multi: true,
    },
  ],
})
export class CoreModule {}
