import { Component, OnInit, DoCheck } from '@angular/core';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import {environment} from '@environments/environment';

@Component({
  selector: 'bid-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, DoCheck {
  showHistoricalSearch: boolean;
  urlKpiDashboard : string = environment.dashboard.kpi;

  constructor(private showHistoricalSearchService: ShowHistoricalSearchService) {
    this.showHistoricalSearch = false;
  }

  ngOnInit() {
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    console.log('dashboard', this.urlKpiDashboard)
    
    const iframe = document.getElementById('dashboardIframe') as HTMLIFrameElement;
    iframe.src = this.urlKpiDashboard;
  }

  ngDoCheck() {
    this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });
  }
}
