<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
<p-toast class="bl-toast bl-toast--error" key="errorUser" closable="true"></p-toast>
<p-toast class="bl-toast bl-toast--success" key="successUser" closable="true"></p-toast>
<p-toast class="bl-toast bl-toast--warn" key="warnUser" closable="true"></p-toast>
<!--bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher-->
<div class="bl-users">
  <div class="bl-wrapper">
    <h2 class="bl-title">
      <span>Administrators and invited users</span>
    </h2>
    <div class="bl-content">
      <p-tabView class="bl-tab-view" (onChange)="resetAdd()">
        <p-tabPanel header="Administrator" class="bl-tab-view__tab">
          <div class="bl-content bl-content--padding-bottom-medium">
            <button class="bl-button bl-button--btn-secundary" (click)="toggleAddAdmin()">
              <i class="fas fa-plus"></i>
              Add Admin
            </button>
          </div>
          <div class="bl-user-form" *ngIf="addAdmin">
            <p class="bl-user-form__txt">
              To add an invited user , the mail must be registered in Active Directory. Please write
              and check does exist.
            </p>
            <div class="bl-user-form__input bl-input">
              <label for="emailAdmin" class="bl-user-form__input-label bl-input__label"
                >Email</label
              >
              <input
                type="text"
                pInputText
                id="emailAdmin"
                class="bl-user-form__input-text bl-input__input-text"
                [(ngModel)]="inputEmailUser"
              />
              <button
                class="bl-user-form__btn-check bl-button bl-button--btn-secundary"
                (click)="checkEmail(userRoles.ADMIN)"
              >
                <svg
                  class="bl-user-form__icon-check"
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0.75L0.25 3.75V8.25C0.25 12.4125 3.13 16.305 7 17.25C10.87 16.305 13.75 12.4125 13.75 8.25V3.75L7 0.75ZM5.5 12.75L2.5 9.75L3.5575 8.6925L5.5 10.6275L10.4425 5.685L11.5 6.75L5.5 12.75Z"
                  />
                </svg>
                <span> Check </span>
              </button>
              <small id="email-help" class="bl-user-form__input-error-txt" *ngIf="showEmailError">
                We could not find the email, try again or registed in Active directory
              </small>
            </div>
            <div class="bl-user-form__input bl-input">
              <label for="nameAdmin" class="bl-user-form__input-label bl-input__label"
                >Nombre</label
              >
              <input
                type="text"
                pInputText
                id="nameAdmin"
                disabled
                class="bl-user-form__input-text bl-input__input-text"
                [value]="userToAdd?.displayName"
              />
              <button
                class="bl-user-form__btn-cancel bl-button bl-button--btn-cancel"
                (click)="cancelAddUser()"
              >
                Cancel
              </button>
              <button
                [disabled]="addUserDisabled"
                class="bl-user-form__btn-add bl-button bl-button--btn-secundary"
                (click)="addUserTable()"
              >
                Add
              </button>
            </div>
          </div>
          <div class="bl-table">
            <!--bid-users-role-table
              [headerTable]="headerTableUsers"
              [bodyTable]="bodyTable?.users ? filterByRole(userRoles.ADMIN) : undefined"
              (onDelete)="deleteUser($event)"
              (onStatusChange)="updateStatus($event)"
            ></bid-users-role-table-->
          </div>
        </p-tabPanel>

        <p-tabPanel header="Invited users" class="bl-tab-view__tab">
          <div class="bl-content bl-content--padding-bottom-medium">
            <button class="bl-button bl-button--btn-secundary" (click)="toggleAddUser()">
              <i class="fas fa-plus"></i>
              Add Invited user
            </button>
          </div>
          <div class="bl-user-form" *ngIf="addUser">
            <p class="bl-user-form__txt">
              To add an invited user , the mail must be registered in Active Directory. Please write
              and check does exist.
            </p>
            <div class="bl-user-form__input bl-input">
              <label for="emailUser" class="bl-user-form__input-label bl-input__label">Email</label>
              <input
                type="text"
                pInputText
                id="emailUser"
                class="bl-user-form__input-text bl-input__input-text"
                [(ngModel)]="inputEmailUser"
              />
              <button
                class="bl-user-form__btn-check bl-button bl-button--btn-secundary"
                (click)="checkEmail(userRoles.INVITED)"
              >
                <svg
                  class="bl-user-form__icon-check"
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0.75L0.25 3.75V8.25C0.25 12.4125 3.13 16.305 7 17.25C10.87 16.305 13.75 12.4125 13.75 8.25V3.75L7 0.75ZM5.5 12.75L2.5 9.75L3.5575 8.6925L5.5 10.6275L10.4425 5.685L11.5 6.75L5.5 12.75Z"
                  />
                </svg>
                <span> Check </span>
              </button>
              <small id="email-help" class="bl-user-form__input-error-txt" *ngIf="showEmailError">
                We could not find the email, try again or registed in Active directory
              </small>
            </div>
            <div class="bl-user-form__input bl-input">
              <label for="nameUser" class="bl-user-form__input-label bl-input__label">Nombre</label>
              <input
                type="text"
                pInputText
                id="nameUser"
                disabled
                class="bl-user-form__input-text bl-input__input-text"
                [value]="userToAdd?.displayName"
              />
              <button class="bl-user-form__btn-cancel bl-button bl-button--btn-cancel">
                Cancel
              </button>
              <button
                [disabled]="addUserDisabled"
                class="bl-user-form__btn-add bl-button bl-button--btn-secundary"
                (click)="addUserTable()"
              >
                Add
              </button>
            </div>
          </div>
          <div class="bl-table">
            <!--bid-users-role-table
              [headerTable]="headerTableUsers"
              [bodyTable]="bodyTable?.users ? filterByRole(userRoles.INVITED) : undefined"
              (onDelete)="deleteUser($event)"
              (onStatusChange)="updateStatus($event)"
            ></bid-users-role-table-->
          </div>
          
        </p-tabPanel>

        <p-tabPanel header="PTL users" class="bl-tab-view__tab">
          <div class="bl-content bl-content--padding-bottom-medium">
            <button class="bl-button bl-button--btn-secundary" (click)="toggleAddUser()">
              <i class="fas fa-plus"></i>
              Add PTL user
            </button>
          </div>
          <div class="bl-user-form" *ngIf="addUser">
            <p class="bl-user-form__txt">
              To add an invited user , the mail must be registered in Active Directory. Please write
              and check does exist.
            </p>
            <div class="bl-user-form__input bl-input">
              <label for="emailUser" class="bl-user-form__input-label bl-input__label">Email</label>
              <input
                type="text"
                pInputText
                id="emailUser"
                class="bl-user-form__input-text bl-input__input-text"
                
              /><!-- [(ngModel)]="inputEmailUser" -->
              <!--button
                class="bl-user-form__btn-check bl-button bl-button--btn-secundary"
                (click)="checkEmail(userRoles.PTL)"
              >
                <svg
                  class="bl-user-form__icon-check"
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0.75L0.25 3.75V8.25C0.25 12.4125 3.13 16.305 7 17.25C10.87 16.305 13.75 12.4125 13.75 8.25V3.75L7 0.75ZM5.5 12.75L2.5 9.75L3.5575 8.6925L5.5 10.6275L10.4425 5.685L11.5 6.75L5.5 12.75Z"
                  />
                </svg>
                <span> Check </span>
              </button-->
              <small id="email-help" class="bl-user-form__input-error-txt" *ngIf="showEmailError">
                We could not find the email, try again or registed in Active directory
              </small>
            </div>
            <div class="bl-user-form__input bl-input">
              <label for="nameUser" class="bl-user-form__input-label bl-input__label">Nombre</label>
              <input
                type="text"
                pInputText
                id="nameUser"
                disabled
                class="bl-user-form__input-text bl-input__input-text"
                [value]="userToAdd?.displayName"
              />
              <button class="bl-user-form__btn-cancel bl-button bl-button--btn-cancel">
                Cancel
              </button>
              <button
                [disabled]="addUserDisabled"
                class="bl-user-form__btn-add bl-button bl-button--btn-secundary"
                (click)="addUserTable()"
              >
                Add
              </button>
            </div>
          </div>
          <div class="bl-table">
            <!--bid-users-role-table
              [headerTable]="headerTableUsers"
              [bodyTable]="bodyTable?.users ? filterByRole(userRoles.PTL) : undefined"
              (onDelete)="deleteUser($event)"
              (onStatusChange)="updateStatus($event)"
            ></bid-users-role-table-->
          </div>
          
        </p-tabPanel>
        <p-tabPanel header="PRM users" class="bl-tab-view__tab">
          <div class="bl-content bl-content--padding-bottom-medium">
            <button class="bl-button bl-button--btn-secundary" (click)="toggleAddUser()">
              <i class="fas fa-plus"></i>
              Add PRM user
            </button>
          </div>
          <div class="bl-user-form" *ngIf="addUser">
            <p class="bl-user-form__txt">
              To add an invited user , the mail must be registered in Active Directory. Please write
              and check does exist.
            </p>
            <div class="bl-user-form__input bl-input">
              <label for="emailUser" class="bl-user-form__input-label bl-input__label">Email</label>
              <input
                type="text"
                pInputText
                id="emailUser"
                class="bl-user-form__input-text bl-input__input-text"
               
              /> <!--  [(ngModel)]="inputEmailUser"-->
              <!--button
                class="bl-user-form__btn-check bl-button bl-button--btn-secundary"
                (click)="checkEmail(userRoles.PRM)"
              >
                <svg
                  class="bl-user-form__icon-check"
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0.75L0.25 3.75V8.25C0.25 12.4125 3.13 16.305 7 17.25C10.87 16.305 13.75 12.4125 13.75 8.25V3.75L7 0.75ZM5.5 12.75L2.5 9.75L3.5575 8.6925L5.5 10.6275L10.4425 5.685L11.5 6.75L5.5 12.75Z"
                  />
                </svg>
                <span> Check </span>
              </button-->
              <small id="email-help" class="bl-user-form__input-error-txt" *ngIf="showEmailError">
                We could not find the email, try again or registed in Active directory
              </small>
            </div>
            <div class="bl-user-form__input bl-input">
              <label for="nameUser" class="bl-user-form__input-label bl-input__label">Nombre</label>
              <input
                type="text"
                pInputText
                id="nameUser"
                disabled
                class="bl-user-form__input-text bl-input__input-text"
                [value]="userToAdd?.displayName"
              />
              <button class="bl-user-form__btn-cancel bl-button bl-button--btn-cancel">
                Cancel
              </button>
              <button
                [disabled]="addUserDisabled"
                class="bl-user-form__btn-add bl-button bl-button--btn-secundary"
                (click)="addUserTable()"
              >
                Add
              </button>
            </div>
          </div>
          <div class="bl-table">
            <!--bid-users-role-table
              [headerTable]="headerTableUsers"
              [bodyTable]="bodyTable?.users ? filterByRole(userRoles.PRM) : undefined"
              (onDelete)="deleteUser($event)"
              (onStatusChange)="updateStatus($event)"
            ></bid-users-role-table-->
          </div>
          
        </p-tabPanel>


      </p-tabView>
    </div>
  </div>
</div>
