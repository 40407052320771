import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strongFindings',
})
export class StrongFindingsPipe implements PipeTransform {
  transform(input: { text: string; search: string }): any {
    const re = new RegExp(input.search, 'gi');
    const newstr = input.text.replace(re, '<strong>' + input.search + '</strong>');
    return newstr;
  }
}
