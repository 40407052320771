<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
<p-toast class="bl-toast bl-toast--warn" key="warnMessageicr-review-form" closable="true"></p-toast>
<div class="bl-icr-review">
  <!--bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher-->
  <section>
    <bid-review-result
    [checkId]="checkId"
    (isNotifySend)="getSendNotify($event)"
    (isFinished)="getIsFinished($event)"
    [finding]="finding"
    [icrData]="icrData"  
    [ActiveNotifyRRC]="ActiveNotifyIR"
    [ActiveSumaryRRC]="ActiveSumaryIR"
    [ActiveSumaryRRC_]="ActiveSumaryIR_"
    [ActiveAddRRC]="ActiveAddIR"
    ></bid-review-result>
  </section>
  <div class="bl-wrapper bl-wrapper--padding-header">
    <main class="col-12 bl-icr-review__container">
      <section class="col-5 bl-icr-review__left-section">
        <bid-finding-crud
          [icrData]="icrData"
          (sendFinding)="getFinding($event)"
          (createNewFinding)="createAnewFinding($event)"
          (checkFinding)="getCheck($event)"
          [checkId]="checkId"
          [isFinished]="isFinished"
          (ActivateAdd)="procesaPropagarAdd($event)"
        >
        </bid-finding-crud>
      </section>
      <section class="col-7 bl-icr-review__right-section">
        <bid-finding-content
          *ngIf="(this.icrData?this.icrData.length:0)>0"          
          [finding]="finding"
          [sendNotify] = "isSendNotify"
          (checkFinding)="getCheck($event)"
          [flatCreateFinding]="flatCreateFinding"
          [isFinished]="isFinished"
          (ActivateNotify)="procesaPropagarNotify($event)"
          (ActivateFinish)="procesaPropagarFinish($event)"
          (ActivateFinish_)="procesaPropagarFinish_($event)"
        ></bid-finding-content>
      </section>
    </main>
  </div>
</div>
