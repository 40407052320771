import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TooltipOperationNumberService {
  tooltipContent = `<div class="bl-tooltip__content">
  <h3 class="bl-tooltip__title">Valid format of loan number</h3>
  <ul class="bl-tooltip__list">
  <li bl-tooltip__item><span class="bl-tooltip__text">####/XX-CC | ####/XX-CC-# </span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">GRT/XX-####-CC </span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">GRT/XX-####-CC-# </span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">ATN/XX-####-CC</span></li>
  </ul>
  </div>`;

  getTooltipContent(): string {
    return this.tooltipContent;
  }
}
