<a
  [ngClass]="{
    'bl-menu__link--step-selected': currentPageAdminModule === true ? true : false,
    'bl-menu__link--step-selected bl-menu__link--active':
      currentPageAdminModule === true ? true : false,
    'bl-menu__link bl-menu__link--disabled-role': !access
  }"
  routerLinkActive="bl-menu__link--active"
  title="Management"
  class="bl-menu__link--dark"
  (click)="menu.toggle($event)"
>
  <i class="fas fa-tasks"></i>
  Management
</a>
<div class="bl-slide-menu bl-slide-menu--gray bl-slide-menu--big-size">
  <p-slideMenu [model]="items" #menu [popup]="true" appendTo="body"></p-slideMenu>
</div>
<!-- [ngClass]="{'bl-menu__link--active bl-menu__link--step-selected' :currentPageAdminModule === true }"  -->
<!-- [ngClass]="{'bl-menu__link bl-menu__link--dark bl-menu__link--active bl-menu__link--step-selected' :currentPageAdminModule === true}" -->
