<div class="grid  ">
  <div class="bl-wrapper bl-wrapper bl-wrapper--padding-header">
    <div class="bl-seacher-historical__width-complete">
  
      <div > <!-- *ngIf="!isShow"-->
        <form [formGroup]="seacherData">
          <fieldset>
            <div class="col-lg-9 col-md-11 col-sm-12 bl-seacher-historical__content">
              <div class="col-lg-12 bl-seacher-historical__separation-row">
                <div class="col-lg-3 col-md-11 col-sm-11 bl-seacher-historical__wrapper">
                  <div class="bl-datepicker">
                    <label class="bl-datepicker__label bl-datepicker__label--big"
                      >Cut off date Range
                    </label>
                    <p-calendar
                      icon="pi pi-calendar"
                      appendTo="body"
                      showIcon="true"
                      selectionMode="range"
                      dateFormat="yy/mm/dd"
                      formControlName="reviewDateRange"
                      [ngClass]="{
                        'bl-datepicker--error': !reviewDateRangeValid
                      }"
                    ></p-calendar>
                  </div>
                </div>
                <div class="col-lg-3 col-md-11 col-sm-11 bl-seacher-historical__wrapper">
                  <div class="bl-select">
                    <label class="bl-select__label">Country</label>
                    <p-dropdown
                      [options]="countryOptions"
                      appendTo="body"
                      formControlName="country"
                      defaultLabel="Select country"
                      optionLabel="name"
                      styleClass="bl-dropdown"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-lg-3 col-md-11 col-sm-11 bl-seacher-historical__wrapper">
                  <div class="bl-input" [ngClass]="{ 'bl-input--error': !operationNumberValid }">
                    <label class="bl-input__label bl-input__label--big">Loan number</label>
                    <input
                      type="text"
                      pInputText
                      placeholder="Ex:####/XX-CC#"
                      formControlName="operationNumber"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-11 col-sm-11 bl-seacher-historical__wrapper"></div>
              </div>
              <div class="col-lg-12 bl-seacher-historical__separation-row">
                <div class="col-lg-3 col-md-11 col-sm-11 bl-seacher-historical__wrapper">
                  <div class="bl-select">
                    <label class="bl-select__label">External Auditor</label>
                    <p-dropdown
                      [options]="externalAuditorOptions"
                      appendTo="body"
                      defaultLabel="select"
                      formControlName="externalAuditor"
                      optionLabel="name"
                      placeholder="Select external Auditor"
                      styleClass="bl-dropdown"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-lg-3 col-md-11 col-sm-11 bl-seacher-historical__wrapper">
                  <div class="bl-select">
                    <label class="bl-select__label">Executing Agency</label>
                    <p-dropdown
                      [options]="executingAgenciesOptions"
                      appendTo="body"
                      defaultLabel="select"
                      optionLabel="name"
                      formControlName="executingAgency"
                      placeholder="Select executing Agency"
                      styleClass="bl-dropdown"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-lg-3 col-md-11 col-sm-11 bl-seacher-historical__wrapper">
                  <div class="bl-input" [ngClass]="{ 'bl-input--error': !operationNumberValid }">
                    <label class="bl-input__label bl-input__label--big">AFS reviewed by</label>
                    <input
                      type="text"
                      pInputText
                      formControlName="user"
                      placeholder="Ex: xname"
                      styleClass="bl-dropdown"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-11 align-end bl-seacher-historical__wrapper--btn">
                  <button
                    (click)="search()"
                    type="submit"
                    class="bl-button--btn-seacher"
                    [attr.disabled]="seacherData.errors"
                  >
                    Search analysis
                  </button>
                </div>
                <div class="col-lg-3 col-sm-11 align-end bl-seacher-historical__wrapper--link">
                  <a (click)="getLastSearchResult()" class="bl-seacher-historical__link">
                    <i class="fas fa-undo"></i> Last Search Results</a
                  >
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
