import { Injectable } from '@angular/core';
import { IIcrInput } from '@bl-interfaces/iicr-input';
import { IIcrOutput } from '@bl-interfaces/iicr-output';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StartAnalysisIcrService {
  icrPostData: IIcrInput | undefined;

  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  sendIcr(icrData: IIcrInput): Observable<IIcrOutput> {
    return this.httpClient.post<IIcrOutput>(environment.api.base + 'icrs', icrData);
  }
}
