import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { UserService } from "@core/user/user.service";
import { Role } from "@bl-enum/role";
import { take } from "rxjs/operators";
import { AuthenticationService } from "@core/authentication/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthRoleGuardGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private auth: AuthenticationService,
    private router: Router
  ) {}
  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.userService
        .getIsAdminUser()
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            resolve(true);
          } else {

            const [account] = this.auth.msalService.instance.getAllAccounts();

            this.userService
              .getUserByMail(account.username)
              .subscribe(
                (res) => {
                  if (res && res.role === Role.ADMIN) {
                    resolve(true);
                  } else {
                    this.userService.setIsAdminUser(false);
                    this.router.navigate(["home"]);
                    resolve(false);
                  }
                },
                () => {
                  this.userService.setIsAdminUser(false);
                  this.router.navigate(["home"]);
                  resolve(false);
                }
              );
          }
        });
    });
  }
}
