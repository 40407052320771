import { Component, OnInit, DoCheck } from '@angular/core';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';

@Component({
  selector: 'bid-dashboard',
  templateUrl: './dashboard-users.component.html',
  styleUrls: ['./dashboard-users.component.scss'],
})
export class DashboardUsersComponent implements OnInit, DoCheck {
  showHistoricalSearch: boolean;
  constructor(private showHistoricalSearchService: ShowHistoricalSearchService) {
    this.showHistoricalSearch = false;
  }

  ngOnInit() {
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
  }

  ngDoCheck() {
    this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });
  }
}
