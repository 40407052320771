<!--header class="bl-content-header">
  <nav class="bl-menu">
    <p-menubar [model]="items">
      <ng-template pTemplate="start">
        <div class="ui-menubar-logo">
          <img src="./../../../../assets/image/logo-luca 1.png" alt="logo" height="40">
        </div>
      </ng-template>

      <ng-template *ngIf="isLogged" let-item pTemplate="item">
        <span>
          <i [ngClass]="item.icon"></i>
          <span class="menu-item-label">{{ item.field }}</span>
        </span>
      </ng-template>
      <ng-template *ngIf="isLogged" pTemplate="item" let-item let-root="root">
        <a pRipple class="flex align-items-center p-menuitem-link">
            <span [class]="item.icon"></span>
            <span class="ml-2">{{ item.field }}</span>
            <i *ngIf="item.items" [ngClass]="['pi', root ? 'pi-angle-down ml-2' : 'pi-angle-right ml-auto']"></i>
        </a>
      </ng-template>
 
      <ng-template *ngIf="isLogged" pTemplate="end">
        <ul class="p-menubar-root-list">
          <li class="p-menuitem" (click)="toggleSearch()">
            <a   class="p-menuitem-link" [attr.tabindex]="0">
              <span class="p-menuitem-icon pi pi-fw pi-search"></span>
              <span class="p-menuitem-text">Search</span>
            </a>
          </li>
          <li class="p-menuitem" (click)="logout()">
            <a   class="p-menuitem-link" [attr.tabindex]="0">
              <span class="p-menuitem-icon pi pi-fw pi-sign-out"></span>
              <span class="p-menuitem-text">Logout</span>
            </a>
          </li>
        </ul>

        <div class="flex align-items-center gap-2">
          <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" shape="circle" />
        </div>
      </ng-template>
    </p-menubar>
  </nav>
</header-->

<!--[ngClass]="{'disabled-menu': steps[i].disabled === true ? true : false }" -->
<!-- routerLinkActive="bl-menu__link--active" 

-->
<header class="bl-content-header">
  <div class="bl-wrapper bl-wrapper--padding-header">
  
    <nav class="bl-menu">
      <div class="bl-menu__wrapper">

        <ul class="bl-menu__list">
          <li>
            <img
             
              src="../../../../assets/image/logo-luca 2.png"
              alt="logo Luca"
              style="width: 200px; height: 50px;"
            />
          </li>
          <li *ngFor="let item of menu; let i = index" class="bl-menu__item">
            <a
              *ngIf="activeNav"
              class="bl-menu__link"
              [routerLink]="item.link"
              routerLinkActive="bl-menu__link--active"
              title="{{ item.field }}"
              [ngClass]="{
                'bl-menu__link--step-selected':
                  steps && steps[i] && steps[i].stepSelected ? true : false,
                'bl-menu__link--disabled': steps && steps[i] && steps[i].disabled ? true : false
              }"
            >
              <i [class]="item.icon"></i>
              <span *ngIf="activeNav" class="bl-menu__text" [attr.data-attr]="item.field">{{
                item.field
              }}</span>

              <span
                *ngIf="activeNav"
                [ngClass]="{
                  'bl-menu__link--step-selected bl-menu__corner':
                    steps && steps[i] && steps[i].stepSelected ? true : false,
                  'bl-menu__link--disabled': steps && steps[i] && steps[i].disabled ? true : false
                }"
              >
              </span>
            </a>
          </li>

          <li *ngFor="let item of menu; let i = index" class="bl-menu__item">
            <a
              *ngIf="!activeNav"
              class="bl-menu__link"
              [routerLink]="item.link"
              routerLinkActive="bl-menu__link--active"
              title="{{ item.field }}"
              [ngClass]="{
                'bl-menu__link--step-selected':
                  steps && steps[i] && steps[i].stepSelected ? true : false,
                'bl-menu__link--disabled': steps && steps[i] && steps[i].disabled ? true : false
              }"
            >
              <i [class]="item.icon"></i>
              <span *ngIf="!activeNav" class="bl-menu__text" [attr.data-attr]="item.field">{{
                item.field
              }}</span>

              <span
                *ngIf="!activeNav"
                [ngClass]="{
                  'bl-menu__link--step-selected bl-menu__corner':
                    steps && steps[i] && steps[i].stepSelected ? true : false,
                  'bl-menu__link--disabled': steps && steps[i] && steps[i].disabled ? true : false
                }"
              >
              </span>
            </a>
          </li>

          <li  *ngIf="isLogged" class="bl-menu__item" >
            <div class="bl-historical-search">
              <a
                tabindex="-1"
                title="Historical Search"
  
                (click)="toggleSearch()"
                class="bl-menu__link"
              >

                <i class="fas fa-search"></i>
                <span  class="bl-menu__text" >Historical Search</span>
            
                  <!--span
                    *ngIf="activeNav"
                    [ngClass]="{
                      'bl-menu__link--step-selected bl-menu__corner':
                        steps && steps[i] && steps[i].stepSelected ? true : false,
                      'bl-menu__link--disabled': steps && steps[i] && steps[i].disabled ? true : false
                    }"
                  >
                  </span-->
              </a>
            </div>
          </li>
        </ul>
      </div>

      <ul class="bl-menu__list bl-menu__list--sub" *ngIf="isLogged">
        <li  class="bl-menu__item"> <!-- *ngIf="activeNav"-->
          <!--a
            class="bl-menu__link--dark"
            routerLink="/dashboard"
            routerLinkActive="bl-menu__link--active"
          >
            <i class="far fa-chart-bar"></i> Analytics
          </a-->
          <bid-menu-dashboard></bid-menu-dashboard>
        </li>

        <li class="bl-menu__item"  *ngIf="isLogged"><!-- *ngIf="activeNav"-->
          <bid-menu-role></bid-menu-role>
        </li>

        <li class="bl-menu__item" *ngIf="isLogged">
          <bid-user-button></bid-user-button>
        </li>
      </ul>
    </nav>
  </div>
</header>
 
<div   class="panel-container" [ngClass]="panelAnimationClass">
  <p-panel header="Search for past analysis" [toggleable]="false">
      <bid-searcher  (toogleSearcher)="toggleSearch()"></bid-searcher>
  </p-panel>
</div>
 