import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EzshareParameterService {
  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  getEzsharedParameters(operationNumber: string) {
    console.log("EzshareParameterService getEzsharedParameters");
    return this.httpClient.get(
      environment.api.base + 'convergences/ezshare?operationNumber=' + operationNumber,
      { responseType: 'text' },
    );
  }
}
