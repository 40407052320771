import { Component, OnInit, DoCheck } from '@angular/core';
import { headersTables } from '@shared/constants/table-header.const';
import { DatePipe } from '@angular/common';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import { HistoricalService } from '@core/history/historical.service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { Message } from 'primeng/api';
import { UserService } from '@core/user/user.service';
import { MessageToastService } from '@core/messages/message-toast.service';
/*import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';*/

@Component({
  selector: 'bid-historical',
  templateUrl: './historical.component.html',
  styleUrls: ['./historical.component.scss'],
  providers: [DatePipe],
})
export class HistoricalComponent implements OnInit, DoCheck {
  headerTable: any;
  bodyTable: any = [];
  //showHistoricalSearch: boolean;
  errorMsg: string = '';
  msgs: Message[];

  canDelete: boolean;

  _showHistoricalSearchService;
  _historicalService;

  //horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  //verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private datePipe: DatePipe,
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private historicalService: HistoricalService,
    //private spinner: NgxSpinnerService,
    private userService: UserService,
    private messageToastService : MessageToastService
    //private _snackBar: MatSnackBar,
  ) {
    this.msgs = [];
    this.canDelete = false;
    this._showHistoricalSearchService = showHistoricalSearchService;
    this._historicalService = historicalService;
  }

  ngOnInit() {
    //this.spinner.show();
    sessionStorage.removeItem('pocessingTime');
    this.msgs = [];
    this.bodyTable = [];
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    //this.showHistoricalSearch = false;
    this.headerTable = headersTables.headerHistorical;
    this.recoberFormData();
    this.userService.getIsAdminUser().subscribe((status: boolean) => {
      this.canDelete = status;
    });
  }

  ngDoCheck() {
    /*this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });*/
  }

  recoberDataForm(dataForm: any) {
    this.loadResult(dataForm);
  }

  removeAnalysis(event: any) {
    this.bodyTable = this.bodyTable.filter((analysis: any) => {
      return analysis.analysisId !== event.analysisId;
    });
    this.saveLocalStorageTableResult(this.bodyTable);
  }

  recoberFormData() {
    this.showHistoricalSearchService.getFormSearcherSearch().subscribe((res: any) => {
      if (res) {
        this.loadResult(res);
      } else {
        this.bodyTable = [];
        //this.spinner.hide();
      }
    });
  }

  loadResult(data: any) {
    //this.spinner.show();
    if (Object.keys(data).length) {
      this.historicalService.getHistory(data).subscribe(
        (res: any) => {
          if (res && res.length > 0) {
            this.bodyTable = res;
            this.parseoDataTable(res);
            this.saveLocalStorageTableResult(this.bodyTable);
          } else {
            this.bodyTable = [];
            //this.spinner.hide();
            this.showMsgError();
          }
        },
        () => {
          this.bodyTable = [];
          //this.spinner.hide();
          this.showMsgError();
        },
      );
    } else {
      //this.spinner.hide();
      const LocalStoragehistoralResult = localStorage.getItem('historalResultLocalStrorage');
      if (LocalStoragehistoralResult !== null) {
        this.bodyTable = JSON.parse(LocalStoragehistoralResult);
      }
    }
  }

  showMsgError() {
    /*this._snackBar.open('No data found', '✖', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
      panelClass: ['red-snackbar'],
    });*/

    this.messageToastService.showToastError(
      'Error',
      'No data found',
      'errorMessage',
    );
  }

  saveLocalStorageTableResult(data: any) {
    const stringData = JSON.stringify(data);
    localStorage.setItem('historalResultLocalStrorage', stringData);
  }

  parseoDataTable(data: any) {
    this.bodyTable = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const historyObj: any = {
          analysisId: '',
          country: '',
          operationNumber: '',
          auditDateRange: '',
          projectName: '',
          reviewDate: '',
          icr: '',
          icrStatus: '',
          afs: '',
          icrLetter: '',
          summaryReport: '',
          delete: '',
          view: '',
          user: '',
        };
        if (data[i].id) {
          historyObj.analysisId = data[i].id;
        }
        if (data[i].country) {
          historyObj.country = data[i].country;
        }
        if (data[i].user) {
          historyObj.user = data[i].user;
        }
        if (data[i].operationNumber) {
          historyObj.operationNumber = data[i].operationNumber;
        }
        if (data[i].initDate || data[i].endDate) {
          historyObj.auditDateRange =
            data[i].initDate.toString().substring(0,10).replace(/\-/g, '/') +
            ' - ' +
            data[i].endDate.toString().substring(0,10).replace(/\-/g, '/');
        }
        if (data[i].projectName) {
          historyObj.projectName = data[i].projectName;
        }
        if (data[i].reviewDate) {
          historyObj.reviewDate = this.datePipe.transform(
            data[i].reviewDate,
            'yyyy/MM/dd HH:mm:ss',
          );
        }
        if (data[i].icr) {
          historyObj.icr = data[i].icr;
        }
        if (data[i].icrStatus) {
          historyObj.icrStatus = data[i].icrStatus;
        }
        if (data[i].efa) {
          historyObj.afs = data[i].efa;
        }
        if (data[i].summaryReport) {
          historyObj.summaryReport = data[i].summaryReport;
          if (data[i].summaryReport.path !== '') {
            historyObj.view = "<a><i class='pi pi-eye'></i></a>";
          } else {
            historyObj.view = "<a><i class='pi pi-pencil'></i></a>";
          }
        } else {
          historyObj.view = "<a><i class='pi pi-pencil'></i></a>";
        }
        if (this.canDelete) {
          historyObj.delete = '<a><i class="pi pi-trash"></i></a>';
        }
        this.bodyTable.push(historyObj);
      }
    }

    console.log(this.bodyTable);
    this.sortDataByReviewDate(this.bodyTable);
    //this.spinner.hide();
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
  }

  sortDataByReviewDate(data: any) {
    this.bodyTable = data.sort((a: any, b: any) => {
      const dateA = Date.parse(a.reviewDate);
      const dateB = Date.parse(b.reviewDate);
      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
