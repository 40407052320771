import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IcrReviewComponent } from "./icr-review/icrReview.component";
import { SharedModule } from "@shared/shared.module";
import { CoreModule } from "@core/core.module";
import { GenerateAnalysisComponent } from "./generate-analysis/generate-analysis.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HistoricalComponent } from "./historical/historical.component";
import { AfsReviewComponent } from "./afs-review/afs-review.component";
import { ScoreComponent } from "./score/score.component";
//import { NgxSpinnerModule } from "ngx-spinner";
import { HomeComponent } from "./home/home.component";
import { UsersComponent } from "./users/users.component";
import { TestDictionaryComponent } from "./test-dictionary/test-dictionary.component";
import { IcrDictionaryComponent } from "./icr-dictionary/icr-dictionary.component";
import { TestDictionaryDetailComponent } from "./test-dictionary-detail/test-dictionary-detail.component";
import { TestDescriptionComponent } from "./test-description/test-description.component";
import { ToolsComponent } from "./tools/tools.component";
import { IcrDictionaryFindingsComponent } from "./icr-dictionary-findings/icr-dictionary-findings.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardUsersComponent } from "./dashboard-users/dashboard-users.component";
import { AdminUsersComponent } from "./admin-users/admin-users.component";

@NgModule({
  declarations: [
    GenerateAnalysisComponent,
    IcrReviewComponent,
    HistoricalComponent,
    AfsReviewComponent,
    ScoreComponent,
    HomeComponent,
    UsersComponent,
    TestDictionaryComponent,
    IcrDictionaryComponent,
    TestDictionaryDetailComponent,
    TestDescriptionComponent,
    ToolsComponent,
    IcrDictionaryFindingsComponent,
    DashboardComponent,
    DashboardUsersComponent,
    //ActionPlanComponent,
    //UpdateActionPlanComponent,
    //MyActionPlanComponent,
    AdminUsersComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    //NgxSpinnerModule,
  ],
  exports: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FeaturesModule {}
