import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SummaryReportService {
  private summaryReportSubject = new BehaviorSubject([]);

  private summaryReportObservable: Observable<any> = this.summaryReportSubject.asObservable();

  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  generateReport(analysisId: string) {
    return this.httpClient.get(environment.api.base + 'summary-reports/' + analysisId, {
      responseType: 'text',
    });
  }

  generateDraftReport(analysisId: string) {
    return this.httpClient.get(environment.api.base + 'summary-reports/' + analysisId + '/draft', {
      responseType: 'text',
    });
  }

  download(url: string, type: string) {
    let params = '?url=' + encodeURIComponent(url) + '&type=' + type;
    return this.httpClient.get(environment.api.baseUpload + 'documents/download' + params, {
      responseType: 'blob',
    });
  }

  completeIcr(analysisId: string) {
    return this.httpClient.put(environment.api.base + 'icrs/' + analysisId + '/complete', {});
  }

  setGenerateSummaryReport(summaryReport: any) {
    this.summaryReportSubject.next(summaryReport);
  }

  getGenerateSummaryReport(): Observable<[]> {
    return this.summaryReportObservable;
  }
}
