import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ValidationErrors, FormGroup, FormControl } from '@angular/forms';
//import { TooltipOperationNumberService } from '@core/messages/tooltip-operation-number.service';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import { Router } from '@angular/router';
//import { DatePipe } from '@angular/common';
import { UtilsService } from '@core/utils/utils.service';
import { historicalSearch } from '@shared/interfaces/historical-search';
import { isNull } from 'lodash';

@Component({
  selector: 'bid-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss'],
  //providers: [DatePipe],
})
export class SearcherComponent implements OnInit {
  @Output() dataForm = new EventEmitter<historicalSearch>();
  seacherData!: FormGroup;
  isShow: boolean = true;
  //tooltipInfo: string = "";
  reviewDateRangeValid = true;
  operationNumberValid = true;
  //@Output() historicalResult = new EventEmitter();
  countryOptions: any = [];
  country!: any[];
  externalAuditorOptions = [];
  executingAgenciesOptions = [];

  @Output() toogleSearcher = new EventEmitter();

  constructor(
    //private tooltipOperationNumberService: TooltipOperationNumberService,
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private utilsService: UtilsService,
    private router: Router,
    //private datePipe: DatePipe,
  ) {
    //this.isShow = false;
    this.seacherData = new FormGroup(
      {
        reviewDateRange: new FormControl(''),
        initDate: new FormControl(''),
        endDate: new FormControl(''),
        country: new FormControl(''),
        operationNumber: new FormControl(''),
        externalAuditor: new FormControl(''),
        executingAgency: new FormControl(''),
        user: new FormControl(''),
      },
      //{ validators: this.oneFieldValidator },
    );
  }

  ngOnInit() {
    this.loadSelect();
    
    //this.tooltipInfo = this.tooltipOperationNumberService.getTooltipContent();
  }

  oneFieldValidator(control: FormGroup): ValidationErrors {
    let returnValue: any = { oneChecked: true };
    const reviewDateRange = control.get('reviewDateRange');
    const country = control.get('country');
    const operationNumber = control.get('operationNumber');
    const externalAuditor = control.get('externalAuditor');
    const executingAgency = control.get('executingAgency');
    const user = control.get('user');
    if (
      control != null &&
      ((reviewDateRange?.value && reviewDateRange.value !== '' && reviewDateRange.value[1]) ||
        (country?.value && country.value !== '' && country.value.value !== null) ||
        (operationNumber?.value && operationNumber.value !== '') ||
        (externalAuditor?.value &&
          externalAuditor.value !== '' &&
          externalAuditor.value.value !== null) ||
        (executingAgency?.value &&
          executingAgency.value !== '' &&
          executingAgency.value.value !== null) ||
        (user?.value && user.value !== '' && user.value.value !== null))
    ) {
      returnValue = null;
    }
    return returnValue;
  }

  loadSelect() {
    this.utilsService.getUtils().subscribe((res: any) => {
      this.parseSelect(res.countries, 'Country');
      this.parseSelect(res.auditors, 'Auditors');
      this.parseSelect(res.executingAgencies, 'Agencies');
      this.countryOptions = res.countries;
      console.log(res.countries)
      this.externalAuditorOptions = res.auditors;
      this.executingAgenciesOptions = res.executingAgencies;
      console.log(this.executingAgenciesOptions)
    });
  }

  parseSelect(data: any, kind: string) {
    const arrSort = data.sort();
    switch (kind) {
      case 'Country':
        arrSort.unshift({ name: 'Select Country', value: null });
        break;
      case 'Auditors':
        arrSort.unshift({ name: 'Select External Auditors', value: null });
        for (let i = 0; i < data.length; i++) {
          if (arrSort[i].name) {
            arrSort[i].name =
              arrSort[i].name[0].toUpperCase() + arrSort[i].name.substr(1).toLowerCase();
          }
        }
        break;
      case 'Agencies':
        data.unshift({ name: 'Select Executing Agency', value: null });
        for (let i = 0; i < data.length; i++) {
          if (arrSort[i].name) {
            arrSort[i].name =
              arrSort[i].name[0].toUpperCase() + arrSort[i].name.substr(1).toLowerCase();
          }
        }
        break;
    }
  }

 

  search() {
    // eslint-disable-next-line
    ////this.reviewDateRangeValid = this.validateDates();
    if (this.reviewDateRangeValid && this.seacherData.value.reviewDateRange) {
      this.parseoDate();
    }
    this.parseoDropdown('country');
    this.parseoDropdown('externalAuditor');
    this.parseoDropdown('executingAgency');
    const checkOperationNumber = this.validateOperationNumber();
    if (this.reviewDateRangeValid && checkOperationNumber) {
      this.searchInProgress();
    }
    this.showAndHideSearcher({})
  }

  getLastSearchResult() {
    if (this.router.url !== '/historical') {
      this.router.navigate(['/historical']);
    } else {
      /*this.showHistoricalSearchService.getFormSearcherSearch().subscribe((res: any) => {
        if (res) {
          this.seacherData.patchValue({
            reviewDateRange: res.reviewDateRange,
            initDate: res.initDate,
            endDate: res.endDate,
            country: res.country,
            operationNumber: res.operationNumber,
            externalAuditor: res.externalAuditor,
            executingAgency: res.executingAgency,
            user: res.user,
          });
          const objToSearch: historicalSearch = {
            reviewDateRange: this.seacherData.value.reviewDateRange,
            initDate: this.seacherData.value.initDate,
            endDate: this.seacherData.value.endDate,
            country:
              this.seacherData.value.country && this.seacherData.value.country.name
                ? this.seacherData.value.country.name
                : this.seacherData.value.country,
            operationNumber: this.seacherData.value.operationNumber,
            externalAuditor:
              this.seacherData.value.externalAuditor && this.seacherData.value.externalAuditor.name
                ? this.seacherData.value.externalAuditor.name.toUpperCase()
                : this.seacherData.value.externalAuditor,
            executingAgency:
              this.seacherData.value.executingAgency && this.seacherData.value.executingAgency.name
                ? this.seacherData.value.executingAgency.name.toUpperCase()
                : this.seacherData.value.executingAgency,
            user: this.seacherData.value.user,
          };
          this.dataForm.emit(objToSearch);
        }
      });*/
    }
  }

  validateDates() {
    let validResult: boolean = false;
    /*if (
      !isNull(this.seacherData.get('reviewDateRange')?.value) &&
      this.seacherData.get('reviewDateRange')?.value !== undefined
    ) {
      let notUndefinedEndDate: boolean;
      let notNullEndDate: boolean;

      notNullEndDate = !isNull(this.seacherData.get('reviewDateRange')?.value[1]);

      notUndefinedEndDate =
         this.seacherData.get('reviewDateRange')?.value[1] !== undefined || false;
      validResult = notUndefinedEndDate && notNullEndDate;
    } else {
      validResult = true;
    }*/
    return validResult;
  }

  validateOperationNumber(): boolean {
    const OperationNumberEmpty =
      this.seacherData.value.operationNumber === undefined ||
      this.seacherData.value.operationNumber === ''
        ? true
        : false;
    if (OperationNumberEmpty) {
      return true;
    } else {
      this.operationNumberValid = this.seacherData.get('operationNumber')?.valid || false;
      if (this.operationNumberValid) {
        return true;
      } else {
        return false;
      }
    }
  }

  parseoDate() 
  {
    this.seacherData.patchValue({
      initDate: this.seacherData.value.reviewDateRange[0].toISOString().substring(0,10).replace(/\-/g, '/'),
      endDate: this.seacherData.value.reviewDateRange[1].toISOString().substring(0,10).replace(/\-/g, '/'),
    });
  }

  parseoDropdown(kind: string) {
    switch (kind) {
      case 'country':
        if (this.seacherData.value.country) {
          this.seacherData.patchValue({
            country: this.seacherData.value.country,
          });
        }
        break;
      case 'externalAuditor':
        if (this.seacherData.value.externalAuditor) {
          this.seacherData.patchValue({
            externalAuditor: this.seacherData.value.externalAuditor,
          });
        }
        break;
      case 'executingAgency':
        if (this.seacherData.value.executingAgency) {
          this.seacherData.patchValue({
            executingAgency: this.seacherData.value.executingAgency,
          });
        }
        break;
    }
  }
  showAndHideSearcher(event: any){
    
      this.toogleSearcher.emit(event);
     
  }

  searchInProgress() {
    const objToSearch: historicalSearch = {
      reviewDateRange: this.seacherData.value.reviewDateRange,
      initDate: this.seacherData.value.initDate ? this.seacherData.value.initDate : null,
      endDate: this.seacherData.value.endDate ? this.seacherData.value.endDate : null,
      country:
        this.seacherData.value.country &&
        this.seacherData.value.country.name &&
        this.seacherData.value.country.value !== null
          ? this.seacherData.value.country.name
          : null,
      operationNumber: this.seacherData.value.operationNumber,
      externalAuditor:
        this.seacherData.value.externalAuditor &&
        this.seacherData.value.externalAuditor.name &&
        this.seacherData.value.externalAuditor.value !== null
          ? this.seacherData.value.externalAuditor.name.toUpperCase()
          : null,
      executingAgency:
        this.seacherData.value.executingAgency &&
        this.seacherData.value.executingAgency.name &&
        this.seacherData.value.executingAgency.value !== null
          ? this.seacherData.value.executingAgency.name.toUpperCase()
          : null,
      user: this.seacherData.value.user,
    };
    this.showHistoricalSearchService.setFormSearcherSearch(objToSearch);
    this.router.navigate(['/historical']);
  }
}
