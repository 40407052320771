<div class="bl-manage-finding">
  <p-dialog header="Deleting confirmation" [(visible)]="display" [modal]="true" [responsive]="true"
    [maximizable]="false" [baseZIndex]="10000" [closable]="false" styleClass="bl-modal" [positionTop]="65"
    [positionLeft]="390">
    <p>
      You're deleting the findings and observations selected. Are you sure that you want to proceed?
    </p>
    <p-footer>
      <button type="button" pButton (click)="cancelRemoveFinding()" label="Cancel"></button>
      <button type="button" pButton *ngIf="arrayDelete.length === 0" (click)="removeFinding()" label="Continue"
        class="ui-button-secondary"></button>
      <button type="button" pButton *ngIf="arrayDelete.length" (click)="removeFindingList()" label="Continue"
        class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <p-panel header="Filters" [toggleable]="true" [collapsed]="true" [ngStyle]="{'padding': '0'}">
    <div class="bl-manage-finding__wrapper-header t-display-flex">
      <div style="width: 100%; padding-right: 15px;">
        <div class="bl-manage-finding__risk-add-wrapper">
          <div>
            <label class="bl-manage-finding__risk-label">Risk:</label>
            <div class="bl-selectbuttons">
              <p-selectButton [options]="menu" [(ngModel)]="selectedRisk" (onChange)="filterFindings()"
                [disabled]="removeAllStatus"></p-selectButton>
            </div>
          </div>
          <button (click)="resetSeacherForm()" class="bl-button--btn-seacher t-margin-top-20 t-margin-left-36"
            type="button">
            Clear all filters
          </button>
        </div>
        <div class="bl-manage-finding__classification-period-wrapper">
          <div class="bl-manage-finding__bucket-wrapper">
            <span class="bl-manage-finding__select-title">Bucket Classification</span>
            <p-dropdown [options]="bucketSelect" [(ngModel)]="bucketSelectItem" styleClass="bl-dropdown"
              (onChange)="filterFindings()" [disabled]="removeAllStatus"></p-dropdown>
          </div>
          <div class="bl-manage-finding__bucket-wrapper">
            <span class="bl-manage-finding__select-title">Period</span>
            <p-dropdown [options]="periodSelect" [(ngModel)]="periodSelectItem" styleClass="bl-dropdown"
              (onChange)="filterFindings()" [disabled]="removeAllStatus"></p-dropdown>
          </div>
        </div>
        <div class="bl-manage-finding__delete-wrapper bl-manage-finding__delete-wrapper--nb">
          <div class="bl-manage-finding__bucket-wrapper">
            <div class="bl-input">
              <label class="bl-manage-finding__select-title">Search findings</label>
              <input type="search" pInputText [(ngModel)]="search"
                (ngModelChange)="!$event.length ? searchFindings() : ''" placeholder="Search"
                styleClass="bl-dropdown" />
            </div>
          </div>
          <button type="button" class="bl-button--btn-secundary t-margin-top-23" (click)="searchFindings()"
            [disabled]="removeAllStatus">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <span *ngIf="nonOcurrences" class="bl-finding-form__no-ocurrences">Your search does not match any results.
          Please
          try again later</span>
        <div class="bl-manage-finding__delete-wrapper" *ngIf="!checkSummaryReport">
          <div class="bl-manage-finding__delete-wrapper-checks">
            <p-checkbox name="check-remove-all" id="check-remove-all" label="Multiple selection to delete findings"
              (onChange)="removeAllChange($event)" [(ngModel)]="removeAllStatus" [value]="removeAllStatus"
              [binary]="true">
            </p-checkbox>
            <p-checkbox *ngIf="removeAllStatus" name="check-select-all" id="check-select-all"
              label="Select/Unselect All" (onChange)="selectAll($event)" [(ngModel)]="selectAllStatus"
              [value]="selectAllStatus" [binary]="true">
            </p-checkbox>
          </div>
          <button class="bl-manage-finding__btn-delete" (click)="showConfirmationDeleteFindingList()"
            *ngIf="arrayDelete.length" title="Remove all selected findings">
            <i class="far fa-trash-alt"></i>
          </button>
        </div>
      </div>
      <div>
        <button *ngIf="ActivateAddFinding" (click)="addFinding()" type="submit"
          class="bl-button--btn-secundary t-margin-top-20" [disabled]="checkSummaryReport === true || removeAllStatus">
          Add finding
        </button>
      </div>
    </div>
  </p-panel>

  <ul [ngStyle]="{'padding-left': '0'}">
    <li *ngFor="let finding of icrData; let i = index" class="bl-manage-finding__link-decoration"
      [hidden]="finding.visible === false">
      <div class="bl-manage-finding__item" [ngClass]="{
          'bl-manage-finding__item--active': selectedItem === 'item' + i
        }" (click)="selectFinding(finding, i)">
        <p-overlayPanel styleClass="bl-manage-finding__overlay-delete" [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'" [appendTo]="'body'" #op1>
          <button class="bl-manage-finding__btn-delete" (click)="showConfirmationDeleteFinding(finding, i)"
            [disabled]="checkSummaryReport">
            <i class="far fa-trash-alt"></i>
          </button>
        </p-overlayPanel>
        <button class="bl-manage-finding__btn-show-delete" pButton (click)="op1.toggle($event)" [ngClass]="{
            'bl-manage-finding__btn-show-delete--noAply': checkSummaryReport === true
          }">
          <i class="fas fa-ellipsis-v"></i>
        </button>
        <div class="bl-manage-finding__header-finding bl-manage-finding__kind-risk" [ngClass]="{
            'bl-manage-finding__kind-risk--low': finding.risk === 'Low',
            'bl-manage-finding__kind-risk--high': finding.risk === 'High'
          }">
          <span class="bl-manage-finding__item-type-finding" *ngIf="finding?.type === 'Automatic'">
            {{ finding?.type }}</span>
          <span class="bl-manage-finding__item-type-finding" *ngIf="finding?.type === 'Manual'">
            {{ finding?.type }}</span>
          <span class="bl-manage-finding__item-type-finding" *ngIf="finding?.type === null">Automatic
          </span>
          <span class="bl-manage-finding__item-year">{{ finding.period }}</span>
          <span class="bl-manage-finding__item-year"
            *ngIf="finding?.risk !== 'Low' && finding?.risk !== 'High'">Period</span>
        </div>
        <div class="bl-manage-finding__width-content" style="max-width:100%">
          <span class="bl-manage-finding__item-bucket"> {{ finding?.buckets }}</span>
          <span class="bl-manage-finding__finding-info"
            [innerHTML]="{ text: finding?.text, search: search } | strongFindings">
          </span>
          <i *ngIf="finding?.reviewed"
            class="bl-manage-finding__check fas fa-check bl-manage-finding__check--checked"></i>
        </div>
      </div>
      <div class="bl-manage-finding__check-delete" *ngIf="removeAllStatus">
        <p-checkbox name="check-{{ i }}" id="check-{{ i }}" (onChange)="checkSelected($event, i, finding)"
          [ngModel]="selectAllStatus" [value]="selectAllStatus" [binary]="true">
        </p-checkbox>
      </div>
    </li>
  </ul>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>