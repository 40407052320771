import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
//import { MessageService } from 'primeng/components/common/messageservice';
//import { Message } from 'primeng/components/common/api';
import { AfsReviewService } from '@core/afs-review/afs-review.service';
import { StartAnalysisService } from '@core/analysis/start-analysis.service';
import { StepperService } from '@core/stepper/stepper.service';
import { GetAnalysisService } from '@core/analysis/get-analysis.service';
import { MessageToastService } from '@core/messages/message-toast.service';
import { SummaryReportService } from '@core/summaryReport/summary-report.service';
import { DOCUMENTS_TYPES } from '@bl-constants/docs-types.const';

@Component({
  selector: 'bid-historical-table',
  templateUrl: './historical-table.component.html',
  styleUrls: ['./historical-table.component.scss'],
  //providers: [MessageService],
})
export class HistoricalTableComponent {
  @Input() headerTable: any;
  @Input() bodyTable: any;
  @Output() analysisRemoved = new EventEmitter();
  //msgs: Message[] = [];
  CURRENTSTEP = [
    { name: 'generateNewAnalysis', stepSelected: false, disabled: false },
    { name: 'icrReview', stepSelected: true, disabled: false },
  ];
  STEPINCOMPLETEANALYSIS = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'afsReview', stepSelected: true },
    { name: 'score', stepSelected: false, disabled: true },
    { name: 'icrReview', stepSelected: false, disabled: false },
  ];
  STEPINCOMPLETEANALYSISNOICR = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'afsReview', stepSelected: true },
    { name: 'score', stepSelected: false, disabled: true },
    { name: 'icrReview', stepSelected: false, disabled: true },
  ];
  STEPCOMPLETEANALYSISNOICR = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'afsReview', stepSelected: false },
    { name: 'score', stepSelected: true },
    { name: 'icrReview', stepSelected: false, disabled: true },
  ];
  
  analysisData: any;
  validateAnalisis: any;
  display = false;
  selectedForDelete: any;

_getAnalysisService: any;
  constructor(
    private router: Router,
    private afsReviewService: AfsReviewService,
    private startAnalysisService: StartAnalysisService,
    private stepperService: StepperService,
    private messageToastService: MessageToastService,
    private getAnalysisService: GetAnalysisService,
    private summaryReportService: SummaryReportService,
  ) {
    //this.msgs = [];
    this._getAnalysisService= getAnalysisService;
  }

  redirectToAfsReview(data: any) {
    const stringData = JSON.stringify(data.analysisId);
    localStorage.setItem('analysisId', stringData);
    localStorage.setItem('analisisResultLocalStorage', JSON.stringify(data));
    localStorage.setItem('saveFindings', '[]');

    this.afsReviewService.setScore(true);
    this.checkStatusAnalysis(data.analysisId, data);
    this.startAnalysisService.setAnalysisData(data.analysisId);
  }

  removeAnalysis() {
    this.display = false;
    this.getAnalysisService.removeAnalysis(this.selectedForDelete.analysisId).subscribe(
      () => {
        this.analysisRemoved.emit(this.selectedForDelete);
        this.selectedForDelete = undefined;
        this.showToastSuccess('Analysis successfully removed', 'successMessageRemoved');
      },
      () => {
        this.showToastError(
          'An error was encountered when removing this analysis, please try again later',
          'errorMessage-remove-analysis',
        );
        this.selectedForDelete = undefined;
      },
    );
  }

  async downloadDocs(url: string, filename: string, type: string)
  {
    let ext = ".pdf";
    if(type == DOCUMENTS_TYPES.summary)
    {
      ext = ".docx";
    }

    if(url.indexOf('blob.core.windows') > 0){
      await this.summaryReportService.download(url, DOCUMENTS_TYPES[type as keyof typeof DOCUMENTS_TYPES]).toPromise()
          .then((response: any) => {

            const blob = new Blob([response], { type: response.type });
            const url = window.URL.createObjectURL(blob);

            const anchorElement = document.createElement('a');
            document.body.appendChild(anchorElement);
            anchorElement.href = url;
            anchorElement.download = filename + ext;
            anchorElement.click();

            window.URL.revokeObjectURL(url);

          }, (_error) => {

          });
    } else {
      window.open(url);
    }
  }

  showConfirmationRemoveAnalysis(rowData: any) {
    this.selectedForDelete = rowData;
    this.display = true;
  }

  checkStatusAnalysis(analysisId: string, data: any) {
    this.getAnalysisService.getAnalysis(analysisId).subscribe((res: any) => {
      if (res) {
        this.analysisData = res;
        this.validateAnalisis = this.analysisData.testResults.filter(
          (item: any) => item.answer_luca === 'Manual' && item.answerFms === '',
        );
        if (this.validateAnalisis.length > 0) {
          this.noFinishAnalysis(data);
        } else {
          this.correctAnalysis(data);
        }
      }
    });
  }

  correctAnalysis(data: any) {
    this.router.navigate(['/score']);
    if (data && data.icr && data.icr.name) {
      this.stepperService.setStepper(this.CURRENTSTEP);
    } else {
      this.CURRENTSTEP[0].stepSelected = false;
      this.stepperService.setStepper(this.STEPCOMPLETEANALYSISNOICR);
    }
  }

  noFinishAnalysis(data: any) {
    this.router.navigate(['/AfsReview']);
    if (data && data.icr && data.icr.name) {
      this.stepperService.setStepper(this.STEPINCOMPLETEANALYSIS);
    } else {
      this.stepperService.setStepper(this.STEPINCOMPLETEANALYSISNOICR);
    }
  }

  showToastWarning(summary: string, detail: string) {
    this.messageToastService.showToastWarn('warn', summary, detail);
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }

  showToastSuccess(summary: string, detail: string) {
    this.messageToastService.showToastSuccess('Success', summary, detail);
  }
}
