import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TooltipInfoTableAfsReviewService {
  tooltipContent = `<div class="bl-tooltip__content">
  <h3 class="bl-tooltip__title">Sample:</h3>
  <ul class="bl-tooltip__list">
  <li class="bl-tooltip__item"><span class="bl-tooltip__text">Paramenter 1 / parameter 2</span></li>
  </ul>
  </div>`;

  getTooltipContent(): string {
    return this.tooltipContent;
  }
}
