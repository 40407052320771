import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/user/user.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'bid-menu-role',
  templateUrl: './menu-role.component.html',
  styleUrls: ['./menu-role.component.scss'],
})
export class MenuRoleComponent implements OnInit, DoCheck {
  items: any;
  currentPageAdminModule: boolean = false;
  specialRoute: string;
  user: any;
  MOCK_USER_ADMIN: any[] = [];
  access: boolean = false;
  constructor(private router: Router, private userService: UserService) {
    this.specialRoute = '';
  }

  ngOnInit() {
    this.userService
      .getIsAdminUser()
      .pipe(takeWhile((isAdmin) => isAdmin === undefined, true))
      .subscribe(
        (res) => {
          if (res !== undefined) {
            this.access = res;
          }
        },
        () => {
          return;
        },
      );
    this.items = [
      {
        label: 'Tests Descriptions',
        command: () => {
          this.router.navigate(['/test-description']);
        },
      },

      {
        label: 'Test Dictionary',
        command: () => {
          this.router.navigate(['/test-dictionary']);
        },
      },
      {
        label: 'ICR Dictionary',
        command: () => {
          this.router.navigate(['/icr-dictionary']);
        },
      },
      {
        label: 'Tools',
        command: () => {
          this.router.navigate(['/tools']);
        },
      },
      {
        label: 'Users',
        command: () => {
          this.router.navigate(['/users']);
        },
      },
      {
        label: 'Admin Users',
        command: () => {
          this.router.navigate(['/adminUsers']);
        },
      },
    ];
    this.checkCurrentUrl(this.router.url.split('/')[1]);
  }

  ngDoCheck() {
    this.checkCurrentUrl(this.router.url.split('/')[1]);
  }

  checkCurrentUrl(currentUrl: string) {
 
    if (currentUrl === 'test-description') {
      this.currentPageAdminModule = true;
    } else if (currentUrl === 'test-dictionary') {
      this.currentPageAdminModule = true;
    } else if (currentUrl === 'icr-dictionary') {
      this.currentPageAdminModule = true;
    } else if (currentUrl === 'tools') {
      this.currentPageAdminModule = true;
    } else if (currentUrl === 'users') {
      this.currentPageAdminModule = true;
    } else if (currentUrl === 'test-dictionary-detail') {
      this.currentPageAdminModule = true;
    } else if (currentUrl === 'icr-dictionary-findings') {
      this.currentPageAdminModule = true;
    } else {
      this.currentPageAdminModule = false;
    }
  }
}
