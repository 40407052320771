<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
<p-toast
  class="bl-toast bl-toast--error"
  key="errorMessageTestDictionaryDetail"
  closable="true"
></p-toast>
<!--bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher-->
<div class="bl-wrapper">
  <div class="bl-dictionary">
    <div class="bl-dictionary__wrapper">
      <div class="bl-title">
        <h1>
          <a class="bl-dictionary__link" routerLink="/test-dictionary">Test dictionary</a>
          / {{ recoverTest }}
        </h1>
      </div>
      <div class="bl-dictionary__text-number">
        <span>Test number : {{ testId }}</span>
      </div>
      <form [formGroup]="formGroupSeacherTestDetailDictionary" class="bl-content">
        <div class="bl-content__custom-size-select">
          <div class="bl-select">
            <label class="bl-select__label bl-select__label--big"
              >Select Dictionary (language)</label
            >
            <p-dropdown
              [options]="languageCombo"
              formControlName="language"
              defaultLabel="select"
              styleClass="bl-dropdown"
              (onChange)="checkLanguages()"
            ></p-dropdown>
          </div>
        </div>
        <div class="bl-searcher-term">
          <div class="bl-input">
            <label class="bl-input__label bl-input__label--big">Search Parameter</label>
            <div class="bl-searcher-term__input ui-inputgroup">
              <input
                type="text"
                pInputText
                formControlName="seacherTerm"
                (keydown.enter)="seacherByTerm()"
                placeholder="Sample: parameter 1"
                [attr.disabled]="seracherTerm"
              />
              <button (click)="seacherByTerm()" pButton type="button" [disabled]="seracherTerm">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div *ngIf="seracherTerm" class="bl-searcher-term__term-seacher-content">
            <span class="bl-searcher-term__term-seacher">
              {{ seracherTerm }}
              <a (click)="removeSeacherTerm()" class="bl-searcher-term__icon-remove"
                ><i class="fas fa-times"></i
              ></a>
            </span>
          </div>
        </div>
      </form>

      <form [formGroup]="formGroupAddParameter">
        <div class="bl-content col-md-10 bl-dictionary__content-no-padding">
          <div class="bl-input bl-dictionary__wrapper-input-field col-lg-6 col-md-6 col-sm-12">
            <label class="bl-input__label bl-input__label--big">New Parameters</label>
            <input
              type="text"
              formControlName="parameter"
              pInputText
              placeholder="Sample: Parameter 1 / Parameter 2"
            />
            <span class="bl-input__acalaration">Please enter parameters separate with / </span>
          </div>
          <div
            *ngIf="testId === '6' || testId === '7'"
            class="bl-select bl-dictionary__wrapper-input-field col-lg-5 col-md-5 col-sm-12"
          >
            <label class="bl-select__label bl-select__label--big"
              >Select Category
              <span class="bl-dictionary__required">(Required)</span>
            </label>
            <p-dropdown
              [options]="categorySelect"
              defaultLabel="Select Category"
              formControlName="category"
              optionLabel="label"
              defaultLabel="Select Category"
              styleClass="bl-dropdown"
            ></p-dropdown>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12">
            <div class="bl-content__button--size">
              <button
                (click)="addParameter()"
                class="bl-button--btn-secundary bl-button--btn-secundary--lg"
                type="submit"
              >
                Add Parameter
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-10 bl-dictionary__content-no-padding bl-dictionary__align-msg">
          <span *ngIf="succsessMsg" class="bl-dictionary__success-msg"
            >New parameter have been added.</span
          >
        </div>
      </form>
      <div *ngFor="let item of arrCategory; let i = index">
        <div *ngIf="item.categoryTypes === 1" class="bl-panel col-md-10">
          <bid-panel
            [header]="item && item.title"
            [chips]="dataTestDictionary?.category1"
            [id]="testId"
            [existingParameter]="dataTestDictionary.error.category1"
            [addingParameter]="dataTestDictionary.adding.category1"
          ></bid-panel>
        </div>
        <div *ngIf="item.categoryTypes === 2" class="bl-panel col-md-10">
          <bid-panel
            [header]="item && item.title"
            [chips]="dataTestDictionary?.category2"
            [id]="testId"
            [existingParameter]="dataTestDictionary.error.category2"
            [addingParameter]="dataTestDictionary.adding.category2"
          ></bid-panel>
        </div>
        <div *ngIf="item.categoryTypes === 3" class="bl-panel col-md-10">
          <bid-panel
            [header]="item && item.title"
            [chips]="dataTestDictionary?.category3"
            [id]="testId"
            [existingParameter]="dataTestDictionary.error.category3"
            [addingParameter]="dataTestDictionary.adding.category3"
          ></bid-panel>
        </div>
        <div *ngIf="item.categoryTypes === 4" class="bl-panel col-md-10">
          <!--bid-panel
            [header]="item && item.title"
            [chips]="dataTestDictionary?.category4"
            [id]="testId"
            [existingParameter]="dataTestDictionary.error.category4"
            [addingParameter]="dataTestDictionary.adding.category4"
          ></bid-panel-->
        </div>
        <div *ngIf="item.categoryTypes === 5" class="bl-panel col-md-10">
          <bid-panel
            [header]="item && item.title"
            [chips]="dataTestDictionary?.category5"
            [id]="testId"
            [existingParameter]="dataTestDictionary.error.category5"
            [addingParameter]="dataTestDictionary.adding.category5"
          ></bid-panel>
        </div>
        <div *ngIf="item.categoryTypes === 6" class="bl-panel col-md-10">
          <bid-panel
            [header]="item && item.title"
            [chips]="dataTestDictionary?.category6"
            [id]="testId"
            [existingParameter]="dataTestDictionary.error.category6"
            [addingParameter]="dataTestDictionary.adding.category6"
          ></bid-panel>
        </div>
        <div *ngIf="item.categoryTypes === 7" class="bl-panel col-md-10">
          <bid-panel
            [header]="item && item.title"
            [chips]="dataTestDictionary?.category7"
            [id]="testId"
            [existingParameter]="dataTestDictionary.error.category7"
            [addingParameter]="dataTestDictionary.adding.category7"
          ></bid-panel>
        </div>
        <div *ngIf="item.categoryTypes === 8" class="bl-panel col-md-10">
          <bid-panel
            [header]="item && item.title"
            [chips]="dataTestDictionary?.category8"
            [id]="testId"
            [existingParameter]="dataTestDictionary.error.category8"
            [addingParameter]="dataTestDictionary.adding.category8"
          ></bid-panel>
        </div>
      </div>
      <div *ngIf="!arrCategory" class="bl-panel col-md-10">
        <bid-panel
          [header]="''"
          [chips]="dataTestDictionary?.withoutCategory"
          [id]="testId"
          [existingParameter]="dataTestDictionary?.error?.withoutCategory"
          [addingParameter]="dataTestDictionary?.adding?.withoutCategory"
        ></bid-panel>
      </div>
    </div>
  </div>
</div>
