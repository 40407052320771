import { Component, Input } from '@angular/core';

@Component({
  selector: 'bid-added-finding-table',
  templateUrl: './added-finding-table.component.html',
  styleUrls: ['./added-finding-table.component.scss'],
})
export class AddedFindingTableComponent {
  @Input() headerTable: any;
  @Input() bodyTable: any;
  display: boolean = false;

  toggleDialog() {
    this.display = !this.display;
  }

  reviewFinding() {
    this.display = true;
  }
}
