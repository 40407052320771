import { Injectable } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthenticationService {
  private interactionInProgress = false;

  constructor(
    private _broadcastService: MsalBroadcastService,
    private _msalService: MsalService
  ) {}

  async login() {

    if (this.interactionInProgress) {
      console.warn('Interacción en progreso');
      return;
    }

    this.interactionInProgress = true;

    this.msalService.loginRedirect()
      .subscribe({
        next: () => {
          this.interactionInProgress = false;
          console.log('Inicio de sesión exitoso');
        },
        error: (err) => {
          this.interactionInProgress = false;
          console.error('Error en inicio de sesión:', err);
        }
      });

    /*await this.msalService.instance.handleRedirectPromise();

    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length === 0) {
      this.interactionInProgress = false;
        // No user signed in
        this.msalService.instance.loginRedirect();
    }*/
  }

  getAccessToken(): Observable<string> {
    const token = localStorage.getItem('accessToken'); // También puedes usar sessionStorage si prefieres
    if (token) {
      return of(token); // Devuelve el token como un observable
    } else {
      // Si no hay token, devuelve un observable vacío o maneja un flujo de actualización del token
      return of('');
    }
  }

  isLogged(){
    const accounts = this.msalService.instance.getAllAccounts();
    return  !(accounts.length === 0);
  }

  logout() {
    this.msalService.logout();
  }

  get msalService(): MsalService {
    return this._msalService;
  }

  get broadcastService(): MsalBroadcastService {
    return this._broadcastService;
  }
}