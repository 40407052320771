import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private langSelectedSubject = new BehaviorSubject<string>('');
  private getLangObservable: Observable<any> = this.langSelectedSubject.asObservable();

  setLang(dataTest: any) {
    this.langSelectedSubject.next(dataTest);
  }

  returnLang(): Observable<[]> {
    return this.getLangObservable;
  }
}
