import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minsec',
})
export class MinsecPipe implements PipeTransform {
  transform(value: number): string {
    let minutes: any = Math.floor(value / 60);
    let hours: any = Math.floor(minutes / 60);
    let seconds: any = value - minutes * 60;
    let auxHours: number;

    if (hours === 0) {
      hours = '';
    } else {
      auxHours = hours;
      hours = hours + 'h ';
      minutes = minutes - auxHours * 60;
    }
    if (minutes === 0 && seconds !== 0 && hours === '') {
      minutes = '';
    } else {
      minutes = minutes + 'm';
    }
    if (seconds === 0 && (minutes !== '' || hours !== '')) {
      seconds = '';
    } else {
      seconds = ' ' + seconds + 's';
    }
    return hours + minutes + seconds;
  }
}
