<p-toast class="bl-toast bl-toast--error" key="errorMessage-remove-analysis"></p-toast>
<p-toast class="bl-toast bl-toast--success" key="successMessageRemoved"></p-toast>
<!--bid-seacher
  *ngIf="showHistoricalSearch === true"
  (dataForm)="recoberDataForm($event)"
></bid-seacher-->
<div class="bl-wrapper">
  <bid-historical-table
    *ngIf="bodyTable.length > 0"
    [headerTable]="headerTable"
    [bodyTable]="bodyTable"
    (analysisRemoved)="removeAnalysis($event)"
  >
  </bid-historical-table>
  <div class="bl-messages--error" *ngIf="!bodyTable || bodyTable.length === 0">
    <p-messages [(value)]="msgs"></p-messages>
  </div>
</div>
<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
