<div class="bl-content-border-info" [ngClass]="{ 'bl-content-border-info--no-icon': noIcon }">
  <div class="bl-content-border-info__group-data-border--small-size">
    <a
      *ngIf="tooltipInfo"
      class="bl-input__tooltip bl-tooltip"
      pTooltip="{{ tooltipInfo }}"
      tooltipPosition="top"
      [escape]="false"
      ><i class="fas fa-info-circle"></i>
    </a>
    <div class="bl-content-border-info__icon-wrapper">
      <i *ngIf="!letter; else circleIcon" [class]="iconClass"></i>
      <ng-template #circleIcon
        ><span
          class="bl-content-border-info__circle"
          [ngClass]="{
            'bl-content-border-info__circle--lightblue': letter === 'L',
            'bl-content-border-info__circle--coral': letter === 'A',
            'bl-content-border-info__circle--green': letter === 'B',
            'bl-content-border-info__circle--yellow': letter === 'C',
            'bl-content-border-info__circle--blue': letter === 'T'
          }"
          >{{ letter }}</span
        ></ng-template
      >
    </div>
    <div class="bl-content-border-info__content-txt">
      <span
        class="bl-content-border-info__group-data-border--letter-big-size"
        [ngClass]="{
          'bl-content-border-info__group-data-border--error': mainTxt === 'Fail'
        }"
        >{{ mainTxt }}{{ type }}
        <span class="bl-content-border-info__type-total-percent">{{ typeTotalPercent }}</span></span
      >
      <span *ngIf="contentTxt" class="bl-content-border-info__tiny-letter">{{ contentTxt }}</span>
    </div>
  </div>
</div>
