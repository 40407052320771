import { Component, OnInit, DoCheck } from '@angular/core';
import { languages } from '@bl-constants/languages.const';
import { selects } from '@bl-constants/selects.const';
import { SelectItem } from 'primeng/api';
import { menuRiskSelectbuttons } from '@bl-constants/menu-risk-select-buttons.const';
import { menuIcrDictionarySelectbuttons } from '@bl-constants/menu-icr-dictionary.const';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageToastService } from '@core/messages/message-toast.service';
import { Languages as languageEnum } from '@bl-enum/languages.enum';
import { Router } from '@angular/router';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import { IcrDictionaryService } from '@core/icr-dictionary/icr-dictionary.service';
//import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'bid-icr-dictionary',
  templateUrl: './icr-dictionary.component.html',
  styleUrls: ['./icr-dictionary.component.scss'],
})
export class IcrDictionaryComponent implements OnInit, DoCheck {
  existingWord: any = [];
  languageCombo: any;
  languageSelected: string = '';
  selectBucket: any;
  menu: SelectItem[] = [];
  selectedRisk: any;
  seacherDictionaryWordForm!: FormGroup;
  createDictionaryWordForm!: FormGroup;
  chips: any;
  seacherResult: any[] = [];
  selectedItem: any;
  pages: any;
  selectedOptionMenu: any;
  showHistoricalSearch: boolean = false;
  completedChips: any;
  resulFilterModel: any;
  flatSearchTerm: boolean = false;
  addedWord: any = [];
  auxAddedWord: any = [];
  flatCheckAddedWork: boolean = false;
  arrRisk: any[];
  arrBuckets: any[];
  parameterError: boolean = false;
  searcherTerm: string = '';

  _icrDictionaryService;
  
  constructor(
    private messageToastService: MessageToastService,
    private router: Router,
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private icrDictionaryService: IcrDictionaryService,
    //private spinner: NgxSpinnerService,
  ) {
    this.arrRisk = [];
    this.arrBuckets = [];
    this._icrDictionaryService = icrDictionaryService;
  }

  ngOnInit() {
    //this.spinner.show();
    this.showHistoricalSearch = false;
    this.pages = menuIcrDictionarySelectbuttons;
    this.selectedOptionMenu = 'ICR dictionary';
    this.languageCombo = languages;
    this.selectBucket = selects.pages.dictionary.select.bucketClassification;
    this.selectedRisk = 'All';
    this.menu = menuRiskSelectbuttons;
    this.seacherDictionaryWordForm = new FormGroup({
      language: new FormControl(''),
      seacherWord: new FormControl(''),
      bucket: new FormControl(''),
      risk: new FormControl(''),
    });

    this.createDictionaryWordForm = new FormGroup({
      word: new FormControl('', Validators.required),
      risk: new FormControl('', Validators.required),
    });
    this.seacherDictionaryWordForm.get('language')?.setValue(languageEnum.ENGLISH);
    this.seacherDictionaryWordForm.get('bucket')?.setValue(this.selectBucket[0].value);
    this.createDictionaryWordForm.get('risk')?.setValue('High');
    this.getDictionary();
  }

  ngDoCheck() {
    /*this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });*/
  }

  getDictionary() {
    const lang = this.seacherDictionaryWordForm.value.language;
    this.icrDictionaryService.getIcrDictionary(lang).subscribe(
      (res: any) => {
        this.chips = this.parseChips(res);
        this.completedChips = this.chips;
        this.searcherTerm = '';
        this.filterBy();
        if (this.flatSearchTerm === true) {
          this.resetResult();
        }
        //this.spinner.hide();
      },
      () => {
        //this.spinner.hide();
        this.messageToastService.showToastError('error', 'We had a problem loading the data, please try again later.', 'Error');
        /*this.showToastError(
          'We had a problem loading the data, please try again later.',
          'errorTools',
        );*/
      },
    );
  }

  getData(reload: any) {
    if (reload) {
      this.addedWord = null;
      this.existingWord = [];
      this.flatCheckAddedWork = false;
      if (this.seacherResult && this.seacherResult.length > 0) {
        this.seacherDictionaryWordForm.get('seacherWord')?.patchValue('');
      }
      this.getDictionary();
    }
  }

  parseChips(data: any) {
    const arr: any = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const obj: any = {
          name: '',
          id: '',
          risk: '',
          bucket: '',
          language: '',
        };
        obj.name = data[i].keyword;
        obj.id = data[i].id;
        obj.risk = data[i].risk;
        obj.bucket = data[i].bucket;
        obj.language = data[i].language;
        arr.push(obj);
      }
    }
    return arr;
  }

  rediredTo(data: any) {
    if (data.value === 'ICR dictionary') {
      this.router.navigate(['/icr-dictionary']);
    }
  }

  filterBy() {
    if (this.seacherResult && this.seacherResult.length > 0) {
      this.seacherDictionaryWordForm.get('seacherWord')?.patchValue('');
    } else if (this.flatSearchTerm === true) {
      this.flatSearchTerm = false;
      this.seacherDictionaryWordForm.get('seacherWord')?.patchValue('');
    }
    this.chips = this.completedChips.filter(
      (chip: any) =>
        (chip.risk === this.seacherDictionaryWordForm.value.risk || this.selectedRisk === 'All') &&
        (chip.bucket === this.seacherDictionaryWordForm.value.bucket ||
          this.seacherDictionaryWordForm.value.bucket === '') &&
        (chip.language === this.seacherDictionaryWordForm.value.language ||
          this.seacherDictionaryWordForm.value.language === undefined) &&
        (this.parseSpecialChar(chip.name) ===
          this.parseSpecialChar(this.seacherDictionaryWordForm.value.seacherWord) ||
          this.seacherDictionaryWordForm.value.seacherWord === ''),
    );
    if (this.flatCheckAddedWork === true) {
      this.addedWord = this.auxAddedWord;
    }
  }

  seacherByTerm() {
    this.flatSearchTerm = true;
    this.selectedItem = 'item0';
    if (this.seacherDictionaryWordForm.value.seacherWord !== '') {
      const resultSeacher = this.completedChips.filter(
        (chip:any) =>
          this.parseSpecialChar(chip.name).indexOf(
            this.parseSpecialChar(this.seacherDictionaryWordForm.value.seacherWord),
          ) > -1,
      );
      this.resulFilterModel = {
        bucketParameter1: [],
        bucketParameter2: [],
        bucketParameter3: [],
        bucketParameter4: [],
        bucketParameter5: [],
        bucketParameter6: [],
      };
      if (resultSeacher.length > 0) {
        this.parseResultSeacher(resultSeacher);
        this.selectParamenterResult(0, this.seacherResult[0]);
      } else {
        this.seacherResult = [];
        setTimeout(() => {
          this.flatSearchTerm = false;
          this.seacherDictionaryWordForm.get('seacherWord')?.patchValue('');
        }, 3000);
      }
    }
  }

  removeSeacherTerm() {
    this.seacherDictionaryWordForm.get('seacherWord')?.patchValue('');
    this.searcherTerm = '';
    this.resetResult();
    this.getDictionary();
  }

  resetResult() {
    this.seacherResult = [];
    this.flatSearchTerm = false;
    this.selectedRisk = 'All';
    this.filterBy();
  }

  parseResultSeacher(data: any) {
    this.seacherResult = [];
    const findDataOtp2 = data.filter((item: any) => item.bucket === 'Contractual compliance');
    if (findDataOtp2.length > 0) {
      const modelResul = {
        bucketClassification: '',
        status: true,
        parameter: [],
      };
      modelResul.bucketClassification = 'Contractual compliance';
      modelResul.parameter = this.createArrResult(
        findDataOtp2,
        this.resulFilterModel.bucketParameter2,
      );
      this.seacherResult.push(modelResul);
    }
    const findDataOtp1 = data.filter((item: any) => item.bucket === 'Eligibility of expenses');
    if (findDataOtp1.length > 0) {
      const modelResul = {
        bucketClassification: '',
        status: true,
        parameter: [],
      };
      modelResul.bucketClassification = 'Eligibility of expenses';
      modelResul.parameter = this.createArrResult(
        findDataOtp1,
        this.resulFilterModel.bucketParameter1,
      );
      this.seacherResult.push(modelResul);
    }
    const findDataOtp3 = data.filter((item: any) => item.bucket === 'Financial information issues');
    if (findDataOtp3.length > 0) {
      const modelResul = {
        bucketClassification: '',
        status: true,
        parameter: [],
      };
      modelResul.bucketClassification = 'Financial information issues';
      modelResul.parameter = this.createArrResult(
        findDataOtp3,
        this.resulFilterModel.bucketParameter3,
      );
      this.seacherResult.push(modelResul);
    }
    const findDataOtp4 = data.filter((item: any) => item.bucket === 'Procurement issues');
    if (findDataOtp4.length > 0) {
      const modelResul = {
        bucketClassification: '',
        status: true,
        parameter: [],
      };
      modelResul.bucketClassification = 'Procurement issues';
      modelResul.parameter = this.createArrResult(
        findDataOtp4,
        this.resulFilterModel.bucketParameter4,
      );
      this.seacherResult.push(modelResul);
    }
    const findDataOtp5 = data.filter((item: any) => item.bucket === 'Technical or operational aspects');
    if (findDataOtp5.length > 0) {
      const modelResul = {
        bucketClassification: '',
        status: true,
        parameter: [],
      };
      modelResul.bucketClassification = 'Technical or operational aspects';
      modelResul.parameter = this.createArrResult(
        findDataOtp5,
        this.resulFilterModel.bucketParameter5,
      );
      this.seacherResult.push(modelResul);
    }
    const findDataOtp6 = data.filter((item: any) => item.bucket === 'Unclassified');
    if (findDataOtp6.length > 0) {
      const modelResul = {
        bucketClassification: '',
        status: true,
        parameter: [],
      };
      modelResul.bucketClassification = 'Unclassified';
      modelResul.parameter = this.createArrResult(
        findDataOtp6,
        this.resulFilterModel.bucketParameter6,
      );
      this.seacherResult.push(modelResul);
    }
    const findDataOtp7 = data.filter((item: any) => item.bucket === null);
    if (findDataOtp7.length > 0) {
      const modelResul = {
        bucketClassification: '',
        status: true,
        parameter: [],
      };
      modelResul.bucketClassification = 'Unclassified';
      modelResul.parameter = this.createArrResult(
        findDataOtp7,
        this.resulFilterModel.bucketParameter6,
      );
      this.seacherResult.push(modelResul);
    }
    if (this.seacherResult && this.seacherResult.length > 0) {
      this.searcherTerm = this.seacherDictionaryWordForm.value.seacherWord;
    }
  }

  createArrResult(arr: any, varArr: any) {
    for (let i = 0; i < arr.length; i++) {
      varArr.push({ name: arr[i].name, risk: arr[i].risk });
    }
    return varArr;
  }

  selectParamenterResult(i: number, element: any) {
    this.selectedItem = 'item' + i;
    this.seacherDictionaryWordForm.get('bucket')?.setValue(element.bucketClassification);
    this.seacherDictionaryWordForm.value.seacherWord = '';
    const arrRisk = [];
    for (let i = 0; i < element.parameter.length; i++) {
      arrRisk.push(element.parameter[i].risk);
    }
    this.selectedRisk = this.applySelectedRisk(arrRisk);
    this.seacherDictionaryWordForm.get('risk')?.setValue(this.selectedRisk);
    this.filterAfterSearch();
  }

  filterAfterSearch() {
    this.chips = this.completedChips.filter(
      (chip: any) =>
        (chip.bucket === this.seacherDictionaryWordForm.value.bucket ||
          this.seacherDictionaryWordForm.value.bucket === '') &&
        (chip.language === this.seacherDictionaryWordForm.value.language ||
          this.seacherDictionaryWordForm.value.language === undefined) &&
        this.parseSpecialChar(chip.name).indexOf(
          this.parseSpecialChar(this.seacherDictionaryWordForm.value.seacherWord),
        ) > -1,
    );
  }

  addNewWord() {
    if (this.createDictionaryWordForm.value.word !== '') {
      const arrAddingWord = this.createDictionaryWordForm.value.word.split('/');
      const wordExist = this.checkIfExis(arrAddingWord);
      const obj = {
        risk: this.createDictionaryWordForm.value.risk,
        keyword: arrAddingWord,
        lang: this.seacherDictionaryWordForm.value.language,
        bucket: this.seacherDictionaryWordForm.value.bucket,
      };
      if (wordExist.length === 0) {
        this.icrDictionaryService.addKeywords(obj).subscribe(
          (res: any) => {
            if (res === false) {
              this.sendError();
            } else {
              this.addingWorkToModel(arrAddingWord);
              this.resetForm();
            }
          },
          () => this.sendError(),
        );
      } else {
        this.selectedRisk = this.applySelectedRisk(this.arrRisk);
        this.seacherDictionaryWordForm.get('bucket')?.setValue(this.arrBuckets[0]);
        this.existingWord = wordExist;
        this.seacherDictionaryWordForm.value.risk = this.selectedRisk;

        this.createDictionaryWordForm.patchValue({ risk: this.selectedRisk });
        this.filterBy();
        this.sendError();
      }
    }
  }

  addingWorkToModel(arrAddingWord: any) {
    for (let i = 0; i < arrAddingWord.length; i++) {
      this.chips.push({
        name: arrAddingWord[i],
        id: '1',
        risk: this.createDictionaryWordForm.value.risk,
        bucket: this.seacherDictionaryWordForm.value.bucket,
        language: this.seacherDictionaryWordForm.value.language,
        status: 'added',
      });
    }
    console.log("addingWorkToModel 1")
    this.auxAddedWord = this.chips.filter((item: any) => item.status === 'added');
    if (this.auxAddedWord.length > 0) {
      this.flatCheckAddedWork = true;
    }
    this.selectedRisk = this.createDictionaryWordForm.value.risk;
    if (this.seacherResult && this.seacherResult.length > 0) {
      this.seacherDictionaryWordForm.get('seacherWord')?.patchValue('');
    }
    this.getDictionary();
    this.filterBy();
  }

  checkIfExis(addingWord: any) {
    let wordFindings;
    const arr = [];
    this.arrRisk = [];
    this.arrBuckets = [];
    for (let i = 0; i < addingWord.length; i++) {
      if (this.completedChips) {
        wordFindings = this.completedChips.filter(
          (chip: any) => this.parseSpecialChar(chip.name) === this.parseSpecialChar(addingWord[i]),
        );
        if (wordFindings.length > 0) {
          arr.push(addingWord[i]);
          this.arrRisk.push(wordFindings[0].risk);
          this.arrBuckets.push(wordFindings[0].bucket);
        }
      }
    }
    return arr;
  }

  applySelectedRisk(data: any) {
    let result = '';
    if (data) {
      const lowRisk = data.includes('Low');
      const highRisk = data.includes('High');
      if (lowRisk && highRisk) {
        result = 'All';
      } else if (lowRisk && !highRisk) {
        result = 'Low';
      } else if (!lowRisk && highRisk) {
        result = 'High';
      }
    }
    return result;
  }

  parseSpecialChar(data: any) {
    let parseParams = data;
    if (parseParams.toLowerCase().indexOf('á')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[àáâãäå]', 'g'), 'a');
    }
    if (parseParams.toLowerCase().indexOf('é')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[èéêë]', 'g'), 'e');
    }
    if (parseParams.toLowerCase().indexOf('í')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[ìíîï]', 'g'), 'i');
    }
    if (parseParams.toLowerCase().indexOf('ó')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[òóôõö]', 'g'), 'o');
    }
    if (parseParams.toLowerCase().indexOf('ú')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[ùúûü]', 'g'), 'u');
    }
    return parseParams;
  }

  resetForm() {
    setTimeout(() => {
      this.createDictionaryWordForm.patchValue({
        word: '',
        risk: 'High',
      });
    }, 3000);
  }

  sendError() {
    this.messageToastService.showToastError('error', 'This keyword already exist. Please, try another keyword.', 'errorMessageIcrDictionary');
    /*this.showToastError(
      'This keyword already exist. Please, try another keyword.',
      'errorMessageIcrDictionary',
    );*/
  }

  
}
