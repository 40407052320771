//import { environment } from "@environments/environment";
import {
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from "@angular/common/http";
import { from, Observable, of } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { MsalService } from "@azure/msal-angular";
import { catchError, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

const scope = environment.api.scope;


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>
  > {

    console.log("interceptor", req.headers);
    return this.auth.getAccessToken().pipe( // Suponiendo que obtienes el token desde un método observable
      mergeMap((accessToken) => {
        // Configurar los encabezados
        const updatedHeaders = req.headers
          .set("authorization", `Bearer ${accessToken}`)
          .set("Ocp-Apim-Subscription-Key", environment.api.subscriptionKey)
          .set("Strict-Transport-Security", "max-age=7200");

        // Clonar la solicitud con los nuevos encabezados
        const nextReq = req.clone({ headers: updatedHeaders });

        console.log("Updated Headers:", nextReq.headers.keys());
        return next.handle(nextReq);
      }),
      catchError((error) => {
        console.error("Error in interceptor:", error);
        return next.handle(req); // Manejo de errores opcional
      })
    );
  }
}

/*
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService) {}
 
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    const s = this.auth.msalService as MsalService;
    console.log("TokenINterceptor")
    const request = from(
      s.acquireTokenSilent({
        scopes: [`${scope}`],
      })
    );

    console.log("subscriptionKey", environment.api.subscriptionKey);

    return request.pipe(
      mergeMap((response) => {
        let headers = req.headers
          .set("authorization", `Bearer ${response.accessToken}`)
          .set("Ocp-Apim-Subscription-Key", environment.api.subscriptionKey)
          .set("Strict-Transport-Security", "max-age=7200");
        
        console.log(headers);
        const nextReq = req.clone({ 
          ...headers,
          setParams: {
            "Ocp-Apim-Subscription-Key": environment.api.subscriptionKey
          }
        });
        
        return next.handle(nextReq);
      }),
      catchError(() => {
        return next.handle(req);
      })
    );

    
  } 
}*/
