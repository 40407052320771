import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TestDictionaryService {
  // eslint-disable-next-line
  constructor(private http: HttpClient) {}

  getTestDictionarySuggested(lang: string) {
    return this.http.get(environment.api.base + 'tests/suggested?language=' + lang);
  }

  getTestDictionaryDetail(id: string) {
    return this.http.get(environment.api.base + 'tests/details?id=' + id);
  }

  addConfirmedParameter(id: string, isSuggested: boolean, data: any) {
    return this.http.post(
      environment.api.base + 'tests/details?id=' + id + '&isSuggested=' + isSuggested,
      data,
    );
  }

  removeParameter(id: string, isSuggested: boolean, data: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http.delete(
      environment.api.base + 'tests/parameters/' + id + '?isSuggested=' + isSuggested,
      options,
    );
  }
}
