<a
 
  routerLinkActive="bl-menu__link--active"
  title="Analytics"
  class="bl-menu__link--dark"
  (click)="menu.toggle($event)"
>
  <i class="far fa-chart-bar"></i> 
  Analytics
</a>
<div class="bl-slide-menu bl-slide-menu--gray bl-slide-menu--big-size">
  <p-slideMenu [model]="items" #menu [popup]="true" appendTo="body"></p-slideMenu>
</div>
