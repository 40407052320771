import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalInterceptor   } from "@azure/msal-angular";

import { MessageService } from 'primeng/api';/*
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton'
import { SelectButtonModule } from 'primeng/selectbutton';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { TabViewModule } from 'primeng/tabview';

import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';



import { HomeComponent } from '@features/home/home.component';

import { GenerateAnalysisComponent } from "@features/generate-analysis/generate-analysis.component";
import { EzshareTableComponent } from "@shared/components/ezshare-table/ezshare-table.component";
import { SearcherComponent } from "@shared/components/searcher/searcher.component";
import { SpinnerComponent } from "@shared/components/spinner/spinner.component";
import { PanelComponent } from "@shared/components/panel/panel.component";
import { UsersRoleTableComponent } from "@shared/components/users-role-table/users-role-table.component";
import { TestDictionaryTableComponent } from "@shared/components/test-dictionary-table/test-dictionary-table.component";
import { TestDescriptionsTableComponent } from "@shared/components/test-descriptions-table/test-descriptions-table.component";*/
import { HeaderComponent } from "@shared/components/header/header.component";
import { SearcherComponent } from "@shared/components/searcher/searcher.component";
import { MenuRoleComponent } from "@shared/components/menu-role/menu-role.component";
import { MenuDashboardComponent } from "@shared/components/menu-dashboard/menu-dashboard.component";
import { UserButtonComponent } from "@shared/components/user-button/user-button.component";

import { AuthenticationModule } from "@core/authentication/authentication.module";
import { SpinnerComponent } from "@shared/components/spinner/spinner.component";
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from "@angular/forms";

import { FeaturesModule } from "@features/features.module";
import { SharedModule } from "@shared/shared.module";
import { CoreModule } from "./core/core.module";



@NgModule({
  declarations: [
    AppComponent,
    SearcherComponent,
    HeaderComponent,
    SpinnerComponent,
    MenuRoleComponent,
    MenuDashboardComponent,
    UserButtonComponent,
    /*AppComponent,
    
    HeaderComponent,
    HomeComponent,
    GenerateAnalysisComponent,
    EzshareTableComponent,
    SearcherComponent,
    SpinnerComponent,
    PanelComponent,
    UsersRoleTableComponent,
    TestDictionaryTableComponent,
    TestDescriptionsTableComponent*/
    // otros componentes
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FeaturesModule,
    CoreModule,
    FormsModule,
 
    AuthenticationModule,

   /* BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ButtonModule,
    PanelModule,
    ToastModule,
    ProgressBarModule,
    CalendarModule,
    MessagesModule,
    FileUploadModule,
    RadioButtonModule,
    SelectButtonModule,
    ReactiveFormsModule,
    MenubarModule,
    InputTextModule,
    DialogModule,
    TableModule,
    DropdownModule,
    BlockUIModule,
    ProgressSpinnerModule,
    RippleModule,
    StyleClassModule,
    TooltipModule,
    TabViewModule,
    AuthenticationModule,*/
    // otros módulos
  ],
  providers: [
    MessageService,
    provideClientHydration(),
    provideHttpClient(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class AppModule { }
