import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TestDictionaryService } from '@core/test-dictionary/test-dictionary.service';
import { MessageToastService } from '@core/messages/message-toast.service';

@Component({
  selector: 'bid-test-dictionary-table',
  templateUrl: './test-dictionary-table.component.html',
  styleUrls: ['./test-dictionary-table.component.scss'],
})
export class TestDictionaryTableComponent implements OnInit, OnChanges {
  @Input() headerTable: any;
  @Input() bodyTable: any;
  @Input() language: string = '';
  @Output() resetFilter = new EventEmitter<boolean>();
  categorySelectAfsIntegity: any;
  categorySelectOperationType: any;
  FormGroupTableTestDictionary!: FormGroup;
  display = false;
  testDictionary!: FormGroup;
  checked: boolean = false;
  submitedForm: boolean = false;
  hasError: boolean = false;
  bodyTableForm: any;
  testId: string = '';
  dataToSend: any[] = [];
  controlError: any;
  rowDisabled: any = [];
  possArr: number = -1;
  action: string = '';
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private testDictionaryService: TestDictionaryService,
    private messageToastService: MessageToastService,
  ) {
    this.display = false;
    this.submitedForm = false;
  }

  ngOnInit() {
    this.generateDinamicForm(this.bodyTable);
  }

  ngOnChanges() {
    this.generateDinamicForm(this.bodyTable);
  }

  loadData() {
    this.testDictionaryService.getTestDictionarySuggested(this.language).subscribe((res: any) => {
      this.bodyTable = res;
    });
  }

  generateDinamicForm(bodyTable: any) {
    const group: any = {};
    const checkboxParameter = 'checkboxParameter-';
    const selectCategories = 'selectCategories-';
    if (bodyTable && bodyTable.length > 0) {
      for (let i = 0; i < bodyTable.length; i++) {
        if (bodyTable[i].suggestedParameters && bodyTable[i].suggestedParameters.length > 0) {
          for (let y = 0; y < bodyTable[i].suggestedParameters.length; y++) {
            if (bodyTable[i].name === 'AFS Integrity' || bodyTable[i].name === 'Opinion Type') {
              group[checkboxParameter + bodyTable[i].id + '-' + y] = new FormControl(
                '',
                this.validateIfChecked(),
              );
              group[selectCategories + bodyTable[i].id + '-' + y] = new FormControl(
                '',
                Validators.required,
              );
              if (bodyTable[i].name === 'AFS Integrity') {
                this.categorySelectAfsIntegity = this.loadSelect(bodyTable[i].categoryType);
              } else if (bodyTable[i].name === 'Opinion Type') {
                this.categorySelectOperationType = this.loadSelect(bodyTable[i].categoryType);
              }
            } else if (
              bodyTable[i].name !== 'AFS Integrity' &&
              bodyTable[i].name !== 'Opinion Type'
            ) {
              group[checkboxParameter + bodyTable[i].id + '-' + y] = new FormControl(
                '',
                this.validateIfChecked(),
              );
            }
          }
        }
      }
      this.testDictionary = new FormGroup(group);
    }
  }

  loadSelect(data: any) {
    const arr: any = [{ label: 'Select Category', value: null }];
    for (let i = 0; i < data.length; i++) {
      switch (data[i]) {
        case 1:
          arr.push({
            label: 'Opinion of the auditor',
            value: 'Opinion_of_the_auditor',
          });
          break;
        case 2:
          arr.push({
            label: 'Statements of cash receipts and payments',
            value: 'Statements_of_cash_receipts_and_payments',
          });
          break;
        case 3:
          arr.push({
            label: 'Statements of Cumulative of investments',
            value: 'Statement_of_Cumulative_Investments',
          });
          break;
        case 4:
          arr.push({
            label: 'Notes of the financial statement',
            value: 'Notes_to_the_financial_statement',
          });
          break;
        case 5:
          arr.push({
            label: 'Disclamer of opinion',
            value: 'Disclaimer_of_opinion',
          });
          break;
        case 6:
          arr.push({ label: 'Adverse', value: 'Adverse' });
          break;
        case 7:
          arr.push({
            label: 'Qualified',
            value: 'Qualified',
          });
          break;
        case 8:
          arr.push({ label: 'Clean', value: 'Clean' });
          break;
      }
    }
    return arr;
  }

  validateIfChecked(): ValidatorFn {
    return (): {
      [key: string]: any;
    } | null => {
      this.submitedForm = false;
      let checked: string = '';
      if (this.testDictionary) {
        checked = this.checkParametersChecked(this.testDictionary);
      }
      if (checked != null && checked.length > 0) {
        return {
          checked: true,
        };
      }
      return {
        checked: false,
      };
    };
  }

  checkRowSelected(row: any, checkbox: any) {
    this.testId = row;
    this.submitedForm = false;
    const dataForm = this.testDictionary;

    const auxArr = this.rowDisabled;
    Object.keys(dataForm.controls).forEach((key) => {
      if (key === checkbox) {
        if (dataForm.get(key)?.errors != null) { // ['checked'] == 'true'
          if (dataForm.controls[key].value.length > 0) {
            this.rowDisabled.push(row);
          } else {
            const pos = this.rowDisabled.indexOf(row);
            this.rowDisabled.splice(pos, 1);
          }
        } else {
          const pos = this.rowDisabled.indexOf(row);
          this.rowDisabled.splice(pos, 1);
        }
      } else if (checkbox === undefined) {
        for (let i = 0; i < auxArr.length; i++) {
          const pos = this.rowDisabled.findIndex((item: any) => item === row);
          if (pos > -1) {
            this.rowDisabled.splice(pos, 1);
          }
        }
        if (this.testId == '6' || this.testId == '7') {
          if (key.indexOf('selectCategories-' + this.testId) > -1) {
            dataForm.controls[key].patchValue({ key: [] });
          }
          if (key.indexOf('checkboxParameter-' + this.testId) > -1) {
            if (dataForm.get(key)?.errors != null) { //if (dataForm.controls[key].errors.checked === true) {
              dataForm.controls[key].patchValue('');
            }
          }
        }
      }
    });
  }

  checkParametersChecked(dataForm: any) {
    let check: string = '';
    Object.keys(dataForm.controls).forEach((key) => {
      if (dataForm.controls[key].value.length > 0) {
        check = dataForm.controls[key].value;
      }
    });
    return check;  
  }

  showModalRejectParamenter(index: any, i: number) {
    this.testId = index;
    this.display = true;
    this.possArr = i;
  }

  rejectWordsDctionary() {
    this.submitedForm = true;
    this.display = false;
    const dataForm = this.testDictionary.value;
    const obj = [];
    for (const form in dataForm) {
      if (form.indexOf(this.testId) > 0) {
        obj.push({ [form]: this.testDictionary.value[form] });
      }
    }
    if (this.testId === '6' || this.testId == '7') {
      this.dataToSend = this.generateCoupleValues(obj, this.testId, 'reject');
      this.testDictionaryService
        .removeParameter(this.testId, true, this.generateBody(this.dataToSend))
        .subscribe(
          (res: any) => {
            if (res) {
              this.loadData();
              this.checkRowSelected(this.testId, undefined);
              this.resetFilter.emit(true);
            } else {
              this.showToastError('You should choose a category.', 'errorFormTestDictionary');
            }
          },
          () => {
            this.showToastError('You should choose a category.', 'errorFormTestDictionary');
          },
        );
    } else {
      this.dataToSend = this.generateSimpleValues(obj, this.testId.toString());
      this.testDictionaryService
        .removeParameter(this.testId, true, this.generateBody(this.dataToSend))
        .subscribe(
          (res: any) => {
            if (res) {
              this.loadData();
              this.checkRowSelected(this.testId, undefined);
              this.resetFilter.emit(true);
            } else {
              this.showToastError('You should choose a category.', 'errorFormTestDictionary');
            }
          },
          () => {
            this.showToastError('You should choose a category.', 'errorFormTestDictionary');
          },
        );
    }
  }

  generateSimpleValues(data: any, index: string) {
    const arr = [];
    const obj = {
      params: '',
      category: 0,
    };
    let cont = 0;
    for (const item of data) {
      const keyParameter = 'checkboxParameter-' + index + '-' + cont;
      if (item.hasOwnProperty(keyParameter)) {
        obj.params = item[keyParameter];
        obj.category = 0;
        arr.push({ param: obj.params, category: obj.category });
      }
      cont += 1;
    }
    return arr;
  }

  generateCoupleValues(data: any, index: string, action: string) {
    let arr: any = [];
    const auxArr = [];
    const obj = {
      params: '',
      category: '',
    };
    let auxCont = 0;
    this.action = action;
    for (let i = 0; i < data.length; i++) {
      if (index == '6' || index == '7') {
        const keyParameter = 'checkboxParameter-' + index + '-' + auxCont;
        const keyCategory = 'selectCategories-' + index + '-' + auxCont;
        const parameter = data.filter((pr: any) => pr.hasOwnProperty(keyParameter));
        const category = data.filter((pr: any) => pr.hasOwnProperty(keyCategory));
        if (
          parameter[0] &&
          parameter[0].hasOwnProperty(keyParameter) &&
          category[0] &&
          category[0].hasOwnProperty(keyCategory)
        ) {
          obj.params =
            parameter[0][keyParameter] && parameter[0][keyParameter].length > 0
              ? parameter[0][keyParameter]
              : null;
          obj.category = category[0][keyCategory].value ? category[0][keyCategory].value : null;
          arr.push({ param: obj.params, category: obj.category });
          auxCont += 1;
        }
      }
    }
    if (action === 'adding') {
      for (let i = 0; i < arr.length; i++) {
        if (
          (arr[i].param !== null && arr[i].category === null) ||
          (arr[i].param === null && arr[i].category !== null)
        ) {
          arr = [];
          break;
        }
      }
    } else if (action === 'reject') {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].param !== null) {
          auxArr.push({ param: arr[i].param, category: 0 });
        }
      }
      arr = auxArr;
    }
    return arr;
  }

  addingWordsDctionary(id: string) {
    this.submitedForm = true;
    this.display = false;
    const dataForm = this.testDictionary.value;
    const obj = [];
    for (const form in dataForm) {
      if (form.indexOf(id) > 0) {
        obj.push({ [form]: this.testDictionary.value[form] });
      }
    }
    if (id == '6' || id == '7') {
      this.dataToSend = this.generateCoupleValues(obj, id, 'adding');
      this.testDictionaryService
        .addConfirmedParameter(id, true, this.generateBody(this.dataToSend))
        .subscribe(
          (res: any) => {
            if (res) {
              this.resetFilter.emit(true);
              this.checkRowSelected(id, undefined);
              this.loadData();
              this.showToastSuccess('New parameters have been added.', 'successFormTestDictionary');
            } else {
              this.showToastError('You should choose a category.', 'errorFormTestDictionary');
            }
          },
          () => {
            this.showToastError('You should choose a category.', 'errorFormTestDictionary');
          },
        );
    } else {
      this.dataToSend = this.generateSimpleValues(obj, id);
      const body = this.generateBodyNotCategory(this.dataToSend);
      this.testDictionaryService.addConfirmedParameter(id, true, body).subscribe(
        (res: any) => {
          if (res) {
            this.resetFilter.emit(true);
            this.checkRowSelected(id, undefined);
            this.loadData();
            this.showToastSuccess('New parameters have been added.', 'successFormTestDictionary');
          } else {
            this.showToastError('You should choose a category.', 'errorFormTestDictionary');
          }
        },
        () => {
          this.showToastError('You should choose a category.', 'errorFormTestDictionary');
        },
      );
    }
  }

  generateBody(body: any) {
    let arr: any = [];
    let findError = false;
    for (let i = 0; i < body.length; i++) {
      if (
        body[i].param &&
        body[i].param.length > 0 &&
        body[i].category !== undefined &&
        body[i].category !== null &&
        body[i].category !== ''
      ) {
        arr.push({
          language: this.language,
          parameter: body[i].param[0],
          categoryType: body[i].category ? body[i].category.split(' ').join('_') : 0,
        });
      }
    }
    findError = this.checkError(arr);
    if (!findError) {
      arr = this.removeParameter(arr);
    } else {
      arr = null;
    }
    return arr;
  }

  generateBodyNotCategory(body: any) {
    const arrBody = [];
    for (let i = 0; i < body.length; i++) {
      if (body[i].param && body[i].param.length > 0 && body[i].param !== '') {
        arrBody.push({
          language: this.language,
          parameter: body[i].param[0],
          categoryType: 0,
        });
      }
    }
    return arrBody;
  }

  checkError(data: any) {
    let error = false;
    if (data.length === 0) {
      error = true;
    } else {
      for (let i = 0; i < data.length; i++) {
        const arrNoParameter = data.filter(
          (parameter: any) => parameter.parameter !== undefined && parameter.categoryType === null,
        );
        if (arrNoParameter.length > 0) {
          if (arrNoParameter.length > 0) {
            error = true;
          } else {
            error = false;
          }
        }
      }
    }
    return error;
  }

  removeParameter(data: any) {
    let arrAux: any = [];
    arrAux = arrAux.concat(data);
    for (let i = 0; i < data.length; i++) {
      const index = arrAux.findIndex(
        (parameter: any) => parameter.parameter === undefined && parameter.categoryType === null,
      );
      if (index > -1) {
        arrAux.splice(index, 1);
      }
    }
    return arrAux;
  }

  shouldShowError(rowData: any, z: number): boolean {
    const checkboxParameterValid = this.testDictionary.get('checkboxParameter-' + rowData['id'] + '-' + z)?.value?.length === 1;
    const selectCategoriesValue = this.testDictionary.get('selectCategories-' + rowData['id'] + '-' + z)?.value;
    const selectCategoriesErrors = this.testDictionary.get('selectCategories-' + rowData['id'] + '-' + z)?.errors?.['checked'] === false;
    const selectCategoriesStatusInvalid = this.testDictionary.get('selectCategories-' + rowData['id'] + '-' + z)?.status === 'INVALID';
    const isRowDisabled = this.rowDisabled.indexOf(rowData['id']) > -1;

    // Lógica combinada
    return (this.action === 'adding' &&
            checkboxParameterValid &&
            (!selectCategoriesValue || selectCategoriesValue === '') &&
            this.submitedForm === true &&
            isRowDisabled &&
            (selectCategoriesErrors || selectCategoriesStatusInvalid)) ||
           (selectCategoriesValue === null && this.submitedForm);
  }

  cancelRejectParameter() {
    this.display = false;
  }

  sendRedirectToDetault(testName: string, numberTest: string) {
    this.router.navigate(['/test-dictionary-detail', testName, numberTest, this.language]);
  }

  showToastSuccess(summary: string, key: string) {
    this.messageToastService.showToastSuccess('Success', summary, key);
  }

  showToastError(summary: string, key: string) {
    this.messageToastService.showToastError('error', summary, key);
  }
}
