<bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher>
<!-- <div class="bl-wrapper">
    <iframe class="bl-iframe" src="https://app.powerbi.com/view?r=eyJrIjoiMTEyNTM0MzYtOTVhYS00N2E5LTg0OGUtZTM3NjMxMzNkOWQzIiwidCI6IjMwNDhkYzg3LTQzZjAtNDEwMC05YWNiLWFlMTk3MWM3OTM5NSIsImMiOjh9" 
    title="Analytics"  allow="fullscreen"></iframe> 
</div> -->
<div class="bl-wrapper">
  
  <iframe
    class="bl-iframe"
    id="dashboardIframe"
    title="Analytics"
    allow="fullscreen"
  ></iframe>
</div>
