import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HistoricalService {
  // eslint-disable-next-line
  constructor(private http: HttpClient) {}

  getHistory(analysisData: any) {
    const paramsQuery = this.checkQuery(analysisData);
    return this.http.get(environment.api.base + 'efas/history' + paramsQuery);
  }

  checkQuery(data: any) {
    let query = '?';
    if (data && data.reviewDateRange && data.reviewDateRange.length > 0) {
      query += 'initDate=' + data.initDate + '&endDate=' + data.endDate + '&';
    }
    if (data.country) {
      query += 'country=' + data.country.replace('&', '%26') + '&';
    }
    if (data.operationNumber) {
      query += 'operationNumber=' + data.operationNumber + '&';
    }
    if (data.externalAuditor) {
      query += 'externalAuditor=' + data.externalAuditor.replace('&', '%26') + '&';
    }
    if (data.executingAgency) {
      query += 'executingAgency=' + data.executingAgency.replace('&', '%26') + '&';
    }
    if (data.user) {
      query += 'user=' + data.user.replace('&', '%26') + '&';
    }
    query = query.substring(0, query.length - 1);
    return query;
  }
}
