import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "../app-routing.module";
import { ReactiveFormsModule } from "@angular/forms";

import { CalendarModule } from "primeng/calendar";
import { TableModule } from "primeng/table";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { TooltipModule } from "primeng/tooltip";
import { SlideMenuModule } from "primeng/slidemenu";
import { DropdownModule } from "primeng/dropdown";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SelectButtonModule } from "primeng/selectbutton";
import { RadioButtonModule } from "primeng/radiobutton";
import { CheckboxModule } from "primeng/checkbox";
import { ToastModule } from "primeng/toast";
import { TabViewModule } from "primeng/tabview";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { ButtonModule } from "primeng/button";
import { ProgressBarModule } from "primeng/progressbar";
import { FileUploadModule } from "primeng/fileupload";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ChipsModule } from "primeng/chips";
import { PanelModule } from "primeng/panel";
import { InputSwitchModule } from "primeng/inputswitch";
import { AutoCompleteModule } from "primeng/autocomplete";
import { MenubarModule } from 'primeng/menubar';
import {AvatarModule} from 'primeng/avatar';

import { DisableControlDirective } from "./directives/disable-control.directive";
//import { HeaderComponent } from "./components/header/header.component";
//import { SeacherComponent } from "./components/seacher/seacher.component";
import { SearchHistoricalComponent } from "./components/search-historical/search-historical.component";

import { HistoricalTableComponent } from "./components/historical-table/historical-table.component";
import { ReviewResultComponent } from "./components/review-result/review-result.component";
import { FindingContentComponent } from "./components/finding-content/finding-content.component";
import { FindingCrudComponent } from "./components/finding-crud/finding-crud.component";
import { AfsReviewTableComponent } from "./components/afs-review-table/afs-review-table.component";
import { ContentBorderInfoComponent } from "./components/content-border-info/content-border-info.component";
import { SumPipe } from "./pipes/sum.pipe";
import { MinsecPipe } from "./pipes/minsec.pipe";
import { StrongFindingsPipe } from "./pipes/strongFindings.pipe";

import { UsersRoleTableComponent } from "./components/users-role-table/users-role-table.component";
import { TestDictionaryTableComponent } from "./components/test-dictionary-table/test-dictionary-table.component";
import { PanelComponent } from "./components/panel/panel.component";
import { TestDescriptionsTableComponent } from "./components/test-descriptions-table/test-descriptions-table.component";
import { ToolsTableComponent } from "./components/tools-table/tools-table.component";
import { AreaChipsComponent } from "./components/area-chips/area-chips.component";
import { AddedFindingTableComponent } from "./components/added-finding-table/added-finding-table.component";
import { RemovedFindingTableComponent } from "./components/removed-finding-table/removed-finding-table.component";

import { EzshareTableComponent } from "./components/ezshare-table/ezshare-table.component";
import { ModalUsersComponent } from "./components/modal-users/modal-users.component";
//import { UpdateActionPlanTableComponent } from "./components/update-action-plan-table/update-action-plan-table.component";
//import { ActionPlanTableComponent } from "./components/action-plan-table/action-plan-table.component";
//import { ModalFindingsComponent } from "./components/modal-findings/modal-findings.component";
//import { ModalInternalReviewComponent } from "./components/modal-internal-review/modal-internal-review.component";
//import { ModalICRRoleComponent } from "./components/modal-icr-role/modal-icr-role.component";

//import { DialogCommentComponent } from "./components/dialog-comment/dialog-comment.component";

@NgModule({
  declarations: [
    //HeaderComponent,
    //SeacherComponent,
    SearchHistoricalComponent,
    DisableControlDirective,
 
    HistoricalTableComponent,
    ReviewResultComponent,
    FindingContentComponent,
    FindingCrudComponent,
    AfsReviewTableComponent,
    ContentBorderInfoComponent,
    SumPipe,
    MinsecPipe,
    StrongFindingsPipe,
    //MenuRoleComponent,
    //MenuDashboardComponent,
    UsersRoleTableComponent,
    TestDictionaryTableComponent,
    PanelComponent,
    TestDescriptionsTableComponent,
    ToolsTableComponent,
    AreaChipsComponent,
    AddedFindingTableComponent,
    RemovedFindingTableComponent,
    EzshareTableComponent,
    ModalUsersComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    CalendarModule,
    AppRoutingModule,
    TableModule,
    DialogModule,
    MultiSelectModule,
    InputTextModule,
    TooltipModule,
    SlideMenuModule,
    DropdownModule,
    ScrollPanelModule,
    SelectButtonModule,
    RadioButtonModule,
    CheckboxModule,
    ReactiveFormsModule,
    ToastModule,
    TabViewModule,
    MessageModule,
    MessagesModule,
    ButtonModule,
    ProgressBarModule,
    FileUploadModule,
    OverlayPanelModule,
    ChipsModule,
    PanelModule,
    InputSwitchModule,
    AutoCompleteModule,
    MenubarModule,
    AvatarModule,
  ],
  exports: [
    //HeaderComponent,
    //SeacherComponent,
    SearchHistoricalComponent,
    HistoricalTableComponent,
    ReviewResultComponent,
    FindingContentComponent,
    FindingCrudComponent,
    AfsReviewTableComponent,
    ContentBorderInfoComponent,
    UsersRoleTableComponent,
    TestDictionaryTableComponent,
    PanelComponent,
    TestDescriptionsTableComponent,
    ToolsTableComponent,
    AreaChipsComponent,
    AddedFindingTableComponent,
    RemovedFindingTableComponent,
    DisableControlDirective,
    CalendarModule,
    AppRoutingModule,
    TableModule,
    DialogModule,
    MultiSelectModule,
    InputTextModule,
    TooltipModule,
    SlideMenuModule,
    DropdownModule,
    ScrollPanelModule,
    SelectButtonModule,
    RadioButtonModule,
    CheckboxModule,
    ReactiveFormsModule,
    ToastModule,
    BrowserAnimationsModule,
    TabViewModule,
    MessageModule,
    MessagesModule,
    ButtonModule,
    ProgressBarModule,
    FileUploadModule,
    OverlayPanelModule,
    ChipsModule,
    PanelModule,
    InputSwitchModule,
    AutoCompleteModule,
    MenubarModule,
    SumPipe,
    MinsecPipe,
    StrongFindingsPipe,
    EzshareTableComponent,
  ],
  bootstrap: [],
  providers: [],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
