import { Component, OnInit, DoCheck } from '@angular/core';
import { ShowHistoricalSearchService } from '../../core/history/show-historical-search.service';
import { StartAnalysisService } from '@core/analysis/start-analysis.service';
import { MessageToastService } from '@core/messages/message-toast.service';
import { Router } from '@angular/router';
import { GetFindingsService } from '@core/icr/get-findings.service';
import { GetAnalysisService } from '@core/analysis/get-analysis.service';
import { StepperService } from '@core/stepper/stepper.service';
//import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'bid-icrReview',
  templateUrl: './icrReview.component.html',
  styleUrls: ['./icrReview.component.scss'],
})
export class IcrReviewComponent implements OnInit, DoCheck {
  showHistoricalSearch: boolean;
  icrData: any;
  finding: any;
  flatCreateFinding: boolean= false;
  checkId: string = '';
  summaReportValueInit: any;
  isSendNotify: any;
  countChanges = 0;
  isFinished: boolean = false;
  ActiveNotifyIR: boolean = false;
  ActiveSumaryIR: boolean = false;
  ActiveSumaryIR_: boolean = false;
  ActiveAddIR: string = '';
  STEP3 = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'icrReview', stepSelected: true },
  ];

  constructor(
    public messageToastService: MessageToastService,
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private getFindingsService: GetFindingsService,
    private startAnalysisService: StartAnalysisService,
    private router: Router,
    private getAnalysisService: GetAnalysisService,
    private stepperService: StepperService,
    //private spinner: NgxSpinnerService,
  ) {
    this.showHistoricalSearch = false;
  }

  ngOnInit() {
    //this.spinner.show();
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.getAnalysisIdObservable();
    this.stepperService.setStepper(this.STEP3);
  }

  ngDoCheck() {
    /*this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });*/
  }

  getFinding(event: any) {
    this.finding = event;
  }

  createAnewFinding(event: any) {
    this.flatCreateFinding = event;
  }

  getAnalysisIdObservable() {
    this.startAnalysisService.getAnalysis().subscribe(
      (_analysisId) => {
        const analysisId: string = localStorage.getItem('analysisId') ||'';
        this.getIcrFindings(analysisId);
      },
      () => {
        //this.spinner.hide();
        this.showToastError(
          'An error was encountered when loading the data, please try again later 1',
          'errorMessageAfsReview',
        );
      },
    );
  }

  getIcrFindings(analysisId: string)
  {
    if (analysisId) {
      this.saveAnalysisId(analysisId);
      this.getAnalysis(analysisId);
      this.getFindingsService.getFindings(analysisId).subscribe((res: any) => {
        const noContentResp = this.isEmpty(res);
        if (res && !noContentResp) {
          this.icrData = res;
          this.saveFindings();
          if (this.icrData.length === 0) {
            this.showToastWarning(
              'Information: No Findings/ Observations were flagged as medium or high risk.If you need if, you can add findings.',
              'warnMessageicr-review-form',
            );
          }
          this.getFindingsService.setcheckMarkFinding(res);
          if (!this.finding) {
            this.finding = this.icrData[0];
          }
          setTimeout(() => {
            //this.spinner.hide();
          }, 500);
        } else {
          //this.spinner.hide();
        }
      });
    } else {
      const analysisIdLocalStorage = localStorage.getItem('analysisId');
      if (analysisIdLocalStorage !== null) {
        this.getIcrFindings(JSON.parse(analysisIdLocalStorage));
      }
    }
  }

  saveFindings() {
    const stringData = JSON.stringify(this.icrData);
    localStorage.setItem('saveFindings', stringData);

    this.countChanges++;
  }

  isEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  getAnalysis(analysisId: string) {
    this.getAnalysisService.getAnalysis(analysisId).subscribe((res: any) => {
      if (res && res.summaryReport !== null) {
        this.summaReportValueInit = true;
      } else {
        this.summaReportValueInit = false;
      }
    });
  }

  saveAnalysisId(data: any) {
    //const stringData = JSON.stringify(data);
    localStorage.setItem('analysisId', data);
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }

  showToastWarning(summary: string, detail: string) {
    this.messageToastService.showToastWarn('warn', summary, detail);
  }

  getCheck(findingId: string) {
    this.checkId = findingId;
    this.getAnalysisIdObservable();
  }

  getSendNotify(isNotify: boolean) {
    this.isSendNotify = true;
  }
  getIsFinished(value: boolean) {
    this.isFinished = value;
  }
  procesaPropagarNotify(value: boolean) {
    this.ActiveNotifyIR = value;
  }
  procesaPropagarFinish(value: boolean) {
    this.ActiveSumaryIR = value;
  }
  procesaPropagarFinish_(value: boolean) {
    this.ActiveSumaryIR_ = value;
  }
  procesaPropagarAdd(value: any) {
    this.ActiveAddIR = value;
  }
}
