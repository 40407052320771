import { Component, OnInit, OnChanges, Input, DoCheck, Output, EventEmitter } from '@angular/core';
import { FindingCrudService } from '@core/icr-review/finding-crud.service';
import { GetAnalysisService } from '@core/analysis/get-analysis.service';
import { StartAnalysisService } from '@core/analysis/start-analysis.service';
import { MessageToastService } from '@core/messages/message-toast.service';
import { SummaryReportService } from '@core/summaryReport/summary-report.service';
import { AnalysisStatusService } from '@core/analysis/analysis-status.service';
import { GetFindingsService } from '@core/icr/get-findings.service';
//import { NgxSpinnerService } from 'ngx-spinner';
//import { ModalFindingsComponent } from '../modal-findings/modal-findings.component';
//import { MatDialog } from '@angular/material';
import { MessageService } from 'primeng/api';
//import { ModalICRRoleComponent } from '@shared/components/modal-icr-role/modal-icr-role.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'bid-review-result',
  templateUrl: './review-result.component.html',
  styleUrls: ['./review-result.component.scss'],
})

// eslint-disable-next-line
export class ReviewResultComponent implements OnInit, OnChanges, DoCheck {
  btnDisabled: boolean;
  analysisData: any;
  urlSummaryReport: string = '';
  summaryReportName: string = '';
  showGenerate = false;
  showBtn = false;
  finding: any;
  isFinishIcr = false;
  dialogFinishedVisible = false;
  isGeneratingIcr = false;

  @Input() checkId: any;
  @Input() onlyButton: boolean = false;
  @Input() analysis: any;
  @Input() icrData: any;
  @Output() isNotifySend = new EventEmitter();
  @Output() isFinished = new EventEmitter();
  @Input() public ActiveNotifyRRC: boolean = false;
  @Input() public ActiveSumaryRRC: boolean = false;
  @Input() public ActiveSumaryRRC_: boolean = false;
  @Output() summaryReportCheck = new EventEmitter<{ name: string; path: string }>();

  display = false;
  displayicr = false;
  notifyConfirmShow = false;
  existSummaryReport = false;
  statusCompleted: boolean = false;
  msgs: any = [];
  status: string = '';
  icrStatus: string = '';
  analisisResult: any;
  term: string = '';
  isShowButtonNotify = false;
  isFollowUpSave = false;
  ableBtnNotify = true;
  analysisId = '';
  findingData: any = null;
  showIcrFeatures: boolean = environment.version != 1;
  _getAnalysisService: any;
  _messageToastService: any;
  _messageService: any;
  _summaryReportService: any;
  _getFindingsService: any;
  _findingServices: any;
  constructor(
    private startAnalysisService: StartAnalysisService,
    private getAnalysisService: GetAnalysisService,
    private messageToastService: MessageToastService,
    private messageService: MessageService,
    private analysisStatusService: AnalysisStatusService,
    private summaryReportService: SummaryReportService,
    private getFindingsService: GetFindingsService,
    //private spinner: NgxSpinnerService,
    //public modalFinding: MatDialog,
    public findingServices: FindingCrudService,
   // public modalICRRole: MatDialog,
  ) {
    this.btnDisabled = true;
    this.showGenerate = true;
    this.existSummaryReport = false;
    this._getAnalysisService=getAnalysisService;    
    this._messageToastService=messageToastService;    
    this._messageService=messageService;    
    this._summaryReportService=summaryReportService;    
    this._getFindingsService=getFindingsService;    
    this._findingServices=findingServices;
  }

  // eslint-disable-next-line
  ngOnInit() {
    //this.spinner.show();
    const dataTest = localStorage.getItem('analisisResultLocalStorage') || '{}';
    this.analisisResult = JSON.parse(dataTest);

    const analysisId = (localStorage.getItem('analysisId') || '').replace(/\"/g, '')
    this.analysisId = analysisId;

    this.status =
      this.analisisResult && this.analisisResult.status
        ? this.analisisResult.status
        : '"In progress';
    this.icrStatus =
      this.analisisResult && this.analisisResult.icrStatus
        ? this.analisisResult.icrStatus
        : 'Completed';

    this.getAnalysis(this.analysisId).then(() => {
      this.summaryReportService.setGenerateSummaryReport(false);

      this.checkFinding(this.analysisId).then(() => {});
    });
  }

  // eslint-disable-next-line
  ngOnChanges(changes: any) {
    
    if (this.checkId) {
      this.getAnalysisIdObservable();
    }
    if (this.analysis) {
      this.status = this.analysis.status;
      this.icrStatus = this.analysis.icrStatus;
    }

    if (changes['icrData']) {
      if (changes['icrData'].currentValue) {
        this.ableBtnNotify = this.checkNotify(changes['icrData'].currentValue);
      }
    }
  }

  checkNotify(data: any) {
    return data.every((v: any) => v.reviewed === true);
  }

  downloadDraftSummaryReport() {
    //this.spinner.show();
    this.getAnalysis(this.analysisId).then(() => {
      this.summaryReportService.generateDraftReport(this.analysisId).subscribe(
        async(res: string) => {
          
          await this.summaryReportService.download(res, 'draft').toPromise()
          .then((response: any) => {

            const blob = new Blob([response], { type: response.type });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
            //this.spinner.hide();

          }, (error) => {
            
            //this.spinner.hide();

          });

        },
        () => {
          this.showToastError(
            'An error occurred when loading summary draft report',
            'errorMessageScore',
          );
        },
      );
    });
  }

  // eslint-disable-next-line
  ngDoCheck() {
    this.getFindingsService.getcheckMarkFinding().subscribe((res: any) => {
      this.btnDisabled = this.checkreviewed(res);
    });
  }

  setStatusCompleted() {
    this.displayicr = false;
    //this.spinner.show();
    if (this.analysisId) {
      this.summaryReportService.completeIcr(this.analysisId).subscribe(
        (respStatus) => {
          if (respStatus) {
            //this.spinner.hide();
            this.getAnalysis(this.analysisId);
            this.statusCompleted = true;
          }
        },
        () => {
          //this.spinner.hide();
          this.showToastError('Error', 'Error al modificar el estado del análisis');
        },
      );
    } else {
      //this.spinner.hide();
    }
  }

  loadBtn() {
    setTimeout(() => {
      this.btnDisabled = true;
      this.showGenerate = true;
      this.existSummaryReport = true;
    }, 500);
  }

  getAnalysisIdObservable() {
    this.getAnalysis(this.analysisId);
  }

  getAnalysis(analysisId: string) {
    return new Promise((resolve, reject) => {
      if (analysisId !== null) {
        this.getAnalysisService.getAnalysis(analysisId).subscribe((res) => {
          const noContentResp = this.isEmpty(res);

          //this.spinner.hide();

          if (res && !noContentResp) {
            this.analysisData = res;
            this.status = res.status;
            this.icrStatus = res.icrStatus;
            this.checkFinding(analysisId).then(() => {
              if (res.icrStatus === 'In progress') {
                this.statusCompleted = false;
              } else {
                this.statusCompleted = true;
                this.isFinishIcr = true;
              }
              if (
                res &&
                res.summaryReport &&
                res.summaryReport.path &&
                res.summaryReport.path !== ''
              ) {
                this.existSummaryReport = true;

                this.showGenerate = true;
                this.showBtn = false;
                this.summaryReportService.setGenerateSummaryReport(this.showGenerate);
                this.summaryReportName = res.summaryReport.name;
                this.urlSummaryReport = res.summaryReport.path;
                this.summaryReportCheck.emit({
                  name: this.summaryReportName,
                  path: this.urlSummaryReport,
                });
              } else {
                this.existSummaryReport = false;
                this.showBtn = true;
                this.showGenerate = false;
              }

              resolve({});
            });
          }
        });
      } else {
        resolve({});
      }
    });
  }

  isEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  checkFinding(analysisId: string) {
    return new Promise((resolve, reject) => {
      this.getFindingsService.getFindings(analysisId).subscribe((res: any) => {
        if (res[0]) {
          this.findingData = res;
          if (
            res.some((item: any) => {
              return !item.reviewed;
            })
          ) {
            this.isShowButtonNotify = false;
            this.isShowButtonNotify = res[0].isNotify ? false : true;
          } else {
            this.isShowButtonNotify = res.some((item: any) => {
              return item.isPRM || item.isPTL;
            });
            this.isShowButtonNotify = res[0].isNotify ? false : true;
          }
          this.btnDisabled = this.checkreviewed(res);

          this.ableBtnNotify = this.checkNotify(res);
        }

        resolve({});
      });
    });
  }

  checkreviewed(data: any) {
    let btnStatus = false;
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].reviewed === false) {
          btnStatus = true;
          break;
        }
      }
    }
    return btnStatus;
  }

  completeICR() {
    //this.spinner.show();

    return new Promise<void>((resolve, reject) => {
      this.summaryReportService.completeIcr(this.analysisId).subscribe(
        () => {
          //this.spinner.hide();
          if (this.analysisId) {
            this.getAnalysis(this.analysisId).then(() => {
              this.dialogFinishedVisible = true;
              this.sendFinished(true);
              resolve();
            });
          } else {
            this.dialogFinishedVisible = true;
            this.sendFinished(true);
            resolve();
          }
        },
        () => {
          //this.spinner.hide();
          this.showToastError(
            'An error occurred when completing the ICR, please try again later',
            'errorMessageGenerateSummaryReport',
          );

          resolve();
        },
      );
    });
  }

  generateSummaryReport() {
    //this.spinner.show();
    this.toggleDialog();
    this.isGeneratingIcr = true;

    this.getAnalysis(this.analysisId).then(() => {
      this.summaryReportService.setGenerateSummaryReport(this.showGenerate);
      this.summaryReportService.generateReport(this.analysisId).subscribe(
        (res) => {
          this.urlSummaryReport = res;
          this.showGenerate = true;
          this.getAnalysis(this.analysisId).then(() => {
            //this.spinner.hide();

            this.showToastSuccess(
              'Summary Report',
              'Summary Report has been created successfully.',
              'successMessage',
            );

            setTimeout(() => {
              this.clear();
              this.isGeneratingIcr = false;
            }, 3000);
          });
        },
        () => {
          this.showToastError(
            'An error occurred when generating the Summary Report, please try again later',
            'errorMessageGenerateSummaryReport',
          );
          this.toggleDialog();
          //this.spinner.hide();
        },
      );
    });
  }

  checkSummaryReport(analysisId: string) {
    this.summaryReportService.generateReport(analysisId).subscribe((res) => {
      this.urlSummaryReport = res;
    });
  }

  async downloadSummary(url: string, filename: string){

    if(url.indexOf('blob.core.windows') > 0){
      await this.summaryReportService.download(url, 'summary').toPromise()
          .then((response: any) => {

            const blob = new Blob([response], { type: response.type });
            const url = window.URL.createObjectURL(blob);

            const anchorElement = document.createElement('a');
            document.body.appendChild(anchorElement);
            anchorElement.href = url;
            anchorElement.download = filename + ".docx";
            anchorElement.click();

            window.URL.revokeObjectURL(url);

            //this.spinner.hide();

          }, (error) => {
            
            //this.spinner.hide();

          });
    } else {
      window.open(url);
    }

  }

  toggleDialog() {
    this.display = !this.display;
  }

  clear() {
    this.msgs = [];
  }

  showToastError(summary: string, key?: string) {
    this.messageToastService.showToastError('error', summary, key);
  }

  /*requestActionPlanOpen() {
    if (this.showIcrFeatures == true) {
      const dialogRef = this.modalFinding.open(ModalFindingsComponent, {
        width: '600px',
        data: { term: '15' },
        disableClose: true,
      });

      // eslint-disable-next-line
      dialogRef.afterClosed().subscribe(result =>{ 
  
        if (result !== false) {
          this.checkFinding(this.analysisId).then(() => {
            this.completeICR();
            this.displayicr = true;
          });
        }
      });
    } else {
      this.checkFinding(this.analysisId).then(() => {
        this.completeICR().then(() => {
          this.existSummaryReport = false;
        });
        this.displayicr = true;
      });
    }
  }*/

  checkUsers() {
   /* this.notifyConfirmShow = false;
    let _data = '';
    this.findingData.forEach((item: any) => {
      if (item.isPRM) {
        _data = _data + 'PRM;';
      }
      if (item.isPTL) {
        _data = _data + 'PTL;';
      }
      if (item.isActionMatrix) {
        _data = _data + 'OEG;OEA;';
      }
    });
    _data = _data + '|' + this.analysisId;
    const dialogRef = this.modalICRRole.open(ModalICRRoleComponent, {
      width: '1024px',
      data: _data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.sendNotify();
    });*/
  }

  /*sendNotify() {
    this.spinner.show();

    this.isShowButtonNotify = false;
    this.notifyConfirmShow = false;
    this.sendNotifyTrue(true);
    this.findingServices.sendNotifications(this.analysisId).subscribe(
      (res: any) => {
        this.spinner.hide();

        this.showToastSuccess(
          'Notification Success',
          'The request for comments has been sent successfully',
          'successMessage',
        );
      },
      (error) => {
        this.spinner.hide();

        this.msgs.push({
          severity: 'error',
          summary: '',
          detail: 'Error.',
        });
      },
    );
  }*/

  showToastSuccess(title: string, msg: string, key: string) {
    this.messageService.add({
      key,
      severity: 'success',
      summary: title,
      detail: msg,
    });
  }

  sendNotifyTrue(event: any) {
    this.isNotifySend.emit(event);
  }

  sendFinished(value: any) {
    this.isFinished.emit(value);
    this.isFinishIcr=true;
  }
}
