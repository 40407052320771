import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalysisStatusService {
  // eslint-disable-next-line
  constructor(private httpClient: HttpClient) {}

  setStatusCompleted(analysisId: string) {
    return this.httpClient.patch(environment.api.base + 'efas/' + analysisId + '/complete', null);
  }
}
