import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageUserService {
  private addUserSubject = new BehaviorSubject({});
  private addUserObservable: Observable<any> = this.addUserSubject.asObservable();

  setAddUser(user: any) {
    this.addUserSubject.next(user);
  }

  getAddUser(): Observable<object> {
    return this.addUserObservable;
  }
}
