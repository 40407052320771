import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class MessageToastService {
  // eslint-disable-next-line
  constructor(private messageService: MessageService) {}

  showToastError(title: string, msg: any, key: any) {
    this.messageService.add({
      key,
      severity: 'error',
      summary: title,
      detail: msg,
    });
  }

  showToastSuccess(title: string, msg: string, key: string) {
    this.messageService.add({
      key,
      severity: 'success',
      summary: title,
      detail: msg,
    });
  }

  showToastWarn(title: any, msg: any, key: any) {
    this.messageService.add({
      key,
      severity: 'warn',
      summary: title,
      detail: msg,
    });
  }

  clear(key: string | undefined) {
    this.messageService.clear(key);
  }
}
