import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TooltipTestLevelService {
  superior: number[] = [];
  Medium: number[] = [];
  Basic: number[] = [];
  Inferior: number[] = [];

  tooltipContent = `<div class="bl-tooltip__content">
  <h3 class="bl-tooltip__title">Level references</h3>
  <ul class="bl-tooltip__list">
  <li bl-tooltip__item><span class="bl-tooltip__text">Superior: >=90%<=100%</span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">Medium: >=80%<90%</span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">Basic: >=70% <80%</span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">Inferior: < 70%</span></li>
  </ul>
  </div>`;

  getTooltipContent(levels: any): string {
    if (levels) {
      levels.forEach((level: any) => {
        switch (level.score) {
          case 'Superior':
            this.superior[0] = level.range.split('-')[0] * 10;
            this.superior[1] = 100;
            break;
          case 'Medium':
            this.Medium[0] = level.range.split('-')[0] * 10;
            break;
          case 'Basic':
            this.Basic[0] = level.range.split('-')[0] * 10;
            break;
          case 'Inferior':
            this.Inferior[0] = level.range.split('-')[0] * 10;
            break;
        }
      });

      this.Inferior[1] = this.Basic[0];
      this.Basic[1] = this.Medium[0];
      this.Medium[1] = this.superior[0];

      this.tooltipContent = `<div class="bl-tooltip__content">
  <h3 class="bl-tooltip__title">Level references</h3>
  <ul class="bl-tooltip__list">
  <li bl-tooltip__item><span class="bl-tooltip__text">Superior: >=${this.superior[0]}<=${this.superior[1]}%</span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">Medium: >=${this.Medium[0]}%<${this.Medium[1]}%</span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">Basic: >=${this.Basic[0]}% <${this.Basic[1]}%</span></li>
  <li bl-tooltip__item><span class="bl-tooltip__text">Inferior: < ${this.Inferior[1]}%</span></li>
  </ul>
  </div>`;

      this.superior = levels;
      return this.tooltipContent;
    } else {
      return '';
    }
  }
}
