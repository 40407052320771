import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShowHistoricalSearchService {
  private showHistoricalSubject = new BehaviorSubject([]);
  private showHistoricalRespSubject = new BehaviorSubject([]);

  private setSeacherFormSubject = new BehaviorSubject([]);
  private getSeacherFormRespSubject = new BehaviorSubject([]);

  private testObservable: Observable<any> = this.showHistoricalSubject.asObservable();
  private shistoricalRespObservable: Observable<any> =
    this.showHistoricalRespSubject.asObservable();

  private formObservable: Observable<any> = this.setSeacherFormSubject.asObservable();
  private showHistoricalRespObservable: Observable<any> =
    this.getSeacherFormRespSubject.asObservable();

  setShowHistoricalSearch(dataTest: any) {
    this.showHistoricalSubject.next(dataTest);
  }

  getShowHistoricalSearch(): Observable<[]> {
    return this.testObservable;
  }

  setFormSearcherSearch(dataForm: any) {
    this.setSeacherFormSubject.next(dataForm);
  }

  getFormSearcherSearch(): Observable<[]> {
    return this.formObservable;
  }
}
