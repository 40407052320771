import { Component, OnInit, DoCheck, ViewChild, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { FormGroup } from '@angular/forms';
import { EstimatedProcessingTimeService } from '@core/estimated-processing-time/estimated-processing-time.service';
import { StartAnalysisService } from '@core/analysis/start-analysis.service';
import { StartAnalysisIcrService } from '@core/analysis/start-analysis-icr.service';
import { UploadFileService } from '@core/upload-pdf/upload-file.service';
import { MessageToastService } from '@core/messages/message-toast.service';
import { GenerateAnalysisFormgroupService } from '@core/analysis/generate-analysis-formgroup.service';
import { TooltipOperationNumberService } from '@core/messages/tooltip-operation-number.service';
import { environment } from '@environments/environment';
import { IEfaOutput } from '@bl-interfaces/iefa-output';
import { IIcrOutput } from '@bl-interfaces/iicr-output';
import { ILabelValue } from '@bl-interfaces/ilabelvalue';
import { isNull } from 'lodash';
import { ControlReportValues as ControlReportValuesEnum } from '@bl-enum/control-report-values.enum';
import { Languages as languageEnum } from '@bl-enum/languages.enum';
import { languages as languageConstValues } from '@bl-constants/languages.const';
import { templateSuccess } from '../../shared/constants/templateSuccess';
import { Router, NavigationEnd } from '@angular/router';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';
import { StepperService } from '@core/stepper/stepper.service';
import { mainMenu } from '@bl-constants/main-menu.const';
import { AfsReviewService } from '@core/afs-review/afs-review.service';
import { EzshareParameterService } from '@core/analysis/ezshare-parameter.service';

@Component({
  selector: 'bid-generate-analysis',
  templateUrl: './generate-analysis.component.html',
  styleUrls: ['./generate-analysis.component.scss'],
  providers: [],
})
export class GenerateAnalysisComponent implements OnInit, DoCheck, OnDestroy {
  @ViewChild('fileReview') fileReview: any;
  analyzeForm!: FormGroup;
  languages: ILabelValue[] = [];
  uploadedFiles: File[] = [];
  reviewFile!: File;
  ICRFile!: File;
  msgs: Message[] = [];
  progressValue = 0;
  estimatedTime = 0;
  errorAnalysis = false;
  analysisNotFinding = false;
  onAnalysis = false;
  reviewFileName: string = "";
  icrFileName: string = "";
  ezsharedLinkEfa: string = "";
  ezsharedLinkIcr: string = "";
  reviewResponse!: IEfaOutput;
  icrResponse!: IIcrOutput;
  operationNumberValid: boolean = true;
  auditDateValid: boolean | null = true;
  analysisCompleted: boolean = false;
  tooltipInfo: string = "";
  generateAnalisis = false;
  controlReportValues = ControlReportValuesEnum;
  templateSuccess = templateSuccess;
  showHistoricalSearch: boolean = false;
  INIT_STEP = mainMenu.steps;
  ERROR_STEP = mainMenu.stepsError;
  STEP1 = [
    { name: 'generateNewAnalysis', stepSelected: true },
    { name: 'afsReview', stepSelected: false, disabled: true },
    { name: 'score', stepSelected: false, disabled: true },
    { name: 'icrReview', stepSelected: false, disabled: true },
  ];
  icrpages: number;
  reviewPages: number;
  ezsharedParameter: string = "";
  analysisInterval: any;
  intervalBar: any;
  ezshareDocument: any;
  ezshareICRDocument: any;
  waitInterval: any;
  preparingDocuments: any;
  intervalBarUpload: any;
  minPage: number = -1;
  maxPage: number = -1;
  errorMessageMinPage = false;
  messageStep: string = "";
  _startAnalysisService;
  _estimatedProcessingTimeService;
  _uploadFileService;
  _ezshareParameterService;

  constructor(
    private estimatedProcessingTimeService: EstimatedProcessingTimeService,
    private startAnalysisService: StartAnalysisService,
    private startAnalysisIcrService: StartAnalysisIcrService,
    private uploadFileService: UploadFileService,
    private messageToastService: MessageToastService,
    private tooltipOperationNumberService: TooltipOperationNumberService,
    private generateAnalysisFormgroupService: GenerateAnalysisFormgroupService,
    private router: Router,
    private showHistoricalSearchService: ShowHistoricalSearchService,
    private stepperService: StepperService,
    private afsReviewService: AfsReviewService,
    private ezshareParameterService: EzshareParameterService,
  ) {
    this.icrpages = 0;
    this.reviewPages = 0;
    this.analysisInterval = undefined;
    this._startAnalysisService = startAnalysisService;
    this._estimatedProcessingTimeService = estimatedProcessingTimeService;
    this._uploadFileService = uploadFileService;
    this._ezshareParameterService = ezshareParameterService;
  }

  ngOnInit() {
    this.startAnalysisService.setAnalysisData(false);
    this.afsReviewService.setScore(null);
    sessionStorage.removeItem('reviewTime');
    localStorage.removeItem('analysisId');
    this.stepperService.setStepper(this.STEP1);
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.showHistoricalSearch = false;
    this.analyzeForm = this.generateAnalysisFormgroupService.getGenerateAnalysisFormgroup();
    this.tooltipInfo = this.tooltipOperationNumberService.getTooltipContent();
    this.languages = languageConstValues;
  this.analyzeForm.get('language')?.setValue(languageEnum.SPANISH);
    this.analyzeForm.get('controlReport')?.setValue(this.controlReportValues.IN_FILE);
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      this.resetForm();
    });
    this.stopTime();

    localStorage.removeItem('saveFindings');
    localStorage.removeItem('stepsLocalStorage');
    localStorage.removeItem('lastFinding');
  }

  ngDoCheck() {
    this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });
  }

  selectDocument(event: any, icr?: boolean): void {
    if (icr) {
      this.ezshareICRDocument = event;
    } else {
      this.ezshareDocument = event;
    }
  }

  showError() {
    this.errorAnalysis = true;
    this.stepperService.setStepper(this.ERROR_STEP);
  }

  getFile(e: any, type: string) {
    if (type === 'review') {
      if (e.files[0].type === 'application/pdf') {
        this.reviewFile = e.files[0];
        this.uploadedFiles[0] = this.reviewFile;
        this.getNumberPagesPdf(this.uploadedFiles[0], 'review');
        this.estimatedTime = this.estimatedProcessingTimeService.calculateEstimatedTime(
          this.uploadedFiles,
          this.reviewPages,
          this.icrpages,
          this.analyzeForm.controls['controlReport'].value,
        );
        this.showToastSuccess('File Uploaded', true);
      } else {
        this.msgs.push({
          severity: 'warn',
          summary: 'The selection has failed (invalid file type)',
        });
      }
    } else {
      if (e.files[0].type === 'application/pdf') {
        this.ICRFile = e.files[0];
        this.uploadedFiles[1] = this.ICRFile;
        this.getNumberPagesPdf(this.uploadedFiles[1], 'ICR');
        this.estimatedTime = this.estimatedProcessingTimeService.calculateEstimatedTime(
          this.uploadedFiles,
          this.reviewPages,
          this.icrpages,
          this.analyzeForm.controls['controlReport'].value,
        );
        this.showToastSuccess('File Uploaded', true);
      } else {
        this.msgs.push({
          severity: 'warn',
          summary: 'The selection has failed (invalid file type)',
        });
      }
    }
  }

  getNumberPagesPdf(file: any, kind: any) {
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onloadend = () => {
      this.checkRegExToGetNumberOfPages(reader.result, kind);
    };
  }

  checkRegExToGetNumberOfPages(data: any, kind: string) {
    const regExp = /\/Type[\s]*\/Page[^s]/g;
    if (kind === 'ICR') {
      if (typeof data === 'string') {
        this.icrpages = (data.match(regExp) !== null ? data.match(regExp)?.length : 30) || 0;
      }
    } else {
      if (typeof data === 'string') {
        this.reviewPages = (data.match(regExp) !== null ? data.match(regExp)?.length : 30) || 0;
      }
    }
    this.estimatedTime = this.estimatedProcessingTimeService.calculateEstimatedTime(
      this.uploadedFiles,
      this.reviewPages,
      this.icrpages,
      this.analyzeForm.controls['controlReport'].value,
    );
  }

  removeFile(type: string) {
    if (type === 'review') {
      this.uploadedFiles.splice(0, 1);
      this.reviewFile;
      this.reviewPages = 0;
    } else {
      this.uploadedFiles.splice(1, 1);
      this.ICRFile;
      this.icrpages = 0;
      this.analyzeForm.patchValue({
        ICRDocument: this.ICRFile,
      });
    }
    this.estimatedTime = this.estimatedProcessingTimeService.calculateEstimatedTime(
      this.uploadedFiles,
      this.reviewPages,
      this.icrpages,
      this.analyzeForm.controls['controlReport'].value,
    );
  }

  getValueOperationNumber() {
    if (this.analyzeForm.get('operationNumber')?.valid) {
      this.getEzshareParameter();
    }
  }

  modifyPage(key: any, quantity: number) {
    this.analyzeForm.controls[key].setValue(this.analyzeForm.value[key] + quantity);
  }

  checkValue(event: any, key: string) {
    const value: number = parseInt((event as HTMLInputElement)?.value || '0', 10);

    return this._checkValue(value, key);
  }

  checkValue2(value: number, key: string) {
   
    return this._checkValue(value, key);
  }

  _checkValue(value: number, key: string){
    if (value < 0) {
      this.analyzeForm.get(key)?.setValue((this as Record<string, any>)[key]); 
      return false;
    }
    if (key === 'minPage') {
      if (this.maxPage !== undefined) {
        value > this.maxPage
          ? this.analyzeForm.get('minPage')?.setValue(this.minPage)
          : (this.minPage == value);
      } else {
        this.minPage = value;
      }
    } else {
      value < this.minPage
        ? this.analyzeForm.get('maxPage')?.setValue(this.maxPage)
        : (this.maxPage = value);
    }
    return true;
  }

  startAnalysis() 
  {
    this.startAnalisysTime();
    this.analysisNotFinding = false;
    this.operationNumberValid = this.analyzeForm.get('operationNumber')?.valid || true;
    this.auditDateValid = this.validateDates(); 
    console.log("startAnalysis 1", this.generateAnalisis)
    
    if (this.generateAnalisis !== true && this.operationNumberValid && this.auditDateValid)
    {
      this.progressbarStartUpload();
      this.errorAnalysis = false;
      console.log("startAnalysis 2 ok");
      this.uploadFileService.uploadFileV2
      (
        environment.api.base + 'caches',
        this.reviewFile
      )
      .subscribe
      (
          (resp: any) => 
          {
            console.log("startAnalysis 2 callback")

            this.reviewFileName = `${environment.api.base}caches?id=${resp.fileId}`;

            if (this.ICRFile || this.ezshareICRDocument) 
            {
              this.uploadFileService
                .uploadFileV2(
                  environment.api.base + 'caches',
                  this.ICRFile
                )
                .subscribe(
                  (respIcr) => 
                  {
                    this.progressbarStart(this.estimatedTime);
                    this.icrFileName = `${environment.api.base}caches?id=${respIcr.fileId}`;

                    this.startAnalysisProcess();
                  },
                  (err) => {
                    console.log("error", err)
                    this.analysisFail(err);
                    this.stepperService.setStepper(this.ERROR_STEP);
                  },
                );
            } 
            else 
            {
              this.progressbarStart(this.estimatedTime);
              if (
                this.analyzeForm.value.controlReport === 'uploadIcr' &&
                !this.analyzeForm.value.ICRDocument
              ) {
                this.analysisFail('error');
                this.stepperService.setStepper(this.ERROR_STEP);
              } else {
                //this.callServiceAnalysis();
                console.log("startAnalysisProcess");
                this.startAnalysisProcess();
              }
            }
          },
          (err) => {
            console.log("error", err)
            this.analysisFail(err);
            this.stepperService.setStepper(this.ERROR_STEP);
          },
        );
    }
    else 
    {
      if (this.analyzeForm.status === 'INVALID' || !this.auditDateValid)
      {
        console.log("startAnalysis 2", this.generateAnalisis)
        this.analysisFail('error');
        this.stepperService.setStepper(this.ERROR_STEP);
        this.generateAnalisis = false;
      } 
      else 
      {
        console.log("startAnalysis 3", this.generateAnalisis)
        console.log(this.router)
        this.router.navigate(['/AfsReview']);
      }
    }
  }

  getEzshareParameter() {
    if (this.analyzeForm.get('operationNumber')?.valid) {
      this.ezshareParameterService
        .getEzsharedParameters(this.analyzeForm.get('operationNumber')?.value.toUpperCase())
        .subscribe(
          (res: any) => {
            if (res) {
              this.ezsharedParameter = res;
            } else {
              this.ezsharedParameter = '';
            }
          },
          // eslint-disable-next-line
          (error) => console.log(error),
        );
    }
  }

  validateDates() {
    let validResult: boolean | null;
    if (!isNull(this.analyzeForm.get('auditDate')?.value)) {
      let notUndefinedEndDate: boolean | null;
      let notNullEndDate: boolean;
      notNullEndDate = !isNull(this.analyzeForm.get('auditDate')?.value[1]);

      notUndefinedEndDate = this.analyzeForm.get('auditDate')?.value[1] !== undefined || null;
      validResult = notUndefinedEndDate && notNullEndDate;
      return validResult;
    } else {
      validResult = false;

      return validResult;
    }
  }

  checkIcr(formResponse: any) {
    if (formResponse === 'noIcr') {
      this.STEP1[0].disabled = false;
      this.STEP1[1].disabled = false;
      this.STEP1[2].disabled = true;
      this.STEP1[3].disabled = true;
      this.stepperService.setStepper(this.STEP1);
    } else {
      this.STEP1[0].disabled = false;
      this.STEP1[1].disabled = false;
      this.STEP1[2].disabled = true;
      this.STEP1[3].disabled = false;
      this.stepperService.setStepper(this.STEP1);
    }
  }

  progressbarStart(estimated: number) {
    let step = ((estimated * 0.4) / 100) * 1000;
    this.showToastSuccess('Prepared documents', true);
    if (this.intervalBarUpload !== undefined) {
      clearInterval(this.intervalBarUpload);
      this.intervalBarUpload = undefined;
    }
    this.preparingDocuments = undefined;
    this.onAnalysis = true;

    this.progressValue = 0;
    //window.scrollTo(0, document.body.scrollHeight);
    this.intervalBar = setInterval(() => {
      this.progressValue += 1;
      if (this.progressValue >= 99) {
        clearInterval(this.intervalBar);
        this.intervalBar = undefined;
      }
    }, step);
    this.addNopointerClass();
  }

  progressbarStartUpload() {

    if(this.estimatedTime == 0){
      this.estimatedTime = 1000;
    }

    let TotalEstimated = this.estimatedTime   / 100;
    this.onAnalysis = true;
    this.preparingDocuments = 0;
   // window.scrollTo(0, document.body.scrollHeight);
    this.intervalBarUpload = setInterval(() => {
      this.preparingDocuments += 1;
      if (this.preparingDocuments >= 100) {
        clearInterval(this.intervalBarUpload);
        this.intervalBarUpload = undefined;
      }
    }, TotalEstimated);
    this.addNopointerClass();
  }

  addNopointerClass() {
    const element = document.getElementsByClassName('ui-button');
    for (let i = 0; i < element.length; i++) {
      element[i].classList.add('bl-generate-analysis__upload-pointer-none');
    }
  }

  startAnalisysTime() {
    if (this.analysisInterval !== undefined) {
      this.stopTime();
    }
    sessionStorage.removeItem('analysisTime');
    this.analysisInterval = setInterval(this.updateAnalysisTime, 1000);
  }

  updateAnalysisTime() {
    let analysisTime : number = 0;
    analysisTime = parseInt(sessionStorage.getItem('analysisTime') || '0', 10);
    if (analysisTime === null) {
      sessionStorage.setItem('analysisTime', '1');
    } else {
      analysisTime = +analysisTime + 1;
      sessionStorage.setItem('analysisTime', analysisTime.toString());
    }
  }

  stopTime() {
    clearInterval(this.analysisInterval);
  }

  removeNoPointerClass() {
    const element = document.getElementsByClassName('ui-button');
    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove('bl-generate-analysis__upload-pointer-none');
    }
  }

  progressbarEnd() {
    this.onAnalysis = false;
    this.generateAnalisis = true;
    this.progressValue = 0;
    if (this.intervalBar !== undefined) {
      clearInterval(this.intervalBar);
      this.intervalBar = undefined;
    }
    this.removeNoPointerClass();
  }

  analysisFail(err: any) {
    // eslint-disable-next-line
    console.error('Observer got an error: ' + err);
    this.stepperService.setStepper(this.ERROR_STEP);
    this.errorAnalysis = true;
    this.progressbarEnd();
    this.generateAnalisis = false;
    //window.scrollTo(0, document.body.scrollHeight);
    this.stopTime();
  }

  callServiceAnalysis() {
    this.startAnalysisService
      .sendEfa(
        this.analyzeForm.value,
        this.reviewFileName,
        this.analyzeForm.value.controlReport === 'inFile' ? true : false,
        this.ezsharedLinkEfa,
        this.minPage,
        this.maxPage,
        this.icrFileName,
      )
      .subscribe(
        (resp: any) => {
          if (resp) {
            this.reviewResponse = resp._EfaOutput;
            if (!isNull(this.icrFileName)) {
              this.icrResponse = resp._IcrOutput;
              this.progressbarEnd();
              this.checkIcr(this.analyzeForm.get('controlReport')?.value);
               
              if (this.reviewResponse && this.reviewResponse.id !== null) {
                this.startAnalysisService.setAnalysisData(this.reviewResponse.id);
                this.analysisCompleted = true;
                this.showToastSuccess('Analysis completed. Go to AFS Review to see the results');
                this.setProcessingTime();
              } else {
                this.analysisFail('err');
              }
            } else {
              this.progressbarEnd();
              this.showToastSuccess('Analysis completed. Go to AFS Review to see the results');
              this.checkIcr(this.analyzeForm.get('controlReport')?.value);
              this.analysisCompleted = true;
              this.startAnalysisService.setAnalysisData(this.reviewResponse.id);

              this.setProcessingTime();
            }
          } else {
            this.analysisNotFinding = true;
            this.operationNumberValid = false;
            this.progressbarEnd();
            this.generateAnalisis = false;
            this.stopTime();
            this.setProcessingTime();
          }
        },
        (err) => {
          this.stopTime();
          this.analysisFail(err);
        },
      );
  }

  startAnalysisProcess()
  {
    this.startAnalysisService
      .start(
        this.analyzeForm.value,
        this.reviewFileName,
        this.analyzeForm.value.controlReport === 'inFile' ? true : false,
        this.ezsharedLinkEfa,
        this.minPage,
        this.maxPage,
        this.icrFileName,
      )
      .subscribe(
        (resp: any) => {
 
          this.statusAnalysisProcess(resp.id_queue);
          /*if (resp) {
            this.reviewResponse = resp._EfaOutput;
            if (!isNull(this.icrFileName)) {
              this.icrResponse = resp._IcrOutput;
              this.progressbarEnd();
              this.checkIcr(this.analyzeForm.controls.controlReport.value);
               
              if (this.reviewResponse && this.reviewResponse.id !== null) {
                this.startAnalysisService.setAnalysisData(this.reviewResponse.id);
                this.analysisCompleted = true;
                this.showToastSuccess('Analysis completed. Go to AFS Review to see the results');
                this.setProcessingTime();
              } else {
                this.analysisFail('err');
              }
            } else {
              this.progressbarEnd();
              this.showToastSuccess('Analysis completed. Go to AFS Review to see the results');
              this.checkIcr(this.analyzeForm.controls.controlReport.value);
              this.analysisCompleted = true;
              this.startAnalysisService.setAnalysisData(this.reviewResponse.id);

              this.setProcessingTime();
            }
          } else {
            this.analysisNotFinding = true;
            this.operationNumberValid = false;
            this.progressbarEnd();
            this.generateAnalisis = false;
            this.stopTime();
            this.setProcessingTime();
          }*/
        },
        (err) => {
          this.stopTime();
          this.analysisFail(err);
        },
      );
  }

  statusAnalysisProcess(id_queue: string)
  {

    this.startAnalysisService
      .status(
        this.analyzeForm.value,
        this.reviewFileName,
        this.analyzeForm.value.controlReport === 'inFile' ? true : false,
        this.ezsharedLinkEfa,
        this.minPage,
        this.maxPage,
        this.icrFileName,
        id_queue
      )
      .subscribe(
        (resp: any) => 
        {
          
          if(resp != null)
          {
            this.messageStep = resp.status;
          }

          if(resp == null || resp.status.indexOf('Processing') != -1)
          {
            setTimeout(()=>{
              this.statusAnalysisProcess(id_queue);
            }, 20 * 1000); 
            return;
          }

          if(resp.status.indexOf("Error") != -1)
          {
            this.stopTime();
            this.analysisFail("Error proccessing documents");
          }

          if(resp.status == "Done")
          {
            this.analysisCompleted = true;
            localStorage.setItem('analysisId', resp.id);

            this.showToastSuccess('Analysis completed. Go to AFS Review to see the results');
            this.progressbarEnd();
            this.generateAnalisis = true;
            this.stopTime();
          }
        },
        (err) => {
          this.stopTime();
          this.analysisFail(err);
        },
      );
  }
 

  setProcessingTime() {
    this.analysisInterval = undefined;
    this.stopTime();
    const timmerAnalysis: string = sessionStorage.getItem('analysisTime') || "";
    sessionStorage.setItem('pocessingTime', timmerAnalysis);
    sessionStorage.removeItem('analysisTime');
    sessionStorage.setItem('analysisTime', '1');
  }

  resetForm() {
    this.analyzeForm.patchValue({
      operationNumber: '',
      language: languageEnum.SPANISH,
      auditDate: '',
      controlReport: this.controlReportValues.IN_FILE,
      reviewDocument: [],
      ICRDocument: null,
    });
    //this.fileReview.files = [];
    this.reviewFile.slice(0,0);
    this.estimatedTime = 0;
    this.onAnalysis = false;
    this.removeNoPointerClass();
    this.errorAnalysis = false;
    this.analysisNotFinding = false;
    this.operationNumberValid = true;
    this.analysisCompleted = false;
    this.generateAnalisis = false;
    sessionStorage.removeItem('analysisTime');
    sessionStorage.setItem('analysisTime', '1');
    this.stopTime();
  }

  showToastSuccess(details: any, upload: boolean = false) {
    this.messageToastService.showToastSuccess(
      'Success',
      details,
      upload ? 'successMessageUpload' : 'successMessage',
    );
  }

  ngOnDestroy(): void {
    if (this.analysisInterval !== undefined) {
      this.stopTime();
    }
    if (this.intervalBar !== undefined) {
      clearInterval(this.intervalBar);
    }
  }
}
