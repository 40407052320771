import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Languages as languageEnum } from '@bl-enum/languages.enum';
import { ControlReportValues as ControlReportValuesEnum } from '@bl-enum/control-report-values.enum';

@Injectable({
  providedIn: 'root',
})
export class GenerateAnalysisFormgroupService {
  getGenerateAnalysisFormgroup(): FormGroup {
    let analysisFormgroup: FormGroup;

    analysisFormgroup = new FormGroup({
      operationNumber: new FormControl('', [Validators.required]),
      language: new FormControl('', Validators.required), // languageEnum.SPANISH
      auditDate: new FormControl('', Validators.required),
      controlReport: new FormControl('', Validators.required), // ControlReportValuesEnum.IN_FILE
      reviewDocument: new FormControl(''),
      ICRDocument: new FormControl(''),
      minPage: new FormControl(undefined),
      maxPage: new FormControl(undefined),
    });

    return analysisFormgroup;
  }
}
