import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TestDescriptionsService {
  // eslint-disable-next-line
  constructor(private http: HttpClient) {}

  getTestDictionary() {
    return this.http.get(environment.api.base + 'tests');
  }

  updateTestDictionary(id: any, body: any, name: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.put(
      environment.api.base + 'tests?id=' + id + '&name=' + name,
      '"' + body + '"',
      httpOptions,
    );
  }
}
