export const languages = [
  { label: 'English', value: 'En' },
  { label: 'French', value: 'Fr' },
  {
    label: 'Portuguese',
    value: 'Po',
  },
  {
    label: 'Spanish',
    value: 'Sp',
  },
];
