import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { languages } from '../../shared/constants/languages.const';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TestDictionaryService } from '@core/test-dictionary/test-dictionary.service';
import { MessageToastService } from '@core/messages/message-toast.service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from '@core/language/language.service';
import { categorLang } from '@bl-interfaces/category-lang';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';

@Component({
  selector: 'bid-test-dictionary-detail',
  templateUrl: './test-dictionary-detail.component.html',
  styleUrls: ['./test-dictionary-detail.component.scss'],
})
export class TestDictionaryDetailComponent implements OnInit, DoCheck {
  recoverTest: string = '';
  testId: string = '';
  lang: string = '';
  languageCombo: any;
  categorySelect: any = [];/*  = [
    {
      label: string;
      value: string;
    },
  ];*/
  display: boolean = false;
  formGroupSeacherTestDetailDictionary!: FormGroup;
  formGroupAddParameter!: FormGroup;
  chips1: any;
  chips2: any;
  testDictionary: any;
  arrCategory: any;
  arrParameters: any;
  dataTestDictionary: any;
  succsessMsg: boolean = false;
  seracherTerm: string  = '';
  showHistoricalSearch: boolean;
  _testDictionaryService: any;
  _messageToastService: any;
  _languageService: any;
  _showHistoricalSearchService: any;
  constructor(
    private route: ActivatedRoute,
    private testDictionaryService: TestDictionaryService,
    private messageToastService: MessageToastService,
    //private spinner: NgxSpinnerService,
    private router: Router,
    private languageService: LanguageService,
    private showHistoricalSearchService: ShowHistoricalSearchService,
  ) {
    this.showHistoricalSearch = false;
    this._testDictionaryService = testDictionaryService;
    this._messageToastService = messageToastService;
    this._languageService = languageService;
    this._showHistoricalSearchService = showHistoricalSearchService;
  }

  ngOnInit() {
    //this.spinner.show();
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.recoverTest = this.route.snapshot.params['test'];
    this.testId = this.route.snapshot.params['id'];
    this.lang = this.route.snapshot.params['lang'];
    this.languageCombo = languages;
    this.getTestDictionaryDetail();
    this.formGroupAddParameter = new FormGroup({
      parameter: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
    });
    this.formGroupSeacherTestDetailDictionary = new FormGroup({
      language: new FormControl(
        this.lang ? this.lang : this.languageCombo[0].value,
        Validators.required,
      ),
      seacherTerm: new FormControl('', Validators.required),
    });
    this.languageService.setLang(this.formGroupSeacherTestDetailDictionary.value.language);
  }

  ngDoCheck() {
    this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });
  }

  getTestDictionaryDetail() {
    this.testDictionaryService.getTestDictionaryDetail(this.testId).subscribe(
      (res: any) => {
        if (res) {
          this.testDictionary = res;
          this.arrCategory = this.checkCategoryTypes(res.categoryTypes, this.testId);
          this.arrParameters = this.parameterCategory(res.parameters);
          this.checkLanguages();
          //this.spinner.hide();
        }
      },
      () => {
        this.showToastError(
          'We have encountered a problem when loading the data, please try again later.',
          'errorMessageTestDictionaryDetail',
        );
      },
    );
  }

  checkLanguages() {
    const lang =
      this.formGroupSeacherTestDetailDictionary.value.language !== undefined
        ? this.formGroupSeacherTestDetailDictionary.value.language
        : 'En';
    this.languageService.setLang(lang);
    switch (lang) {
      case 'En':
        this.dataTestDictionary = this.arrParameters.En;
        break;
      case 'Fr':
        this.dataTestDictionary = this.arrParameters.Fr;
        break;
      case 'Po':
        this.dataTestDictionary = this.arrParameters.Po;
        break;
      case 'Sp':
        this.dataTestDictionary = this.arrParameters.Sp;
        break;
    }
  }

  checkCategoryTypes(categoryTypes: any, testId: string) {
    let arr: any = [];
    if (testId === '6' || testId === '7') {
      this.categorySelect = [{ label: 'Select Category', value: null }];
      if (categoryTypes.length > 0) {
        for (let i = 0; i <= categoryTypes.length; i++) {
          if (categoryTypes[i] === 1) {
            arr.push({ title: 'Opinion of the auditor', categoryTypes: 1 });
            this.categorySelect.push({
              label: 'Opinion of the auditor',
              value: 'Opinion_of_the_auditor',
            });
          }
          if (categoryTypes[i] === 2) {
            arr.push({
              title: 'Statements of cash receipts and payments',
              categoryTypes: 2,
            });
            this.categorySelect.push({
              label: 'Statements of cash receipts and payments',
              value: 'Statements_of_cash_receipts_and_payments',
            });
          }
          if (categoryTypes[i] === 3) {
            arr.push({
              title: 'Statements of Cumulative Investments',
              categoryTypes: 3,
            });
            this.categorySelect.push({
              label: 'Statements of Cumulative of investments',
              value: 'Statement_of_Cumulative_Investments',
            });
          }
          if (categoryTypes[i] === 4) {
            arr.push({
              title: 'Notes to the financial statement',
              categoryTypes: 4,
            });
            this.categorySelect.push({
              label: 'Notes of the financial statement',
              value: 'Notes_to_the_financial_statement',
            });
          }
          if (categoryTypes[i] === 5) {
            arr.push({ title: 'Disclamer of opinion', categoryTypes: 5 });
            this.categorySelect.push({
              label: 'Disclamer of opinion',
              value: 'Disclaimer_of_opinion',
            });
          }
          if (categoryTypes[i] === 6) {
            arr.push({ title: 'Adverse', categoryTypes: 6 });
            this.categorySelect.push({ label: 'Adverse', value: 'Adverse' });
          }
          if (categoryTypes[i] === 7) {
            arr.push({ title: 'Qualified', categoryTypes: 7 });
            this.categorySelect.push({
              label: 'Qualified',
              value: 'Qualified',
            });
          }
          if (categoryTypes[i] === 8) {
            arr.push({ title: 'Clean', categoryTypes: 8 });
            this.categorySelect.push({ label: 'Clean', value: 'Clean' });
          }
        }
      }
    } else {
      arr = null;
    }
    return arr;
  }

  parameterCategory(parameter: any) {
    const arr: categorLang = {
      En: {
        category1: [],
        category2: [],
        category3: [],
        category4: [],
        category5: [],
        category6: [],
        category7: [],
        category8: [],
        withoutCategory: [],
        error: {
          category1: [
            {
              status: '',
            },
          ],
          category2: [
            {
              status: '',
            },
          ],
          category3: [
            {
              status: '',
            },
          ],
          category4: [
            {
              status: '',
            },
          ],
          category5: [
            {
              status: '',
            },
          ],
          category6: [
            {
              status: '',
            },
          ],
          category7: [
            {
              status: '',
            },
          ],
          category8: [
            {
              status: '',
            },
          ],
          withoutCategory: [
            {
              status: '',
            },
          ],
        },
        adding: {
          category1: [
            {
              status: '',
            },
          ],
          category2: [
            {
              status: '',
            },
          ],
          category3: [
            {
              status: '',
            },
          ],
          category4: [
            {
              status: '',
            },
          ],
          category5: [
            {
              status: '',
            },
          ],
          category6: [
            {
              status: '',
            },
          ],
          category7: [
            {
              status: '',
            },
          ],
          category8: [
            {
              status: '',
            },
          ],
          withoutCategory: [
            {
              status: '',
            },
          ],
        },
      },
      Fr: {
        category1: [],
        category2: [],
        category3: [],
        category4: [],
        category5: [],
        category6: [],
        category7: [],
        category8: [],
        withoutCategory: [],
        error: {
          category1: [
            {
              status: '',
            },
          ],
          category2: [
            {
              status: '',
            },
          ],
          category3: [
            {
              status: '',
            },
          ],
          category4: [
            {
              status: '',
            },
          ],
          category5: [
            {
              status: '',
            },
          ],
          category6: [
            {
              status: '',
            },
          ],
          category7: [
            {
              status: '',
            },
          ],
          category8: [
            {
              status: '',
            },
          ],
          withoutCategory: [
            {
              status: '',
            },
          ],
        },
        adding: {
          category1: [
            {
              status: '',
            },
          ],
          category2: [
            {
              status: '',
            },
          ],
          category3: [
            {
              status: '',
            },
          ],
          category4: [
            {
              status: '',
            },
          ],
          category5: [
            {
              status: '',
            },
          ],
          category6: [
            {
              status: '',
            },
          ],
          category7: [
            {
              status: '',
            },
          ],
          category8: [
            {
              status: '',
            },
          ],
          withoutCategory: [
            {
              status: '',
            },
          ],
        },
      },
      Po: {
        category1: [],
        category2: [],
        category3: [],
        category4: [],
        category5: [],
        category6: [],
        category7: [],
        category8: [],
        withoutCategory: [],
        error: {
          category1: [
            {
              status: '',
            },
          ],
          category2: [
            {
              status: '',
            },
          ],
          category3: [
            {
              status: '',
            },
          ],
          category4: [
            {
              status: '',
            },
          ],
          category5: [
            {
              status: '',
            },
          ],
          category6: [
            {
              status: '',
            },
          ],
          category7: [
            {
              status: '',
            },
          ],
          category8: [
            {
              status: '',
            },
          ],
          withoutCategory: [
            {
              status: '',
            },
          ],
        },
        adding: {
          category1: [
            {
              status: '',
            },
          ],
          category2: [
            {
              status: '',
            },
          ],
          category3: [
            {
              status: '',
            },
          ],
          category4: [
            {
              status: '',
            },
          ],
          category5: [
            {
              status: '',
            },
          ],
          category6: [
            {
              status: '',
            },
          ],
          category7: [
            {
              status: '',
            },
          ],
          category8: [
            {
              status: '',
            },
          ],
          withoutCategory: [
            {
              status: '',
            },
          ],
        },
      },
      Sp: {
        category1: [],
        category2: [],
        category3: [],
        category4: [],
        category5: [],
        category6: [],
        category7: [],
        category8: [],
        withoutCategory: [],
        error: {
          category1: [
            {
              status: '',
            },
          ],
          category2: [
            {
              status: '',
            },
          ],
          category3: [
            {
              status: '',
            },
          ],
          category4: [
            {
              status: '',
            },
          ],
          category5: [
            {
              status: '',
            },
          ],
          category6: [
            {
              status: '',
            },
          ],
          category7: [
            {
              status: '',
            },
          ],
          category8: [
            {
              status: '',
            },
          ],
          withoutCategory: [
            {
              status: '',
            },
          ],
        },
        adding: {
          category1: [
            {
              status: '',
            },
          ],
          category2: [
            {
              status: '',
            },
          ],
          category3: [
            {
              status: '',
            },
          ],
          category4: [
            {
              status: '',
            },
          ],
          category5: [
            {
              status: '',
            },
          ],
          category6: [
            {
              status: '',
            },
          ],
          category7: [
            {
              status: '',
            },
          ],
          category8: [
            {
              status: '',
            },
          ],
          withoutCategory: [
            {
              status: '',
            },
          ],
        },
      },
    };
    if (parameter.length > 0) {
      for (let i = 0; i < parameter.length; i++) {
        switch (parameter[i].language.toUpperCase()) {
          case 'EN':
            arr.En = this.builtCategoryTypes(parameter[i], arr.En);
            break;
          case 'FR':
            arr.Fr = this.builtCategoryTypes(parameter[i], arr.Fr);
            break;
          case 'PO':
            arr.Po = this.builtCategoryTypes(parameter[i], arr.Po);
            break;
          case 'SP':
            arr.Sp = this.builtCategoryTypes(parameter[i], arr.Sp);
            break;
        }
      }
    }
    return arr;
  }

  builtCategoryTypes(data: any, arr: any) {
    switch (data.categoryType) {
      case 1:
        arr.category1.push(data);
        break;
      case 2:
        arr.category2.push(data);
        break;
      case 3:
        arr.category3.push(data);
        break;
      case 4:
        arr.category4.push(data);
        break;
      case 5:
        arr.category5.push(data);
        break;
      case 6:
        arr.category6.push(data);
        break;
      case 7:
        arr.category7.push(data);
        break;
      case 8:
        arr.category8.push(data);
        break;
      default:
        arr.withoutCategory.push(data);
        break;
    }
    return arr;
  }

  seacherByTerm() {
    if (this.formGroupSeacherTestDetailDictionary.value.seacherTerm !== '') {
      this.seracherTerm = this.formGroupSeacherTestDetailDictionary.value.seacherTerm;
      const result = this.testDictionary.parameters.filter(
        (item: any) =>
          (this.parseSpecialChar(item.parameter).trim() ===
            this.parseSpecialChar(this.seracherTerm).trim() ||
            this.parseSpecialChar(item.parameter)
              .trim()
              .indexOf(this.parseSpecialChar(this.seracherTerm).trim()) > -1) &&
          item.language.toLowerCase() ===
          this.formGroupSeacherTestDetailDictionary.value.language.toLowerCase(),
      );
      this.formGroupSeacherTestDetailDictionary.patchValue({
        seacherTerm: '',
      });
      this.recognizeCategory(result);
    }
  }

  recognizeCategory(data: any) {
    if (this.testId === '6' || this.testId === '7') {
      this.dataTestDictionary.category1 = data.filter((item: any) => item.categoryType === 1);
      this.dataTestDictionary.category2 = data.filter((item: any) => item.categoryType === 2);
      this.dataTestDictionary.category3 = data.filter((item: any) => item.categoryType === 3);
      this.dataTestDictionary.category4 = data.filter((item: any) => item.categoryType === 4);
      this.dataTestDictionary.category5 = data.filter((item: any) => item.categoryType === 5);
      this.dataTestDictionary.category6 = data.filter((item: any) => item.categoryType === 6);
      this.dataTestDictionary.category7 = data.filter((item: any) => item.categoryType === 7);
      this.dataTestDictionary.category8 = data.filter((item: any) => item.categoryType === 8);
    } else {
      this.dataTestDictionary.withoutCategory = data;
    }
  }

  removeSeacherTerm() {
    this.seracherTerm = '';
    this.formGroupSeacherTestDetailDictionary.patchValue({
      seacherTerm: '',
    });
    //this.seracherTerm = null;
    this.getTestDictionaryDetail();
  }

  addParameter() {
    const body = this.checkNumberOfParams(this.formGroupAddParameter.value.parameter.trim());
    const flatValidation = this.validationAddingParameterForm(body);
    if (flatValidation === true) {
      this.testDictionaryService.addConfirmedParameter(this.testId, false, body).subscribe(
        (res: any) => {
          if (res) {
            this.succsessMsg = true;
            setTimeout(() => {
              this.formGroupAddParameter.reset();
            }, 3000);
            this.addLogicalParameter(body);
          } else {
            this.showToastError('This keyword already exists.', 'errorMessageTestDictionaryDetail');
          }
        },
        () => {
          this.showToastError('This keyword already exists.', 'errorMessageTestDictionaryDetail');
        },
      );
    } else {
      this.checkKindError(this.testId, body);
    }
    setTimeout(() => {
      this.succsessMsg = false;
      this.resetParameterError();
      this.resetParameterAdding();
    }, 3000);
  }

  checkKindError(id: string, body: any) {
    if (id === '6' || id === '7') {
      if (body[0] && body[0].categoryType === 0) {
        this.showToastError('You should choose a category', 'errorMessageTestDictionaryDetail');
      } else {
        this.showToastError('This keyword already exists.', 'errorMessageTestDictionaryDetail');
      }
    } else {
      this.showToastError('This keyword already exists.', 'errorMessageTestDictionaryDetail');
    }
  }

  checkNumberOfParams(params: string) {
    const arr = params.split('/');
    const arrParams = [];
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        arrParams.push({
          language: this.formGroupSeacherTestDetailDictionary.value.language,
          parameter: arr[i].trim(),
          categoryType:
            this.formGroupAddParameter.value.category &&
              this.formGroupAddParameter.value.category.value
              ? this.formGroupAddParameter.value.category.value
              : 0,
        });
      }
    }
    return arrParams;
  }

  addLogicalParameter(dataForm: any) {
    for (let i = 0; i < dataForm.length; i++) {
      switch (dataForm[i].language.toUpperCase()) {
        case 'EN':
          this.addingInCategory(dataForm[i], this.arrParameters.En);
          break;
        case 'FR':
          this.addingInCategory(dataForm[i], this.arrParameters.Fr);
          break;
        case 'PO':
          this.addingInCategory(dataForm[i], this.arrParameters.Po);
          break;
        case 'SP':
          this.addingInCategory(dataForm[i], this.arrParameters.Sp);
          break;
      }
    }
    this.checkLanguages();
  }

  addingInCategory(data: any, arr: any) {
    if (data.categoryType === 'Opinion_of_the_auditor') {
      arr.category1.push(data);
      const idxArr = arr.category1.findIndex((parameter: any) => parameter.parameter === data.parameter);
      this.dataTestDictionary.adding.category1 = this.dataTestDictionary.category1;
      this.dataTestDictionary.adding.category1[idxArr].status = 'adding';
    }
    if (data.categoryType === 'Statements_of_cash_receipts_and_payments') {
      arr.category2.push(data);
      const idxArr2 = arr.category2.findIndex(
        (parameter: any) => parameter.parameter === data.parameter,
      );
      this.dataTestDictionary.adding.category2 = this.dataTestDictionary.category2;
      this.dataTestDictionary.adding.category2[idxArr2].status = 'adding';
    }
    if (data.categoryType === 'Statement_of_Cumulative_Investments') {
      arr.category3.push(data);
      const idxArr3 = arr.category3.findIndex(
        (parameter: any) => parameter.parameter === data.parameter,
      );
      this.dataTestDictionary.adding.category3 = this.dataTestDictionary.category3;
      this.dataTestDictionary.adding.category3[idxArr3].status = 'adding';
    }
    if (data.categoryType === 'Notes_to_the_financial_statement') {
      arr.category4.push(data);
      const idxArr4 = arr.category4.findIndex(
        (parameter: any) => parameter.parameter === data.parameter,
      );
      this.dataTestDictionary.adding.category4 = this.dataTestDictionary.category4;
      this.dataTestDictionary.adding.category4[idxArr4].status = 'adding';
    }
    if (data.categoryType === 'Disclaimer_of_opinion') {
      arr.category5.push(data);
      const idxArr5 = arr.category5.findIndex(
        (parameter: any) => parameter.parameter === data.parameter,
      );
      this.dataTestDictionary.adding.category5 = this.dataTestDictionary.category5;
      this.dataTestDictionary.adding.category5[idxArr5].status = 'adding';
    }
    if (data.categoryType === 'Adverse') {
      arr.category6.push(data);
      const idxArr6 = arr.category6.findIndex(
        (parameter: any) => parameter.parameter === data.parameter,
      );
      this.dataTestDictionary.adding.category6 = this.dataTestDictionary.category6;
      this.dataTestDictionary.adding.category6[idxArr6].status = 'adding';
    }
    if (data.categoryType === 'Qualified') {
      arr.category7.push(data);
      const idxArr7 = arr.category7.findIndex(
        (parameter: any) => parameter.parameter === data.parameter,
      );
      this.dataTestDictionary.adding.category7 = this.dataTestDictionary.category7;
      this.dataTestDictionary.adding.category7[idxArr7].status = 'adding';
    }
    if (data.categoryType === 'Clean') {
      arr.category8.push(data);
      const idxArr8 = arr.category8.findIndex(
        (parameter: any) => parameter.parameter === data.parameter,
      );
      this.dataTestDictionary.adding.category8 = this.dataTestDictionary.category8;
      this.dataTestDictionary.adding.category8[idxArr8].status = 'adding';
    }
    if (data.categoryType === 0) {
      arr.withoutCategory.push(data);
      const idxArr4 = arr.withoutCategory.findIndex(
        (parameter: any) => parameter.parameter === data.parameter,
      );
      this.dataTestDictionary.adding.withoutCategory = this.dataTestDictionary.withoutCategory;
      this.dataTestDictionary.adding.withoutCategory[idxArr4].status = 'adding';
    }
    return arr;
  }

  validationAddingParameterForm(dataForm: any) {
    let flat = true;
    flat = this.findDuplicates(dataForm);
    let idxParameter = -1;
    if (flat) {
      for (let i = 0; i < dataForm.length; i++) {
        if (dataForm[i].language && dataForm[i].parameter) {
          if (
            (!dataForm[i].categoryType || dataForm[i].categoryType === 0) &&
            (this.testId === '6' || this.testId === '7')
          ) {
            flat = false;
            break;
          } else {
            idxParameter = this.checkIfExist(dataForm[i]);
            if (flat) {
              flat = idxParameter > -1 ? false : true;
            }
          }
        } else {
          flat = false;
          break;
        }
      }
    }
    return flat;
  }

  findDuplicates(data: any) {
    const resultado = Array.from(new Set(data.map((s: any) => s.parameter))).map((parameter) => {
      return {
        parameter: data.find((s: any) => s.parameter === parameter),
      };
    });
    if (resultado.length === data.length) {
      return true;
    } else {
      return false;
    }
  }

  checkIfExist(data: any) {
    let checkRepitParameter: number;
    this.checkLanguages();
    switch (data.categoryType) {
      case 'Opinion_of_the_auditor':
        const obj1 = this.dataTestDictionary.category1.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.category1 = this.dataTestDictionary.category1;
        if (obj1 > -1) {
          this.dataTestDictionary.error.category1[obj1].status = 'error';
        }
        checkRepitParameter = obj1;
        break;
      case 'Statements_of_cash_receipts_and_payments':
        const obj2 = this.dataTestDictionary.category2.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.category2 = this.dataTestDictionary.category2;
        if (obj2 > -1) {
          this.dataTestDictionary.error.category2[obj2].status = 'error';
        }
        checkRepitParameter = obj2;
        break;
      case 'Statement_of_Cumulative_Investments':
        const obj3 = this.dataTestDictionary.category3.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.category3 = this.dataTestDictionary.category3;
        if (obj3 > -1) {
          this.dataTestDictionary.error.category3[obj3].status = 'error';
        }
        checkRepitParameter = obj3;
        break;
      case 'Notes_to_the_financial_statement':
        const obj4 = this.dataTestDictionary.category4.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.category4 = this.dataTestDictionary.category4;
        if (obj4 > -1) {
          this.dataTestDictionary.error.category4[obj4].status = 'error';
        }
        checkRepitParameter = obj4;
        break;
      case 'Disclaimer_of_opinion':
        const obj5 = this.dataTestDictionary.category5.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.category5 = this.dataTestDictionary.category5;
        if (obj5 > -1) {
          this.dataTestDictionary.error.category5[obj5].status = 'error';
        }
        checkRepitParameter = obj5;
        break;
      case 'Adverse':
        const obj6 = this.dataTestDictionary.category6.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.category6 = this.dataTestDictionary.category6;
        if (obj6 > -1) {
          this.dataTestDictionary.error.category6[obj6].status = 'error';
        }
        checkRepitParameter = obj6;
        break;
      case 'Qualified':
        const obj7 = this.dataTestDictionary.category7.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.category7 = this.dataTestDictionary.category7;
        if (obj7 > -1) {
          this.dataTestDictionary.error.category7[obj7].status = 'error';
        }
        checkRepitParameter = obj7;
        break;
      case 'Clean':
        const obj8 = this.dataTestDictionary.category8.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.category8 = this.dataTestDictionary.category8;
        if (obj8 > -1) {
          this.dataTestDictionary.error.category8[obj8].status = 'error';
        }
        checkRepitParameter = obj8;
        break;
      default:
        const obj9 = this.dataTestDictionary.withoutCategory.findIndex(
          (item: any) =>
            this.parseSpecialChar(item.parameter.trim().toLowerCase()) ===
            this.parseSpecialChar(data.parameter.trim().toLowerCase()),
        );
        this.dataTestDictionary.error.withoutCategory = this.dataTestDictionary.withoutCategory;
        if (obj9 > -1) {
          this.dataTestDictionary.error.withoutCategory[obj9].status = 'error';
        }
        checkRepitParameter = obj9;
        break;
    }
    return checkRepitParameter;
  }

  parseSpecialChar(data: string) {
    let parseParams = data;
    if (parseParams.toLowerCase().indexOf('á')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[àáâãäå]', 'g'), 'a');
    }
    if (parseParams.toLowerCase().indexOf('é')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[èéêë]', 'g'), 'e');
    }
    if (parseParams.toLowerCase().indexOf('í')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[ìíîï]', 'g'), 'i');
    }
    if (parseParams.toLowerCase().indexOf('ó')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[òóôõö]', 'g'), 'o');
    }
    if (parseParams.toLowerCase().indexOf('ú')) {
      parseParams = parseParams.toLowerCase().replace(new RegExp('[ùúûü]', 'g'), 'u');
    }
    return parseParams;
  }

  resetParameterError() {
    for (let i = 0; i < this.dataTestDictionary.error.category1.length; i++) {
      if (this.dataTestDictionary.error.category1[i].status === 'error') {
        this.dataTestDictionary.error.category1[i].status = null;
      }
    }
    for (let i = 0; i < this.dataTestDictionary.error.category2.length; i++) {
      if (this.dataTestDictionary.error.category2[i].status === 'error') {
        this.dataTestDictionary.error.category2[i].status = null;
      }
    }

    for (let i = 0; i < this.dataTestDictionary.error.category3.length; i++) {
      if (this.dataTestDictionary.error.category3[i].status === 'error') {
        this.dataTestDictionary.error.category3[i].status = null;
      }
    }

    for (let i = 0; i < this.dataTestDictionary.error.category4.length; i++) {
      if (this.dataTestDictionary.error.category4[i].status === 'error') {
        this.dataTestDictionary.error.category4[i].status = null;
      }
    }
    for (let i = 0; i < this.dataTestDictionary.error.category5.length; i++) {
      if (this.dataTestDictionary.error.category5[i].status === 'error') {
        this.dataTestDictionary.error.category5[i].status = null;
      }
    }
    for (let i = 0; i < this.dataTestDictionary.error.category6.length; i++) {
      if (this.dataTestDictionary.error.category6[i].status === 'error') {
        this.dataTestDictionary.error.category6[i].status = null;
      }
    }
    for (let i = 0; i < this.dataTestDictionary.error.category7.length; i++) {
      if (this.dataTestDictionary.error.category7[i].status === 'error') {
        this.dataTestDictionary.error.category7[i].status = null;
      }
    }
    for (let i = 0; i < this.dataTestDictionary.error.category8.length; i++) {
      if (this.dataTestDictionary.error.category8[i].status === 'error') {
        this.dataTestDictionary.error.category8[i].status = null;
      }
    }
    for (let i = 0; i < this.dataTestDictionary.error.withoutCategory.length; i++) {
      if (this.dataTestDictionary.error.withoutCategory[i].status === 'error') {
        this.dataTestDictionary.error.withoutCategory[i].status = null;
      }
    }
  }

  resetParameterAdding() {
    for (let i = 0; i < this.dataTestDictionary.adding.category1.length; i++) {
      if (this.dataTestDictionary.adding.category1[i].status === 'adding') {
        this.dataTestDictionary.adding.category1[i].status = null;
        this.dataTestDictionary.adding.category1 = [];
      }
    }
    for (let i = 0; i < this.dataTestDictionary.adding.category2.length; i++) {
      if (this.dataTestDictionary.adding.category2[i].status === 'adding') {
        this.dataTestDictionary.adding.category2[i].status = null;
        this.dataTestDictionary.adding.category2 = [];
      }
    }

    for (let i = 0; i < this.dataTestDictionary.adding.category3.length; i++) {
      if (this.dataTestDictionary.adding.category3[i].status === 'adding') {
        this.dataTestDictionary.adding.category3[i].status = null;
        this.dataTestDictionary.adding.category3 = [];
      }
    }

    for (let i = 0; i < this.dataTestDictionary.adding.category4.length; i++) {
      if (this.dataTestDictionary.adding.category4[i].status === 'adding') {
        this.dataTestDictionary.adding.category4[i].status = null;
        this.dataTestDictionary.adding.category4 = [];
      }
    }
    for (let i = 0; i < this.dataTestDictionary.adding.category5.length; i++) {
      if (this.dataTestDictionary.adding.category5[i].status === 'adding') {
        this.dataTestDictionary.adding.category5[i].status = null;
        this.dataTestDictionary.adding.category5 = [];
      }
    }
    for (let i = 0; i < this.dataTestDictionary.adding.category6.length; i++) {
      if (this.dataTestDictionary.adding.category6[i].status === 'adding') {
        this.dataTestDictionary.adding.category6[i].status = null;
        this.dataTestDictionary.adding.category6 = [];
      }
    }
    for (let i = 0; i < this.dataTestDictionary.adding.category7.length; i++) {
      if (this.dataTestDictionary.adding.category7[i].status === 'adding') {
        this.dataTestDictionary.adding.category7[i].status = null;
        this.dataTestDictionary.adding.category7 = [];
      }
    }
    for (let i = 0; i < this.dataTestDictionary.adding.category8.length; i++) {
      if (this.dataTestDictionary.adding.category8[i].status === 'adding') {
        this.dataTestDictionary.adding.category8[i].status = null;
        this.dataTestDictionary.adding.category8 = [];
      }
    }
    for (let i = 0; i < this.dataTestDictionary.adding.withoutCategory.length; i++) {
      if (this.dataTestDictionary.adding.withoutCategory[i].status === 'adding') {
        this.dataTestDictionary.adding.withoutCategory[i].status = null;
        this.dataTestDictionary.adding.withoutCategory = [];
      }
    }
  }

  showToastError(summary: string, key: string) {
    this.messageToastService.showToastError('error', summary, key);
  }
}
