import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TestDescriptionsService } from '@core/test-descriptions/test-descriptions.service';
import { MessageToastService } from '@core/messages/message-toast.service';
/*import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';*/

@Component({
  selector: 'bid-test-descriptions-table',
  templateUrl: './test-descriptions-table.component.html',
  styleUrls: ['./test-descriptions-table.component.scss'],
})
export class TestDescriptionsTableComponent implements OnInit {
  @Input() headerTable: any;
  @Input() bodyTable: any;
  formTestDescriptions!: FormGroup;
  formTestNames!: FormGroup;
  showTextArea: boolean = false;
  row: number = 0;
  display: boolean;
  id: number = 0;
  index: number = 0;
  returnLastTxt: string = "";
  returnLastNameTxt: string= "";
  bodyParameterAdding: string= "";
  rowSelected: number = 0;

  //horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  //verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private testDescriptionsService: TestDescriptionsService,
    private messageToastService: MessageToastService,
    //private _snackBar: MatSnackBar,
  ) {
    this.display = false;
  }

  ngOnInit() {
    this.generateDinamicForm(this.bodyTable);
  }

  generateDinamicForm(bodyTable: any) {
    const group: any = {};
    const textareaTestDescriptions = 'textareaTestDescriptions-';
    const textareaTestNames = 'textareaTestNames-';
    if (bodyTable && bodyTable.length > 1) {
      for (let i = 0; i <= bodyTable.length; i++) {
        group[textareaTestDescriptions + i] = new FormControl('');
        group[textareaTestNames + i] = new FormControl('');
      }
      this.formTestDescriptions = new FormGroup(group);
      this.formTestNames = new FormGroup(group);
    }
  }

  updateTest(i: number, data:any) {
    this.showTextArea = true;
    this.row = i;
    this.returnLastTxt = data.description;
    this.returnLastNameTxt = data.name;
  }

  closeTextArea(i: number) {
    this.display = true;
    this.rowSelected = i;
  }

  confirmEditing() {
    this.display = false;
    this.showTextArea = true;
  }

  disclaimerEdition() {
    this.formTestDescriptions.controls['textareaTestDescriptions-' + this.rowSelected].setValue(
      this.returnLastTxt,
    );
    this.formTestNames.controls['textareaTestNames-' + this.rowSelected].setValue(
      this.returnLastNameTxt,
    );
    this.showTextArea = false;
    this.display = false;
    this.row = -1;
  }

  returnLastText(data: any, i: number) {
    this.formTestDescriptions.controls['textareaTestDescriptions-' + i].setValue(
      this.returnLastTxt,
    );
    this.formTestNames.controls['textareaTestNames-' + i].setValue(this.returnLastNameTxt);
  }

  confirmSaveData(data: any, i: number) {
    this.id = data.id;
    this.row = i;
    this.index = i;
    this.bodyParameterAdding = data.description;
    this.testDescriptionsService
      .updateTestDictionary(this.id, this.bodyParameterAdding, data.name)
      .subscribe(
        (res: any) => {
          this.showTextArea = false;
          if (res) {
            this.showToastSuccess();
            this.row = -1;
          } else {
            this.showToastError(
              'An error was encountered when loading the data, please try again later 15',
              'errorMessageTestDescriptions',
            );
            this.formTestDescriptions.controls['textareaTestDescriptions-' + this.index].setValue(
              this.returnLastTxt,
            );
            this.formTestNames.controls['textareaTestNames-' + this.index].setValue(
              this.returnLastNameTxt,
            );
          }
        },
        () => {
          this.showTextArea = false;
          this.showToastError(
            'An error was encountered when loading the data, please try again later 16',
            'errorMessageTestDescriptions',
          );
          this.formTestDescriptions.controls['textareaTestDescriptions-' + this.index].setValue(
            this.returnLastTxt,
          );
          this.formTestNames.controls['textareaTestNames-' + this.index].setValue(
            this.returnLastNameTxt,
          );
        },
      );
  }

  toggleDialog() {
    this.display = !this.display;
  }

  showToastSuccess() {
    /*this._snackBar.open('Update Successfully', '✔', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
      panelClass: ['green-snackbar'],
    });*/
    this.messageToastService.showToastError('success', '', 'Update Successfully');
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }
}
