import { languages } from './../../shared/constants/languages.const';
import { Component, OnInit, DoCheck } from '@angular/core';
import { headersTables } from '../../shared/constants/table-header.const';
import { MessageToastService } from '@core/messages/message-toast.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TestDictionaryService } from '@core/test-dictionary/test-dictionary.service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { StepperService } from '@core/stepper/stepper.service';
import { LanguageService } from '@core/language/language.service';
import { ShowHistoricalSearchService } from '@core/history/show-historical-search.service';

@Component({
  selector: 'bid-test-dictionary',
  templateUrl: './test-dictionary.component.html',
  styleUrls: ['./test-dictionary.component.scss'],
})
export class TestDictionaryComponent implements OnInit, DoCheck {
  languageCombo: any;
  languageSelected: any;
  headerTableTestDictionary: any;
  formGroupSeacherTestDictionary!: FormGroup;
  testDictionaryTable: any;
  seracherTerm: string = '';
  disableSeacherTerm: boolean = false;
  STEP0: any = [
    { name: 'generateNewAnalysis', stepSelected: false },
    { name: 'afsReview', stepSelected: false, disabled: true },
    { name: 'score', stepSelected: false, disabled: true },
    { name: 'icrReview', stepSelected: false, disabled: true },
  ];
  showHistoricalSearch: boolean;
  keepFilter: boolean = false;

  _messageToastService: any;
  _testDictionaryService: any;
  _stepperService: any;
  _languageService: any;
  _showHistoricalSearchService: any;

  constructor(
    private messageToastService: MessageToastService,
    private testDictionaryService: TestDictionaryService,
    //private spinner: NgxSpinnerService,
    private stepperService: StepperService,
    private languageService: LanguageService,
    private showHistoricalSearchService: ShowHistoricalSearchService,
  ) {
    this.showHistoricalSearch = false;
    this._messageToastService= messageToastService;
    this._testDictionaryService= testDictionaryService;
    this._stepperService= stepperService;
    this._languageService= languageService;
    this._showHistoricalSearchService= showHistoricalSearchService;
  }

  ngOnInit() {
    //this.spinner.show();
    this.stepperService.setStepper(this.STEP0);
    this.showHistoricalSearchService.setShowHistoricalSearch(false);
    this.languageCombo = languages;
    this.headerTableTestDictionary = headersTables.headerTestDictionaryTable;
    this.formGroupSeacherTestDictionary = new FormGroup({
      language: new FormControl(this.languageCombo[0].value, Validators.required),
      seacherTerm: new FormControl('', Validators.required),
    });
    this.keepFilter = false;
    this.getLanguage();
    this.checkLanguages();
  }

  ngDoCheck() {
    /*this.showHistoricalSearchService.getShowHistoricalSearch().subscribe((res: any) => {
      this.showHistoricalSearch = res;
    });*/
  }

  getLanguage() {
    this.languageService.returnLang().subscribe((res: any) => {
      if (res) {
        this.formGroupSeacherTestDictionary.patchValue({
          language: res,
        });
      }
    });
  }

  checkLanguages() {
    const lang =
      this.formGroupSeacherTestDictionary.value.language !== undefined
        ? this.formGroupSeacherTestDictionary.value.language
        : 'En';
    this.languageService.setLang(lang);
    this.testDictionaryService.getTestDictionarySuggested(lang).subscribe(
      (res: any) => {
        this.testDictionaryTable = res;
        if (this.keepFilter === true && this.formGroupSeacherTestDictionary.value.seacherTerm) {
          this.formGroupSeacherTestDictionary.patchValue({
            seacherTerm: '',
          });
          this.seracherTerm = '';
          this.seacherByTerm();
        } else if (this.disableSeacherTerm === true) {
          this.disableSeacherTerm = false;
          this.seracherTerm = '';
          this.formGroupSeacherTestDictionary.patchValue({
            seacherTerm: '',
          });
          this.keepFilter = false;
        }
        //this.spinner.hide();
      },
      () => {
        //this.spinner.hide();
        this.showToastError(
          'An error was encountered when loading the data, please try again later 4',
          'errorMessageTestDictionary',
        );
      },
    );
  }

  checkLanguagesCombo() {
    this.keepFilter = false;
    this.checkLanguages();
  }

  seacherByTerm() {
    if (this.formGroupSeacherTestDictionary.value.seacherTerm !== '') {
      this.disableSeacherTerm = true;
      this.seracherTerm = this.formGroupSeacherTestDictionary.value.seacherTerm;
      this.testDictionaryTable = this.testDictionaryTable.filter(
        (testDictionary: any) =>
          testDictionary.name
            .toLowerCase()
            .replace(/\s/g, '')
            .indexOf(
              this.formGroupSeacherTestDictionary.value.seacherTerm
                .toLowerCase()
                .replace(/\s/g, ''),
            ) > -1 ||
          testDictionary.id === parseInt(this.formGroupSeacherTestDictionary.value.seacherTerm, 0),
      );
      this.formGroupSeacherTestDictionary.patchValue({
        seacherTerm: '',
      });
    } else {
      this.disableSeacherTerm = false;
    }
  }

  removeSeacherTerm() {
    this.seracherTerm = '';
    this.formGroupSeacherTestDictionary.patchValue({
      seacherTerm: '',
    });
 
    this.disableSeacherTerm = false;
    this.checkLanguages();
  }

  resetFilter(data: any) {
    if (data === true) {
      this.disableSeacherTerm = false;
      const auxTerm = this.seracherTerm;
      this.keepFilter = data;
      this.checkLanguages();
      this.formGroupSeacherTestDictionary.value.seacherTerm = auxTerm;
    }
  }

  showToastError(summary: string, detail: string) {
    this.messageToastService.showToastError('error', summary, detail);
  }
}
