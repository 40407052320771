import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { headersTables } from '../../shared/constants/table-header.const';
import { ImagenService } from './imagen.service';

// eslint-disable-next-line
//pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class ExportPdfService {
  headerTableAFS = headersTables.headerAFSTable;
  // eslint-disable-next-line
  constructor(private imagenService: ImagenService) {}

  layoutPDF(data:any, analysisId: string) {
    const dd: any = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: [20, 100, 20, 50],
      header: {
        image: this.imagenService.imgHeader,
        height: 90,
        width: 840,
        margin: [0, 0, 0, 0],
      },
      footer: (currentPage: any, pageCount: any) => {
        return {
          table: {
            margin: [0, 0, 0, 0],
            widths: ['100%'],
            body: [
              [
                {
                  text: currentPage.toString() + '/' + pageCount,
                  alignment: 'center',
                  style: 'normalText',
                  margin: [0, 20, 0, 20],
                },
              ],
            ],
          },
          layout: 'lightHorizontalLines',
        };
      },
      content: [
        {
          image: this.imagenService.leyenda,
          margin: [0, 0, 0, 0],
          height: 70,
          width: 600,
        },
        {
          layout: {
            // eslint-disable-next-line
            paddingTop: (i: number, node: any) => {
              return 5;
            },
            // eslint-disable-next-line
            paddingBottom: (i: number, node: any) => {
              return 5;
            },
            // eslint-disable-next-line
            hLineColor: (i: number, node: any) => {
              return '#CCCCCC';
            },
            // eslint-disable-next-line
            vLineColor: (i: number, node: any) => {
              return '#CCCCCC';
            },
            // eslint-disable-next-line
            hLineWidth: (i: number, node: any) => {
              return 1;
            },
            // eslint-disable-next-line
            vLineWidth: (i: number, node: any) => {
              return 1;
            },
          },
          table: {
            width: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: this.generateBodyPdf(data),
          },
        },
      ],
      defaultStyle: {
        fontSize: 9,
        bold: false,
      },
    };
    const registro = Date.now();
    return pdfMake.createPdf(dd).download('Luca-' + analysisId + '-' + registro + '.pdf');
  }

  generateBodyPdf(data: any) {
    const bodyDataPdf = [];
    bodyDataPdf.push(this.generateHeader(this.headerTableAFS));
    let cont = 0;
    let oddOrEvent = false;
    let checkColorLetter = '';
    for (const row of data) {
      const rowObject = [];
      oddOrEvent = cont % 2 === 0 ? true : false;
      if (row.answer_luca === 'Manual') {
        checkColorLetter = '#82838b';
      } else {
        checkColorLetter =
          row.answerFms === 'No' || row.answer_luca === 'No' ? '#dc3545' : '#004d72';
      }
      rowObject.push(this.generateRowFieldCheckBullets(row.type, oddOrEvent));
      rowObject.push(this.generateRowField(row.test_id, oddOrEvent, checkColorLetter));
      rowObject.push(this.generateRowField(row.name, oddOrEvent, checkColorLetter));
      rowObject.push(this.generateRowField(row.answer_luca, oddOrEvent, checkColorLetter));
      rowObject.push(this.generateRowField(row.parameter_luca, oddOrEvent, checkColorLetter));
      rowObject.push(this.generateRowField(row.answerFms, oddOrEvent, checkColorLetter));
      rowObject.push(this.generateRowField(row.parameterFms, oddOrEvent, checkColorLetter));
      rowObject.push(this.generateRowField(row.suggestedParameters, oddOrEvent, checkColorLetter));
      bodyDataPdf.push(rowObject);
      cont = cont + 1;
    }
    return bodyDataPdf;
  }

  generateRowFieldCheckBullets(headerText: string, oddOrEvent: boolean): object {
    switch (headerText) {
      case 'Init':
        return {
          image: this.imagenService.bulletsBlue,
          height: 10,
          width: 10,
          fillColor: oddOrEvent === true ? '#f0fafe' : '#ffffff',
          border: [false, false, false, false],
        };
      case 'Init_pass':
        return {
          image: this.imagenService.bulletsBlue,
          height: 10,
          width: 10,
          fillColor: oddOrEvent === true ? '#f0fafe' : '#ffffff',
          border: [false, false, false, false],
        };
      case 'A_pass':
        return {
          image: this.imagenService.bulletsTwoColor,
          height: 10,
          width: 10,
          fillColor: oddOrEvent === true ? '#f0fafe' : '#ffffff',
          border: [false, false, false, false],
        };
      case 'A':
        return {
          image: this.imagenService.bulletsBrown,
          height: 10,
          width: 10,
          fillColor: oddOrEvent === true ? '#f0fafe' : '#ffffff',
          border: [false, false, false, false],
        };
      case 'B':
        return {
          image: this.imagenService.bulletsGreen,
          height: 10,
          width: 10,
          fillColor: oddOrEvent === true ? '#f0fafe' : '#ffffff',
          border: [false, false, false, false],
        };
      case 'C':
        return {
          image: this.imagenService.bulletsYellow,
          height: 10,
          width: 10,
          fillColor: oddOrEvent === true ? '#f0fafe' : '#ffffff',
          border: [false, false, false, false],
        };
      default:
        return {
          image: this.imagenService.bulletsError2,
          height: 10,
          width: 10,
          fillColor: oddOrEvent === true ? '#f0fafe' : '#ffffff',
          border: [false, false, false, false],
        };
    }
  }

  generateHeader(headerTexts: any): Array<object> {
    const header = [];
    for (const headerText of headerTexts) {
      header.push(this.generateHeaderField(headerText.header));
    }
    return header;
  }

  generateHeaderField(headerText: string): object {
    return {
      text: headerText,
      fillColor: '#5c84c3',
      color: 'white',
      fontSize: 10,
      border: [false, false, false, false],
    };
  }

  generateRowField(rowField: string, oddOrEvent: boolean, checkColorLetter: string): any {
    const value = rowField && rowField !== undefined ? rowField : '-';
    return {
      text: value,
      fillColor: oddOrEvent === true ? '#f0fafe' : '#ffffff',
      color: checkColorLetter,
      border: [false, false, false, false],
    };
  }
}
