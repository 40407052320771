import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { IUploadResultDto } from '@bl-interfaces/iupload-result-dto';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  obs$: Subject<Observable<IUploadResultDto>>;
  ezsharedParameter: string | undefined;
  constructor(private httpClient: HttpClient) {
    this.obs$ = new Subject();
  }

  uploadFile(
    url: string,
    opNumber: string,
    file: File | null,
    ezsharedParameter: string,
  ): Observable<IUploadResultDto> {
    let req: string;
    const fileData = new FormData();
    if (file === null) 
    {
      req = `?operationNumber=${opNumber}&documentNumber=${ezsharedParameter}`;
      return this.httpClient.post<IUploadResultDto>(url + req, null);
    } 
    else
    {
      fileData.append('file', file);
      req = `?documentName=${file.name}&operationNumber=${opNumber}&auditYear=2019
      &searchParameters=${ezsharedParameter}`;
      return this.httpClient.post<IUploadResultDto>(url + req, fileData);
    }
  }


  uploadFileV2(
    url: string,
    file: File,
  ): Observable<any> {
    const fileData = new FormData();
    fileData.append('file', file);
    return this.httpClient.post<any>(url, fileData);
  }

  documentSearch(operationNumber: string): Observable<any> {
    return this.httpClient.get(
      environment.api.baseUpload + 'documents' + '?OperationNumber=' + operationNumber,
    );
  }
}
