import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScoreService {
  // eslint-disable-next-line
  constructor(private http: HttpClient) {}

  getScoreRanges(): Observable<any> {
    return this.http.get(environment.api.base + 'scores/ranges');
  }

  updateScore(data: any, scoreId: string): Observable<any> {
    return this.http.put(environment.api.base + 'scores/' + scoreId, data);
  }
}
