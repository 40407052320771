<!--ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner-->
<p-toast class="bl-toast bl-toast--error" key="errorMessageIcrDictionary" closable="true"></p-toast>
<!--bid-seacher *ngIf="showHistoricalSearch === true"></bid-seacher-->
<div class="bl-users">
  <div class="bl-wrapper">
    <!-- <h2 class="bl-title">
      <span>ICR dictionary</span>
    </h2> -->
    <div class="bl-selectbuttonMenu">
      <p-selectButton
        [options]="pages"
        [(ngModel)]="selectedOptionMenu"
        (onChange)="rediredTo($event)"
      ></p-selectButton>
    </div>
    <form [formGroup]="seacherDictionaryWordForm">
      <div class="bl-content bl-content--no-padding-bottom">
        <div class="bl-select__width-small">
          <div class="bl-select">
            <label class="bl-select__label bl-select__label--big"
              >Select Dictionary (language)</label
            >
            <p-dropdown
              [options]="languageCombo"
              formControlName="language"
              defaultLabel="select"
              styleClass="bl-dropdown"
              (onChange)="getDictionary()"
            ></p-dropdown>
          </div>
        </div>
        <div class="bl-searcher-term bl-searcher-term--heigth">
          <div class="bl-input">
            <label class="bl-input__label bl-input__label--big">Search keyword</label>
            <div class="bl-searcher-term__input ui-inputgroup">
              <input
                type="text"
                formControlName="seacherWord"
                (keydown.enter)="seacherByTerm()"
                pInputText
                [attr.disabled]="searcherTerm"
                placeholder="Sample: Project documents"
              />
              <button (click)="seacherByTerm()" [disabled]="searcherTerm" pButton type="button">
                <i class="fas fa-search"></i>
              </button>
            </div>
            <div *ngIf="searcherTerm" class="bl-searcher-term__term-seacher-content">
              <span class="bl-searcher-term__term-seacher">
                {{ searcherTerm }}
                <a (click)="removeSeacherTerm()" class="bl-searcher-term__icon-remove"
                  ><i class="fas fa-times"></i
                ></a>
              </span>
            </div>
          </div>
          <div
            *ngIf="seacherResult && seacherResult.length === 0 && flatSearchTerm === true"
            class="bl-searcher-term__text-no-found-result"
          >
            <span
              ><i class="fas fa-exclamation-circle"></i> Sorry we didn't find any results matching
              this search, please replace and try again.</span
            >
          </div>
        </div>
      </div>
      <div class="bl-content bl-content--inline bl-content--no-padding-bottom">
        <div class="bl-select__width-medium">
          <div class="bl-select">
            <label class="bl-select__label bl-select__label--big"
              >Select Bucket Classification</label
            >
            <p-dropdown
              [options]="selectBucket"
              formControlName="bucket"
              defaultLabel="select"
              styleClass="bl-dropdown"
              (onChange)="filterBy()"
            ></p-dropdown>
          </div>
        </div>
        <div class="bl-content__select">
          <label class="bl-manage-finding__risk-label">Select a risk to filter keywords:</label>
          <div class="bl-selectbuttons">
            <p-selectButton
              [options]="menu"
              formControlName="risk"
              [(ngModel)]="selectedRisk"
              (onChange)="filterBy()"
            ></p-selectButton>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="bl-background bl-background--anchor bl-background--light-blue">
    <div class="bl-wrapper">
      <form [formGroup]="createDictionaryWordForm">
        <div class="bl-content bl-content--inline">
          <div class="col-lg-4 col-md-4 bl-content__padding">
            <div
              class="bl-input"
              [ngClass]="{
                'bl-input--error': existingWord && existingWord.length > 0
              }"
            >
              <label class="bl-input__label bl-input__label--big">Create a new keywords</label>
              <input
                type="text"
                formControlName="word"
                class="bl-input__input-text bl-input__input-text--big"
                pInputText
              />
              <span class="bl-input__acalaration">Please enter parameters separate with / </span>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 bl-content__padding">
            <label class="bl-finding-form__label_inline--bold">Select a risk</label>
            <span class="bl-dictionary__required"> (Required)</span>
            <div class="bl-radiobutton bl-radiobutton--space-bottom">
              <div class="bl-radiobutton__block-line">
                <p-radioButton name="risk" value="High" formControlName="risk"></p-radioButton>
                <label class="bl-radiobutton__label">High</label>
              </div>
              <div class="bl-radiobutton__block-line--mrg-left">
                <p-radioButton name="risk" value="Low" formControlName="risk"></p-radioButton>
                <label class="bl-radiobutton__label">Low</label>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 bl-content__padding">
            <div class="bl-content__button--max">
              <button (click)="addNewWord()" class="bl-button--btn-secundary">Add keywords</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="bl-wrapper">
    <div class="col-9 bl-content__padding">
      <bid-area-chips
        [chips]="chips"
        [existingWord]="existingWord"
        [addedWord]="addedWord"
        (reloadData)="getData($event)"
      >
      </bid-area-chips>
    </div>
    <div class="col-3 bl-content__padding">
      <div
        *ngIf="seacherResult && seacherResult.length > 0"
        class="bl-dictionary__seacher-result-box"
      >
        <div class="bl-dictionary__seacher-result-box-header">
          <span>All search results</span>
        </div>
        <div
          class="bl-dictionary__seacher-result-pointer"
          *ngFor="let item of seacherResult; let i = index"
          (click)="selectParamenterResult(i, item)"
          [ngClass]="{
            'bl-dictionary__seacher-result-pointer--selected': selectedItem === 'item' + i
          }"
        >
          <div class="bl-dictionary__seacher-result-box-inline">
            <span class="bl-dictionary__seacher-result-box-butcket">{{
              item.bucketClassification
            }}</span>
            <span
              *ngIf="selectedItem === 'item' + i"
              class="bl-dictionary__seacher-result-box-selected"
              >SELECTED</span
            >
          </div>
          <div class="bl-dictionary__seacher-result-box-inline">
            <ul class="bl-dictionary__seacher-result-box-list-parameter">
              <li *ngFor="let param of item.parameter; let z = index">
                <i
                  [ngClass]="{
                    'bl-dictionary__seacher-result-box-bullets-low': param.risk === 'Low',
                    'bl-dictionary__seacher-result-box-bullets-high': param.risk === 'High'
                  }"
                ></i>
                <span class="bl-dictionary__seacher-result-box-text">{{ param.name }}</span>
              </li>
            </ul>
            <span
              class="bl-dictionary__seacher-result-box-icon-visible"
              [ngClass]="{
                'bl-dictionary__seacher-result-box-icon-visible--active':
                  selectedItem === 'item' + i
              }"
            >
              <i class="fas fa-eye"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
