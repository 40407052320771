<p-toast
  class="bl-toast bl-toast--error"
  key="errorMessageTestDictionaryDetail"
  closable="true"
></p-toast>
<p-dialog
  header="Remove parameters(s)"
  [(visible)]="display"
  [modal]="true"
  [responsive]="true"
  [maximizable]="false"
  [baseZIndex]="10000"
  [closable]="false"
  styleClass="bl-modal"
  [positionTop]="65"
  [positionLeft]="390"
>
  <p>Do you want to remove the parameter ? This operation cannot be undone , it is irreversible.</p>
  <p-footer>
    <button type="button" pButton (click)="cancelRemoveParameter()" label="Cancel"></button>
    <button
      type="button"
      pButton
      (click)="confirmRemoveWordsDctionary()"
      label="Yes, Remove"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>
<p-panel
  *ngIf="header"
  header="{{ header }}"
  [toggleable]="true"
  expandIcon="pi pi-chevron-down"
  collapseIcon="pi pi-chevron-up"
>
  <ul class="bl-chips__wrapper">
    <li *ngFor="let chip of chips; let i = index" class="bl-chips__dictionary-list">
      <span
        class="bl-chips__dictionary"
        [ngClass]="{
          'bl-chips__red': existingParameter[i]?.status === 'error',
          'bl-chips__green': addingParameter[i]?.status === 'adding'
        }"
      >
        {{ chip.parameter }}
        <a (click)="removeWordsDctionary(chips, chip, i)" class="bl-chips__icon-remove">
          <i class="fas fa-times"></i>
        </a>
      </span>
    </li>
  </ul>
</p-panel>

<div *ngIf="!header" class="bl-chips__area-content bl-chips__area-content--small-heigth">
  <ul class="bl-chips__wrapper">
    <li *ngFor="let chip of chips; let i = index" class="bl-chips__dictionary-list">
      <span
        class="bl-chips__dictionary"
        [ngClass]="{
          'bl-chips__red': existingParameter[i]?.status === 'error',
          'bl-chips__green': addingParameter[i]?.status === 'adding'
        }"
      >
        {{ chip.parameter }}
        <a (click)="removeWordsDctionary(chips, chip, i)" class="bl-chips__icon-remove"
          ><i class="fas fa-times"></i
        ></a>
      </span>
    </li>
  </ul>
</div>
