<p-toast class="bl-toast bl-toast--success" key="successFormTestDictionary"></p-toast>
<p-toast class="bl-toast bl-toast--error" key="errorFormTestDictionary" closable="true"></p-toast>
<p-dialog
  header="Reject parameters(s)"
  [(visible)]="display"
  [modal]="true"
  [responsive]="true"
  [maximizable]="false"
  [baseZIndex]="10000"
  [closable]="false"
  styleClass="bl-modal"
  [positionTop]="65"
  [positionLeft]="390"
>
  <p>
    Do you want to reject the selected parameter(s) ? This operation cannot be undone , it is
    irreversible.
  </p>
  <p-footer>
    <button type="button" pButton (click)="cancelRejectParameter()" label="Cancel"></button>
    <button
      type="button"
      pButton
      (click)="rejectWordsDctionary()"
      label="Yes, Reject"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>

<fieldset>
  <legend class="hide-accessible">Form of test</legend>
  <div class="bl-table bl-table--test-dictionary">
    <p-table [columns]="headerTable" [value]="bodyTable" [responsive]="true" sortMode="multiple">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            [ngClass]="{
              sortable: col.field === 'type' || col.field === 'id' || col.field === 'name'
            }"
            *ngFor="let col of columns"
            [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="
              col.field === 'suggestedParameters' || col.field === 'dictionary'
            "
            [ngStyle]="{ width: col.width }"
          >
            <span *ngIf="col.field !== 'parameterFms'"> {{ col.header }}</span>
            <span *ngIf="col.field === 'parameterFms'">
              {{ col.header }}
              <a
                class="bl-input__tooltip bl-tooltip__down"
                pTooltip=""
                tooltipPosition="top"
                [escape]="false"
              >
                <i class="fas fa-info-circle"></i>
              </a>
            </span>
            <p-sortIcon
              *ngIf="col.field === 'type' || col.field === 'id' || col.field === 'name'"
              [field]="col.field"
              ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order"
              ariaLabelAsc="Activate to sort in ascending order"
            >
            </p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
        <tr>
          <td *ngFor="let col of columns">
            <div [ngSwitch]="col.field">
              <div *ngSwitchCase="'type'">
                <a
                  title="Initial analysis"
                  *ngIf="rowData[col.field] === 'Init' || rowData[col.field] === 'Init_pass'"
                  ><i class="bullet-blue"></i
                ></a>

                <a
                  title="A: Basic Elements of the Auditor`s Opinion"
                  *ngIf="rowData[col.field] === 'A'"
                  ><i class="bullet-brown"></i
                ></a>
                <a title="B: Project Financial Statements" *ngIf="rowData[col.field] === 'B'"
                  ><i class="bullet-green"></i
                ></a>
                <a title="C: Notes to Financial Statements" *ngIf="rowData[col.field] === 'C'"
                  ><i class="bullet-yellow"></i
                ></a>
              </div>
              <div *ngSwitchCase="'id'">
                {{ rowData[col.field] }}
              </div>
              <div *ngSwitchCase="'name'">
                <a class="tooltip top"
                  >{{ rowData[col.field] }}
                  <span class="tiptext">{{ rowData[col.field] }}</span>
                </a>
              </div>
              <div *ngSwitchCase="'suggestedParameters'">
                <form [formGroup]="testDictionary">
                  <!--AFS Integrity ò Opinion Type -->
                  <div
                    *ngIf="
                      rowData['name'] === 'AFS Integrity' || rowData['name'] === 'Opinion Type'
                    "
                    class="bl-test-dictionary"
                  >
                    <div>
                      <div *ngFor="let item of rowData[col.field]; let z = index">
                        <div class="bl-test-dictionary__content-form">
                          <div
                            *ngIf="rowData[col.field] !== null && rowData[col.field].length > 0"
                            class="bl-checkbox bl-checkbox--test-dictionary ui-g"
                            style="width: 200px; margin-bottom: 10px"
                          >
                            <!-- *ngFor="let item of rowData[col.field]; let z = index" -->
                            <div class="ui-g-12">
                              <p-checkbox
                                name="parameter1"
                                [formControlName]="'checkboxParameter-' + rowData['id'] + '-' + z"
                                value="{{ item.parameter }}"
                                label="{{ item.parameter }}"
                                (onChange)="
                                  checkRowSelected(
                                    rowData['id'],
                                    'checkboxParameter-' + rowData['id'] + '-' + z
                                  )
                                "
                                required
                              ></p-checkbox>
                            </div>
                          </div>
                          <div class="bl-test-dictionary__content-selects">
                            <!-- *ngFor="let item of rowData[col.field]; let z = index" -->
                            <div class="bl-test-dictionary__select-inline">
                              <div class="bl-test-dictionary__required">
                                <i class="colorRed">*</i>
                              </div>
                              <div class="bl-test-dictionary__select">
                                <div *ngIf="rowData['name'] === 'AFS Integrity'" class="bl-select">
                                  <p-dropdown
                                    [options]="categorySelectAfsIntegity"
                                    [formControlName]="
                                      'selectCategories-' + rowData['id'] + '-' + z
                                    "
                                    dataKey="value"
                                    optionLabel="label"
                                    styleClass="bl-dropdown"
                                    [disabled]="
                                      testDictionary.controls[
                                        'checkboxParameter-' + rowData['id'] + '-' + z
                                      ].value.length !== 1
                                    "
                                    [ngClass]="{ 'bl-select--has-error': shouldShowError(rowData, z) }"
                                  ></p-dropdown>
                                </div>
                                <div *ngIf="rowData['name'] === 'Opinion Type'" class="bl-select">
                                  <p-dropdown
                                    [options]="categorySelectOperationType"
                                    [formControlName]="
                                      'selectCategories-' + rowData['id'] + '-' + z
                                    "
                                    dataKey="value"
                                    optionLabel="label"
                                    styleClass="bl-dropdown"
                                    [disabled]="
                                      testDictionary.controls[
                                        'checkboxParameter-' + rowData['id'] + '-' + z
                                      ].value.length !== 1
                                    "
                                    [ngClass]="{ 'bl-select--has-error': shouldShowError(rowData, z) }"
                                  ></p-dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="rowData['suggestedParameters'].length > 0"
                      class="bl-test-dictionary__icons"
                    >
                      <a
                        (click)="showModalRejectParamenter(rowData['id'], i)"
                        class="bl-test-dictionary__icon-form"
                        [class.disabled]="
                          rowDisabled.indexOf(rowData['id']) === -1 || rowDisabled.length === 0
                        "
                      >
                        <i class="far fa-trash-alt"></i>
                      </a>
                      <a
                        (click)="addingWordsDctionary(rowData['id'])"
                        class="bl-test-dictionary__icon-form"
                        [class.disabled]="
                          rowDisabled.indexOf(rowData['id']) === -1 || rowDisabled.length === 0
                        "
                      >
                        <!-- [class.disabled]="
                      !testDictionary.touched ||
                      (rowData['id'] !== rowDisabled || rowDisabled === -1)
                      " -->
                        <i class="fas fa-check"></i>
                      </a>
                    </div>
                  </div>

                  <!-- END AFS Integrity ò Opinion Type -->
                  <!-- start disting  AFS Integrity ò Opinion Type -->
                  <div
                    *ngIf="
                      rowData['name'] !== 'AFS Integrity' && rowData['name'] !== 'Opinion Type'
                    "
                    class="bl-test-dictionary"
                  >
                    <div class="bl-test-dictionary__content-form">
                      <div
                        *ngIf="rowData[col.field] !== null && rowData[col.field].length > 0"
                        class="bl-checkbox bl-checkbox--test-dictionary ui-g"
                        style="width: 200px; margin-bottom: 10px"
                      >
                        <div *ngFor="let item of rowData[col.field]; let z = index" class="ui-g-12">
                          <p-checkbox
                            name="parameter1"
                            [formControlName]="'checkboxParameter-' + rowData['id'] + '-' + z"
                            value="{{ item.parameter }}"
                            label="{{ item.parameter }}"
                            (onChange)="
                              checkRowSelected(
                                rowData['id'],
                                'checkboxParameter-' + rowData['id'] + '-' + z
                              )
                            "
                            required
                          ></p-checkbox>
                        </div>
                      </div>
                      <div class="bl-test-dictionary__content-selects"></div>
                    </div>
                    <div
                      *ngIf="rowData['suggestedParameters'].length > 0"
                      class="bl-test-dictionary__icons"
                    >
                      <a
                        (click)="showModalRejectParamenter(rowData['id'], i)"
                        class="bl-test-dictionary__icon-form"
                        [class.disabled]="
                          rowDisabled.indexOf(rowData['id']) === -1 || rowDisabled.length === 0
                        "
                      >
                        <i class="far fa-trash-alt"></i>
                      </a>
                      <a
                        (click)="addingWordsDctionary(rowData['id'])"
                        class="bl-test-dictionary__icon-form"
                        [class.disabled]="
                          rowDisabled.indexOf(rowData['id']) === -1 || rowDisabled.length === 0
                        "
                      >
                        <i class="fas fa-check"></i>
                      </a>
                    </div>
                  </div>
                  <!-- End disting  AFS Integrity ò Opinion Type -->
                </form>
              </div>
              <div *ngSwitchCase="'dictionary'">
                <div class="bl-link--border-left">
                  <a
                    (click)="sendRedirectToDetault(rowData.name, rowData.id)"
                    class="bl-link__text"
                  >
                    <i class="fas fa-book"></i>
                    Add or remove parameters
                  </a>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</fieldset>
<!-- </form> -->
