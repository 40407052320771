import { Routes } from '@angular/router';


import { HomeComponent } from '@features/home/home.component';
import { GenerateAnalysisComponent } from "@features/generate-analysis/generate-analysis.component";
import { AfsReviewComponent } from "@features/afs-review/afs-review.component";
import { IcrReviewComponent } from "@features/icr-review/icrReview.component";
import { HistoricalComponent } from "@features/historical/historical.component";
import { ScoreComponent } from "@features/score/score.component";
import { UsersComponent } from "@features/users/users.component";
import { IcrDictionaryComponent } from "@features/icr-dictionary/icr-dictionary.component";
import { IcrDictionaryFindingsComponent } from "@features/icr-dictionary-findings/icr-dictionary-findings.component";
import { TestDictionaryComponent } from "@features/test-dictionary/test-dictionary.component";
import { TestDictionaryDetailComponent } from "@features/test-dictionary-detail/test-dictionary-detail.component";
import { TestDescriptionComponent } from "@features/test-description/test-description.component";
import { ToolsComponent } from "@features/tools/tools.component";
import { DashboardComponent } from "@features/dashboard/dashboard.component";
import { DashboardUsersComponent } from "@features/dashboard-users/dashboard-users.component";
import { AuthRoleGuardGuard } from "./core/guards/auth-role-guard.guard";
import { AdminUsersComponent } from "@features/admin-users/admin-users.component";
import { MsalGuard } from "@azure/msal-angular";

export const routes: Routes = [
    { path: "", redirectTo: "home", pathMatch: "full" },
    { path: "home", component: HomeComponent },
    {
        path: "generate-new-analysis",
        component: GenerateAnalysisComponent,
        //canActivate: [MsalGuard],
    },
    {
        path: "IcrReview",
        component: IcrReviewComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "AfsReview",
        component: AfsReviewComponent,
        canActivate: [MsalGuard],
      },
      { path: "score", component: ScoreComponent, canActivate: [MsalGuard] },
 
      {
        path: "historical",
        component: HistoricalComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "dashboard-users",
        component: DashboardUsersComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [MsalGuard, AuthRoleGuardGuard],
      },
      {
        path: "icr-dictionary",
        component: IcrDictionaryComponent,
        canActivate: [MsalGuard, AuthRoleGuardGuard],
      },
      {
        path: "icr-dictionary-findings",
        component: IcrDictionaryFindingsComponent,
        canActivate: [MsalGuard, AuthRoleGuardGuard],
      },
      {
        path: "test-dictionary",
        component: TestDictionaryComponent,
        canActivate: [MsalGuard, AuthRoleGuardGuard],
      },
      {
        path: "test-dictionary-detail/:test/:id/:lang",
        component: TestDictionaryDetailComponent,
        canActivate: [MsalGuard, AuthRoleGuardGuard],
      },
      {
        path: "test-description",
        component: TestDescriptionComponent,
        canActivate: [MsalGuard, AuthRoleGuardGuard],
      },
      {
        path: "tools",
        component: ToolsComponent,
        canActivate: [MsalGuard, AuthRoleGuardGuard],
      },
      {
        path: "adminUsers",
        component: AdminUsersComponent,
        canActivate: [MsalGuard, AuthRoleGuardGuard],
      },
];
