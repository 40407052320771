<div class="bl-table bl-table--removed-finding">
  <p-table [columns]="headerTable" [value]="bodyTable" [lazy]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          class="historical-search-size"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="
            col.field === 'finding' ||
            col.field === 'operationNumber' ||
            col.field === 'AcceptandEditKeyword' ||
            col.field === 'reviewList'
          "
          [ngStyle]="{ width: col.width }"
        >
          {{ col.header }}
          <p-sortIcon
            *ngIf="
              col.field === 'creationDate' || col.field === 'risk' || col.field === 'auditPeriod'
            "
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div [ngSwitch]="col.field">
            <div *ngSwitchCase="'creationDate'">{{ rowData[col.field] }}</div>
            <div *ngSwitchCase="'risk'">
              <i
                [ngClass]="{
                  'bl-chips__bullets-yellow': rowData[col.field] === 'Low',
                  'bl-chips__bullets-red': rowData[col.field] === 'High'
                }"
              ></i>
              <span class="bl-chips__text">{{ rowData[col.field] }}</span>
            </div>
            <div *ngSwitchCase="'finding'">
              <span class="bl-table__title-content-table">{{ rowData[col.field] }}</span>
              <p class="bl-table__paragraph-content-table">
                {{ rowData['observation'] }}
              </p>
            </div>
            <div *ngSwitchCase="'operationNumber'">
              {{ rowData[col.field] }}
            </div>
            <div *ngSwitchCase="'auditPeriod'">{{ rowData[col.field] }}</div>
            <div *ngSwitchCase="'keyWordsSuggested'">
              <ul *ngFor="let item of rowData[col.field]">
                <li>{{ item.word }}</li>
              </ul>
            </div>
            <div *ngSwitchCase="'AcceptandEditKeyword'">
              <div class="bl-table__edit-suggested-parameter">
                <div class="bl-table__edit-suggested-icon">
                  <i class="fas fa-trash-restore"></i>
                </div>
                <button class="bl-button--btn-secundary bl-button--small-size" type="submit">
                  Edit keywords
                </button>
              </div>
            </div>
            <div *ngSwitchCase="'reviewList'">
              <div class="bl-table__icon-check-row bl-table__icon-check-row--margin-top-removed">
                <i class="fas fa-check-double"></i>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
