export const headersTables = {
  headerHistorical: [
    { field: 'country', header: 'Country', width: '8%' },
    { field: 'auditDateRange', header: 'Audit Period', width: '9%' },
    { field: 'operationNumber', header: 'Loan number', width: '10%' },
    { field: 'projectName', header: 'Project name', width: '11%' },
    { field: 'user', header: 'AFS reviewed by', width: '11%' },
    { field: 'reviewDate', header: 'Analysis Date', width: '10%' },
    { field: 'summaryReport', header: 'Summary Report', width: '15%' },
    { field: 'afs', header: 'Documents', width: '12%' },
    { field: 'delete', header: '', width: '5%' },
    { field: 'view', header: '', width: '4%' },
  ],
  headerAFSTable: [
    { field: 'type', header: 'TT', width: '5%' },
    { field: 'test_id', header: 'Test #', width: '6%' },
    { field: 'name', header: 'Test name', width: '9%' },
    { field: 'answer_luca', header: 'Answer LUCA', width: '10%' },
    { field: 'parameter_luca', header: 'Parameter LUCA', width: '14%' },
    { field: 'answerFms', header: 'Answer FMS', width: '9%' },
    { field: 'parameterFms', header: 'Parameter FMS', width: '17%' },
    {
      field: 'suggestedParameters',
      header: 'Enter new parameters. If there`s more than one separate them with /',
      width: '19%',
    },
  ],
  headerUpdateActionPlanTable: [
    { field: 'item_id', header: 'Item', width: '5%' },
    { field: 'finding', header: 'Finding', width: '21%' },
    { field: 'recommendation', header: 'Recommendation', width: '21%' },
    { field: 'actionsDE', header: 'Actions defined by the Executor', width: '24%' },
    { field: 'responsable', header: 'Responsable', width: '10%' },
    { field: 'estimatedID', header: 'Estimated implementation date', width: '13%' },
    { field: 'meansV', header: 'Means of verification', width: '16%' },
  ],
  headerActionPlanTable: [
    { field: 'item_id', header: 'Item', width: '5%' },
    { field: 'finding', header: 'Finding', width: '21%' },
    { field: 'recommendation', header: 'Recommendation', width: '21%' },
    { field: 'actionsDE', header: 'Actions defined by the Executor', width: '24%' },
    { field: 'responsable', header: 'Responsable', width: '10%' },
    { field: 'estimatedID', header: 'Estimated implementation date', width: '13%' },
    { field: 'meansV', header: 'Means of verification', width: '16%' },
  ],
  headerMyActionPlanTable: [
    { field: 'operation_id', header: 'Operation number', width: '7%' },
    { field: 'operationNumber', header: 'Loan Number', width: '10%' },
    { field: 'projectName', header: 'Project name', width: '30%' },
    { field: 'auditPeriod', header: 'Audit period', width: '12%' },
    { field: 'status', header: 'Status', width: '8%' },
    { field: 'view', header: '', width: '5%' },
  ],
  headerScoreTable: [
    { field: 'type', header: 'TT', width: '4%' },
    { field: 'test_id', header: 'Test #', width: '5%' },
    { field: 'name', header: 'Test name', width: '8%' },
    { field: 'answer_luca', header: 'Answer LUCA', width: '8%' },
    { field: 'parameter_luca', header: 'Parameter LUCA', width: '17%' },
    { field: 'answerFms', header: 'Answer FMS', width: '8%' },
    { field: 'parameterFms', header: 'Parameter FMS', width: '17%' },
  ],
  headerTestDictionaryTable: [
    { field: 'type', header: 'TT', width: '2%' },
    { field: 'id', header: 'Test #', width: '3%' },
    { field: 'name', header: 'Test name', width: '4%' },
    {
      field: 'suggestedParameters',
      header: 'Accept or reject the suggested parameters',
      width: '14%',
    },
    { field: 'dictionary', header: 'Access to dictionary', width: '8%' },
  ],
  headerUsersTable: [
    { field: 'userType', header: 'Role', width: '5%' },
    { field: 'displayName', header: 'Name', width: '8%' },
    { field: 'mail', header: 'Email', width: '8%' },
    { field: 'department', header: 'Department', width: '15%' },
  ],
  headerDesciptionTable: [
    { field: 'type', header: 'TT', width: '2%' },
    { field: 'id', header: 'Test #', width: '2%' },
    { field: 'name', header: 'Test name', width: '5%' },
    { field: 'description', header: 'Test Descriptions', width: '10%' },
    { field: 'crud', header: '', width: '7%' },
  ],
  headerToolsTable: [
    { field: 'date', header: 'Date', width: '2%' },
    { field: 'hour', header: 'Hour', width: '3%' },
    { field: 'runmode', header: 'Run mode', width: '3%' },
  ],
  headerAddedFindingsTable: [
    { field: 'creationDate', header: 'Creation Date', width: '3%' },
    { field: 'risk', header: 'Risk', width: '2%' },
    { field: 'finding', header: 'Findings', width: '4%' },
    { field: 'operationNumber', header: 'Loan number', width: '3%' },
    { field: 'auditPeriod', header: 'Audit period', width: '3%' },
    {
      field: 'AcceptandEditKeyword',
      header: 'Accept and edit keywords',
      width: '9%',
    },
    { field: 'reviewList', header: 'Review and remove from list', width: '3%' },
  ],
  headerRemovedFindingsTable: [
    { field: 'creationDate', header: 'Creation Date', width: '3%' },
    { field: 'risk', header: 'Risk', width: '2%' },
    { field: 'finding', header: 'Findings / Observations', width: '4%' },
    { field: 'operationNumber', header: 'Loan number', width: '3%' },
    { field: 'auditPeriod', header: 'Audit period', width: '3%' },
    {
      field: 'keyWordsSuggested',
      header: 'Keyword suggested LUCA',
      width: '3%',
    },
    {
      field: 'AcceptandEditKeyword',
      header: 'Include and edit keywords',
      width: '5%',
    },
    { field: 'reviewList', header: 'Review and remove from list', width: '3%' },
  ],
  headerSelectEZshareTable: [
    { field: 'radio', header: '', width: '5%' },
    { field: 'documentNumber', header: 'Document ID', width: '20%' },
    { field: 'siscorNumber', header: 'SISCO Number', width: '20%' },
    { field: 'documentName', header: 'Document Name', width: '25%' },
    { field: 'date', header: 'Date', width: '15%' },
    { field: 'user', header: 'User', width: '15%' },
  ],
};
